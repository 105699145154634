import { Box } from '@sefar/design-system'
import { Heading } from './components/heading'
import { StepsProgress } from './components/steps-progress'
import { BottomActionsBar } from './components/bottom-actions-bar'
import { useOrderSteps } from './hooks/use-order-steps'
import { useEffect } from 'react'
import { useOrderState } from './hooks/use-order-state'

export const CreatePurchaseRequisition = () => {
  const { hasNextStep, StepComponent } = useOrderSteps()
  const { reset } = useOrderState()
  useEffect(() => {
    reset()
  }, [])
  return (
    <Box
      css={{
        d: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pb: '$40'
      }}
    >
      <Heading />
      <StepsProgress />
      <StepComponent />
      {hasNextStep && <BottomActionsBar />}
    </Box>
  )
}
