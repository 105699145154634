import {
  Body5,
  Body7,
  Box,
  Button,
  Checkbox,
  Dialog,
  EditIcon20,
  Grid,
  ReactSelect,
  SelectOption,
  Space,
  Spinner,
  StyledInputLabel,
  TextArea,
  Video
} from '@sefar/design-system'
import React, { useEffect, useState } from 'react'
import { components, OptionProps } from 'react-select'
import {
  FileMeta,
  getNewlyAddedFileMetaInitialState
} from '../../pages/news-details/helpers'
import { FileTypeEnum, getFileType } from '../../api'
import { ImageCropper } from '../common/ImageCropper'
import { useTranslate } from '../../hooks/useTranslate'

export function FileInfoDialog({
  file,
  meta: initialMeta,
  open,
  src,
  onInsert,
  setOpen,
  onCancel,
  categories,
  countries
}: {
  meta?: FileMeta
  file?: File
  open: boolean
  src?: string
  onInsert: (meta: FileMeta, updatedFile?: File) => void
  setOpen: (open: boolean) => void
  onCancel?: () => void
  categories: SelectOption[]
  countries: SelectOption[]
}) {
  const { t } = useTranslate()
  const [meta, setMeta] = useState<FileMeta>(
    getNewlyAddedFileMetaInitialState()
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [imgSrc, setImgSrc] = useState<string>()
  const [editedImgFile, setEditedImgFile] = useState<File>()
  const type: FileTypeEnum = getFileType(file)

  const onMetaForNewFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMeta((oldMeta: any) => ({
      ...oldMeta,
      [e.target.name]: e.target.value
    }))
  }

  useEffect(() => {
    setIsLoading(false)

    if (!open) {
      if (imgSrc) {
        URL.revokeObjectURL(imgSrc)
      }
      setMeta(getNewlyAddedFileMetaInitialState())
    }
  }, [open])

  useEffect(() => {
    if (initialMeta && open) {
      setMeta(initialMeta)
    }
  }, [initialMeta, open])

  useEffect(() => {
    setImgSrc(src || (file && URL.createObjectURL(file)))
  }, [file, src])

  const Option = (props: OptionProps<SelectOption>) => {
    return (
      <components.Option {...props}>
        <Checkbox
          checked={props.isSelected}
          onChange={() => null}
          label={props.label}
        />
      </components.Option>
    )
  }

  return (
    <Dialog
      title={
        initialMeta?.category?.length
          ? 'Edit media metadata'
          : 'Complete inserting media'
      }
      setOpenControlledDialog={setOpen}
      open={open}
      contentProps={{ css: { width: 720 } }}
      footer={
        <Space
          css={{
            d: 'flex',
            justifyContent: 'flex-end',
            gap: '$3'
          }}
        >
          <Button
            variant="secondary"
            data-cy="cancelFileInfoDialog"
            onClick={() => {
              setOpen(false)
              onCancel && onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            data-cy="insertFileInfoDialog"
            disabled={!meta?.category?.length}
            onClick={() => {
              setIsLoading(true)
              onInsert(meta, editedImgFile)
              setMeta(getNewlyAddedFileMetaInitialState())
            }}
          >
            Insert
          </Button>
        </Space>
      }
    >
      <Body5 css={{ color: '$neutral' }}>
        {`${
          initialMeta?.category?.length ? 'Edit metadata of' : 'Add metadata to'
        } the uploaded media. This helps when searching for it in the library.`}
      </Body5>
      <Space as={Grid} my="SM">
        <Box
          css={{
            gc: '1 / span 4',
            gcMd: '1 / span 5',
            position: 'relative'
          }}
        >
          <Box
            as={
              type === FileTypeEnum.Images
                ? 'img'
                : type === FileTypeEnum.Video
                  ? Video
                  : 'iframe'
            }
            src={imgSrc}
            alt="media file preview"
            css={{
              display: 'block',
              objectFit: 'contain',
              width: '100%',
              maxWidth: '100%',
              height: 'auto',
              transition: '$default'
            }}
          />
          <Body7
            css={{ mt: '$2', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {file?.name}
          </Body7>
          {type === FileTypeEnum.Images && (
            <ImageCropper
              originalImageFileUrl={imgSrc}
              trigger={
                <Button
                  disabled={isLoading}
                  variant="secondary"
                  css={{
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    width: 38,
                    height: 38,
                    padding: 0
                  }}
                >
                  <Box as="span" className="icon">
                    <EditIcon20 />
                  </Box>
                </Button>
              }
              onSave={(imageFile) => {
                setImgSrc(URL.createObjectURL(imageFile))
                setEditedImgFile(imageFile)
              }}
            />
          )}
        </Box>
        <Box
          css={{
            gc: '1 / span 12',
            gcMd: '6 / span 7',
            d: 'flex',
            flexDirection: 'column',
            gap: '$5',
            '@md': {
              flex: '0 1 50%'
            }
          }}
        >
          <Box css={{ d: 'flex', gap: '$2', flexDirection: 'column' }}>
            <StyledInputLabel htmlFor="category">Category*</StyledInputLabel>
            <ReactSelect
              isMulti
              value={categories?.filter(({ value }) =>
                meta?.category?.includes(value)
              )}
              components={{ Option }}
              id="category"
              maxMenuHeight={220}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              css={{ width: '100%' }}
              options={categories}
              name="category"
              placeholder="Select one or more categories."
              onChange={(options) => {
                onMetaForNewFileChange({
                  target: {
                    name: 'category',
                    value: options.map(({ value }: SelectOption) => value)
                  }
                } as React.ChangeEvent<HTMLInputElement>)
              }}
            />
          </Box>
          {countries?.length ? (
            <Box
              css={{
                d: 'flex',
                gap: '$2',
                flexDirection: 'column'
              }}
            >
              <StyledInputLabel htmlFor="country">Country</StyledInputLabel>
              <ReactSelect
                isMulti
                value={countries?.filter(({ value }) =>
                  meta?.country?.includes(value)
                )}
                components={{ Option }}
                id="country"
                maxMenuHeight={180}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                css={{ width: '100%' }}
                options={countries}
                name="country"
                placeholder="Select one or more countries."
                onChange={(options) => {
                  onMetaForNewFileChange({
                    target: {
                      name: 'country',
                      value: options.map(({ value }: SelectOption) => value)
                    }
                  } as React.ChangeEvent<HTMLInputElement>)
                }}
              />
            </Box>
          ) : (
            <></>
          )}
          <TextArea
            value={meta?.keywords}
            label="Add keywords to facilitate the search"
            id="keywords"
            name="keywords"
            placeholder="Add keywords to facilitate the search"
            onChange={onMetaForNewFileChange}
          />
          {(type === FileTypeEnum.Images || type === FileTypeEnum.Video) && (
            <TextArea
              value={meta?.copyright}
              label="Add copyright info"
              id="copyright"
              name="copyright"
              placeholder="Add copyright info"
              onChange={onMetaForNewFileChange}
            />
          )}
        </Box>

        {isLoading && (
          <Box
            css={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '$cardBackgroundBlur'
            }}
          >
            <Spinner />
          </Box>
        )}
      </Space>
    </Dialog>
  )
}
