import { useMedia } from 'react-use'
import {
  BREAKPOINTS_QUERIES,
  styled
} from '../../../../../../../stitches.config'
import React, {
  ComponentProps,
  ForwardedRef,
  forwardRef,
  ReactNode
} from 'react'
import {
  Box,
  Input,
  SearchIcon20,
  CloseIcon16,
  FilterIcon20
} from '@sefar/design-system'

type TComponentSearchProps = Omit<ComponentProps<typeof Input>, 'onChange'> & {
  small?: boolean
  onChange: (value: string) => void
  onCancel?: any
  placeholderTextLg?: string
  placeholderTextSm?: string
  withOptions?: boolean
  OptionsButton?: ReactNode
  onOptionsClick?: any
  onSearchIconClick?: any
  onBlur?: any
  onFocus?: any
}

const CloseIcon = styled(CloseIcon16, { cursor: 'pointer', marginRight: '0px' })

export const SearchInput = forwardRef(
  (
    {
      small = false,
      value,
      onChange,
      onCancel,
      placeholderTextLg,
      placeholderTextSm,
      withOptions = false,
      OptionsButton,
      onOptionsClick,
      onSearchIconClick,
      onBlur,
      onFocus,
      ...props
    }: TComponentSearchProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const isMd = useMedia(BREAKPOINTS_QUERIES.md)
    const isLg = useMedia(BREAKPOINTS_QUERIES.lg)

    const onCancelHandler = () => {
      onChange('')
      onCancel && onCancel()
    }

    return (
      <Input
        autoComplete="off"
        placeholder={
          isMd
            ? (placeholderTextLg ?? 'Search')
            : (placeholderTextSm ?? 'Search')
        }
        startEnhancer={
          <Box
            as={SearchIcon20}
            onClick={onSearchIconClick}
            css={{ cursor: onSearchIconClick ? 'pointer' : 'auto' }}
          />
        }
        /* @ts-ignore */
        endEnhancer={
          <Box
            css={{
              d: 'flex',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <Box
              css={{
                d: 'flex',
                transitionProperty: 'background-color',
                borderRadius: '50%',
                mr: '-$2',
                py: small ? 0 : '$2',
                px: value && !small ? '$2' : 0,
                '&:hover': { backgroundColor: small ? '' : '$neutralLighten93' }
              }}
            >
              <CloseIcon
                data-cy="clearSearchInputButton"
                css={{ w: value ? 'auto' : 0 }}
                onClick={() => onCancelHandler()}
              />
            </Box>
            {(withOptions || OptionsButton) && !isLg && (
              <>
                <Box
                  as="span"
                  css={{
                    borderRight: '1px solid $neutralLighten80',
                    height: '100%',
                    mx: '$3'
                  }}
                />
                {OptionsButton || (
                  <Box as="button" onClick={onOptionsClick}>
                    <FilterIcon20 />
                  </Box>
                )}
              </>
            )}
          </Box>
        }
        value={value}
        id="search-input"
        containerProps={{ css: { width: '100%' } }}
        inputContainerProps={{
          css: {
            borderRadius: 26,
            '&:hover, &:focus-within, &.__hover, &.__focus': {
              backgroundColor: '$white'
            }
          }
        }}
        onChange={({ currentTarget }) => onChange(currentTarget.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={ref}
        {...props}
      />
    )
  }
)
