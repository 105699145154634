import {
  BackLink,
  Body2,
  Body3,
  Body5,
  Box,
  Button,
  Container,
  ContainerPadding,
  Grid,
  Heading1,
  Line,
  Space
} from '@sefar/design-system'
import { AnimatePresence, motion } from 'framer-motion'
import { ArticleInfo } from '../../components/article/article-info'
import { EditorStyles } from '../../components/TipTap/TipTap'
import { EditorContent, useEditor } from '@tiptap/react'
import React, { useEffect, useRef, useState } from 'react'
import { SPACE_ARTICLE_TYPE, SpaceArticle, useSpaces } from '../../api'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TipTapLink from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import VideoExtension from '../../components/TipTap/components/video'
import FileInContent from '../../components/TipTap/components/FileInContentUserView'
import { styled } from '../../../../../../stitches.config'
import { Link, Params, useLocation, useSearchParams } from 'react-router-dom'
import { Figure } from '../../components/TipTap/components/FigureUserView'
import { useLocationStateFrom } from '../../hooks/useLocationStateFrom'
import {
  ArticleComments,
  getUserFullName,
  mixpanelAnalyticsHelper
} from '../../components'
import { subSpacePageUrl } from '../../app'
import { useTranslate } from '../../hooks/useTranslate'
import { Sidebar } from '../../components/sidebar'
import { useBookmarksWithDetails } from './hooks/use-bookmarks-with-details'

const NewsArticleDetailsCard = styled('article', {
  pt: '$6',
  color: '$neutral'
})

const AnimatedNewsArticleDetailsCard = motion(NewsArticleDetailsCard)

export function SpaceArticleDetailsView({
  article,
  notAllowedToEdit,
  params,
  setSearchParams
}: {
  article: SpaceArticle
  notAllowedToEdit: boolean
  params: Readonly<Params<string>>
  setSearchParams: ReturnType<typeof useSearchParams>[1]
}) {
  const { t } = useTranslate()
  const commentsBox = useRef<HTMLHeadingElement>(null)
  const location = useLocation()
  const [enable, setEnable] = useState<boolean>(false)
  const from = useLocationStateFrom(
    [article],
    ([article]) =>
      `/${subSpacePageUrl}/${article?.subspace?.spaceId}/${article?.subspace.id}`
  )

  const [collapsed, setCollapsed] = useState(false)
  const { bookmarks, isBookmarksLoading } = useBookmarksWithDetails()
  const { spaces, isLoading: isLoadingSpace } = useSpaces(
    article?.subspace?.spaceId
  )

  const scrollToComments = () => {
    if (commentsBox.current) {
      commentsBox.current.scrollIntoView()
    }
  }

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TipTapLink,
      Image,
      Figure,
      VideoExtension,
      FileInContent
    ],
    content: article?.text,
    editable: false
  })

  useEffect(() => {
    if (article?.text) {
      editor?.commands.setContent(article?.text)
      const authorFullName = getUserFullName(
        article.author?.firstName,
        article.author?.lastName
      )
      mixpanelAnalyticsHelper().track('Subspace Article viewed', {
        subspaceArticleId: article.id,
        subspaceArticleTitle: article.title,
        subspaceArticleAuthor: authorFullName,
        subspaceArticleLanguage: article.lang,
        subspaceId: article.subspace?.id
      })
    }
  }, [article?.text])

  return (
    <Box css={{ d: 'flex', flexDirection: 'column', height: '100%' }}>
      <ContainerPadding css={{ flex: 1 }}>
        <Container size="large">
          <AnimatePresence>
            {article && (
              <Box
                css={{
                  d: 'flex',
                  flexDirection: 'column-reverse',
                  justifyContent: 'center',
                  '@lg': { flexDirection: 'row' }
                }}
              >
                <Sidebar
                  isCollapsed={collapsed}
                  toggleCollapse={() => setCollapsed(!collapsed)}
                  isLoading={isLoadingSpace || isBookmarksLoading}
                  space={spaces?.[0]}
                  bookmarks={bookmarks}
                  topSpacing
                />
                <Box css={{ flex: 3, p: '$6' }}>
                  <AnimatedNewsArticleDetailsCard
                    transition={{
                      type: 'spring',
                      bounce: 0,
                      duration: 0.2
                    }}
                    initial={enable ? { opacity: 0 } : {}}
                    animate={{ opacity: 1 }}
                    exit={enable ? { opacity: 0 } : {}}
                  >
                    <Space mb="3XL">
                      <Grid>
                        <Box
                          css={{
                            gc: '1 / span 12',
                            gcSm: '1 / span 12',
                            d: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          {from && (
                            <Box css={{ d: 'flex' }}>
                              <BackLink
                                as={Link}
                                paths={[
                                  article?.subspace?.title || t('field_spaces')
                                ]}
                                to={from}
                                css={{ mb: '$10', '@sm': { mb: '$7' } }}
                              />
                            </Box>
                          )}

                          <Box
                            css={{
                              d: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              mb: '$8',
                              '@sm': {
                                mb: '$4'
                              }
                            }}
                          >
                            <Body5
                              css={{
                                color: '$neutralLighten30'
                              }}
                            >
                              {article.date}
                            </Body5>

                            {!notAllowedToEdit && (
                              <Button
                                variant="secondary"
                                onClick={() =>
                                  setSearchParams(
                                    { editMode: 'true' },
                                    { replace: true, state: location.state }
                                  )
                                }
                              >
                                Edit Article
                              </Button>
                            )}
                          </Box>

                          <Heading1 css={{ mb: '$4' }}>
                            {article.title}
                          </Heading1>
                          <Body2
                            dangerouslySetInnerHTML={{ __html: article.lead }}
                          />
                        </Box>
                        <Box
                          css={{
                            gc: '1 / span 12',
                            gcSm: '1 / span 12'
                          }}
                        >
                          <Space mb="LG">
                            <Line mt="MD" mb="XS" />
                            <ArticleInfo
                              article={article}
                              scrollToComments={scrollToComments}
                              withLikes={false}
                            />
                          </Space>
                        </Box>
                      </Grid>
                      <Grid>
                        <Body3
                          size="xl"
                          fontWeight="light"
                          css={{
                            gc: '1 / span 12',
                            gcSm: '1 / span 12',
                            ...EditorStyles
                          }}
                        >
                          <EditorContent editor={editor} />
                        </Body3>
                      </Grid>
                    </Space>
                  </AnimatedNewsArticleDetailsCard>
                </Box>
              </Box>
            )}
          </AnimatePresence>
        </Container>
      </ContainerPadding>

      <div ref={commentsBox}>
        {params?.articleId && (
          <ArticleComments
            articleId={params.articleId}
            type={SPACE_ARTICLE_TYPE}
            commentCount={article?.commentCount}
          />
        )}
      </div>
    </Box>
  )
}
