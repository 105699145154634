import { Box, Button, ButtonWithLoading } from '@sefar/design-system'
import {
  MAP_STEP_NAME_TO_LABEL_KEY,
  PurchaseRequisitionStepsEnum
} from '../consts'
import { useOrderState } from '../hooks/use-order-state'
import { useOrderSteps } from '../hooks/use-order-steps'
import { useTranslate } from '../../../../hooks/useTranslate'

export const BottomActionsBar = () => {
  const { t } = useTranslate()
  const {
    nextStepKey,
    goToNextStep,
    goToPrevStep,
    saveAsDraft,
    currentStepKey,
    loading
  } = useOrderSteps()
  const { order } = useOrderState()

  return (
    <Box
      css={{
        position: 'fixed',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '$inlineEditingBottomToolbar',
        px: '$55',
        py: '$5',
        d: 'flex',
        justifyContent: 'space-between',
        gap: '$4',
        background: '$neutralLighten20',
        zIndex: 100
      }}
    >
      <Button variant="secondary" onClick={goToPrevStep}>
        {t('field_back')}
      </Button>
      <Box css={{ d: 'flex', gap: '$4' }}>
        {currentStepKey === PurchaseRequisitionStepsEnum.OVERVIEW && (
          <Button variant="secondary" onClick={saveAsDraft}>
            {order.status === 'DRAFT'
              ? t('field_update_draft')
              : t('field_save_as_draft')}
          </Button>
        )}
        <ButtonWithLoading onClick={goToNextStep} loading={loading}>
          {t('field_next')}: {t(MAP_STEP_NAME_TO_LABEL_KEY[nextStepKey])}
        </ButtonWithLoading>
      </Box>
    </Box>
  )
}
