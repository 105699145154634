import React from 'react'
import { Box, CloseIcon20 } from '@sefar/design-system'
import { Link, useNavigate } from 'react-router-dom'
import { ideasPageUrl } from '../../app'
import { useCanNavigateBack } from '../../hooks/use-can-navigate-back'

export const CloseButton = () => {
  const canNavigateBack = useCanNavigateBack()
  const navigate = useNavigate()

  return (
    <Box
      as={Link}
      to={`/${ideasPageUrl}`}
      onClick={(e) => {
        if (canNavigateBack) {
          e.preventDefault()
          navigate(-1)
        }
      }}
      css={{
        d: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '$2',
        border: '1px solid $neutralLighten80',
        cursor: 'pointer',
        color: 'inherit'
      }}
    >
      <CloseIcon20 />
    </Box>
  )
}
