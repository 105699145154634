import React, { ComponentProps } from 'react'
import * as ReactSlider from '@radix-ui/react-slider'
import { styled } from '../../../../../stitches.config'

const StyledSlider = styled(ReactSlider.Root, {
  position: 'relative',
  d: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  touchAction: 'none',
  width: '100%',

  '&[data-orientation=horizontal]': {
    height: 20
  },
  '&[data-orientation=vertical]': {
    flexDirection: 'column',
    width: 20,
    height: 100
  }
})
const StyledSliderTrack = styled(ReactSlider.Track, {
  backgroundColor: '$neutralLighten80',
  position: 'relative',
  flexGrow: 1,
  borderRadius: '$round',
  '&[data-orientation=horizontal]': {
    height: 3
  },
  '&[data-orientation=vertical]': {
    width: 3
  }
})
const StyledSliderRange = styled(ReactSlider.Range, {
  position: 'absolute',
  backgroundColor: '$primaryBlue',
  borderRadius: '$round%',
  height: '100%'
})
const StyledSliderThumb = styled(ReactSlider.Thumb, {
  d: 'block',
  position: 'relative',
  cursor: 'pointer',
  transition: '$default',
  size: 20,
  backgroundColor: '$primaryBlue',
  borderRadius: '$round',
  '&:hover, &:focus': {
    '&::after': {
      content: '',
      d: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)',
      size: '200%',
      background: '$primaryBlueLighten95',
      borderRadius: '$round',
      zIndex: -1
    }
  },
  '&:focus ': {
    outline: 'none',
    '&::after': {
      background: '$primaryBlueLighten90'
    }
  }
})

export const Slider = (props: ComponentProps<typeof StyledSlider>) => (
  <StyledSlider
    defaultValue={[50]}
    max={100}
    step={1}
    aria-label="Volume"
    {...props}
  >
    <StyledSliderTrack className="SliderTrack">
      <StyledSliderRange className="SliderRange" />
    </StyledSliderTrack>
    <StyledSliderThumb className="SliderThumb" />
  </StyledSlider>
)
