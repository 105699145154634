import React from 'react'
import { Link } from 'react-router-dom'
import { ideasPageUrl } from '../../app'
import IdeaImage from '../../../assets/ideas-management-visual.png'

import {
  ContainerPadding,
  Container,
  Body3,
  Heading2,
  Space,
  TextLink,
  ArrowRightIcon16,
  Box,
  LazyImage
} from '@sefar/design-system'
import { useTranslate } from '../../hooks/useTranslate'

export function IdeaCreateConfirmation() {
  const { t } = useTranslate()
  return (
    <ContainerPadding css={{ flex: 1 }}>
      <Container size="small" css={{ '@lg': { maxWidth: '680px' } }}>
        <Space mt="3XL" mb="SM">
          <Heading2>{t('field_idea_confirm_thank_you')}</Heading2>
        </Space>
        <Space mb="MD">
          <Body3 size="xl">{t('field_idea_confirm_descr_text')}</Body3>
        </Space>
        <Space mb="SM">
          <TextLink as={Link} to={`/${ideasPageUrl}`}>
            <span>{t('field_idea_confirm_back_text')}</span>
            <span className="icon icon-right">
              <ArrowRightIcon16 />
            </span>
          </TextLink>
        </Space>
        <Space mb="XL" css={{ d: 'flex', justifyContent: 'center' }}>
          <Box css={{ '@md': { maxWidth: '440px' } }}>
            <LazyImage src={IdeaImage} aspectRatio="auto" />
          </Box>
        </Space>
      </Container>
    </ContainerPadding>
  )
}
