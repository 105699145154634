import { Body3, Box, Checkbox } from '@sefar/design-system'
import remove from 'lodash/remove'

type CheckboxGroupProps = {
  options: {
    label: string
    value: string
  }[]
  value?: string[]
  onChange: (v: string[]) => void
}

export const CheckboxGroup = ({
  options = [],
  onChange,
  value = []
}: CheckboxGroupProps) => {
  return (
    <Box>
      {options.map((opt) => (
        <Box css={{ my: '$3', cursor: 'pointer' }} key={opt.value}>
          <Checkbox
            label={opt.label}
            value={opt.value}
            checked={value?.includes(opt.value)}
            onCheckedChange={(checked) => {
              const updatedSelection = [...value]
              if (checked) {
                updatedSelection.push(opt.value)
              } else {
                remove(updatedSelection, (val) => val === opt.value)
              }
              onChange(updatedSelection)
            }}
          />
        </Box>
      ))}
    </Box>
  )
}

export const CardsCheckboxGroup = ({
  options = [],
  value = [],
  onChange
}: CheckboxGroupProps) => {
  return (
    <Box css={{ mx: '-$1', d: 'flex', flexDirection: 'row' }}>
      {options.map((opt) => (
        <Box
          key={opt.value}
          css={{
            mx: '$1',
            h: 64,
            flex: '1 1 0',
            d: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid $neutralLighten60',
            borderRadius: 8,
            backgroundColor: value?.includes(opt.value)
              ? '$primaryBlue'
              : '#FFFFFF',
            transition: 'background-color .3s',
            cursor: 'pointer'
          }}
          onClick={() => {
            const updatedSelection = [...value]
            const checked = value?.includes(opt.value)
            if (!checked) {
              updatedSelection.push(opt.value)
            } else {
              remove(updatedSelection, (val) => val === opt.value)
            }
            onChange(updatedSelection)
          }}
        >
          <Body3
            css={{
              fontWeight: 500,
              color: value?.includes(opt.value) ? '#FFFFFF' : 'inherit'
            }}
          >
            {opt.label}
          </Body3>
        </Box>
      ))}
    </Box>
  )
}
