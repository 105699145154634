import { useSearchParams } from 'react-router-dom'
import { useTranslate } from '../../hooks/useTranslate'
import {
  Body4,
  Body5,
  Box,
  Button,
  Container,
  ContainerPadding,
  Dialog,
  DialogClose,
  FilterIcon20,
  FilterUnreadIcon16,
  Heading1,
  Pagination,
  ReactSelect,
  SearchNoResultsIcon48,
  SelectOption,
  Space,
  Spinner,
  Tabs,
  TabsList,
  TabsTrigger
} from '@sefar/design-system'
import React, { useEffect, useMemo, useState } from 'react'
import {
  Idea,
  IdeaGroupStatus,
  IdeaStatusesByGroups,
  useIdeaCategories,
  useIdeas,
  isIdeaManager,
  SEARCH_PAGE_LIMIT,
  IdeaStatuses,
  IdeaLocs
} from '../../api'
import { LastUpdateHeader, Table, TD, TH, TR } from '../my-content/common'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { SearchInput } from '../../components/search/search-input'
import { searchParamsNames } from '../my-content/my-content-filters'
import { IdeaRow } from './idea-row'
import { ideasPageUrl } from '../../app'
import { AddNewArticleButton } from '../../components'
import { useConfigStore } from '../../state/config'
import MyIdeas from './my-ideas'
import TopIdeas from './top-ideas'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import qs from 'qs'

const START_YEAR = new Date().getFullYear()

type LocationOption = {
  value: string[]
  label: string
}

export function IdeasOverview() {
  const { me } = useConfigStore()
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)

  const [searchParams, setSearchParams] = useSearchParams()
  console.log('searchParams', [...searchParams])
  const { t } = useTranslate()
  const { translations } = useConfigStore()
  const { ideas, count, mutate, isLoading } = useIdeas(
    qs.parse(searchParams.toString())
  )
  const { categories } = useIdeaCategories()
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])
  const [yearOptions, setYearOptions] = useState<SelectOption[]>([])
  const [statusOptions, setStatusOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    setCategoryOptions([
      { value: '', label: 'All categories' },
      ...Object.values(categories || {}).map((value) => ({
        value,
        label: value
      }))
    ])
  }, [Object.values(categories || {}).length])

  useEffect(() => {
    if (me) {
      if (!searchParams.get('type') && !searchParams.get('author')) {
        searchParams.set(
          'type',
          isIdeaManager(me) ? IdeaGroupStatus.New : IdeaGroupStatus.Progress
        )
        setSearchParams(searchParams)
        mutate()
      }

      if (searchParams.get('type')) {
        setStatusOptions([
          { value: '', label: 'All statuses' },
          ...Object.values(IdeaStatusesByGroups[searchParams.get('type')]).map(
            (value) => ({
              value,
              label: value
            })
          )
        ])
      }
    }

    setYearOptions([
      { value: '', label: 'All years' },
      ...Array.from(
        { length: new Date().getFullYear() - START_YEAR + 1 },
        (item, index) => ({
          value: START_YEAR + index,
          label: START_YEAR + index
        })
      )
    ])
  }, [me])

  const locationQuery = searchParams.getAll(searchParamsNames.location)

  const currentLocation = useMemo(() => {
    if (locationQuery) {
      for (const locOption of IdeaLocs) {
        if (isEqual(sortBy(locOption.value), sortBy(locationQuery))) {
          return locOption
        }
      }
    }
    return undefined
  }, [IdeaLocs, locationQuery])

  useEffect(() => {
    if (count && !ideas?.length) {
      searchParams.set('page', '0')
      setSearchParams(searchParams)
    }
  }, [count, ideas?.length])

  const FilterControllers = (
    <>
      {!isLg && (
        <SearchInput
          small={true}
          placeholder="Search by title, author"
          data-cy="filterSearchInput"
          value={searchParams.get(searchParamsNames.search)}
          onChange={(value: string) => {
            searchParams.set(searchParamsNames.search, value)
            setSearchParams(searchParams)
          }}
          placeholderTextLg={t('field_search_by_title_author')}
          placeholderTextSm={t('field_search')}
          containerProps={{ css: { flexShrink: 2 } }}
        />
      )}
      <Box
        css={{
          d: 'flex',
          flexDirection: 'row',
          mx: '-$1',
          flexGrow: 1,
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <Box
          css={{
            flexBasis: '100%',
            px: '$1',
            my: '$1',
            '@lg': { flexBasis: '50%' },
            '@xl': { flexBasis: '25%', my: 0 }
          }}
        >
          {searchParams.get('type') &&
            searchParams.get('type') !== IdeaGroupStatus.New && (
              <ReactSelect
                css={{ width: 'auto' }}
                value={
                  statusOptions?.find(
                    ({ value }) =>
                      value === searchParams.get(searchParamsNames.status)
                  ) || statusOptions?.[0]
                }
                options={statusOptions}
                onChange={(option: string) => {
                  searchParams.set(searchParamsNames.status, option.value)
                  setSearchParams(searchParams)
                }}
                id="status filter"
                placeholder="All statuses"
              />
            )}
        </Box>

        <Box
          css={{
            flexBasis: '100%',
            px: '$1',
            my: '$1',
            '@lg': { flexBasis: '50%' },
            '@xl': { flexBasis: '25%', my: 0 }
          }}
        >
          <ReactSelect
            css={{ width: 'auto' }}
            value={currentLocation}
            options={IdeaLocs}
            onChange={(option: LocationOption) => {
              if (option.value !== undefined) {
                searchParams.delete(searchParamsNames.location)
                option.value.forEach((loc) => {
                  searchParams.append(searchParamsNames.location, loc)
                })
              } else {
                searchParams.delete(searchParamsNames.location)
              }
              setSearchParams(searchParams)
            }}
            id="location filter"
            placeholder="All locations"
          />
        </Box>
        <Box
          css={{
            flexBasis: '100%',
            px: '$1',
            '@lg': { flexBasis: '50%' },
            '@xl': { flexBasis: '25%', my: 0 }
          }}
        >
          <ReactSelect
            css={{ width: 'auto' }}
            value={
              categoryOptions?.find(
                ({ value }) =>
                  value === searchParams.get(searchParamsNames.category)
              ) || categoryOptions?.[0]
            }
            options={categoryOptions}
            onChange={(option: string) => {
              searchParams.set(searchParamsNames.category, option.value)
              setSearchParams(searchParams)
            }}
            id="category filter"
            placeholder="All categories"
          />
        </Box>
        <Box
          css={{
            flexBasis: '100%',
            px: '$1',
            '@lg': { flexBasis: '50%' },
            '@xl': { flexBasis: '25%', my: 0 }
          }}
        >
          <ReactSelect
            css={{ width: 'auto' }}
            value={
              yearOptions?.find(
                ({ value }) =>
                  value === +searchParams.get(searchParamsNames.year)
              ) || yearOptions?.[0]
            }
            options={yearOptions}
            onChange={(option: string) => {
              searchParams.set(searchParamsNames.year, option.value)
              setSearchParams(searchParams)
            }}
            id="year filter"
            placeholder="All years"
          />
        </Box>
      </Box>
    </>
  )

  return (
    <>
      <Box css={{ backgroundColor: '$neutralLighten97', pt: '$8', pb: '$4' }}>
        <ContainerPadding>
          <Container>
            <Box
              css={{
                display: 'flex',
                ml: 0,
                mr: 0,
                flexDirection: 'column',
                '@xl': { ml: '-$3', mr: '-$3', flexDirection: 'row' }
              }}
            >
              <Box
                css={{
                  pl: '$3',
                  pr: '$3',
                  mb: '$4',
                  '@xl': { width: 404, mb: 0 }
                }}
              >
                <MyIdeas />
              </Box>
              <Box css={{ pl: '$3', pr: '$3', flex: 1 }}>
                <TopIdeas />
              </Box>
            </Box>
          </Container>
        </ContainerPadding>
      </Box>
      <Tabs
        onValueChange={(value) => {
          if (value !== me?.internalUid?.toString()) {
            setSearchParams({ type: value })
            setStatusOptions([
              { value: '', label: 'All statuses' },
              ...Object.values(IdeaStatusesByGroups[value]).map((value) => ({
                value,
                label: value
              }))
            ])
          } else {
            setSearchParams({ author: me?.internalUid })
            const myIdeasStatuses = {
              [IdeaStatuses['Review Pending']]: IdeaStatuses['Review Pending'],
              [IdeaStatuses['Approved']]: IdeaStatuses['Approved'],
              [IdeaStatuses['Implementation in Progress']]:
                IdeaStatuses['Implementation in Progress'],
              [IdeaStatuses['Implemented']]: IdeaStatuses['Implemented'],
              [IdeaStatuses['Rewarded']]: IdeaStatuses['Rewarded']
            }
            setStatusOptions([
              { value: '', label: 'All statuses' },
              ...Object.values(myIdeasStatuses).map((value) => ({
                value,
                label: value
              }))
            ])
          }
        }}
        value={searchParams.get('type') ?? searchParams.get('author')}
        css={{ flex: 1, height: '100%' }}
      >
        <Space
          pt="LG"
          css={{
            backgroundColor: '$neutralLighten97',
            borderBottom: '1px $neutralLighten90 solid'
          }}
        >
          <ContainerPadding>
            <Container>
              <Space mb="SM">
                <Heading1>{t('field_ideas')}</Heading1>
              </Space>
              <TabsList aria-label="Ideas types">
                {isIdeaManager(me) && (
                  <TabsTrigger value={IdeaGroupStatus.New}>
                    {t('field_status_new')}
                  </TabsTrigger>
                )}
                <TabsTrigger value={IdeaGroupStatus.Progress}>
                  {t('field_status_progress')}
                </TabsTrigger>
                <TabsTrigger value={IdeaGroupStatus.Completed}>
                  {t('field_status_completed')}
                </TabsTrigger>
                <TabsTrigger value={IdeaGroupStatus.Rejected}>
                  {t('field_status_rejected')}
                </TabsTrigger>
                <TabsTrigger value={me?.internalUid?.toString()}>
                  {t('field_idea_my_ideas')}
                </TabsTrigger>
              </TabsList>
            </Container>
          </ContainerPadding>
        </Space>

        <ContainerPadding css={{ flexGrow: 1, d: 'flex' }}>
          <Container css={{ flexGrow: 1, d: 'flex', flexDirection: 'column' }}>
            <Space my="XL" css={{ flexGrow: 1 }}>
              <Space mb="SM">
                <Box css={{ d: 'flex', flexDirection: 'row' }}>
                  <SearchInput
                    small={true}
                    placeholder={t('field_search_by_title_author')}
                    data-cy="filterSearchInput"
                    value={searchParams.get(searchParamsNames.search)}
                    onChange={(value: string) => {
                      searchParams.set(searchParamsNames.search, value)
                      setSearchParams(searchParams)
                    }}
                    placeholderTextLg={t('field_search_by_title_author')}
                    placeholderTextSm={t('field_search')}
                    containerProps={{ css: { flexShrink: 2 } }}
                    OptionsButton={
                      <Dialog
                        title="Filters"
                        trigger={<FilterIcon20 />}
                        footer={
                          <Space
                            css={{
                              d: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <Button as={DialogClose}>Done</Button>
                          </Space>
                        }
                      >
                        <Space
                          my="MD"
                          css={{
                            d: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            gap: '$6'
                          }}
                        >
                          {FilterControllers}
                        </Space>
                      </Dialog>
                    }
                  />
                  {searchParams.get('type') &&
                    searchParams.get('type') !== IdeaGroupStatus.New && (
                      <Button
                        css={{
                          ml: '$8',
                          flexShrink: 0,
                          '&:hover:not(:disabled), &.__active': {
                            borderColor: '$neutralLighten10',
                            backgroundColor: 'initial',
                            color: '$neutral',
                            '& .icon': {
                              color: '$neutral'
                            }
                          }
                        }}
                        size="small"
                        className={
                          searchParams.get(searchParamsNames.my) === '1'
                            ? '__active'
                            : ''
                        }
                        variant={'secondary'}
                        onClick={() => {
                          searchParams.set(
                            searchParamsNames.my,
                            (+searchParams.get(searchParamsNames.my) + 1) % 2
                          )
                          setSearchParams(searchParams)
                        }}
                      >
                        <Box as="span" className="icon icon-left">
                          <FilterUnreadIcon16 />
                        </Box>
                        {t('field_idea_only_assigned_to_me')}
                      </Button>
                    )}
                </Box>
                {isLg ? (
                  <Space
                    mt="SM"
                    css={{
                      flexDirection: 'column',
                      d: 'flex',
                      gap: '$4',
                      '@md': { flexDirection: 'row' }
                    }}
                  >
                    {FilterControllers}
                  </Space>
                ) : (
                  <Space
                    mt="SM"
                    css={{
                      d: 'flex',
                      gap: '$3',
                      justifyContent: 'flex-end'
                    }}
                  >
                    {searchParams.get('search') ||
                    searchParams.get('my') ||
                    searchParams.get('category') ||
                    searchParams.get('status') ||
                    searchParams.get('year') ? (
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setSearchParams({
                            type: searchParams.get('type')
                          })
                        }}
                      >
                        {t('field_clear_filters')}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Space>
                )}
              </Space>

              <Table css={{ tableLayout: 'fixed' }}>
                <thead>
                  {isLg ? (
                    <TR header={true}>
                      <TH css={{ width: 60 }}></TH>
                      <TH className="pl-8">{t('field_title')}</TH>
                      <TH css={{ width: 170 }}>
                        <LastUpdateHeader title={t('field_submitted_on')} />
                      </TH>
                      <TH css={{ width: 80 }}>{t('field_author')}</TH>
                      <TH css={{ width: 120 }}>
                        {t('field_idea_implementer')}
                      </TH>
                      <TH css={{ width: 140 }}></TH>
                    </TR>
                  ) : (
                    <TR
                      css={{
                        '& th': { borderBottom: '1px solid $neutralLighten80' }
                      }}
                    >
                      <TH colSpan={2}>
                        <Box
                          css={{ d: 'flex', justifyContent: 'space-between' }}
                        >
                          <Body5>{t('field_title')}</Body5>
                          <Body5>
                            {t('field_author')}/{t('field_idea_implementer')}
                          </Body5>
                        </Box>
                      </TH>
                      <TH css={{ width: 120 }}></TH>
                    </TR>
                  )}
                </thead>

                <tbody>
                  {ideas?.length || isLoading ? (
                    ideas?.map((idea: Idea) => (
                      <IdeaRow
                        key={idea.id}
                        idea={idea}
                        mutate={mutate}
                      ></IdeaRow>
                    ))
                  ) : (
                    <TR>
                      <TD colSpan={6} align="center">
                        <Box>
                          <Space mt="2XL" mb="SM">
                            <SearchNoResultsIcon48 />
                          </Space>
                          <Body4>{t('field_no_results_found')}</Body4>
                        </Box>
                      </TD>
                    </TR>
                  )}
                </tbody>
              </Table>

              {isLoading && (
                <Space mt="XS">
                  <Spinner />
                </Space>
              )}

              {count > SEARCH_PAGE_LIMIT ? (
                <Space mt="LG" css={{ d: 'flex', justifyContent: 'flex-end' }}>
                  <Pagination
                    currentPage={+searchParams.get('page') || 0}
                    count={count}
                    limit={SEARCH_PAGE_LIMIT}
                    onChange={(value) => {
                      searchParams.set('page', value)
                      setSearchParams(searchParams)
                    }}
                    translations={translations}
                  />
                </Space>
              ) : (
                <></>
              )}
            </Space>
          </Container>
        </ContainerPadding>

        <AddNewArticleButton
          url={`/${ideasPageUrl}/create`}
          text={t('field_idea_add_idea')}
          padding={!isLg}
        />
      </Tabs>
    </>
  )
}
