import React, { useRef } from 'react'
import { Box } from '../utils'
import { Body6, Body7 } from '../typography'
import { RemoveActionButton, AddActionButton } from '../add-remove-buttons'
import { Add20, Bookmark32, ToolsGridIcon, CloseIcon20 } from '../icons'
import Bookmark32Svg from '../icons/bookmark-32.svg'
import { useDrag, useDrop } from 'react-dnd'

export enum ToolCardType {
  FAVORITE = 'favorite',
  OTHER = 'other'
}

export type DraggableItem = {
  id: string
  index: number
  type: string
}

export function DraggableToolCardDefault({
  name,
  link,
  id,
  index,
  disabled = false,
  wrapperCss = {},
  iconWrapperCss = {},
  onCardDrop,
  actionType,
  iconUrl,
  onActionClick,
  onClick,
  className = '',
  children,
  moveCard,
  cardType
}: {
  name?: string
  link?: string
  index?: number
  id?: string
  disabled?: boolean
  moveCard?: (dragIndex: number, hoverIndex: number) => void
  onCardDrop?: (item: DraggableItem) => void
  wrapperCss?: Record<string, string>
  iconWrapperCss?: Record<string, string>
  actionType?: string
  iconUrl?: string
  onActionClick?: (e: React.SyntheticEvent<EventTarget>) => void
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
  className?: string
  children?: React.ReactNode
  cardType: ToolCardType
}) {
  const ref = useRef<any | null>(null)
  const [, drop] = useDrop({
    accept: cardType,
    drop(item: DraggableItem) {
      if (onCardDrop) {
        onCardDrop(item)
      }
    },
    hover(item: DraggableItem, monitor) {
      if (!ref.current || !moveCard) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex || typeof hoverIndex !== 'number') {
        return
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleX =
        (hoverBoundingRect.left - hoverBoundingRect.right) / 2
      const clientOffset = monitor.getClientOffset()
      if (clientOffset) {
        const hoverClientX = clientOffset.x - hoverBoundingRect.right

        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return
        }
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return
        }
        moveCard(dragIndex, hoverIndex)
        item.index = hoverIndex
      }
    }
  })
  const [{ isDragging }, drag] = useDrag({
    type: cardType,
    item: { type: cardType, id, index },
    collect: (monitor) => ({
      isDragging: id === monitor?.getItem()?.id
    })
  })
  drag(drop(ref))
  return (
    <Box
      as={!actionType && link ? 'a' : 'button'}
      href={!actionType && link ? link : undefined}
      ref={ref}
      target="_blank"
      className={`${disabled ? 'disabled' : ''} ${
        !actionType && link ? 'link' : ''
      } ${className}`}
      onClick={onClick}
      css={{
        all: 'unset',
        textDecoration: 'none',
        position: 'relative',
        color: '$white',
        width: '64px',
        '&.link': {
          cursor: 'pointer'
        },
        '&.disabled': {
          cursor: 'auto',
          '& .icon-wrapper': {
            '& svg': {
              fill: '$neutralLighten60',
              path: {
                fill: '$neutralLighten60'
              }
            }
          },
          '&:hover, &.__hover': {
            '& .icon-wrapper': {
              transform: 'none'
            }
          }
        },
        '&:hover:not(.disabled), &.__hover:not(.disabled)': {
          '& .icon-wrapper': {
            transform: 'scale(1.05)'
          }
        },
        ...wrapperCss
      }}
    >
      {actionType && (
        <Box
          css={{
            position: 'absolute',
            left: 0,
            top: 0,
            transform: 'translateX(-50%) translateY(-50%)'
          }}
        >
          {actionType === 'add' ? (
            <AddActionButton onClick={onActionClick} />
          ) : actionType === 'remove' ? (
            <RemoveActionButton onClick={onActionClick} />
          ) : (
            <></>
          )}
        </Box>
      )}
      <Box
        css={{
          border: isDragging ? '1px dashed #ffffff' : '1px solid transparent',
          borderRadius: '10px'
        }}
      >
        <Box
          className="icon-wrapper"
          css={{
            w: '100%',
            height: '64px',
            d: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '$white',
            borderRadius: '10px',
            opacity: isDragging ? 0 : 1,
            '& object': {
              maxWidth: '32px',
              maxHeight: '32px'
            },
            ...iconWrapperCss
          }}
          onClick={actionType === 'add' ? onActionClick : undefined}
        >
          {children ? (
            <>{children}</>
          ) : (
            <>
              {iconUrl ? (
                <object data={iconUrl} type="image/png">
                  <img src={Bookmark32Svg} alt="Logo" />
                </object>
              ) : (
                <Body6 css={{ color: '$neutralLighten60' }}>
                  <Bookmark32 />
                </Body6>
              )}
            </>
          )}
        </Box>
      </Box>

      {name && (
        <Body7
          css={{
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            my: '$2'
          }}
        >
          {name}
        </Body7>
      )}
    </Box>
  )
}

export function ToolCardDefault({
  name,
  link,
  disabled = false,
  wrapperCss = {},
  iconWrapperCss = {},
  actionType,
  iconUrl,
  onActionClick,
  onClick,
  className = '',
  children
}: {
  name?: string
  link?: string
  disabled?: boolean
  wrapperCss?: Record<string, string>
  iconWrapperCss?: Record<string, string>
  actionType?: string
  iconUrl?: string
  onActionClick?: (e: React.SyntheticEvent<EventTarget>) => void
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
  className?: string
  children?: React.ReactNode
}) {
  return (
    <Box
      as={!actionType && link ? 'a' : 'button'}
      data-label={name}
      href={!actionType && link ? link : undefined}
      target="_blank"
      className={`${disabled ? 'disabled' : ''} ${
        !actionType && link ? 'link' : ''
      } ${className}`}
      onClick={onClick}
      css={{
        all: 'unset',
        textDecoration: 'none',
        position: 'relative',
        color: '$white',
        display: 'block',
        width: 64,
        '&.link': {
          cursor: 'pointer'
        },
        '&.disabled': {
          cursor: 'auto',
          '& .icon-wrapper': {
            '& svg': {
              fill: '$neutralLighten60',
              path: {
                fill: '$neutralLighten60'
              }
            }
          },
          '&:hover, &.__hover': {
            '& .icon-wrapper': {
              transform: 'none'
            }
          }
        },
        '&:hover:not(.disabled), &.__hover:not(.disabled)': {
          '& .icon-wrapper': {
            transform: 'scale(1.05)'
          }
        },
        ...wrapperCss
      }}
    >
      {actionType && (
        <Box
          css={{
            position: 'absolute',
            left: 0,
            top: 0,
            transform: 'translateX(-50%) translateY(-50%)'
          }}
        >
          {actionType === 'add' ? (
            <AddActionButton onClick={onActionClick} />
          ) : actionType === 'remove' ? (
            <RemoveActionButton onClick={onActionClick} />
          ) : (
            <></>
          )}
        </Box>
      )}

      <Box
        className="icon-wrapper"
        css={{
          w: '100%',
          height: '64px',
          d: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '$white',
          borderRadius: '10px',
          '& object': {
            maxWidth: '32px',
            maxHeight: '32px'
          },
          ...iconWrapperCss
        }}
        onClick={actionType === 'add' ? onActionClick : undefined}
      >
        {children ? (
          <>{children}</>
        ) : (
          <>
            {iconUrl ? (
              <object data={iconUrl} type="image/png">
                <img src={Bookmark32Svg} alt="Logo" />
              </object>
            ) : (
              <Body6 css={{ color: '$neutralLighten60' }}>
                <Bookmark32 />
              </Body6>
            )}
          </>
        )}
      </Box>
      {name && (
        <Body7
          css={{
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            my: '$2'
          }}
        >
          {name}
        </Body7>
      )}
    </Box>
  )
}

export const ToolCard = React.memo(ToolCardDefault)

export const DraggableToolCard = React.memo(DraggableToolCardDefault)

export function ToolCardButton({
  icon,
  text,
  disabled,
  onClick,
  className = ''
}: {
  icon: React.ReactNode
  text?: string
  disabled?: boolean
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
  className?: string
}) {
  return (
    <ToolCard
      name={text}
      wrapperCss={{
        cursor: 'pointer'
      }}
      iconWrapperCss={{
        background: '$neutralLighten30'
      }}
      disabled={disabled}
      onClick={onClick}
      className={className}
    >
      {icon}
    </ToolCard>
  )
}

export function EditToolCardButton({
  disabled,
  onClick
}: {
  disabled?: boolean
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
}) {
  return (
    <ToolCardButton
      disabled={disabled}
      icon={<ToolsGridIcon />}
      onClick={onClick}
      text="All tools"
    />
  )
}

export function CloseEditCardButton({
  onClick
}: {
  disabled?: boolean
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
}) {
  return (
    <ToolCardButton icon={<CloseIcon20 />} onClick={onClick} text="Close" />
  )
}

export function AddToolCardButton({
  onClick,
  translations
}: {
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
  translations?: Record<string, string>
}) {
  return (
    <ToolCardButton
      icon={<Add20 />}
      text={translations?.field_tools_add_link_text || 'Add link'}
      onClick={onClick}
    />
  )
}
