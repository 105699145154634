import { useMedia, useUpdateEffect } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import React, { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Body5,
  Body7,
  Box,
  DropdownMenu,
  DropdownMenuItem,
  EllipsisHorizontalIcon20,
  FolderClosedIcon20,
  FolderOpenIcon20,
  Heading4,
  Space,
  Spinner
} from '@sefar/design-system'
import { AnimatePresence } from 'framer-motion'
import { Table, TH, TR, TD } from './common'
import { useConfigStore } from '../../state/config'
import {
  MyContentRow,
  NewsArticle,
  SPACE_ARTICLE_TYPE,
  SubSpace,
  useLanguages,
  useAllowedSpaces,
  useUserSpaceContent
} from '../../api'
import { filterContent, MyContentFilters } from './my-content-filters'
import { spacesPageUrl, spacesCreateArticlePageUrl } from '../../app'
import { MyContentArticle } from './my-content-article'
import { DeleteArticleDialog } from './deleteArticleDialog'
import { TranslateDialog } from './translate-dialog'

function getFilteredSpaceContent(spaces, content, searchParams) {
  return spaces?.map((space) => {
    return {
      ...space,
      subspaces: space.subspaces.map(
        (subspace): SubSpace => ({
          ...subspace,
          articles: filterContent(content, searchParams)?.filter(
            (article: MyContentRow) => article.field_space === subspace.id
          )
        })
      )
    }
  })
}

export function MySpaceArticles() {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const { languages } = useLanguages()
  const [searchParams] = useSearchParams()
  const { me } = useConfigStore()
  const { content, isLoading, mutate } = useUserSpaceContent()
  const { spaces } = useAllowedSpaces(undefined, me?.id)
  const [filteredSpaceContent, setFilteredSpaceContent] = useState(
    getFilteredSpaceContent(spaces, content, searchParams)
  )

  useUpdateEffect(() => {
    if (spaces && content) {
      setFilteredSpaceContent(
        getFilteredSpaceContent(spaces, content, searchParams)
      )
    }
  }, [searchParams, spaces?.length, Object.values(content || {}).length])

  return (
    <>
      <MyContentFilters languages={languages} />

      {isLoading && (
        <Box
          css={{
            flexGrow: 1,
            d: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Spinner />
        </Box>
      )}

      <Space my="XL">
        <Table>
          <thead>
            {isLg ? (
              <TR header={true}>
                <th colSpan={3}>
                  <Body5>Title</Body5>
                </th>
                <th>
                  <Body5 as="span">Last update</Body5>
                </th>
                <TH css={{ width: 130 }}>
                  <Body5>Status</Body5>
                </TH>
                <TH css={{ width: 100 }}>
                  <Body5>Articles / Language(s)</Body5>
                </TH>
                <th></th>
              </TR>
            ) : (
              <></>
            )}
          </thead>

          <tbody>
            {filteredSpaceContent?.map((space) => (
              <>
                <TR
                  css={{
                    '& td': {
                      borderBottom: '1px solid $neutralLighten70'
                    }
                  }}
                >
                  <td colSpan={7}>
                    <Heading4>{space.title}</Heading4>
                  </td>
                </TR>
                {space.subspaces.map((subspace) => (
                  <SubSpaceRow
                    mutate={mutate}
                    subspace={subspace}
                    content={content}
                    key={subspace.id}
                  />
                ))}
              </>
            ))}
          </tbody>
        </Table>
      </Space>
    </>
  )
}

export function SubSpaceRow({ subspace, content, mutate }) {
  const location = useLocation()
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const navigate = useNavigate()
  const [opened, setOpened] = useState<boolean>(false)

  const [activeArticle, setActiveArticle] = useState(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [selectedArticle, setSelectedArticle] = useState<NewsArticle>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isHasTranslations, setIsHasTranslations] = useState(false)
  const [isOriginal, setIsOriginal] = useState(false)

  const Actions = (
    <DropdownMenu
      trigger={() => (
        <Box
          css={{
            transform: 'rotate(90deg)',
            '@lg': { transform: 'none' }
          }}
        >
          <EllipsisHorizontalIcon20 />
        </Box>
      )}
      content={() => (
        <DropdownMenuItem
          onClick={() => {
            navigate(`/${spacesCreateArticlePageUrl}/${subspace.id}`, {
              state: {
                from: `${location.pathname}${location.search || ''}`
              }
            })
          }}
        >
          <Body5 as="span">Add article</Body5>
        </DropdownMenuItem>
      )}
      contentProps={{
        align: 'end',
        side: 'bottom'
      }}
    />
  )

  const Opener = !subspace.articles?.length ? (
    <></>
  ) : opened ? (
    <FolderOpenIcon20 />
  ) : (
    <FolderClosedIcon20 />
  )

  return (
    <>
      <TR
        hover={true}
        onClick={() => subspace.articles?.length && setOpened(!opened)}
        css={{
          '& td': {
            borderBottom: opened
              ? isLg
                ? '1px solid $neutralLighten90'
                : ''
              : '1px solid $neutralLighten80'
          }
        }}
      >
        {isLg ? (
          <>
            <TD css={{ width: 45 }}>{Opener}</TD>
            <td colSpan={2}>
              <Body5 fontWeight="bold">{subspace.title}</Body5>
            </td>
            <td>
              <Body5>{subspace.date?.split(', ')[0]}</Body5>
              <Body7 css={{ color: '$neutralLighten30' }}>
                {subspace.date?.split(', ')[1]}
              </Body7>
            </td>
            <td></td>
            <TD css={{ textAlign: 'center' }}>{subspace?.articles?.length}</TD>
            <td>{Actions}</td>
          </>
        ) : (
          <>
            <td>{Opener}</td>
            <td>
              <Box
                css={{
                  d: 'flex',
                  gap: '$2',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box
                  css={{
                    d: 'flex',
                    flexDirection: 'column',
                    gap: '$2'
                  }}
                >
                  <Body5
                    fontWeight="bold"
                    css={{
                      textDecoration: 'none',
                      color: '$neutral'
                    }}
                  >
                    {subspace.title}
                  </Body5>
                  <Body7 css={{ color: '$neutralLighten30' }}>
                    Last update {subspace.changed}
                  </Body7>
                </Box>
                {subspace?.articles?.length ? (
                  <Body7
                    as="div"
                    css={{
                      backgroundColor: '$neutralLighten93',
                      borderRadius: '$round',
                      size: 24,
                      d: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexShrink: 0
                    }}
                  >
                    {subspace?.articles?.length}
                  </Body7>
                ) : (
                  <></>
                )}
              </Box>
            </td>
            <td>{Actions}</td>
          </>
        )}
      </TR>
      <AnimatePresence>
        {opened ? (
          subspace.articles.map((article: MyContentRow) => (
            <MyContentArticle
              nested={true}
              key={`${article.langcode}-${article.id}`}
              to={(article: MyContentRow) =>
                `/${spacesPageUrl}/${article.id}/${article.langcode}`
              }
              setIsOriginal={setIsOriginal}
              setActiveArticle={setActiveArticle}
              content={content}
              article={article}
              setOpenDialog={setOpenDialog}
              setSelectedArticle={setSelectedArticle}
              setIsModalOpen={setIsModalOpen}
              setIsHasTranslations={setIsHasTranslations}
              onPublishAll={mutate}
            />
          ))
        ) : (
          <></>
        )}
      </AnimatePresence>

      <DeleteArticleDialog
        type={SPACE_ARTICLE_TYPE}
        isOriginal={isOriginal}
        onDelete={() => mutate()}
        activeArticle={activeArticle}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        isHasTranslations={isHasTranslations}
      />

      <TranslateDialog
        article={selectedArticle}
        onTranslate={() => mutate()}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  )
}
