import React, { LegacyRef, useEffect, useRef, useState } from 'react'
import Cropper, { Area } from 'react-easy-crop'
import {
  ArrowRoundLeftIcon20,
  ArrowRoundRightIcon20,
  Body5,
  Box,
  Button,
  Dialog,
  Slider
} from '@sefar/design-system'
import { useTranslate } from '../../../hooks/useTranslate'
import getCroppedImg from './utils'

type ImageCropperProps = {
  originalImageFileUrl?: string
  trigger?: React.ReactNode
  aspectRatio?: number
  onSave?: (croppedImageFile: File) => void
}

export const ImageCropper = ({
  originalImageFileUrl,
  trigger,
  aspectRatio = 2 / 1,
  onSave
}: ImageCropperProps) => {
  const { t } = useTranslate()

  const [isOpen, setIsOpen] = useState(false)

  const cropperRef = useRef<Cropper>()

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()

  useEffect(() => {
    if (isOpen) {
      const timerId = setTimeout(() => {
        cropperRef.current?.computeSizes()
      }, 200)
      return () => clearTimeout(timerId)
    }
  }, [isOpen])

  const onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onClickSave = async () => {
    if (!originalImageFileUrl || !croppedAreaPixels) {
      return null
    }

    const croppedImageFile = await getCroppedImg(
      originalImageFileUrl,
      croppedAreaPixels,
      rotation
    )
    if (croppedImageFile) {
      onSave?.(croppedImageFile)
    }
    setIsOpen(false)
  }

  return (
    <Dialog
      onOpenChange={(open) => {
        setIsOpen(open)
      }}
      open={isOpen}
      contentProps={{
        css: {
          width: 550,
          maxHeight: 'calc(100vh - 120px)'
        }
      }}
      innerContentCss={{
        flex: `1 1 550px`,
        d: 'flex',
        justifyContent: 'center'
      }}
      trigger={trigger}
      title={t('field_edit')}
      footerCss={{
        '@md': { borderTop: 'initial' }
      }}
      footer={
        <Box
          css={{
            d: 'flex',
            gap: '$3'
          }}
        >
          <Button
            data-cy="SaveImageButton"
            variant="primary"
            css={{ '@md': { marginLeft: 'auto' } }}
            onClick={onClickSave}
          >
            {t('field_save')}
          </Button>
        </Box>
      }
    >
      <Box css={{ width: '100%' }}>
        <Box
          css={{
            width: '100%',
            height: 'calc(100% - 178px)',
            position: 'relative',
            '@md': { height: 'calc(100% - 132px)' }
          }}
        >
          <Cropper
            ref={cropperRef as LegacyRef<Cropper>}
            image={originalImageFileUrl}
            rotation={rotation}
            crop={crop}
            zoom={zoom}
            aspect={aspectRatio}
            showGrid={false}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
          />
        </Box>

        <Box>
          <Box
            css={{
              d: 'flex',
              gap: '$2',
              justifyContent: 'end',
              my: '$4',
              w: '100%'
            }}
          >
            <Button
              className="only-icon"
              variant="secondary"
              onClick={() => setRotation((rotation + 90) % 360)}
            >
              <ArrowRoundRightIcon20 />
            </Button>
            <Button
              className="only-icon"
              variant="secondary"
              onClick={() =>
                setRotation(
                  rotation - 90 < 0
                    ? rotation - 90 + 360
                    : (rotation - 90) % 360
                )
              }
            >
              <ArrowRoundLeftIcon20 />
            </Button>
          </Box>
          <Box
            css={{
              d: 'flex',
              gap: '$10',
              width: '100%',
              height: 60,
              flexDirection: 'column',
              '@md': { flexDirection: 'row' }
            }}
          >
            <Box css={{ flex: '1 0 0' }}>
              <Body5 css={{ mb: '$4' }}>{t('field_edit_prof_pic_zoom')}</Body5>
              <Slider
                value={[zoom]}
                onValueChange={(value) => setZoom(value[0])}
                min={1}
                max={5}
                step={0.2}
              />
            </Box>
            <Box css={{ flex: '1 0 0' }}>
              <Body5 css={{ mb: '$4' }}>
                {t('field_edit_prof_pic_straighten')}
              </Body5>
              <Slider
                value={[rotation]}
                onValueChange={(value) => setRotation(value[0])}
                min={0}
                max={359}
                step={1}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
