import { styled } from '../../../../../stitches.config'

export const Heading1Styles = {
  fontWeight: '$bold',
  fontSize: '$h1Sm',
  lineHeight: '$12',
  letterSpacing: '-0.2px',
  '@lg': {
    fontSize: '$h1Lg',
    letterSpacing: '-0.3px'
  },
  '@xl': {
    fontSize: '$h1Xl',
    letterSpacing: '-0.3px'
  }
}
export const Heading1 = styled('h1', Heading1Styles)

export const Heading2Styles = {
  fontWeight: '$bold',
  fontSize: '$h2Sm',
  lineHeight: '$12',
  letterSpacing: '-0.2px',
  '@lg': {
    fontSize: '$h2Lg',
    letterSpacing: '-0.3px'
  },
  '@xl': {
    fontSize: '$h2Xl',
    letterSpacing: '-0.3px'
  }
}
export const Heading2 = styled('h2', Heading2Styles)

export const Heading3Styles = {
  fontWeight: '$bold',
  letterSpacing: '-0.2px',
  fontSize: '$h3Sm',
  lineHeight: '$13',
  '@lg': {
    fontSize: '$h3Lg',
    lineHeight: '$12'
  },
  '@xl': {
    fontSize: '$h3Xl',
    lineHeight: '$12'
  }
}
export const Heading3 = styled('h3', Heading3Styles)

export const Heading4Styles = {
  fontWeight: '$bold',
  fontSize: '$h4Sm',
  lineHeight: '$12',
  letterSpacing: '-0.2px',
  '@lg': {
    fontSize: '$h4Lg'
  },
  '@xl': {
    fontSize: '$h4Xl'
  }
}
export const Heading4 = styled('h4', Heading4Styles)

export const Heading5 = styled('h5', {
  fontWeight: '$bold',
  fontSize: '$h5Sm',
  lineHeight: '$13',
  '@lg': {
    fontSize: '$h5Lg'
  },
  '@xl': {
    fontSize: '$h5Xl',
    letterSpacing: 'normal'
  }
})

export const Body1 = styled('div', {
  fontWeight: '$light',
  fontSize: '$body1Sm',
  lineHeight: '$13',
  letterSpacing: '0.15px',
  '@lg': {
    fontSize: '$body1Lg'
  },
  '@xl': {
    fontSize: '$body1Xl'
  }
})

export const Body2 = styled('div', {
  fontSize: '$body2Sm',
  lineHeight: '$14',
  letterSpacing: '0.15px',
  '@lg': {
    fontSize: '$body2Lg'
  },
  '@xl': {
    fontSize: '$body2Xl'
  }
})

export const Body3Styles = {
  fontSize: '$body3',
  lineHeight: '$16',
  letterSpacing: '0.15px',
  variants: {
    fontWeight: {
      bold: {
        fontWeight: '$semiBold'
      },
      light: {
        fontWeight: '$light'
      }
    },
    size: {
      xl: {
        fontSize: '$body3Xl'
      }
    }
  }
}
export const Body3 = styled('div', Body3Styles)

export const Body4 = styled('div', {
  fontSize: '$body4',
  lineHeight: '$13',
  '@xl': {
    fontSize: '$body4Xl'
  },
  variants: {
    fontWeight: {
      bold: {
        fontWeight: '$bold'
      }
    }
  }
})

export const Body5Styles = {
  fontSize: '$body5',
  lineHeight: '$15',
  '@xl': {
    fontSize: '$body5Xl'
  },
  variants: {
    fontWeight: {
      bold: {
        fontWeight: '$bold'
      }
    }
  }
}

export const Body5 = styled('div', Body5Styles)

export const Body6Styles = {
  fontSize: '$body6',
  lineHeight: '$14',
  variants: {
    fontWeight: {
      bold: {
        fontWeight: '$bold'
      }
    }
  }
}

export const Body6 = styled('div', Body6Styles)

export const Body7 = styled('div', {
  fontSize: '$body7',
  lineHeight: '$13',
  variants: {
    fontWeight: {
      bold: {
        fontWeight: '$bold'
      }
    }
  }
})
