import useSWR from 'swr'
import { API_DOMAIN, fetcherFullRequest, init } from './common'
import { NEWS_ARTICLE_TYPE } from './news'
import { SpaceArticle } from './space'

export type MyContentRow = {
  id: string
  drupal_internal__nid: string
  title: string
  field_lead: string
  published: boolean
  field_translation_type: string
  content_translation_source: string
  langcode: string
  publish_on: string
  unpublish_on: string
  changed: string
  field_space?: string
}

export type MyContentRawRow = {
  uuid: string
  nid: string
  title: string
  field_space_lead: string
  status: string
  field_spaces_translation_type: string
  content_translation_source: string
  langcode: string
  publish_on: string
  unpublish_on: string
  changed: string
  field_space?: string
}

export function useUserSpaceContent() {
  const { data, error, mutate } = useSWR<
    Record<number, Record<string, MyContentRawRow>>
  >([`${API_DOMAIN}/spaces/all`, init], fetcherFullRequest)

  return {
    content:
      data &&
      Object.entries(data).reduce(
        (
          res: Record<string, Record<string, MyContentRow>>,
          [nid, articleLanguages]
        ) => {
          res[nid] = Object.entries(articleLanguages).reduce(
            (
              mappedArticle: Record<string, MyContentRow>,
              [key, translation]
            ) => {
              mappedArticle[key] = {
                id: translation.uuid,
                drupal_internal__nid: translation.nid,
                title: translation.title,
                field_lead: translation.field_space_lead,
                published: !!+translation.status,
                field_translation_type:
                  translation.field_spaces_translation_type,
                content_translation_source:
                  translation.content_translation_source,
                langcode: translation.langcode,
                publish_on:
                  translation.publish_on === '1970-01-01 00:00:00'
                    ? ''
                    : translation.publish_on,
                unpublish_on: translation.unpublish_on,
                changed: translation.changed,
                field_space: translation.field_space
              }
              return mappedArticle
            },
            {}
          )
          return res
        },
        {}
      ),
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

export function useUserNewsContent() {
  const { data, error, mutate } = useSWR(
    [`${API_DOMAIN}/news_api/all`, init],
    fetcherFullRequest
  )

  return {
    content: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  }
}

export function deleteUserArticleContent(id: string, lang: string) {
  return fetch(`${API_DOMAIN}/article/delete?nid=${id}&langs=${lang}`, {
    method: 'DELETE',
    ...init
  })
}

export function deleteGroupArticleContent(id: string, type: string) {
  return fetch(
    `${API_DOMAIN}/jsonapi/node/${
      type === NEWS_ARTICLE_TYPE ? 'news' : 'space_article'
    }/${id}`,
    {
      method: 'DELETE',
      ...init
    }
  )
}
