import {
  Body5,
  Box,
  Button,
  CloseIcon16,
  Line,
  Pagination,
  Space,
  Spinner
} from '@sefar/design-system'
import { Link, useSearchParams } from 'react-router-dom'
import { newsCreatePageUrl, newsPageUrl } from '../../app'
import {
  filterContent,
  MyContentFilters,
  searchParamsNames
} from './my-content-filters'
import { LastUpdateHeader, Table, TH, TR } from './common'
import {
  MyContentRow,
  NEWS_ARTICLE_TYPE,
  NewsArticle,
  SEARCH_PAGE_LIMIT,
  useCountries,
  useLanguages,
  useUserNewsContent
} from '../../api'
import { MyContentArticle } from './my-content-article'
import React, { useEffect, useState } from 'react'
import { TranslateDialog } from './translate-dialog'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { DeleteArticleDialog } from './deleteArticleDialog'
import { sessionStorageKeys } from '../../constants'

export function MyNewsArticles() {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const [searchParams, setSearchParams] = useSearchParams()
  const { content, isLoading, mutate } = useUserNewsContent()
  const [activeArticle, setActiveArticle] = useState(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [selectedArticle, setSelectedArticle] = useState<NewsArticle>()
  const [filteredNewsContent, setFilteredNewsContent] = useState(
    filterContent(content, searchParams)
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isHasTranslations, setIsHasTranslations] = useState(false)
  const [isOriginal, setIsOriginal] = useState(false)
  const { languages } = useLanguages()
  const { countries } = useCountries()

  useEffect(() => {
    const cachedSearchParams = sessionStorage.getItem(
      sessionStorageKeys.MY_CONTENT_FILTERS
    )
    if (cachedSearchParams && !searchParams.toString()) {
      setSearchParams(new URLSearchParams(cachedSearchParams))
    }
    setFilteredNewsContent(filterContent(content, searchParams))
  }, [searchParams, content])

  return (
    <>
      {isLoading && (
        <Box
          css={{
            flexGrow: 1,
            d: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Spinner />
        </Box>
      )}

      {!isLoading && (
        <Space mt="LG">
          <Box
            as={Link}
            to={`/${newsCreatePageUrl}`}
            css={{ textDecoration: 'none', d: 'inline-flex' }}
          >
            <Button>
              <Box
                as="span"
                className="icon icon-left"
                css={{
                  svg: {
                    transform: 'rotate(45deg)',
                    maxWidth: '12px'
                  }
                }}
              >
                <CloseIcon16 />
              </Box>
              Add news article
            </Button>
          </Box>
        </Space>
      )}

      {content && Object.keys(content).length > 0 && (
        <>
          {isLg && <Line css={{ mt: '$12', mb: '$10' }}></Line>}

          <MyContentFilters languages={languages} countries={countries} />

          <Space my="XL">
            <Table>
              <thead>
                {isLg ? (
                  <TR header={true}>
                    <th></th>
                    <th>
                      <Body5>Title</Body5>
                    </th>
                    <th>
                      <LastUpdateHeader />
                    </th>
                    <TH css={{ width: 130 }}>
                      <Body5>Status</Body5>
                    </TH>
                    <TH css={{ width: 100 }}>
                      <Body5>Language(s)</Body5>
                    </TH>
                    <TH css={{ width: 100 }}>
                      <Body5>Creator/Author</Body5>
                    </TH>
                    <th></th>
                  </TR>
                ) : (
                  <tr>
                    <TH
                      colSpan="3"
                      css={{
                        borderBottom: '1px solid $neutralLighten80'
                      }}
                    >
                      <LastUpdateHeader />
                    </TH>
                  </tr>
                )}
              </thead>

              <tbody>
                {content &&
                  filteredNewsContent
                    .sort((a, b) => {
                      const bTime = new Date(
                        b?.changed?.replace(' ', 'T')
                      ).getTime()
                      const aTime = new Date(
                        a?.changed?.replace(' ', 'T')
                      ).getTime()
                      return +searchParams.get('sort')
                        ? aTime - bTime
                        : bTime - aTime
                    })
                    .slice(
                      (+searchParams.get('page') || 0) * SEARCH_PAGE_LIMIT,
                      ((+searchParams.get('page') || 0) + 1) * SEARCH_PAGE_LIMIT
                    )
                    .map((article) => (
                      <MyContentArticle
                        key={`${article?.drupal_internal__nid}-${article?.langcode}`}
                        to={(article: MyContentRow) =>
                          `/${newsPageUrl}/${article.id}/${article.langcode}`
                        }
                        setIsOriginal={setIsOriginal}
                        setActiveArticle={setActiveArticle}
                        content={content}
                        article={article}
                        setOpenDialog={setOpenDialog}
                        setSelectedArticle={setSelectedArticle}
                        setIsModalOpen={setIsModalOpen}
                        setIsHasTranslations={setIsHasTranslations}
                        onPublishAll={() => {
                          mutate()
                          searchParams.set('page', '0')
                          setSearchParams(searchParams)
                        }}
                      />
                    ))}
              </tbody>
            </Table>

            <Space mt="LG" css={{ d: 'flex', justifyContent: 'flex-end' }}>
              <Pagination
                currentPage={+searchParams.get('page') || 0}
                count={filteredNewsContent.length}
                limit={SEARCH_PAGE_LIMIT}
                onChange={(value) => {
                  searchParams.set('page', value)
                  setSearchParams(searchParams)
                }}
              />
            </Space>
          </Space>
        </>
      )}

      <DeleteArticleDialog
        type={NEWS_ARTICLE_TYPE}
        isOriginal={isOriginal}
        onDelete={() => mutate()}
        activeArticle={activeArticle}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        isHasTranslations={isHasTranslations}
      />

      <TranslateDialog
        article={selectedArticle}
        onTranslate={() => mutate()}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  )
}
