import { useConfigStore } from '../state/config'
import { defaultTranslations } from '../defaultTranslations'

export function useTranslate() {
  const translate = (key: string, placeholders?: string[]) => {
    const translations = useConfigStore.getState().translations
    const trans = translations?.[key]?.trim() ?? defaultTranslations[key] ?? ''
    return (
      placeholders?.reduce((acc: string, placeholder: string, i: number) => {
        return acc.replace(`$${i + 1}`, placeholder || '')
      }, trans) || trans
    )
  }

  return {
    t: translate
  }
}
