import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useMedia } from 'react-use'
import {
  ArrowRightIcon16,
  Body4,
  Body5,
  Box,
  Container,
  Heading3,
  Heading4,
  LazyImage,
  Spinner,
  TextLink
} from '@sefar/design-system'
import { SPACE_ARTICLE_TYPE, SpaceUpdate, useSpaceUpdates } from '../../api'
import { spacesPageUrl, subSpacePageUrl } from '../../app'
import { formatArticleDate } from '../../components'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'

type Props = {
  spaceUpdate: SpaceUpdate
}

const HomeSpacesUpdateCard = ({ spaceUpdate }: Props) => {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)

  return (
    <Link
      to={
        spaceUpdate.type === SPACE_ARTICLE_TYPE
          ? `/${spacesPageUrl}/${spaceUpdate.id}`
          : `/${subSpacePageUrl}/${spaceUpdate.space}/${spaceUpdate.id}`
      }
      style={{
        textDecoration: 'none',
        width: isLg ? 'calc(50% - 20px)' : '100%'
      }}
    >
      <Box
        css={{
          d: 'flex',
          alignItems: 'center',
          gap: '1rem',
          cursor: 'pointer',
          '&:hover': { opacity: 0.7 }
        }}
      >
        <Box
          css={{
            w: isLg ? 140 : 100,
            h: 100,
            flexShrink: 0,
            borderRadius: '$4',
            overflow: 'hidden'
          }}
        >
          <LazyImage
            src={spaceUpdate.images?.Smallpreview?.url}
            aspectRatio={isLg ? '7 / 5' : '1 / 1'}
          />
        </Box>
        <Box>
          <Box
            css={{
              color: '$neutralLighten30',
              fontSize: isLg ? '$body6' : '$body7',
              lineHeight: '18px'
            }}
          >
            {spaceUpdate.category ?? ''}
            {spaceUpdate.category ? ' · ' : ''}
            {formatArticleDate(spaceUpdate.date)}
          </Box>
          <Body5
            css={{
              color: '$black',
              mt: '2px'
            }}
            fontWeight="bold"
          >
            {spaceUpdate.title}
          </Body5>
        </Box>
      </Box>
    </Link>
  )
}

export const HomeSpacesUpdates = () => {
  const { updates, isLoading } = useSpaceUpdates()
  const navigate = useNavigate()

  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const TitleComponent = isLg ? Heading3 : Heading4

  return (
    <Box
      css={{
        background: '$primaryBlueLighten95',
        width: '100%',
        mb: '$16',
        py: isLg ? '$10' : '$15',
        px: isLg ? undefined : '$4'
      }}
    >
      <Container>
        <Box
          css={{
            d: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <TitleComponent css={{ mb: '$8' }}>Spaces updates</TitleComponent>
          <Body4 css={{ mt: isLg ? '$2' : undefined }}>
            <TextLink
              css={{
                fontSize: '$body4',
                lineHeight: '$8'
              }}
              className="no-underline"
              onClick={() => navigate(`/${spacesPageUrl}`)}
            >
              <Box as="span" css={{ mr: '$2' }}>
                Go to spaces
              </Box>
              <ArrowRightIcon16 />
            </TextLink>
          </Body4>
        </Box>
        <Box
          css={{
            d: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            columnGap: '$10',
            rowGap: '$5'
          }}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            updates.map((spaceUpdate: SpaceUpdate) => (
              <HomeSpacesUpdateCard
                key={spaceUpdate.id}
                spaceUpdate={spaceUpdate}
              />
            ))
          )}
        </Box>
      </Container>
    </Box>
  )
}

export default HomeSpacesUpdates
