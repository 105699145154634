import {
  ArrowRightIcon16,
  Badge,
  Body4,
  Body5,
  Body6,
  Box,
  Button,
  Spinner
} from '@sefar/design-system'
import { Idea, IdeaStatusesTranslations, useIdeas } from '../../api'
import { useTranslate } from '../../hooks/useTranslate'
import { useConfigStore } from '../../state/config'
import MyIdeasIcon from '../../../assets/MyIdeas.png'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { ideasPageUrl } from '../../app'
import { TR, TD } from '../my-content/common'
import {
  likeCounterTableStyles,
  LikesCounter
} from '../../components/article/article-info'
import { DateOfRealization } from '../../components/ideas/date-of-realization'
import { mapIdeaStatusesToBadgeColors } from '../../components/ideas/utils'

const MyIdeas = () => {
  const { me } = useConfigStore.getState()
  const { ideas, isLoading } = useIdeas({ author: me?.internalUid })
  const { t } = useTranslate()
  const navigate = useNavigate()
  const [_, setSearchParams] = useSearchParams()

  return (
    <Box
      css={{
        backgroundColor: '$white',
        height: 256,
        border: '1px solid #EFEFEF',
        borderRadius: '$2',
        p: '$4',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box css={{ d: 'flex', alignItems: 'center', mb: '$2' }}>
        <Body4 fontWeight="bold">{t('field_idea_my_ideas')}</Body4>
        {ideas?.length && (
          <Box
            css={{
              backgroundColor: '$primaryBlueLighten90',
              h: 23,
              minWidth: 23,
              borderRadius: 13,
              ml: '$2',
              d: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              pl: '$2',
              pr: '$2'
            }}
          >
            <Body6 fontWeight="bold" css={{ color: '$primaryBlue' }}>
              {ideas.length}
            </Body6>
          </Box>
        )}
        <Box
          css={{
            d: 'flex',
            alignItems: 'center',
            opacity: 1,
            transition: 'opacity .3',
            cursor: 'pointer',
            ml: 'auto',
            '&:hover': { opacity: 0.7 }
          }}
          onClick={() => {
            if (me?.internalUid) setSearchParams({ author: me?.internalUid })
          }}
        >
          <Body4
            fontWeight="bold"
            css={{
              color: '$primaryBlue',
              textDecoration: 'underline'
            }}
          >
            {t('field_idea_view_all')}
          </Body4>
        </Box>
      </Box>
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          flexDirection: 'column'
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : ideas?.length ? (
          <table style={{ width: '100%', tableLayout: 'fixed' }}>
            {ideas.slice(0, 3).map((idea: Idea) => (
              <TR
                hover={idea.implementer?.id !== me?.id}
                css={{
                  backgroundColor:
                    idea.implementer?.id === me?.id
                      ? '$primaryBlueLighten95'
                      : '',
                  '&:hover': {
                    backgroundColor:
                      idea.implementer?.id === me?.id
                        ? '$primaryBlueLighten90'
                        : ''
                  },
                  width: '100%'
                }}
              >
                <TD css={{ paddingTop: '$2', paddingBottom: '$2', width: 70 }}>
                  <LikesCounter
                    article={idea}
                    likeProps={{ css: likeCounterTableStyles }}
                  />
                </TD>
                <TD css={{ paddingTop: '$2', paddingBottom: '$2' }}>
                  <Box css={{ position: 'relative' }}>
                    <Box
                      css={{
                        d: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline'
                      }}
                    >
                      <Body5
                        fontWeight="bold"
                        as={Link}
                        to={`/${ideasPageUrl}/${idea.id}`}
                        css={{
                          textDecoration: 'none',
                          color: '$neutral',
                          position: 'relative'
                        }}
                      >
                        {idea.title}
                      </Body5>
                      <Box
                        css={{
                          d: 'flex',
                          gap: '$1',
                          flexDirection: 'column',
                          '@lg': { flexDirection: 'row' }
                        }}
                      >
                        <Badge
                          status={mapIdeaStatusesToBadgeColors[idea.status]}
                        >
                          {t(IdeaStatusesTranslations[idea.status])}
                        </Badge>
                        <DateOfRealization idea={idea} />
                      </Box>
                    </Box>
                  </Box>
                </TD>
              </TR>
            ))}
          </table>
        ) : (
          <>
            <img src={MyIdeasIcon} alt="my-ideas" />
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                navigate(`/${ideasPageUrl}/create?editMode=true`)
              }}
            >
              {t('field_ideas_add_your_idea')}
            </Button>
          </>
        )}
      </Box>
    </Box>
  )
}

export default MyIdeas
