import { useState } from 'react'
import {
  Avatar,
  AvatarImage,
  Body5,
  Box,
  ChevronDownIcon16
} from '@sefar/design-system'
import { SelectUserDialog, SelectUserDialogProps } from '.'
import AvatarPlaceholder from '../../assets/avatar-placeholder-blue.jpg'
import { User } from '../api'

type SelectUserProps = {
  user?: User
  setUser: (implementer: User) => void
  placeholder?: string
  dialogProps?: Partial<SelectUserDialogProps>
}

const SelectUser = ({
  setUser,
  user,
  placeholder = 'Please select user',
  dialogProps = {}
}: SelectUserProps) => {
  const [open, setOpen] = useState(false)

  return (
    <Box>
      <Box
        onClick={() => setOpen(true)}
        css={{
          width: '100%',
          border: '1px solid $neutralLighten80',
          borderRadius: '$1',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          px: '$3',
          cursor: 'pointer',
          position: 'relative'
        }}
      >
        {user ? (
          <Box css={{ display: 'flex', alignItems: 'center' }}>
            <Avatar css={{ width: 27, height: 27 }}>
              <AvatarImage
                src={user?.image || AvatarPlaceholder}
                alt="avatar"
              />
            </Avatar>
            <Body5 css={{ color: '$neutral', ml: '$4' }}>
              {user.firstName} {user.lastName}
            </Body5>
          </Box>
        ) : (
          <Body5 css={{ color: '$neutral' }}>{placeholder}</Body5>
        )}
        <Box
          css={{
            position: 'absolute',
            right: '$4',
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ChevronDownIcon16 />
        </Box>
      </Box>
      <SelectUserDialog
        title="Assign user"
        description="Select the user"
        saveButtonLabel="Assign"
        open={open}
        setOpen={setOpen}
        onSetImplementer={(user) => {
          setUser(user)
          setOpen(false)
        }}
        {...dialogProps}
      />
    </Box>
  )
}

export default SelectUser
