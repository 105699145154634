import { Body5, Box } from '@sefar/design-system'
import type * as Stitches from '@stitches/react'
import { useTranslate } from '../../hooks/useTranslate'

export const MustReadBadge = ({ css }: { css?: Stitches.CSS }) => {
  const { t } = useTranslate()

  return (
    <Box
      css={{
        background: 'linear-gradient(90deg, #F4943F 0%, #E32127 99.81%)',
        px: '$3',
        py: '2px',
        borderRadius: '$2',
        w: 'fit-content',
        ...css
      }}
    >
      <Body5 fontWeight="bold" css={{ color: '$white' }}>
        {t('field_must_read_label')}
      </Body5>
    </Box>
  )
}
