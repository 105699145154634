import { TextLink } from '../text-link/text-link'
import { Box } from '../utils'
import {
  ChevronLeftIcon16,
  ChevronRightIcon16,
  ChevronRightDoubleIcon16,
  ChevronLeftDoubleIcon16
} from '../icons'
import { styled } from '../../../stitches.config'
import { useUpdateEffect } from 'react-use'
import { useState } from 'react'

const TextPageLink = styled(TextLink, {
  d: 'flex',
  alignItems: 'center',
  gap: '$1',
  p: '$2 $3',
  '&:hover': {
    borderBottomColor: 'transparent !important',
    backgroundColor: '$primaryBlueLighten95'
  }
})

const getState = (limit: number, currentPage: number, count: number) => {
  const maxPage = Math.ceil(count / limit)
  const prevPage = Math.max(
    currentPage === maxPage - 1 ? maxPage - 2 : currentPage,
    1
  )
  const pages = [prevPage, prevPage + 1, prevPage + 2].filter(
    (n) => n && n <= maxPage
  )

  return { maxPage, prevPage, pages }
}

export function Pagination({
  currentPage,
  count,
  limit,
  onChange,
  translations
}: {
  currentPage: number
  count: number
  limit: number
  onChange: (page: number) => void
  translations?: Record<string, string>
}) {
  const [state, setState] = useState<{
    maxPage: number
    prevPage: number
    pages: number[]
  }>(() => getState(limit, currentPage, count))

  useUpdateEffect(() => {
    setState(getState(limit, currentPage, count))
  }, [count, limit, currentPage])

  return state && count ? (
    <Box css={{ d: 'flex' }}>
      <TextPageLink
        key="start"
        onClick={() => onChange(0)}
        className={currentPage === 0 ? 'disabled' : ''}
      >
        <ChevronLeftDoubleIcon16 />

        <Box as="span" css={{ '@sm': { d: 'none' } }}>
          {translations?.field_first || 'First'}
        </Box>
      </TextPageLink>
      <TextPageLink
        key="prev"
        onClick={() => onChange(currentPage - 1)}
        className={currentPage === 0 ? 'disabled' : ''}
      >
        <ChevronLeftIcon16 />
        <Box as="span" css={{ '@sm': { d: 'none' } }}>
          {translations?.field_previous || 'Previous'}
        </Box>
      </TextPageLink>
      {state.pages.map((n) => (
        <TextPageLink
          key={n}
          onClick={() => onChange(n - 1)}
          css={currentPage + 1 === n ? { color: '$primaryBlueDarken30' } : {}}
        >
          {n}
        </TextPageLink>
      ))}
      <TextPageLink
        key="next"
        className={currentPage === state.maxPage - 1 ? 'disabled' : ''}
        onClick={() => onChange(currentPage + 1)}
      >
        <Box as="span" css={{ '@sm': { d: 'none' } }}>
          {translations?.field_next || 'Next'}
        </Box>
        <ChevronRightIcon16 />
      </TextPageLink>
      <TextPageLink
        key="end"
        className={currentPage === state.maxPage - 1 ? 'disabled' : ''}
        onClick={() => onChange(state.maxPage - 1)}
      >
        <Box as="span" css={{ '@sm': { d: 'none' } }}>
          {translations?.field_last || 'Last'}
        </Box>

        <ChevronRightDoubleIcon16 />
      </TextPageLink>
    </Box>
  ) : (
    <></>
  )
}
