import {
  Body5,
  Box,
  Button,
  Dialog,
  Space,
  TextArea
} from '@sefar/design-system'
import React, { useState } from 'react'
import { useTranslate } from '../../hooks/useTranslate'

export function ImplementedConfirmationDialog({
  open,
  setOpen,
  onConfirm
}: {
  open: boolean
  setOpen: (open: boolean) => void
  onConfirm: (feedbackMessage: string) => void
}) {
  const [summaryMessage, setSummaryMessage] = useState<string>('')
  const { t } = useTranslate()

  const onClickSetToImplemented = () => onConfirm(summaryMessage)

  return (
    <Dialog
      setOpenControlledDialog={setOpen}
      open={open}
      title={t('field_idea_implemented_confirm_t')}
      contentProps={{ css: { '@lg': { width: 550 } } }}
      footer={
        <Box css={{ d: 'flex', justifyContent: 'flex-end', gap: '$3' }}>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t('field_cancel')}
          </Button>
          <Button onClick={onClickSetToImplemented} variant="primary">
            {t('field_idea_implemented')}
          </Button>
        </Box>
      }
    >
      <Space mb="MD">
        <Body5>{t('field_idea_implemented_confirm_d')}</Body5>
      </Space>

      <Space mb="MD">
        <Body5>{t('field_idea_implemented_desc')}</Body5>
      </Space>

      <TextArea
        value={summaryMessage}
        label={`${t('field_idea_implemented_summary')}*`}
        id="summary"
        name="summary"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setSummaryMessage(e.target.value)
        }
        css={{ minHeight: '200px' }}
      />
    </Dialog>
  )
}
