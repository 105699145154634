import React from 'react'
import { Body5, Button, Dialog, Space } from '@sefar/design-system'
import { useTranslate } from '../../../../hooks/useTranslate'

export function DeleteConfirmationDialog({
  isOpen = false,
  onDelete,
  onClose
}: {
  isOpen?: boolean
  onDelete: () => void
  onClose: () => void
}) {
  const { t } = useTranslate()

  const onConfirm = () => {
    onDelete()
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      closeButtonProps={{ onClick: onClose }}
      title={t('field_purchase_requisition_delete_title')}
      footer={
        <Space
          css={{
            d: 'flex',
            justifyContent: 'flex-end',
            w: '100%',
            gap: '$2'
          }}
        >
          <Button variant="secondary" onClick={onClose}>
            {t('field_cancel')}
          </Button>
          <Button onClick={onConfirm}>
            {t('field_purchase_requisition_actions_delete')}
          </Button>
        </Space>
      }
      innerContentCss={{ maxWidth: '32rem' }}
    >
      <Body5>{t('field_purchase_requisition_delete_message')}</Body5>
    </Dialog>
  )
}
