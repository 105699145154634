import {
  ArrowRightIcon16,
  Body4,
  Box,
  Heading3,
  StarOutline16,
  TextLink
} from '@sefar/design-system'
import { useTranslate } from '../../hooks/useTranslate'

type Props = {
  scrollToMainSection: () => void
}

export const FavouriteSpacesEmptyState = ({ scrollToMainSection }: Props) => {
  const { t } = useTranslate()

  return (
    <Box css={{ textAlign: 'center', color: '$neutralLighten30' }}>
      <StarOutline16 width={48} height={48} />
      <Heading3 css={{ mt: '$4' }}>
        {t('field_favourites_empty_title')}
      </Heading3>
      <Body4 css={{ mt: '$2' }}>{t('field_favourites_empty_desc')}</Body4>
      <Body4 css={{ mt: '$6', mb: '$4' }}>
        <TextLink
          css={{
            fontSize: '$body4',
            lineHeight: '$8'
          }}
          className="no-underline"
          onClick={scrollToMainSection}
        >
          <Box as="span" css={{ mr: '$2' }}>
            {t('field_favourites_empty_cta')}
          </Box>
          <ArrowRightIcon16 />
        </TextLink>
      </Body4>
    </Box>
  )
}
