import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  Input,
  Space,
  EditIcon16
} from '@sefar/design-system'
import { useTranslate } from '../../hooks/useTranslate'

export function RenameSubspaceDialog({
  currentName = '',
  onSave
}: {
  currentName?: string
  onSave: (name: string) => void
}) {
  const { t } = useTranslate()
  const [isOpen, setIsOpen] = useState(false)
  const [subspaceName, setSubspaceName] = useState(currentName)

  return (
    <Dialog
      title={t('field_rename_subspace_title')}
      open={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      trigger={
        <Button
          variant="secondary"
          icon
          aria-label={t('field_rename_subspace_title')}
        >
          <EditIcon16 />
        </Button>
      }
      footer={
        <Space
          css={{
            d: 'flex',
            justifyContent: 'flex-end',
            gap: '$3'
          }}
        >
          <Button
            variant="secondary"
            data-cy="cancelFileInfoDialog"
            onClick={() => {
              setIsOpen(false)
              setSubspaceName(currentName)
            }}
          >
            {t('field_cancel')}
          </Button>
          <Button
            data-cy="insertFileInfoDialog"
            disabled={!subspaceName}
            onClick={() => {
              onSave(subspaceName)
              setIsOpen(false)
            }}
          >
            {t('field_save')}
          </Button>
        </Space>
      }
    >
      <Box
        css={{
          d: 'flex',
          alignItems: 'center',
          '@md': {
            pr: '$4',
            minWidth: '480px'
          }
        }}
      >
        <Input
          label={`${t('field_rename_subspace_description')}`}
          id="title_of_subspace"
          value={subspaceName}
          onChange={(e) => setSubspaceName(e.target.value)}
        />
      </Box>
    </Dialog>
  )
}
