import { Body7 } from '../typography'

import { styled } from '../../../stitches.config'

export const Badge = styled(Body7, {
  color: '$neutral',
  px: '$2',
  py: 2,
  borderRadius: 10,
  d: 'inline-block',
  transition: '$default',

  variants: {
    status: {
      grayed: {
        backgroundColor: '$neutralLighten93'
      },
      progressLight: {
        backgroundColor: '$primaryBlueLighten95'
      },
      progress: {
        backgroundColor: '$primaryBlueLighten90'
      },
      pendingLight: {
        backgroundColor: '$purpleLighten75'
      },
      pending: {
        backgroundColor: '$orangeLighten75'
      },
      success: {
        backgroundColor: '$successLighten80'
      },
      warning: {
        color: '$white',
        backgroundColor: '$primaryRed'
      },
      error: {
        backgroundColor: '$errorLighten80'
      }
    },
    variant: {
      clickable: {
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
          backgroundColor: '$orangeLighten50'
        }
      }
    }
  }
})
