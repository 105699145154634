import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { patchSpacesArticle, SpaceArticle, SUBSPACE_TYPE } from '../../api'
import {
  Box,
  Button,
  DateRangeDateOutput,
  Tooltip,
  ToastTypeEnum,
  useToastContext
} from '@sefar/design-system'
import { myContentPageUrl } from '../../app'
import { convertDateToSend, updateMediaSrc } from '../../components'
import { useLocationStateFrom } from '../../hooks/useLocationStateFrom'
import { ActionsMenuDropdown } from '../../components/article/actions-menu-dropdown'
import { NewsArticleSettings } from '../news-details/news-details-settings'

export const MANDATORY_FIELDS_INFO =
  'Please add all mandatory information: title, lead'

export function SpaceArticlesDetailsActions({
  article,
  settings,
  params,
  onCancelButtonCLick,
  setRequestInProgress,
  isReadyToSave
}: {
  article: SpaceArticle
  settings: NewsArticleSettings
  params: Record<string, string | undefined>
  onCancelButtonCLick: () => void
  setRequestInProgress: (requestInProgress: boolean) => void
  isReadyToSave: boolean
}) {
  const from = useLocationStateFrom([], () => `/${myContentPageUrl}`)
  const setToast = useToastContext()
  const navigate = useNavigate()
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const [publicationDates, setPublicationDates] =
    useState<DateRangeDateOutput>()

  const onHtmlSave = (publishStatus?: boolean) => {
    setRequestInProgress(true)

    const publishDate: Record<string, string> = publicationDates?.start
      ? {
          publish_on: convertDateToSend(publicationDates.start)
        }
      : {}
    const unPublishDate: Record<string, string> = publicationDates?.end
      ? {
          unpublish_on: convertDateToSend(
            publishStatus === false && !publicationDates.end
              ? new Date()
              : publicationDates.end
          )
        }
      : {}

    const publishAttributes: Record<string, boolean> =
      publishStatus !== undefined
        ? { status: publishStatus }
        : !params?.articleId
          ? { status: false }
          : {}

    const { langcode } = settings

    patchSpacesArticle(
      params.articleId!,
      {
        field_space_text: {
          value: updateMediaSrc(article!.text)
        },
        field_space_lead: {
          value: article!.lead
        },
        langcode,
        title: article!.title,
        status: article!.status,
        ...publishDate,
        ...unPublishDate,
        ...publishAttributes
      },
      {
        field_space: {
          data: {
            type: SUBSPACE_TYPE,
            id: params?.subspaceId || article?.subspace?.id
          }
        }
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res?.errors?.length) {
          setToast({
            message: 'Some error occurred. Please try again later.',
            type: ToastTypeEnum.error
          })
        } else {
          setToast({
            message: params?.articleId
              ? `The article has been ${
                  publishStatus !== undefined
                    ? publishStatus
                      ? 'published'
                      : 'unpublished'
                    : 'updated'
                } successfully!`
              : 'The article has been successfully created!',
            type: ToastTypeEnum.success
          })

          navigate(from)
        }
      })
      .finally(() => {
        setRequestInProgress(false)
      })
  }

  return (
    <Box
      css={{
        d: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '$4',
        height: '100%'
      }}
    >
      {!isSm && (
        <Tooltip
          contentProps={{ sideOffset: 1 }}
          text={MANDATORY_FIELDS_INFO}
          active={!isReadyToSave}
        >
          <Button
            variant={article?.status ? 'primary' : 'secondary'}
            onClick={() => onHtmlSave()}
            disabled={!isReadyToSave}
          >
            {article?.status ? 'Save & Publish' : 'Save & Close'}
          </Button>
        </Tooltip>
      )}

      <Button variant="secondary" onClick={onCancelButtonCLick}>
        Discard changes
      </Button>

      <Box css={{ d: 'flex' }}>
        <Tooltip
          contentProps={{ sideOffset: 1 }}
          text={MANDATORY_FIELDS_INFO}
          active={!isReadyToSave}
        >
          <Button
            variant={article?.status ? 'secondary' : 'primary'}
            disabled={isSm ? false : !isReadyToSave}
            onClick={() => {
              if (isReadyToSave) {
                onHtmlSave(!article?.status)
              } else if (isSm) {
                setToast({
                  type: ToastTypeEnum.warning,
                  message: MANDATORY_FIELDS_INFO
                })
              }
            }}
          >
            {!article?.status ? 'Publish' : 'Unpublish'}
          </Button>
        </Tooltip>

        <ActionsMenuDropdown
          article={article}
          isReadyToSave={isReadyToSave}
          onChange={setPublicationDates}
        />
      </Box>
    </Box>
  )
}
