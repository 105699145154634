import {
  Box,
  Button,
  Container,
  ContainerPadding,
  Grid,
  Heading1Styles,
  Space,
  TextArea
} from '@sefar/design-system'
import { NewsMainMedia } from '../../pages/news-details/news-main-media'
import { TipTap } from '../TipTap/TipTap'
import { getSubCategoriesByCategory, getCountryListCategories } from './helpers'
import { fileDrop, FileMeta } from '../../pages/news-details/helpers'
import {
  FileTypeEnum,
  FileTypeHTMLFormat,
  getFileType,
  NewsArticle,
  SelectedValuesType,
  UploadProfiles,
  uploadFileToDAM,
  useCategories,
  MediaAssetsTypes
} from '../../api'
import React, { useEffect, useState } from 'react'
import { insertFileTipTapEditor } from '../TipTap/components/TipTapEditor'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { Editor } from '@tiptap/react'
import { FileInfoDialog } from '../gallery/file-info-dialog'
import { GalleryDialog } from '../gallery/gallery-dialog'
import { AddMediaInput } from '../gallery/add-media-input'
import { useConfigStore } from '../../state/config'

export function ArticleEditor({
  article,
  isEditorFocused,
  onArticleChange,
  onEditorFocusChange,
  enableMainMedia = true,
  uploadProfile,
  contentType,
  leadPlaceholderText
}: {
  article?: NewsArticle
  isEditorFocused: boolean
  onArticleChange: (articlePatch: Partial<NewsArticle>) => void
  onEditorFocusChange: (focus: boolean) => void
  uploadProfile: UploadProfiles
  contentType: keyof typeof MediaAssetsTypes
  enableMainMedia?: boolean
  leadPlaceholderText?: string
}) {
  const { categories } = useCategories()
  const { me: user } = useConfigStore()
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)

  const [chosenFile, setChosenFile] = useState<any>(undefined)
  const [contentFile, setContentFile] = useState<File>()
  const [isGalleryOpened, setIsGalleryOpened] = useState(false)
  const [preparedAssetsTypes, setPreparedAssetsTypes] = useState<
    string | Array<string>
  >('')
  const [isUploadMediaDialogOpened, setIsUploadMediaDialogOpened] =
    useState(false)

  const [editor, setEditor] = useState<Editor>()

  const [filterCategories, setFilterCategories] = useState<SelectedValuesType>(
    []
  )
  const [filterCountries, setFilterCountries] = useState<SelectedValuesType>([])
  const onInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && getFileType(e.currentTarget.files[0])) {
      setIsGalleryOpened(false)
      setContentFile(e.currentTarget.files[0])
      setIsUploadMediaDialogOpened(true)
    }
  }

  useEffect(() => {
    if (contentType) {
      setPreparedAssetsTypes([
        MediaAssetsTypes[contentType].key,
        MediaAssetsTypes[contentType].enum.contentAsset
      ])
    }
  }, [contentType])

  useEffect(() => {
    if (categories && categories.length) {
      setFilterCategories(
        getSubCategoriesByCategory(
          categories,
          contentType,
          MediaAssetsTypes[contentType].enum.contentAsset
        )
      )
      setFilterCountries(getCountryListCategories(categories))
    }
  }, [categories])

  const onMediaFileSelected = () => {
    if (chosenFile && chosenFile.id && editor) {
      insertFileTipTapEditor(
        editor,
        {
          embed_link: chosenFile?.embeds?.original?.url,
          filename: chosenFile?.filename,
          id: chosenFile.id
        },
        chosenFile?.file_properties?.format_type
      )
      setIsGalleryOpened(false)
    }
  }

  return (
    <>
      <Container css={{ mt: '$20', flexGrow: 1 }}>
        <ContainerPadding>
          <Grid>
            <Box
              css={{
                gc: '2 / span 10',
                gcSm: '1 / span 12',
                d: 'flex',
                flexDirection: 'column'
              }}
            >
              <TextArea
                css={{
                  ...Heading1Styles,
                  border: 'none',
                  resize: 'none',
                  boxShadow: 'none'
                }}
                data-cy="articleTitle"
                value={article?.title}
                placeholder="What's the title? *"
                onChange={(e) => onArticleChange({ title: e.target.value })}
                borderless
              />

              <Space my="MD">
                <TextArea
                  css={{
                    fontSize: '$body2Sm',
                    border: 'none',
                    resize: 'none',
                    letterSpacing: '0.15px',
                    boxShadow: 'none',
                    '@md': {
                      fontSize: '$body2Lg'
                    },
                    '@lg': {
                      fontSize: '$body2Xl'
                    }
                  }}
                  data-cy="articleLead"
                  placeholder={
                    leadPlaceholderText ??
                    "The lead text is displayed in the preview of the article. It has to win readers' attention and heart :) *"
                  }
                  onChange={(e) => onArticleChange({ lead: e.target.value })}
                  value={article?.lead}
                  borderless
                />
              </Space>
            </Box>
          </Grid>

          {enableMainMedia && (
            <NewsMainMedia
              mainMedia={article?.media}
              onMainMediaChange={(value) => {
                onArticleChange({ media: { ...article?.media, ...value } })
              }}
              categories={categories}
            />
          )}

          <Grid>
            <Box
              css={{
                gc: '1 / span 12',
                gcXl: '2 / span 10',
                d: 'flex',
                flexDirection: 'column'
              }}
            >
              <TipTap
                text={article?.text}
                onUpdate={(html: string) => {
                  onArticleChange({ text: html })
                }}
                isEditorFocused={isEditorFocused}
                onEditorFocusChange={(param: boolean) =>
                  onEditorFocusChange(param)
                }
                onOpenGallery={() => {
                  setIsGalleryOpened(true)
                }}
                handleDOMEvents={(editorInstance) => {
                  setTimeout(() => {
                    setEditor(editorInstance)
                  }, 0)

                  return {
                    drop: (view: any, e: DragEvent) => {
                      const files = fileDrop(e)
                      if (files?.length && getFileType(files[0])) {
                        e.preventDefault()
                        setContentFile(files[0])
                        setIsUploadMediaDialogOpened(true)
                      }
                    }
                  }
                }}
              />
            </Box>
          </Grid>
        </ContainerPadding>
      </Container>

      <FileInfoDialog
        categories={filterCategories}
        countries={filterCountries}
        file={contentFile}
        open={isUploadMediaDialogOpened}
        setOpen={setIsUploadMediaDialogOpened}
        onInsert={(contentFileMeta: FileMeta, updatedImageFile?: File) => {
          const { category, country, ...meta } = contentFileMeta
          const fileType = getFileType(contentFile)
          if (fileType && contentFile && editor) {
            uploadFileToDAM(
              updatedImageFile ?? contentFile,
              {
                ...meta,
                author: `${user.firstName} ${user.lastName}`
              },
              uploadProfile,
              [...(category || []), ...(country || [])].toString()
            )
              .then((res) => {
                if (res) {
                  insertFileTipTapEditor(
                    editor,
                    {
                      embed_link:
                        fileType === FileTypeEnum.Video ||
                        fileType === FileTypeEnum.Images
                          ? URL.createObjectURL(updatedImageFile ?? contentFile)
                          : res?.embeds?.original?.url,
                      filename: res?.filename,
                      id: res?.id
                    },
                    FileTypeHTMLFormat[fileType],
                    res.id,
                    fileType === FileTypeEnum.Video ||
                      fileType === FileTypeEnum.Images
                      ? res?.embeds?.original?.url
                      : undefined
                  )

                  setContentFile(undefined)
                }
              })
              .catch((err) => {
                console.warn(err)
              })
              .finally(() => {
                setIsUploadMediaDialogOpened(false)
              })
          }
        }}
        onCancel={() => setContentFile(undefined)}
      />

      <GalleryDialog
        open={isGalleryOpened}
        setOpenDialog={setIsGalleryOpened}
        chosenFile={chosenFile}
        setChosenFile={setChosenFile}
        filterCategories={filterCategories}
        filterCountries={filterCountries}
        assetsType={preparedAssetsTypes}
        footer={
          <Box
            css={{
              d: 'flex',
              justifyContent: 'flex-end',
              '@lg': {
                justifyContent: 'space-between'
              }
            }}
          >
            <Box css={{ d: 'flex', alignItems: 'center' }}>
              <>
                {isLg && (
                  <AddMediaInput
                    id="article-file"
                    onInputFileChange={onInputFileChange}
                    size="small"
                  />
                )}
              </>
            </Box>
            <Button
              onClick={onMediaFileSelected}
              disabled={!chosenFile}
              size="small"
            >
              Insert media
            </Button>
          </Box>
        }
      >
        {!isLg && (
          <AddMediaInput
            id="article-file"
            onInputFileChange={onInputFileChange}
            buttonVariant="textLink"
          />
        )}
      </GalleryDialog>
    </>
  )
}
