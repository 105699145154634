import React, { useEffect, useState } from 'react'
import {
  useParams,
  useSearchParams,
  useLocation,
  useNavigate
} from 'react-router-dom'
import {
  useIdea,
  Idea,
  createOrPatchIdea,
  useIdeaCategories,
  IdeaCountries
} from '../../api'
import { useConfigStore } from '../../state/config'
import { IdeaDetailsView } from './idea-details-view'
import { IdeaDetailsEdit } from './idea-details-edit'
import {
  ideaCreatePageUrl,
  ideasPageUrl,
  ideaCreateConfirmationPageUrl
} from '../../app'
import {
  Box,
  Spinner,
  ContainerPadding,
  ToastTypeEnum,
  useToastContext
} from '@sefar/design-system'
import { CloseButton } from './close-button'
import { useTranslate } from '../../hooks/useTranslate'
import { useCanNavigateBack } from '../../hooks/use-can-navigate-back'

export const languageOptions = [
  { value: 'de', label: 'Deutsch' },
  { value: 'en', label: 'English' }
]

export const countryOptions = IdeaCountries.map((country) => ({
  label: country,
  value: country
}))

const defaultIdea: Partial<Idea> = {
  lang: languageOptions[0].value,
  title: '',
  anonymous: true,
  aimsBenefits: '',
  improvement: '',
  problem: '',
  involved: '',
  location: undefined,
  categories: [],
  implementer: undefined
}

export function IdeaDetails() {
  const location = useLocation()
  const params = useParams()
  const setToast = useToastContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslate()
  const { categories } = useIdeaCategories()
  const canNavigateBack = useCanNavigateBack()

  const { contentLang: langConfig } = useConfigStore()
  const lang = params.lang || langConfig

  const {
    idea: ideaTranslated,
    isLoading,
    mutate
  } = useIdea(params.ideaId!, lang)

  const [idea, setIdea] = useState(defaultIdea)
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false)

  useEffect(() => {
    ideaTranslated && setIdea(ideaTranslated)
  }, [`${ideaTranslated?.id}-${ideaTranslated?.commentCount}`])

  useEffect(() => {
    if (location.pathname === `/${ideaCreatePageUrl}`) {
      setSearchParams({ editMode: 'true' })
    }
  }, [])

  const onCategoryTagClick = (category: string) => {
    setIdea((oldIdea: Idea) => ({
      ...oldIdea,
      categories: oldIdea?.categories?.includes(category)
        ? oldIdea.categories.filter((v) => v !== category)
        : [...(oldIdea?.categories || []), category]
    }))
  }

  const onSubmitClick = () => {
    const ideaToSend = {
      langcode: idea.lang,
      title: idea.title,
      field_ideas_privacy: idea.anonymous,
      field_ideas_aims_benefits: idea.aimsBenefits,
      field_ideas_improvement: idea.improvement,
      field_ideas_problem: idea.problem,
      field_ideas_involved_implements: idea.involved,
      field_ideas_location: idea.location,
      field_ideas_category: idea.categories,
      field_ideas_implementer: idea.implementer
    }
    setRequestInProgress(true)
    createOrPatchIdea(ideaToSend, params?.ideaId)
      .then((res) => {
        if (res?.errors?.length) {
          setToast({
            message: t('field_error_common_toast_text'),
            type: ToastTypeEnum.error
          })
        } else {
          setToast({
            message: params?.ideaId
              ? t('field_idea_edit_toast_success')
              : t('field_idea_save_toast_success'),
            type: ToastTypeEnum.success
          })
          if (params?.ideaId) {
            canNavigateBack ? navigate(-1) : navigate(`/${ideasPageUrl}`)
          } else {
            navigate(`/${ideaCreateConfirmationPageUrl}`)
          }
        }
      })
      .catch(() => {
        setToast({
          message: t('field_error_common_toast_text'),
          type: ToastTypeEnum.error
        })
      })
      .finally(() => {
        setRequestInProgress(false)
      })
  }

  return (
    <Box
      css={{
        d: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        justifyContent:
          (params?.ideaId && isLoading) || requestInProgress
            ? 'center'
            : 'flex-start'
      }}
    >
      {(params?.ideaId && isLoading) || requestInProgress ? (
        <Spinner />
      ) : (
        <>
          <Box
            css={{
              d: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              p: '$8',
              mb: '$2'
            }}
          >
            <CloseButton />
          </Box>
          <ContainerPadding css={{ flex: 1 }}>
            {!searchParams.get('editMode') ? (
              <IdeaDetailsView
                idea={idea}
                isLoading={isLoading}
                onCategoryTagClick={onCategoryTagClick}
                onSubmitClick={onSubmitClick}
                categories={categories}
                refresh={mutate}
              />
            ) : (
              <IdeaDetailsEdit
                idea={idea}
                lang={lang}
                setIdea={setIdea}
                setRequestInProgress={setRequestInProgress}
                onCategoryTagClick={onCategoryTagClick}
                onSubmitClick={onSubmitClick}
                categories={categories}
              />
            )}
          </ContainerPadding>
        </>
      )}
    </Box>
  )
}
