import { Box, Tabs } from '@sefar/design-system'
import { SearchInput, useSearchLogic } from '../search/search-utils'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import { useClickAway, useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'

export function HomeSearch({ focused }: { focused: boolean }) {
  const { isLoading, searchParamsUi, changeSearchParamsUi, changeType } =
    useSearchLogic()
  const navigate = useNavigate()
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const isMd = useMedia(BREAKPOINTS_QUERIES.md)

  const onFocus = () => {
    if (isSm) {
      document.body.style.overflowY = 'hidden'
    }
    navigate('/search')
  }
  const ref = useRef(null)
  useClickAway(ref, () => {
    document.body.style.overflowY = 'initial'
  })

  useEffect(() => {
    return () => {
      document.body.style.overflowY = 'initial'
    }
  }, [])

  return (
    <Box
      css={{
        gc: 'span 2',
        '@md': {
          gc: 'span 4'
        },
        '@lg': {
          gc: 'span 6'
        },
        '@xl': {
          gc: 'span 8'
        },
        '@sm': focused
          ? {
              width: '100%'
            }
          : {},
        position: 'relative',
        zIndex: 1
      }}
    >
      <Tabs
        value={searchParamsUi.type as string}
        onValueChange={changeType as (type: string) => void}
      >
        <Box
          ref={ref}
          css={{
            backgroundColor: 'white',
            borderRadius: isSm ? 10 : 26,
            d: 'flex',
            flexDirection: 'column',
            width: 65,
            height: 65,
            alignItems: 'center',
            justifyContent: 'center',
            '@sm': focused
              ? {
                  height: 'calc(var(--app-height) - 155px)',
                  maxHeight: 'calc(var(--app-height) - 155px)'
                }
              : {},
            '@md': {
              width: '100%',
              h: 64,
              maxHeight: 'calc(var(--app-height) - 380px)',
              pt: 0
            },
            '&:hover > fieldset > div': {
              px: '$1'
            }
          }}
        >
          {isMd && (
            <SearchInput
              value={searchParamsUi.search}
              onChange={(value: string) => {
                changeSearchParamsUi({ search: value, page: 0 })
              }}
              disabled={isLoading}
              onFocus={!focused ? onFocus : undefined}
            />
          )}
        </Box>
      </Tabs>
    </Box>
  )
}
