import React, { ComponentProps, ReactNode } from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { TooltipProps } from '@radix-ui/react-tooltip'
import { styled } from '../../../stitches.config'

export const tooltipStyles = {
  backgroundColor: '$white',
  color: '$neutralLighten30',
  padding: '0.5rem 0.75rem',
  maxWidth: '260px',
  boxShadow: '$card',
  border: '1px solid $destructiveDisabled',
  fontSize: '$tooltip',
  lineHeight: '1.1375rem',
  borderRadius: '$3',
  zIndex: '$tooltip'
}

const StyledTooltip = styled(TooltipPrimitive.Content, tooltipStyles)

const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: 'white',
  strokeDasharray: '0 30 30',
  strokeWidth: 3,
  stroke: '$destructiveDisabled',
  position: 'relative',
  top: -1
})

export function Tooltip({
  children,
  text,
  withArrow = true,
  active = true,
  contentProps,
  ...props
}: TooltipProps & {
  children: ReactNode
  text: string
  withArrow?: boolean
  active?: boolean
  contentProps?: ComponentProps<typeof StyledTooltip>
}) {
  return (
    <>
      {active ? (
        <TooltipPrimitive.Provider delayDuration={300}>
          <TooltipPrimitive.Root {...props}>
            <TooltipPrimitive.Trigger asChild>
              {children}
            </TooltipPrimitive.Trigger>
            <TooltipPrimitive.Portal>
              <StyledTooltip {...contentProps}>
                {text}
                {withArrow && <StyledArrow />}
              </StyledTooltip>
            </TooltipPrimitive.Portal>
          </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
