import React, { useEffect, useRef } from 'react'
import { Button, Dialog, Box, Body5 } from '@sefar/design-system'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import { deleteUserArticleContent, deleteGroupArticleContent } from '../../api'

export function DeleteArticleDialog({
  setIsModalOpen,
  activeArticle,
  isHasTranslations,
  onDelete,
  isModalOpen,
  isOriginal,
  type
}) {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const deleteHandler = () => {
    const performedFunction = isHasTranslations
      ? deleteGroupArticleContent(activeArticle?.id, type)
      : deleteUserArticleContent(
          activeArticle?.drupal_internal__nid,
          activeArticle?.langcode
        )
    performedFunction.then((res) => {
      onDelete && onDelete()
    })
    setIsModalOpen(false)
  }

  const cancelButtonRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        cancelButtonRef?.current?.focus()
      }, 300)
    }
  }, [isModalOpen])

  return (
    <Dialog
      setOpenControlledDialog={setIsModalOpen}
      open={isModalOpen}
      title={`Delete ${
        isHasTranslations
          ? 'article group'
          : isOriginal
            ? 'original article and translations'
            : 'article'
      }?`}
      footerCss={{
        '@md': {
          borderTop: 'none'
        }
      }}
      closeButtonProps={{ variant: 'secondary-destructive' }}
      footer={
        <Box
          css={{
            d: 'flex',
            justifyContent: 'flex-end',
            gap: '$3'
          }}
        >
          <Button
            autoFocus={true}
            ref={cancelButtonRef}
            onClick={() => setIsModalOpen(false)}
            variant={'secondary-destructive'}
            size={'medium'}
          >
            Cancel
          </Button>
          <Button
            onClick={deleteHandler}
            variant={'primary-destructive'}
            size={'medium'}
            data-cy="deleteDialogButton"
          >
            Delete
          </Button>
        </Box>
      }
    >
      <Box>
        <Body5
          css={{
            width: `${isLg ? '550px' : '100%'}`
          }}
        >
          {isHasTranslations
            ? 'Are you sure you want to delete this article group?'
            : isOriginal
              ? 'Are you sure you want to delete this article? All its translations will be deleted.'
              : 'Are you sure you want to delete this article?'}
        </Body5>
      </Box>
    </Dialog>
  )
}
