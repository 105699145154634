import {
  useToastContext,
  Box,
  Button,
  ButtonWithLoading,
  ToastTypeEnum
} from '@sefar/design-system'
import { useMe, useUserByEmployeeId } from '../../../../api'
import { useTranslate } from '../../../../hooks/useTranslate'
import { purchaseApi } from '../../api'
import { PurchaseRequisitionStatusType } from '../../consts'
import { useNavigate } from 'react-router-dom'
import { purchaseRequisitionsPageUrl } from '../../../../app'

export const ApproverBottomBar = ({
  requestId,
  requestApprover
}: {
  requestId?: string
  requestApprover?: string
}) => {
  const setToast = useToastContext()
  const { t } = useTranslate()
  const { me } = useMe()
  const navigate = useNavigate()
  const { user: approverData } = useUserByEmployeeId(requestApprover)
  const currentEmployeeId = me?.employeeid
  const isCurrentUserApprover =
    approverData?.id && currentEmployeeId === approverData?.id

  if (!isCurrentUserApprover) {
    return null
  }

  return (
    <Box
      css={{
        position: 'fixed',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '$inlineEditingBottomToolbar',
        px: '$55',
        py: '$5',
        d: 'flex',
        justifyContent: 'flex-end',
        gap: '$4',
        background: '$neutralLighten20',
        zIndex: 100
      }}
    >
      <Box css={{ d: 'flex', gap: '$4' }}>
        <Button
          variant="secondary"
          onClick={() => {
            try {
              requestId &&
                purchaseApi.changeStatusOrderRequest(
                  requestId,
                  PurchaseRequisitionStatusType.Review
                )
              navigate(`/${purchaseRequisitionsPageUrl}`)
            } catch (e) {
              setToast({
                message: t('field_error_common_toast_text'),
                type: ToastTypeEnum.error
              })
            }
          }}
        >
          {t('field_purchase_requisition_actions_to_be_reviewed')}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            try {
              requestId &&
                purchaseApi.changeStatusOrderRequest(
                  requestId,
                  PurchaseRequisitionStatusType.Rejected
                )
              navigate(`/${purchaseRequisitionsPageUrl}`)
            } catch (e) {
              setToast({
                message: t('field_error_common_toast_text'),
                type: ToastTypeEnum.error
              })
            }
          }}
        >
          {t('field_purchase_requisition_actions_reject')}
        </Button>
        <ButtonWithLoading
          onClick={() => {
            try {
              requestId &&
                purchaseApi.changeStatusOrderRequest(
                  requestId,
                  PurchaseRequisitionStatusType.Approved
                )
              navigate(`/${purchaseRequisitionsPageUrl}`)
            } catch (e) {
              setToast({
                message: t('field_error_common_toast_text'),
                type: ToastTypeEnum.error
              })
            }
          }}
        >
          {t('field_purchase_requisition_actions_approve')}
        </ButtonWithLoading>
      </Box>
    </Box>
  )
}
