import { useTranslate } from '../../hooks/useTranslate'
import { Box, Button, Dialog, Space, Spinner } from '@sefar/design-system'
import { SearchInput } from '../../components/search/search-input'
import React, { useEffect, useState } from 'react'
import { editSubspaceEditors, SubSpace, User, useSearchUsers } from '../../api'
import { AssigneeUserCard } from '../../components/common/assignee-user-card'
import { UserWithDelete } from '../../components/common/user-with-delete'
import { KeyedMutator } from 'swr'
import { useConfigStore } from '../../state/config'

export function SelectUser({ onSetUser }: { onSetUser: (user: User) => void }) {
  const [selectedUser, setSelectedUser] = useState<User>()
  const [userSearch, setUserSearch] = useState<string>('')
  const [userSearchQuery, setUserSearchQuery] = useState<
    Record<string, string | number>
  >({ page: 0 })
  const { users, isLoading } = useSearchUsers(userSearchQuery, 50)
  useEffect(() => {
    setUserSearchQuery((oldValue) => ({
      ...oldValue,
      search: userSearch.length > 1 ? userSearch : ''
    }))
  }, [userSearch])

  return (
    <Space
      css={{
        color: '$neutral',
        d: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box css={{ marginBottom: '$4' }}>
        <SearchInput
          small={true}
          placeholder="Search a user"
          data-cy="searchUserInput"
          value={userSearch}
          onChange={setUserSearch}
        />
      </Box>
      <Box
        style={{
          display: 'flex',
          flexGrow: 1,
          overflow: 'auto',
          flexDirection: 'column'
        }}
      >
        {isLoading && (
          <Box
            css={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Spinner />
          </Box>
        )}
        <Space css={{ d: 'flex', gap: '$2', flexDirection: 'column' }}>
          {users?.map((user: User) => (
            <AssigneeUserCard
              key={user?.id}
              selected={user?.id === selectedUser?.id}
              user={user}
              onClick={() => setSelectedUser(user)}
            />
          ))}
        </Space>
      </Box>
      <Box
        css={{
          d: 'flex',
          justifyContent: 'flex-end',
          marginTop: 'auto',
          paddingTop: '$4'
        }}
      >
        <Button
          data-cy="sendToReviewerButton"
          variant="primary"
          disabled={!selectedUser}
          onClick={() => {
            if (selectedUser) onSetUser(selectedUser)
          }}
        >
          Add to Editors
        </Button>
      </Box>
    </Space>
  )
}

export function SpaceEditorsDialog({
  open,
  setOpen,
  subspace,
  refetchSubspace
}: {
  open: boolean
  setOpen: (open: boolean) => void
  subspace: SubSpace
  refetchSubspace: KeyedMutator<any>
}) {
  const { t } = useTranslate()
  const { me } = useConfigStore()
  const [tab, setTab] = useState<'editors' | 'user-search'>('editors')
  return (
    <Dialog
      open={open}
      setOpenControlledDialog={setOpen}
      onOpenChange={(open) => {
        setTab('editors')
      }}
      title={t('field_subspace_editors_title')}
    >
      <Space
        css={{
          color: '$neutral',
          width: 520,
          height: 400
        }}
      >
        {tab === 'editors' && (
          <Box css={{ d: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box>
              <Button
                data-cy="sendToReviewerButton"
                variant="secondary"
                onClick={() => setTab('user-search')}
              >
                Add editor
              </Button>
            </Box>
            <Box
              css={{
                flexGrow: 1,
                overflow: 'auto',
                '@lg': { marginLeft: '-$2', marginRight: '-$2' }
              }}
            >
              <Space mt="MD" css={{ d: 'flex', flexWrap: 'wrap' }}>
                {subspace?.editors?.map((user: User) => (
                  <Box
                    css={{
                      width: '100%',
                      '@lg': { width: '50%', padding: '$2' }
                    }}
                    key={user?.id}
                  >
                    <UserWithDelete
                      user={user}
                      key={user?.id}
                      onDelete={async (user) => {
                        const confirmed = window.confirm(
                          t('field_subspace_editor_removal', [
                            user.firstName,
                            user.lastName
                          ])
                        )
                        if (confirmed) {
                          const editorIds = subspace?.editors
                            ?.map(({ id }) => id)
                            .filter((id) => id !== user.id)
                          await editSubspaceEditors({
                            editorIds,
                            subspaceId: subspace.id
                          })
                          refetchSubspace()
                        }
                      }}
                    />
                  </Box>
                ))}
              </Space>
            </Box>
          </Box>
        )}
        {tab === 'user-search' && (
          <SelectUser
            onSetUser={async (user) => {
              if (subspace.editors) {
                const currentEditors = subspace?.editors.map(({ id }) => id)
                if (user.id === me?.id) {
                  alert(t('field_subspace_selfassign_error'))
                } else if (!currentEditors.includes(user.id)) {
                  const editorIds = [
                    ...subspace.editors.map(({ id }) => id),
                    user.id
                  ]
                  await editSubspaceEditors({
                    editorIds,
                    subspaceId: subspace.id
                  })
                  refetchSubspace()
                  setTab('editors')
                } else {
                  alert(t('field_subspace_already_editor'))
                }
              }
            }}
          />
        )}
      </Space>
    </Dialog>
  )
}
