import React, { useCallback, useEffect, useState } from 'react'
import { EditorContent, useEditor } from '@tiptap/react'
import { EditorProps } from '@tiptap/pm/view'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import { useMedia } from 'react-use'
import Placeholder from '@tiptap/extension-placeholder'
import {
  ScrollDirectionEnum,
  useScrollDirection
} from '../../../hooks/useScrollDirection'
import {
  BREAKPOINTS_QUERIES,
  stitchesConfig,
  styled
} from '../../../../../stitches.config'

import {
  Add16,
  AddColumn16,
  AddFile16,
  Bold16,
  Bold20,
  Box,
  BulletList16,
  BulletList20,
  ChevronLeftIcon16,
  ChevronUpIcon16,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Heading3,
  Heading4,
  Heading5,
  Italics16,
  Italics20,
  Keyboard16,
  Link16,
  Link20,
  OrderedList16,
  OrderedList20,
  Paragraph16,
  Quote16,
  Quote20,
  Redo16,
  Redo20,
  Underline16,
  Underline20,
  Undo16,
  Undo20,
  UnLink16,
  UnLink20
} from '@sefar/design-system'
import Video from './video'
import FileInContent from './FileInContentEditView'
import DraggableItem from './Draggable'
import { Editor } from '@tiptap/react'
import { Figure, ImageOrientation } from './FigureEditView'
import { ColumnExtension } from './ColumnExtention'

const StyledMenuBar = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '64px',
  boxShadow: '0px 3px 8px 0px rgba(0,0,0,0.04)',
  border: '1px solid $neutralLighten90',
  borderRadius: '4px',
  backgroundColor: '$white',
  maxWidth: '100%',
  overflow: 'hidden',

  '@sm': {
    height: '49px'
  }
})

const StyledMenuBarPart = styled('div', {
  display: 'flex',

  '@sm': {
    justifyContent: 'space-between'
  }
})

const StyledMenuBarSubPart = styled('div', {
  display: 'flex',
  padding: '0 0.3rem',

  '@sm': {
    justifyContent: 'space-around'
  },

  '@xs': {
    padding: '0 0.1rem'
  }
})

const StyledMenuBarButton = styled('button', {
  p: '0 $1',
  d: 'inline-block',
  width: 'auto',
  height: 'auto',
  margin: 0,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.125rem',
  outline: 'none',
  border: 'none',
  background: 'none',
  color: 'rgb(70, 70, 70)',
  cursor: 'pointer',

  '&.is-active': {
    background: '$primaryBlue',
    borderRadius: '2px',
    color: '$white',

    svg: {
      g: {
        fill: '$white'
      },
      path: {
        fill: '$white'
      },
      fill: '$white'
    }
  },

  '@md': {
    display: 'flex',
    width: '40px',
    height: '40px',
    margin: '0 $1'
  },

  '@xs': {
    svg: {
      maxWidth: '14px'
    }
  }
})

const StyledSeparator = styled('span', {
  width: '1px',
  backgroundColor: '$neutralLighten90'
})

const textsForDropdown = {
  default: 'Paragraph',
  normal: 'normal text',
  heading: 'Heading'
}

type MenuBarProps = {
  editor: any
  onEditorFocusChange: (value: boolean) => void
  onOpenGallery?: () => void
}

const MenuBar = ({
  editor,
  onEditorFocusChange,
  onOpenGallery
}: MenuBarProps) => {
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const [isMobileMenuExpanded, setIsMobileMenuExpanded] =
    useState<boolean>(false)

  const getDropdownText = () => {
    let dropdownText = textsForDropdown.default
    if (editor.isFocused) {
      dropdownText = textsForDropdown.normal
      if (editor.isActive('heading', { level: 2 })) {
        dropdownText = `${textsForDropdown.heading} 1`
      } else if (editor.isActive('heading', { level: 3 })) {
        dropdownText = `${textsForDropdown.heading} 2`
      } else if (editor.isActive('heading', { level: 4 })) {
        dropdownText = `${textsForDropdown.heading} 3`
      }
    }
    return dropdownText
  }

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }, [editor])

  if (!editor) {
    return null
  }

  return (
    <StyledMenuBar data-cy="tipTapMenuBar">
      <StyledMenuBarPart css={{ paddingLeft: '0.5rem' }}>
        {isMobileMenuExpanded ? (
          <StyledMenuBarButton onClick={() => setIsMobileMenuExpanded(false)}>
            <ChevronLeftIcon16 />
          </StyledMenuBarButton>
        ) : (
          <>
            <StyledMenuBarButton
              onClick={() => editor.chain().focus().undo().run()}
            >
              {isSm ? <Undo16 /> : <Undo20 />}
            </StyledMenuBarButton>
            <StyledMenuBarButton
              onClick={() => editor.chain().focus().redo().run()}
            >
              {isSm ? <Redo16 /> : <Redo20 />}
            </StyledMenuBarButton>
          </>
        )}
        <StyledSeparator
          css={{
            marginLeft: '0.6rem'
          }}
        />
      </StyledMenuBarPart>
      <StyledMenuBarPart css={{ flex: '1' }}>
        {(!isSm || !isMobileMenuExpanded) && (
          <StyledMenuBarSubPart
            css={{
              pl: isSm ? '0.2rem' : '2rem'
            }}
          >
            <DropdownMenu
              trigger={(isOpen) => (
                <DropdownMenuTrigger asChild>
                  <Box
                    css={{
                      d: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      p: isSm ? '0 0.3rem' : '0 0.7rem',
                      borderRadius: '$3',
                      border: isOpen
                        ? '2px solid $primaryBlueLighten50'
                        : '2px solid transparent',
                      boxShadow: isOpen
                        ? '0px 1px 2px 0px rgba(0,0,0,0.05)'
                        : '',
                      minWidth: isSm ? '40px' : '150px'
                    }}
                  >
                    {isSm ? <Paragraph16 /> : <span>{getDropdownText()}</span>}
                    <Box
                      as="span"
                      css={{
                        ml: isSm ? '0.1rem' : '0.5rem',
                        transform: isOpen ? 'translateY(9%)' : 'rotate(180deg)'
                      }}
                    >
                      <ChevronUpIcon16 />
                    </Box>
                  </Box>
                </DropdownMenuTrigger>
              )}
              content={() => (
                <>
                  <DropdownMenuItem
                    onClick={() => editor.chain().focus().setParagraph().run()}
                    active={editor.isActive('paragraph')}
                  >
                    <p>{textsForDropdown.normal}</p>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() =>
                      editor.chain().focus().toggleHeading({ level: 2 }).run()
                    }
                    active={editor.isActive('heading', { level: 2 })}
                  >
                    <Heading3>{`${textsForDropdown.heading} 1`}</Heading3>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() =>
                      editor.chain().focus().toggleHeading({ level: 3 }).run()
                    }
                    active={editor.isActive('heading', { level: 3 })}
                  >
                    <Heading4>{`${textsForDropdown.heading} 2`}</Heading4>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() =>
                      editor.chain().focus().toggleHeading({ level: 4 }).run()
                    }
                    active={editor.isActive('heading', { level: 4 })}
                  >
                    <Heading5>{`${textsForDropdown.heading} 3`}</Heading5>
                  </DropdownMenuItem>
                </>
              )}
              contentProps={{ align: 'start' }}
            />
          </StyledMenuBarSubPart>
        )}
        <StyledMenuBarSubPart
          css={{
            flex: isSm && isMobileMenuExpanded ? '1' : ''
          }}
        >
          {isSm && !isMobileMenuExpanded ? (
            <Box
              css={{
                d: 'flex',
                backgroundColor: '$neutralLighten93',
                p: '0.2rem 0.4rem',
                borderRadius: '$3',

                '@xs': {
                  p: '0.1rem 0.2rem'
                }
              }}
              onClick={() => setIsMobileMenuExpanded(true)}
            >
              <StyledMenuBarButton css={{ '@xs': { maxWidth: '15px' } }}>
                <Bold16 />
              </StyledMenuBarButton>
              <StyledMenuBarButton css={{ '@xs': { maxWidth: '15px' } }}>
                <Italics16 />
              </StyledMenuBarButton>
              <StyledMenuBarButton>
                <Underline16 />
              </StyledMenuBarButton>
            </Box>
          ) : (
            <>
              <StyledMenuBarButton
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'is-active' : ''}
              >
                {isSm ? <Bold16 /> : <Bold20 />}
              </StyledMenuBarButton>
              <StyledMenuBarButton
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'is-active' : ''}
              >
                {isSm ? <Italics16 /> : <Italics20 />}
              </StyledMenuBarButton>
              <StyledMenuBarButton
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                className={editor.isActive('underline') ? 'is-active' : ''}
              >
                {isSm ? <Underline16 /> : <Underline20 />}
              </StyledMenuBarButton>
            </>
          )}
        </StyledMenuBarSubPart>
        {(!isSm || isMobileMenuExpanded) && (
          <StyledMenuBarSubPart
            css={{
              flex: isSm && isMobileMenuExpanded ? '1' : ''
            }}
          >
            <>
              <StyledMenuBarButton
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
              >
                {isSm ? <BulletList16 /> : <BulletList20 />}
              </StyledMenuBarButton>
              <StyledMenuBarButton
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
              >
                {isSm ? <OrderedList16 /> : <OrderedList20 />}
              </StyledMenuBarButton>
              <StyledMenuBarButton
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
              >
                {isSm ? <Quote16 /> : <Quote20 />}
              </StyledMenuBarButton>
            </>
          </StyledMenuBarSubPart>
        )}
        {(!isSm || !isMobileMenuExpanded) && (
          <StyledMenuBarSubPart css={{ flex: isSm ? '1' : '' }}>
            <StyledMenuBarButton
              onClick={setLink}
              className={editor.isActive('link') ? 'is-active' : ''}
            >
              {isSm ? <Link16 /> : <Link20 />}
            </StyledMenuBarButton>
            <StyledMenuBarButton
              onClick={() => editor.chain().focus().unsetLink().run()}
              disabled={!editor.isActive('link')}
            >
              {isSm ? <UnLink16 /> : <UnLink20 />}
            </StyledMenuBarButton>
            {isSm && !isMobileMenuExpanded && (
              <StyledMenuBarButton onClick={onOpenGallery}>
                <AddFile16 />
              </StyledMenuBarButton>
            )}
          </StyledMenuBarSubPart>
        )}
      </StyledMenuBarPart>
      <StyledMenuBarPart css={{ paddingRight: '0.5rem' }}>
        <StyledSeparator
          css={{
            marginRight: '0.6rem'
          }}
        />
        {isSm ? (
          <StyledMenuBarButton
            css={{ mr: '0.5rem' }}
            onClick={() => {
              editor.commands.focus(false)
              onEditorFocusChange(false)
            }}
          >
            <Keyboard16 />
          </StyledMenuBarButton>
        ) : (
          <StyledMenuBarButton
            css={{
              mr: '0.5rem',
              svg: { w: '20px', height: '20px' }
            }}
            onClick={onOpenGallery}
          >
            <AddFile16 />
          </StyledMenuBarButton>
        )}
      </StyledMenuBarPart>
      <StyledMenuBarPart css={{ paddingRight: '0.5rem' }}>
        <StyledSeparator
          css={{
            marginRight: '0.6rem'
          }}
        />
        <StyledMenuBarButton
          css={{
            mr: '0.5rem',
            svg: { w: '20px', height: '20px' }
          }}
          onClick={() => {
            editor.commands.setColumns(2).createParagraphNear()
          }}
        >
          <AddColumn16 />
        </StyledMenuBarButton>
      </StyledMenuBarPart>
    </StyledMenuBar>
  )
}

export function insertFileTipTapEditor(
  editor: Editor,
  file: any,
  fileType?: string,
  id?: string,
  dataSrc?: string,
  poster?: string
) {
  if (fileType === 'video') {
    editor
      .chain()
      .focus()
      .setVideo({ src: file?.embed_link, id, 'data-src': dataSrc, poster })
      .run()
  } else if (fileType === 'image') {
    const img = document.createElement('img')
    img.onload = function () {
      editor.commands.setFigure({
        src: file?.embed_link,
        caption: '',
        id,
        'data-src': dataSrc,
        'data-orientation':
          // @ts-ignore
          this.naturalWidth > this.naturalHeight
            ? ImageOrientation.horizontal
            : ImageOrientation.vertical,
        alt: file?.filename
      })
    }
    img.src = file?.embed_link
  } else {
    editor
      .chain()
      .focus()
      .insertContent(
        `<fileInContent name=${file?.filename} src=${file?.embed_link} preview-id=${file?.id}>`
      )
      .createParagraphNear()
      .run()
  }
}

export type TipTapProps = {
  onUpdate: (value: string) => void
  text?: string
  isEditorFocused: boolean
  onEditorFocusChange: (value: boolean) => void
  handleDOMEvents?: (editor: Editor) => EditorProps['handleDOMEvents']
  onOpenGallery?: () => void
}

export const TipTapEditor = ({
  onUpdate,
  text,
  isEditorFocused,
  onEditorFocusChange,
  handleDOMEvents,
  onOpenGallery
}: TipTapProps) => {
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const [scrollDirection] = useScrollDirection()
  const editor = useEditor({
    extensions: [
      StarterKit.configure({ dropcursor: { width: 3, color: '#4D9DD1' } }),
      Underline,
      Link,
      Figure,
      Image,
      Video,
      FileInContent,
      DraggableItem,
      Placeholder.configure({
        placeholder: 'Write your contents'
      }),
      ColumnExtension
    ],
    content: text,
    onCreate({ editor }) {
      editor.setOptions({
        editorProps: {
          handleDOMEvents: handleDOMEvents && handleDOMEvents(editor as Editor)
        }
      })
    },

    onUpdate: ({ editor }) => {
      onUpdate(editor.getHTML())
    }
  })
  const isFocused = editor?.isFocused

  useEffect(() => {
    if (isFocused) {
      onEditorFocusChange(isFocused)
    }
  }, [isFocused])

  useEffect(() => {
    if (editor && editor.getHTML() !== text) {
      editor.commands.setContent(text ?? null)
    }
  }, [text])

  useEffect(() => {
    if (
      handleDOMEvents &&
      editor &&
      !editor.options.editorProps.handleDOMEvents
    ) {
      editor.setOptions({
        editorProps: {
          handleDOMEvents: handleDOMEvents && handleDOMEvents(editor)
        }
      })
    }
  }, [handleDOMEvents, editor])

  return (
    <Box css={{ d: 'flex', flexDirection: 'column' }}>
      {(!isSm || isEditorFocused) && (
        <Box
          css={{
            position: 'sticky',
            top: isSm
              ? ''
              : scrollDirection === ScrollDirectionEnum.Up
                ? stitchesConfig.theme.sizes.headerHeight
                : 0,
            bottom: isSm ? 0 : '',
            order: isSm ? '1' : '0',
            zIndex: '$inlineEditorMenuBar',
            transitionProperty: 'top',
            transitionTimingFunction: '$easingMove',
            transitionDuration: '500ms',
            mb: isSm ? '' : '1rem',
            width: 'calc(100% + 64px)',
            m: '0 -32px',
            '@sm': {
              width: 'calc(100% + 64px)',
              m: '0 -32px'
            }
          }}
        >
          <MenuBar
            editor={editor}
            onEditorFocusChange={onEditorFocusChange}
            onOpenGallery={onOpenGallery}
          />
        </Box>
      )}
      <EditorContent data-cy="tipTapEditorContent" editor={editor} />
    </Box>
  )
}
