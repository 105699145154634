import { styled } from '../../../stitches.config'
import { StyledComponent } from '@stitches/react/types/styled-component'

export type BooleanString = boolean | 'false' | 'true'

export const StyledFieldsetContainer = styled('fieldset', {
  all: 'unset',
  d: 'flex',
  flexDirection: 'column',
  gap: '$1',
  width: '100%'
})
export const StyledInputLabel: StyledComponent<
  'label',
  {
    disabled?: BooleanString
  },
  any,
  any
> = styled('label', {
  fontSize: '$body6',
  lineHeight: '$14',
  color: '$neutralLighten30',
  mb: '$1',
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none'
      },
      false: {}
    }
  }
})
export const StyledInputMessage = styled('span', {
  fontSize: '$body6',
  lineHeight: '$14',
  color: '$neutralLighten50'
})
