import { useEffect, useState } from 'react'
import {
  getSpaceArticleById,
  Space as SpaceType,
  SPACE_ARTICLE_TYPE,
  SpacesBookmark,
  SpacesBookmarkSimple,
  SUBSPACE_TYPE,
  SubSpaceArticleSimple as SubSpaceArticleSimpleType,
  SubSpaceSimple as SubSpaceSimpleType,
  transferArticleSimple,
  useMe,
  useSpaces
} from '../../../api'
import { useConfigStore } from '../../../state/config'

export const useBookmarksWithDetails = () => {
  const [isBookmarksLoading, setIsBookmarksLoading] = useState(false)
  const [bookmarks, setBookmarks] = useState<SpacesBookmark[]>([])
  const { id: userId } = useConfigStore()
  const { spaces, isLoading } = useSpaces()
  const { me } = useMe(userId)

  const getSpaceBookmarkInfo = (id: string, spaces: SpaceType[]) => {
    const subSpace = spaces
      .map((space: SpaceType) => space.subspaces)
      .flat()
      .find((subspace: SubSpaceSimpleType) => subspace.id === id)
    return {
      ...subSpace,
      spaceTitle: spaces.find(
        (space: SpaceType) => space.id === subSpace?.spaceId
      )?.title,
      type: SUBSPACE_TYPE
    }
  }

  const getSpaceArticleBookmarkInfo = (
    id: string,
    articles: SubSpaceArticleSimpleType[],
    spaces: SpaceType[]
  ) => {
    const article = articles.find((article) => article.id === id)
    const subspace = spaces
      .map((space: SpaceType) => space.subspaces)
      .flat()
      .find(
        (subspace: SubSpaceSimpleType) => subspace.id === article?.subspaceId
      )
    const space = spaces.find((space) => space.id === subspace?.spaceId)
    return {
      ...article,
      subspaceTitle: subspace?.title,
      spaceId: space?.id,
      spaceTitle: space?.title,
      type: SPACE_ARTICLE_TYPE
    }
  }

  useEffect(() => {
    setIsBookmarksLoading(true)
    if (me?.spacesBookmarks && spaces?.length) {
      const promises = me?.spacesBookmarks?.map(
        (bookmark: SpacesBookmarkSimple) => {
          if (bookmark.type === SUBSPACE_TYPE) {
            return bookmark
          }
          return getSpaceArticleById(bookmark.id)
        }
      )

      Promise.all(promises)
        .then(function (results) {
          const articles = results
            .filter((result) => result?.data)
            .map((res) => transferArticleSimple(res?.data))

          // Set user's bookmarks for articles and spaces
          me?.spacesBookmarks &&
            spaces?.length &&
            setBookmarks(
              me?.spacesBookmarks.map((bookmark: SpacesBookmarkSimple) => {
                return bookmark.type === SUBSPACE_TYPE
                  ? getSpaceBookmarkInfo(bookmark.id, spaces)
                  : getSpaceArticleBookmarkInfo(bookmark.id, articles, spaces)
              })
            )
        })
        .catch((err) => console.error(err))
        .finally(() => setIsBookmarksLoading(false))
    }
  }, [me?.id, spaces?.length])

  return { bookmarks, isBookmarksLoading }
}
