import {
  Body5,
  Body7,
  Box,
  DropdownMenu,
  DropdownMenuItem,
  EllipsisHorizontalIcon20,
  FolderClosedIcon20,
  FolderOpenIcon20,
  Space,
  Dialog,
  useGlobalSpinner,
  ToastTypeEnum,
  useToastContext
} from '@sefar/design-system'
import { Link, useSearchParams } from 'react-router-dom'
import {
  formatDateOfficial,
  formatDateTimeOfficial,
  getTime,
  parseFromString
} from '../../components'
import React, { useState } from 'react'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { useConfigStore } from '../../state/config'
import {
  isUserReviewer,
  publishAllTranslations,
  ReviewStateEnum
} from '../../api'
import { AnimatePresence } from 'framer-motion'
import { useMedia } from 'react-use'
import {
  AuthorEditorAvatars,
  getArticleStatus,
  MyContentArticleRowAnimated,
  TD,
  MyContentArticleRowAnimation,
  ReviewStatus
} from './common'
import { useTranslate } from '../../hooks/useTranslate'

function ArticleInfoSmall({
  article,
  originalLang,
  to,
  onFeedbackWithMessageClick
}) {
  return (
    <Box css={{ d: 'flex', flexDirection: 'column', gap: '$2' }}>
      <ReviewStatus
        article={article}
        onFeedbackWithMessageClick={onFeedbackWithMessageClick}
      />
      <Body5 fontWeight="bold">
        {article.langcode?.toUpperCase()}{' '}
        {originalLang === article.langcode && '(Original)'}
      </Body5>

      <Body5
        css={{
          textDecoration: 'none',
          color: '$neutral'
        }}
        as={Link}
        to={to}
      >
        {article.title}
      </Body5>

      <Body7 css={{ color: '$neutralLighten30' }}>
        Last update {formatDateTimeOfficial(article.changed)}
      </Body7>

      <Body7 fontWeight="bold" css={{ color: '$neutralLighten25' }}>
        {getArticleStatus(article)}
      </Body7>
    </Box>
  )
}

export function MyContentArticleRow({
  last,
  originalLang,
  article,
  content,
  setSelectedArticle,
  setOpenDialog,
  setIsModalOpen,
  setActiveArticle,
  setIsOriginal,
  setIsHasTranslations,
  nested,
  to,
  onFeedbackWithMessageClick
}) {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const Actions = (
    <DropdownMenu
      onOpenChange={(open) =>
        setSelectedArticle(
          open ? content[article.drupal_internal__nid] : undefined
        )
      }
      trigger={() => (
        <Box css={{ transform: 'rotate(90deg)', '@lg': { transform: 'none' } }}>
          <EllipsisHorizontalIcon20 />
        </Box>
      )}
      content={(setOpen) => (
        <>
          <DropdownMenuItem
            onClick={(e) => {
              setOpen(false)
              setOpenDialog(true)
            }}
          >
            <Body5 as="span">Translations</Body5>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={(e) => {
              e.stopPropagation()
              setOpen(false)
              setIsModalOpen(true)
              setActiveArticle(article)
              setIsHasTranslations(false)
              setIsOriginal(originalLang === article.langcode)
            }}
            css={{
              borderTop: '1px solid $neutralLighten90'
            }}
          >
            <Body5
              as="span"
              css={{
                color: '$primaryRed'
              }}
            >
              Delete Article
            </Body5>
          </DropdownMenuItem>
        </>
      )}
      contentProps={{
        align: 'center',
        side: 'top'
      }}
    />
  )

  return (
    <MyContentArticleRowAnimated
      hover={true}
      {...MyContentArticleRowAnimation}
      css={{
        position: 'relative',
        '& td': {
          borderBottom: last
            ? '1px solid $neutralLighten80'
            : isLg
              ? '1px solid $neutralLighten90'
              : ''
        }
      }}
    >
      {isLg ? (
        <>
          <td></td>
          <td colSpan={nested ? 2 : 1}>
            <Body5
              css={{
                textDecoration: 'none',
                color: '$neutral'
              }}
              as={Link}
              to={to}
            >
              {article.title}
            </Body5>
            <ReviewStatus
              article={article}
              onFeedbackWithMessageClick={onFeedbackWithMessageClick}
            />
          </td>
          <td>
            <Body5>{formatDateOfficial(article.changed)}</Body5>
            <Body7 css={{ color: '$neutralLighten30' }}>
              {getTime(article.changed)}
            </Body7>
          </td>
          <td>
            <Body5>{getArticleStatus(article)}</Body5>
          </td>
          <TD
            css={{
              textAlign: 'right',
              borderBottom: '1px solid $neutralLighten90'
            }}
          >
            <Body5>{article.langcode?.toUpperCase()}</Body5>
            {originalLang === article.langcode && (
              <Body7 css={{ color: '$neutralLighten30' }}>Original</Body7>
            )}
          </TD>
          <TD
            css={{
              textAlign: 'right',
              borderBottom: '1px solid $neutralLighten90'
            }}
          >
            <AuthorEditorAvatars article={article} />
          </TD>
          <td>{Actions}</td>
        </>
      ) : (
        <>
          <td></td>
          <td>
            <ArticleInfoSmall
              to={to}
              article={article}
              originalLang={originalLang}
              onFeedbackWithMessageClick={onFeedbackWithMessageClick}
            />
          </td>
          <td>{Actions}</td>
        </>
      )}
    </MyContentArticleRowAnimated>
  )
}

export function MyContentArticle({
  content,
  article,
  setSelectedArticle,
  setOpenDialog,
  setIsModalOpen,
  setIsHasTranslations,
  setIsOriginal,
  setActiveArticle,
  nested,
  to,
  onPublishAll
}) {
  const { me } = useConfigStore()
  const setToast = useToastContext()
  const { t } = useTranslate()
  const setGlobalSpinner = useGlobalSpinner()
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const [opened, setOpened] = useState<boolean>(false)
  const [isDialogWithFeedbackOpened, setIsDialogWithFeedbackOpened] =
    useState<boolean>(false)
  const [currentFeedbackMessage, setCurrentFeedbackMessage] =
    useState<string>('')
  const originalLang: string = article && article.langcode
  const isHasTranslations =
    Object.values(content?.[article?.drupal_internal__nid] || {})?.length > 1
  const [searchParams] = useSearchParams()

  const search = searchParams.get('search')
  const status = parseFromString.boolean(searchParams.get('status'))
  const language = searchParams.get('language')
  const target = searchParams.get('target')

  const isArticleNeedsReview = (article) => {
    return (
      article?.review_state === ReviewStateEnum['On review'] &&
      !article.reviewer
    )
  }

  const isUserNotAuthor = (article, me) => {
    return article?.uid !== me?.id
  }

  const feedBackMessageClick = (message: string) => {
    setCurrentFeedbackMessage(message)
    setIsDialogWithFeedbackOpened(true)
  }

  const Actions = (article) => (
    <DropdownMenu
      onOpenChange={(open) =>
        setSelectedArticle(
          open ? content[article.drupal_internal__nid] : undefined
        )
      }
      trigger={() => (
        <Box
          css={{ transform: 'rotate(90deg)', '@lg': { transform: 'none' } }}
          data-cy="articleDropdownButton"
        >
          <EllipsisHorizontalIcon20 />
        </Box>
      )}
      content={(setOpen) => {
        return (
          <>
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
                setOpenDialog(true)
              }}
            >
              <Body5 as="span">Translations</Body5>
            </DropdownMenuItem>
            {isUserReviewer(me) &&
              isArticleNeedsReview(article) &&
              isUserNotAuthor(me, article) && (
                <DropdownMenuItem
                  onClick={(e) => {
                    e.stopPropagation()
                    setOpen(false)
                    setGlobalSpinner({ show: true })
                    publishAllTranslations(article.drupal_internal__nid)
                      .then(() => {
                        onPublishAll()
                      })
                      .catch(() => {
                        setToast({
                          message: t('field_error_common_toast_text'),
                          type: ToastTypeEnum.error
                        })
                      })
                      .finally(() => {
                        setGlobalSpinner({ show: false })
                      })
                  }}
                >
                  <Body5 as="span">
                    {isHasTranslations
                      ? 'Publish Articles Group'
                      : 'Publish Article'}
                  </Body5>
                </DropdownMenuItem>
              )}
            <DropdownMenuItem
              onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
                setIsModalOpen(true)
                setActiveArticle(article)
                setIsHasTranslations(isHasTranslations)
                setIsOriginal(isHasTranslations)
              }}
              data-cy="deleteArticleDropdownButton"
              css={{
                borderTop: '1px solid $neutralLighten90'
              }}
            >
              <Body5
                as="span"
                css={{
                  color: '$primaryRed'
                }}
              >
                {isHasTranslations ? 'Delete Articles Group' : 'Delete Article'}
              </Body5>
            </DropdownMenuItem>
          </>
        )
      }}
      contentProps={{
        align: 'end',
        side: 'bottom'
      }}
    />
  )
  return (
    <>
      <MyContentArticleRowAnimated
        hover={true}
        onClick={() => isHasTranslations && setOpened(!opened)}
        data-cy="myNewsArticles"
        cy-id={article.drupal_internal__nid}
        css={{
          '& td': {
            borderBottom: opened
              ? isLg
                ? '1px solid $neutralLighten90'
                : ''
              : '1px solid $neutralLighten80'
          }
        }}
      >
        {isLg ? (
          <>
            {nested && isHasTranslations && <TD css={{ width: 45 }}></TD>}
            <TD css={{ width: 45 }}>
              {isHasTranslations ? (
                opened ? (
                  <FolderOpenIcon20 />
                ) : (
                  <FolderClosedIcon20 />
                )
              ) : (
                <></>
              )}
            </TD>
            <td colSpan={nested && !isHasTranslations ? 2 : 1}>
              <Body5
                fontWeight="bold"
                css={{
                  textDecoration: 'none',
                  color: '$neutral'
                }}
                as={isHasTranslations ? Body5 : Link}
                to={to(article)}
              >
                {article.title}
              </Body5>
              {!isHasTranslations && (
                <ReviewStatus
                  article={article}
                  onFeedbackWithMessageClick={feedBackMessageClick}
                />
              )}
            </td>
            <td>
              <Body5>{formatDateOfficial(article.changed)} </Body5>
              <Body7 css={{ color: '$neutralLighten30' }}>
                {getTime(article.changed)}{' '}
              </Body7>
            </td>
            <td>
              <Body5>
                {isHasTranslations ? '' : getArticleStatus(article)}
              </Body5>
            </td>
            <TD css={{ textAlign: 'center' }}>
              {isHasTranslations
                ? Object.values(content[article.drupal_internal__nid])?.length
                : article.langcode?.toUpperCase()}
            </TD>
            <TD css={{ textAlign: 'right' }}>
              {!isHasTranslations && <AuthorEditorAvatars article={article} />}
            </TD>
            <td>{Actions(article)}</td>
          </>
        ) : (
          <>
            <td>
              {isHasTranslations ? (
                opened ? (
                  <FolderOpenIcon20 />
                ) : (
                  <FolderClosedIcon20 />
                )
              ) : (
                <></>
              )}
            </td>
            <td>
              {isHasTranslations ? (
                <Box css={{ d: 'flex', flexDirection: 'column', gap: '$2' }}>
                  <Body5
                    fontWeight="bold"
                    css={{
                      textDecoration: 'none',
                      color: '$neutral'
                    }}
                    as={isHasTranslations ? Body5 : Link}
                    to={to(article)}
                  >
                    {article.title}
                  </Body5>
                  <Body7 css={{ color: '$neutralLighten30' }}>
                    Last update {formatDateTimeOfficial(article.changed)}
                  </Body7>
                </Box>
              ) : (
                <ArticleInfoSmall
                  to={to(article)}
                  article={article}
                  originalLang={originalLang}
                  onFeedbackWithMessageClick={feedBackMessageClick}
                />
              )}
            </td>
            <td>
              {isHasTranslations ? (
                <Body7
                  css={{
                    backgroundColor: '$neutralLighten93',
                    borderRadius: '$round',
                    size: 24,
                    d: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {Object.values(content[article.drupal_internal__nid])?.length}
                </Body7>
              ) : (
                Actions(article)
              )}
            </td>
          </>
        )}
      </MyContentArticleRowAnimated>
      <AnimatePresence>
        {isHasTranslations && opened ? (
          Object.values(content[article.drupal_internal__nid])
            .filter(
              ({ title }) =>
                !search ||
                title.toLowerCase().indexOf(search.toLowerCase()) !== -1
            )
            .filter(({ langcode }) => !language || langcode === language)
            .filter(
              ({ published }) => status === undefined || status === published
            )
            .filter(
              ({ publication_target }) =>
                !target ||
                publication_target?.some(({ value }) => value === target)
            )
            .map((a, i, array) => (
              <MyContentArticleRow
                to={to(a)}
                nested={nested}
                setIsModalOpen={setIsModalOpen}
                setActiveArticle={setActiveArticle}
                last={i === array.length - 1}
                key={`${a.langcode}-${a.id}`}
                article={a}
                content={content}
                originalLang={originalLang}
                setSelectedArticle={setSelectedArticle}
                setOpenDialog={setOpenDialog}
                setIsHasTranslations={setIsHasTranslations}
                setIsOriginal={setIsOriginal}
                onFeedbackWithMessageClick={feedBackMessageClick}
              ></MyContentArticleRow>
            ))
        ) : (
          <></>
        )}
      </AnimatePresence>
      <Dialog
        setOpenControlledDialog={setIsDialogWithFeedbackOpened}
        open={isDialogWithFeedbackOpened}
        title={'Feedback'}
        contentProps={{ css: { '@lg': { width: 550 } } }}
      >
        <Space mb="SM">
          <Body5>{currentFeedbackMessage}</Body5>
        </Space>
      </Dialog>
    </>
  )
}
