import React from 'react'
import { Box, Dialog } from '@sefar/design-system'
import { useTranslate } from '../../../../hooks/useTranslate'
import { OrderCommentsHistoryItem } from '../../create/components/order-comments-history-item'
import { StatusChangeComment } from '../../create/types'

export function CommentsDialog({
  isOpen = false,
  comments,
  onClose
}: {
  isOpen?: boolean
  comments?: StatusChangeComment[]
  onClose: () => void
}) {
  const { t } = useTranslate()

  return (
    <Dialog
      open={isOpen}
      closeButtonProps={{ onClick: onClose }}
      title={t('field_purchase_requisition_comment_from_approver')}
      innerContentCss={{
        maxWidth: '32rem',
        backgroundColor: '$neutralLighten97'
      }}
    >
      <Box css={{ d: 'flex', flexDirection: 'column', gap: '$4' }}>
        {comments?.map((commentData) => (
          <OrderCommentsHistoryItem
            key={commentData.id}
            commentData={commentData}
          />
        ))}
      </Box>
    </Dialog>
  )
}
