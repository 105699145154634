import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { CheckboxProps } from '@radix-ui/react-checkbox'
import { styled } from '../../../stitches.config'
import { CheckmarkIcon16 } from '../icons'
import { Box, Label } from '../utils'

const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: 'unset',
  width: 24,
  height: 24,
  flexBasis: 24,
  flexShrink: 0,
  borderRadius: '$2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '$default',
  boxSizing: 'border-box',
  border: '1px $neutralLighten80 solid',
  backgroundColor: '$white',
  cursor: 'pointer',

  '&:hover, &:focus, &.__hover, &.__focus': {
    backgroundColor: '$neutralLighten97',
    borderColor: '$neutralLighten60'
  },
  '&[data-state=checked]:not(:disabled)': {
    backgroundColor: '$primaryBlue',
    borderColor: '$primaryBlue',

    '&:hover, &:focus, &.__hover, &.__focus': {
      backgroundColor: '$primaryBlueDarken30',
      borderColor: '$primaryBlueDarken30'
    }
  },
  '&:focus:not(:disabled), &.__focus:not(:disabled)': {
    outline: '2px $primaryBlueLighten30 solid'
  },
  '&:disabled': {
    cursor: 'not-allowed',
    borderColor: '$neutralLighten80',
    backgroundColor: '$neutralLighten90'
  },

  variants: {
    variant: {
      inverted: {
        '&[data-state=checked]:not(:disabled)': {
          backgroundColor: '$white',
          border: 'none',
          outline: '1px $primaryBlueLighten30 solid',

          '&:hover, &:focus, &.__hover, &.__focus': {
            backgroundColor: '$white'
          },

          '&:focus:not(:disabled), &.__focus:not(:disabled)': {
            outline: '1px $primaryBlueLighten30 solid'
          },

          svg: {
            fill: '$primaryBlue',
            path: {
              fill: '$primaryBlue'
            }
          }
        }
      }
    }
  }
})

const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  color: '$white',
  d: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&[data-disabled]': {
    color: '$neutral'
  }
})

export function Checkbox(
  args: CheckboxProps & {
    label?: string
    wrapperCss?: Record<string, string>
    checkboxCss?: Record<string, string>
    variant?: 'inverted'
  }
) {
  const { label, wrapperCss, checkboxCss, variant, ...props } = args
  const id = args.id || label
  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        ...wrapperCss
      }}
    >
      <StyledCheckbox
        {...props}
        id={id}
        css={{ ...checkboxCss }}
        variant={variant}
      >
        <StyledIndicator>
          <CheckmarkIcon16 />
        </StyledIndicator>
      </StyledCheckbox>
      {label && (
        <Label htmlFor={id} data-disabled={args.disabled}>
          {label}
        </Label>
      )}
    </Box>
  )
}
