import {
  Body6,
  Box,
  Button,
  DateTimePicker,
  Dialog,
  RadioGroup,
  RadioItem,
  Space,
  TextArea
} from '@sefar/design-system'
import React, { useEffect, useState } from 'react'
import { IdeaStatuses } from '../../api/ideas'
import { useTranslate } from '../../hooks/useTranslate'

export function AssessDialog({
  open,
  setOpen,
  onAssess
}: {
  open: boolean
  setOpen: (open: boolean) => void
  onAssess: (
    feedBackMessage: string,
    assessStatus: IdeaStatuses,
    dateOfRealization?: Date
  ) => void
}) {
  const { t } = useTranslate()
  const [feedBackMessage, setFeedBackMessage] = useState<string>('')
  const [assessStatus, setAssessStatus] = useState<IdeaStatuses>()
  const [dateOfRealization, setDateOfRealization] = useState<Date>()
  useEffect(() => {
    if (assessStatus !== IdeaStatuses['Implementation in Progress']) {
      setDateOfRealization(undefined)
    }
  }, [assessStatus])
  return (
    <Dialog
      onOpenChange={() => {
        setFeedBackMessage('')
        setAssessStatus(undefined)
      }}
      setOpenControlledDialog={setOpen}
      open={open}
      title={t('field_idea_assess')}
      contentProps={{ css: { '@lg': { width: 550 } } }}
      footer={
        <Box css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={
              !feedBackMessage ||
              !assessStatus ||
              (assessStatus === IdeaStatuses['Implementation in Progress'] &&
                !dateOfRealization)
            }
            onClick={() => {
              if (assessStatus)
                onAssess(feedBackMessage, assessStatus, dateOfRealization)
            }}
            variant="primary"
          >
            {t('field_idea_assess')}
          </Button>
        </Box>
      }
    >
      <Space css={{ mb: '$4' }}>
        <RadioGroup
          id="asses status"
          value={assessStatus}
          onValueChange={(status: IdeaStatuses) => setAssessStatus(status)}
          aria-label="asses status"
          css={{
            d: 'flex',
            flexDirection: 'column',
            gap: '$4'
          }}
        >
          <RadioItem
            id={IdeaStatuses['Implementation in Progress']}
            value={IdeaStatuses['Implementation in Progress']}
            label={t('field_idea_assess_approved')}
          />
          <RadioItem
            id={IdeaStatuses.Rejected}
            value={IdeaStatuses.Rejected}
            label={t('field_idea_assess_rejected')}
          />
        </RadioGroup>
      </Space>
      {assessStatus === IdeaStatuses['Implementation in Progress'] && (
        <Box css={{ mb: '$4' }}>
          <Space mb="2XS">
            <Body6 css={{ color: '$neutralLighten30' }}>
              {t('field_idea_realization_date_name')}
            </Body6>
          </Space>
          <DateTimePicker
            id="dateOfRealization"
            initialValue={dateOfRealization}
            onChange={setDateOfRealization}
            min={new Date()}
            dateOnlyMode
          />
        </Box>
      )}
      <TextArea
        value={feedBackMessage}
        label={`${t('field_idea_assess_decision')}*`}
        id="feedback"
        name="feedback"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setFeedBackMessage(e.target.value)
        }
        css={{ minHeight: '200px' }}
      />
    </Dialog>
  )
}
