import useSWR from 'swr'
import { checkIsWebUri } from '../utils/url'
import { API_DOMAIN, fetcherFullRequest, init } from './common'

export const TOOLS_TYPE = 'node--tools'

export enum ToolsTypes {
  default = 'Default',
  custom = 'Custom'
}

export type Tool = {
  id: string
  name?: string
  link: string
  type?: ToolsTypes
  iconUrl?: string
}

export type Bookmark = {
  link: string
  name: string
}

export const getToolIconByUrl = (url: string | undefined, size = 64) =>
  url &&
  checkIsWebUri(url) &&
  `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=${size}`

export function useDefaultTools() {
  const { data, error } = useSWR(
    [`${API_DOMAIN}/jsonapi/node/tools?filter[field_tool_type]=Default`, init],
    fetcherFullRequest
  )

  return {
    defaultTools: data ? transferTools(data?.data) : data,
    isLoading: !error && !data,
    isError: error
  }
}

export function addBookmark(bookmark: Bookmark) {
  return fetch(`${API_DOMAIN}/jsonapi/node/tools`, {
    ...init,
    headers: {
      ...init.headers,
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: TOOLS_TYPE,
        attributes: {
          title: bookmark.name,
          field_link: {
            uri: bookmark.link
          },
          field_tool_type: ToolsTypes.custom
        }
      }
    })
  })
}

export function deleteBookmark(id: string) {
  const res = fetch(`${API_DOMAIN}/jsonapi/node/tools/${id}`, {
    method: 'DELETE',
    ...init
  })

  return res
}

export function transferTools(tools: any[]): Tool[] | undefined {
  return tools?.map((tool: any) => transferTool(tool))
}

export function transferTool(tool: any) {
  return {
    id: tool.id,
    name: tool?.attributes?.title,
    link: tool?.attributes?.field_link?.uri,
    type: tool?.attributes?.field_tool_type,
    iconUrl: tool?.attributes?.field_icon_link?.uri
  }
}
