import {
  Body1,
  Body3,
  Body5,
  Box,
  CloseIcon16,
  Container,
  ContainerPadding,
  DateTimePicker,
  ReactSelect,
  SelectOption,
  Spinner
} from '@sefar/design-system'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import {
  IdeaGroupStatus,
  IdeaLocs,
  useDepartments,
  useIdeasV2,
  USER_ROLES
} from '../../api'
import { sub, getUnixTime, fromUnixTime } from 'date-fns'
import { useTranslate } from '../../hooks/useTranslate'
import { useCountUp } from 'react-countup'
import { useConfigStore } from '../../state/config'
import { useNavigate, useSearchParams } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import isEqual from 'lodash/isEqual'

const StatBox = ({
  label,
  count = 0,
  loading
}: {
  label: string
  count: number
  loading: boolean
}) => {
  const countUpRef = useRef(null)
  const { start } = useCountUp({
    ref: countUpRef,
    end: count,
    start: 0,
    duration: 1
  })
  useEffect(() => {
    if (!loading && typeof count === 'number') {
      start()
    }
  }, [loading, count])
  return (
    <Box
      css={{
        backgroundColor: '$white',
        height: 256,
        border: '1px solid #EFEFEF',
        borderRadius: '$2',
        p: '$4',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        css={{
          height: 63,
          d: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {loading && !count ? (
          <Spinner />
        ) : (
          <Body1 css={{ fontSize: 48 }} ref={countUpRef} />
        )}
      </Box>
      <Body3 fontWeight="bold">{label}</Body3>
    </Box>
  )
}

type LocationOption = {
  value: string[]
  label: string
}

export const IdeasDashboard = () => {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const { t } = useTranslate()
  const { me } = useConfigStore.getState()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [locationFilter, setLocationFilter] = useState<
    string | string[] | null
  >(searchParams.getAll('location'))
  const [departmentFilter, setDepartmentFilter] = useState<string | null>(
    searchParams.get('department')
  )
  const { departments } = useDepartments()
  const [createdAfter, setCreatedAfter] = useState<Date | undefined>(() => {
    const queryDate = searchParams.get('createdAfter')
    if (queryDate) {
      return fromUnixTime(parseInt(queryDate))
    } else {
      return sub(new Date(), { weeks: 4 })
    }
  })

  useEffect(() => {
    if (me && !me.roles.includes(USER_ROLES.ideaManager)) {
      navigate('/ideas')
    }
  }, [me, navigate])

  const { count: createdIdeas, isLoading: createdIdeasLoading } = useIdeasV2({
    createdAfter: createdAfter && getUnixTime(createdAfter),
    department: departmentFilter,
    location: locationFilter
  })

  const { count: rejectedIdeas, isLoading: rejectedIdeasLoading } = useIdeasV2({
    createdAfter: createdAfter && getUnixTime(createdAfter),
    department: departmentFilter,
    location: locationFilter,
    type: IdeaGroupStatus.Rejected
  })

  const { count: inProgressIdeas, isLoading: inProgressIdeasLoading } =
    useIdeasV2({
      createdAfter: createdAfter && getUnixTime(createdAfter),
      department: departmentFilter,
      location: locationFilter,
      type: IdeaGroupStatus.Progress
    })

  const { count: completedIdeas, isLoading: completedIdeasLoading } =
    useIdeasV2({
      createdAfter: createdAfter && getUnixTime(createdAfter),
      type: IdeaGroupStatus.Completed,
      location: locationFilter,
      department: departmentFilter
    })

  const currentLocation = useMemo(() => {
    if (locationFilter) {
      for (const locOption of IdeaLocs) {
        if (isEqual(sortBy(locOption.value), sortBy(locationFilter))) {
          return locOption
        }
      }
    } else {
      return null
    }
  }, [IdeaLocs, locationFilter])

  const cleanFilters = () => {
    setSearchParams({})
    setCreatedAfter(undefined)
    setDepartmentFilter(null)
    setLocationFilter(null)
  }

  return (
    <Box
      css={{
        backgroundColor: '$neutralLighten97',
        borderBottom: '1px $neutralLighten90 solid',
        flexGrow: 1,
        paddingTop: '$8',
        paddingBottom: '$4'
      }}
    >
      <ContainerPadding>
        <Container>
          <Body1 css={{ mb: '$8', fontWeight: 'bold' }}>
            {t('field_idea_manager_dashboard')}
          </Body1>
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
              ml: '-$2',
              mr: '-$2',
              mb: '$4',
              '@md': { flexDirection: 'row' }
            }}
          >
            <Box css={{ pl: '$2', pr: '$2' }}>
              <Body5 css={{ mb: '$1' }}>{t('field_idea_created_after')}</Body5>
              <Box css={{ height: 40 }}>
                <DateTimePicker
                  id="createdAfter"
                  value={createdAfter}
                  autoFocus={false}
                  onChange={(date) => {
                    if (date) {
                      setCreatedAfter(date)
                      searchParams.set(
                        'createdAfter',
                        getUnixTime(date).toString()
                      )
                      setSearchParams(searchParams)
                    }
                  }}
                  max={new Date()}
                  dateOnlyMode
                />
              </Box>
            </Box>
            <Box css={{ pl: '$2', pr: '$2' }}>
              <Body5 css={{ mb: '$1' }}>{t('field_idea_form_location')}</Body5>
              <ReactSelect
                css={{ width: isLg ? 240 : 'auto' }}
                value={currentLocation}
                options={IdeaLocs}
                onChange={(option: LocationOption) => {
                  setLocationFilter(option.value)
                  option.value.forEach((loc) => {
                    searchParams.append('location', loc)
                  })
                  setSearchParams(searchParams)
                }}
                id="location"
                placeholder={t('field_idea_form_location')}
              />
            </Box>
            <Box css={{ pl: '$2', pr: '$2' }}>
              <Body5 css={{ mb: '$1' }}>{t('field_idea_department')}</Body5>
              <ReactSelect
                css={{ width: isLg ? 240 : 'auto' }}
                value={
                  departmentFilter
                    ? { label: departmentFilter, value: departmentFilter }
                    : null
                }
                options={departments?.map((dep: string) => ({
                  label: dep,
                  value: dep
                }))}
                onChange={(option: SelectOption) => {
                  setDepartmentFilter(option.value)
                  searchParams.set('department', option.value)
                  setSearchParams(searchParams)
                }}
                id="department"
                placeholder={t('field_idea_department')}
              />
            </Box>
            <Box
              css={{
                pl: '$2',
                pr: '$2',
                d: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                cursor: 'pointer'
              }}
              onClick={cleanFilters}
            >
              <Box css={{ height: 40, d: 'flex', alignItems: 'center' }}>
                <Body3 css={{ mr: '$1' }}>
                  {t('field_clear_search_filters')}
                </Body3>
                <Box css={{ mt: 5 }}>
                  <CloseIcon16 css={{ mt: 1 }} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box css={{ display: 'flex', m: '-$2', flexWrap: 'wrap' }}>
            <Box
              css={{ p: '$2', flexBasis: '100%', '@lg': { flexBasis: '50%' } }}
            >
              <StatBox
                count={createdIdeas}
                label={'Entered Ideas'}
                loading={createdIdeasLoading}
              />
            </Box>
            <Box
              css={{ p: '$2', flexBasis: '100%', '@lg': { flexBasis: '50%' } }}
            >
              <StatBox
                count={rejectedIdeas}
                label={'Rejected Ideas'}
                loading={rejectedIdeasLoading}
              />
            </Box>
            <Box
              css={{ p: '$2', flexBasis: '100%', '@lg': { flexBasis: '50%' } }}
            >
              <StatBox
                count={inProgressIdeas}
                label={'Ideas in progress'}
                loading={inProgressIdeasLoading}
              />
            </Box>
            <Box
              css={{ p: '$2', flexBasis: '100%', '@lg': { flexBasis: '50%' } }}
            >
              <StatBox
                count={completedIdeas}
                label={'Completed Ideas'}
                loading={completedIdeasLoading}
              />
            </Box>
          </Box>
        </Container>
      </ContainerPadding>
    </Box>
  )
}
