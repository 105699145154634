import {
  AddFile20,
  Box,
  Body5,
  Button,
  Dialog,
  DialogClose,
  EditIcon20,
  Heading5,
  LazyImage,
  PhotocameraIcon20,
  Slider,
  Space,
  Spinner,
  ToastTypeEnum,
  useToastContext,
  ArrowRoundLeftIcon20,
  ArrowRoundRightIcon20
} from '@sefar/design-system'
import AvatarPlaceholder from '../../../assets/avatar-placeholder-blue.jpg'
import React, { useEffect, useRef, useState } from 'react'
import { AddMediaInput } from '../../components'
import {
  API_DOMAIN,
  FileTypeHTMLFormat,
  getFileType,
  updateUserPicture,
  User
} from '../../api'
import { fileDrop } from '../news-details/helpers'
import { useContainerSize } from '../../hooks/use-container-size'
import { useMobile } from '../../hooks/use-mobile'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { useConfigStore } from '../../state/config'
import AvatarEditor from 'react-avatar-editor'
import { useTranslate } from '../../hooks/useTranslate'

export function EditAvatarImageDialog({
  image,
  user
}: {
  image: string
  user: User
}) {
  const { t } = useTranslate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { setMe } = useConfigStore()
  const closeDialogRef = useRef<HTMLButtonElement>(null)
  const setToast = useToastContext()
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const mobile = useMobile()
  const [dragCounter, setDragCounter] = useState<number>(0)
  const [chosenFile, setChosenFile] = useState<File>()
  const [imageSrc, setImageSrc] = useState<string>()
  const [edit, setEdit] = useState<boolean>(false)
  const [scale, setScale] = useState<number>(1)
  const [rotate, setRotate] = useState<number>(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const editor = useRef(null)
  const modalContentContainerRef = useRef(null)
  const { width, height } = useContainerSize(
    modalContentContainerRef,
    !isModalOpen
  )
  const imagePreviewSize = Math.floor(
    Math.min(width, edit ? height - 132 : height)
  )

  const saveEditedImage = () => {
    if (editor?.current) {
      const quality = 0.85
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      const canvas = editor.current.getImage()
      canvas.toBlob(
        (blob: Blob) => {
          const file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' })
          setChosenFile(file)
          setImageSrc(canvas.toDataURL('image/jpeg', quality))
          setEdit(false)
          setRotate(0)
          setScale(1)
        },
        'image/jpeg',
        quality
      )
    }
  }

  const onFileChange = (file?: File) => {
    const type = file && getFileType(file)
    if (
      !isLoading &&
      file &&
      type &&
      ['image'].includes(FileTypeHTMLFormat[type])
    ) {
      setChosenFile(file)
      URL.revokeObjectURL(imageSrc)
      setImageSrc(URL.createObjectURL(file))
    }
  }

  const savePicture = () => {
    setIsLoading(true)
    updateUserPicture(user?.id, chosenFile!)
      .then((res) => {
        if (res?.errors?.length) {
          setToast({
            message: t('field_error_common_toast_text'),
            type: ToastTypeEnum.error
          })
        } else {
          setToast({
            message: t('field_profile_pic_updated_toast'),
            type: ToastTypeEnum.success
          })
          if (res?.data?.attributes?.uri?.url) {
            setMe({
              ...user,
              image: `${API_DOMAIN}${res.data.attributes.uri.url}`
            })
          }
          closeDialogRef?.current?.click()
        }
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (image) {
      setImageSrc(image)
    }
  }, [image])

  useEffect(() => {
    return () => URL.revokeObjectURL(imageSrc)
  }, [])

  return (
    <Dialog
      onOpenChange={(open) => {
        setIsModalOpen(open)
        if (!open) {
          setChosenFile(undefined)
          setImageSrc('')
          setDragCounter(0)
          setIsLoading(false)
          setEdit(false)
          setRotate(0)
          setScale(1)
        } else if (image) {
          setImageSrc(image)
        }
      }}
      contentProps={{
        css: {
          width: 550,
          maxHeight: 'calc(100vh - 120px)'
        }
      }}
      innerContentCss={{
        flex: `1 1 ${edit ? '550px' : '500px'}`,
        d: 'flex',
        justifyContent: 'center'
      }}
      trigger={
        <Button
          variant="secondary"
          className="icon only-icon round"
          data-cy="editAvatarButton"
          css={{
            position: 'absolute',
            bottom: 0,
            left: '70%'
          }}
        >
          <PhotocameraIcon20 />
        </Button>
      }
      title={t('field_update_profile_picture')}
      footerCss={{
        '@md': { borderTop: 'initial' }
      }}
      footer={
        <Box
          css={{
            d: 'flex',
            gap: '$3'
          }}
        >
          {edit ? (
            <>
              <Button
                disabled={isLoading}
                variant="secondary"
                css={{ marginLeft: 'auto' }}
                onClick={() => {
                  setEdit(false)
                  setRotate(0)
                  setScale(1)
                }}
              >
                {t('field_cancel')}
              </Button>
              <Button
                data-cy="SaveAvatarButton"
                variant="primary"
                onClick={saveEditedImage}
              >
                {t('field_save')}
              </Button>
            </>
          ) : (
            <>
              {!isSm && (
                <AddMediaInput
                  disabled={isLoading}
                  id="new-profile-picture"
                  onInputFileChange={(e) =>
                    onFileChange(e.currentTarget?.files?.[0])
                  }
                  allowedFileTypes={['image']}
                />
              )}
              {!!imageSrc && (
                <Button
                  disabled={isLoading}
                  variant="secondary"
                  css={{ '@sm': { marginLeft: 'auto' } }}
                  onClick={() => setEdit(true)}
                >
                  <Box as="span" className="icon icon-left">
                    <EditIcon20 />
                  </Box>
                  {t('field_edit')}
                </Button>
              )}
              <Button
                data-cy="SaveAvatarButton"
                disabled={isLoading || !chosenFile}
                variant="primary"
                css={{ '@md': { marginLeft: 'auto' } }}
                onClick={savePicture}
              >
                {t('field_save')}
              </Button>
              <DialogClose
                css={{ all: 'unset', size: 0 }}
                ref={closeDialogRef}
              ></DialogClose>
            </>
          )}
        </Box>
      }
    >
      <Box
        ref={modalContentContainerRef}
        css={{ flex: 1, minHeight: 240, maxWidth: '100%' }}
      >
        {edit ? (
          <Box
            css={{
              d: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <AvatarEditor
              ref={editor}
              image={imageSrc}
              width={imagePreviewSize - 50}
              height={imagePreviewSize - 50}
              borderRadius={imagePreviewSize / 2}
              color={[255, 255, 255, 0.6]}
              scale={scale}
              rotate={rotate}
              crossOrigin={'anonymous'}
            />
            <Box
              css={{
                d: 'flex',
                gap: '$2',
                justifyContent: 'end',
                my: '$4',
                w: '100%'
              }}
            >
              <Button
                className="only-icon"
                variant="secondary"
                onClick={() => setRotate((rotate + 90) % 360)}
              >
                <ArrowRoundRightIcon20 />
              </Button>
              <Button
                className="only-icon"
                variant="secondary"
                onClick={() =>
                  setRotate(
                    rotate - 90 < 0 ? rotate - 90 + 360 : (rotate - 90) % 360
                  )
                }
              >
                <ArrowRoundLeftIcon20 />
              </Button>
            </Box>
            <Box
              css={{
                d: 'flex',
                gap: '$10',
                width: '100%',
                height: 60,
                flexDirection: 'column',
                '@md': { flexDirection: 'row' }
              }}
            >
              <Box css={{ flex: '1 0 0' }}>
                <Body5 css={{ mb: '$4' }}>
                  {t('field_edit_prof_pic_zoom')}
                </Body5>
                <Slider
                  value={[scale]}
                  onValueChange={(value) => setScale(value[0])}
                  min={1}
                  max={5}
                  step={0.2}
                />
              </Box>
              <Box css={{ flex: '1 0 0' }}>
                <Body5 css={{ mb: '$4' }}>
                  {t('field_edit_prof_pic_straighten')}
                </Body5>
                <Slider
                  value={[rotate]}
                  onValueChange={(value) => setRotate(value[0])}
                  min={0}
                  max={359}
                  step={1}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Space
            css={{
              d: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '$12',
              width: '100%',
              height: '100%',
              borderColor: dragCounter
                ? '$primaryBlueLighten30'
                : 'transparent',
              background: dragCounter ? '$primaryBlueLighten97' : 'transparent',
              '&:hover .actions': {
                d: 'flex'
              }
            }}
            onDrop={(e) => {
              setDragCounter(0)
              e.preventDefault()
              const files = fileDrop(e)
              onFileChange(files[0])
            }}
            onDragEnter={(e) => {
              setDragCounter((v) => v + 1)
            }}
            onDragLeave={(e) => {
              setDragCounter((v) => v - 1)
            }}
            onDragOver={(event) => {
              event.preventDefault()
            }}
          >
            {isLoading && (
              <Box
                css={{
                  zIndex: 1,
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '$cardBackgroundBlur'
                }}
              >
                <Spinner />
              </Box>
            )}
            <Box
              css={{
                borderRadius: '$round',
                position: 'relative',
                overflow: 'hidden',
                width: isSm ? imagePreviewSize - 88 : imagePreviewSize,
                height: isSm ? imagePreviewSize - 88 : imagePreviewSize
              }}
            >
              <LazyImage
                src={imageSrc || AvatarPlaceholder}
                aspectRatio="1 / 1"
                alt="avatar edit"
              />

              {!mobile && (
                <>
                  <Box
                    css={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: '$scrim',
                      opacity: 0.6,
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translateY(-50%) translateX(-50%)',
                      d: imageSrc && !dragCounter ? 'none' : 'flex',
                      pointerEvents: 'none'
                    }}
                    className="actions"
                  ></Box>
                  <Box
                    css={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      width: '100%',
                      transform: 'translateY(-50%) translateX(-50%)',
                      d: imageSrc && !dragCounter ? 'none' : 'flex',
                      gap: '$3',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '$white',
                      textAlign: 'center'
                    }}
                    className="actions"
                  >
                    <AddFile20 />
                    <Heading5>{t('field_edit_prof_pic_drag_drop')}</Heading5>
                  </Box>
                </>
              )}
            </Box>
            {isSm && (
              <AddMediaInput
                id="new-profile-picture"
                onInputFileChange={(e) =>
                  onFileChange(e.currentTarget?.files?.[0])
                }
                allowedFileTypes={['image']}
              />
            )}
          </Space>
        )}
      </Box>
    </Dialog>
  )
}
