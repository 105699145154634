import React from 'react'
// @ts-ignore
import Clamp from 'react-multiline-clamp'
import { Link, useLocation } from 'react-router-dom'
import { useMedia } from 'react-use'
import {
  Body5,
  Body7,
  Heading2,
  Box,
  LazyImage,
  Video,
  Space
} from '@sefar/design-system'
import { MustReadBadge } from './must-read-badge'
import { ArticleInfo } from '../article/article-info'
import { MEDIA_TYPES, NewsArticle } from '../../api'
import { BREAKPOINTS_QUERIES, styled } from '../../../../../../stitches.config'

export const ArticleCard = styled('article', {
  color: '$neutral',
  border: '1px $neutralLighten90 solid',
  borderRadius: '$4',
  boxShadow: '$card',
  height: '400px',
  '@lg': {
    height: '468px'
  },
  '&:hover': {
    boxShadow: '$cardHover'
  },
  overflow: 'hidden'
})

export const ArticleContent = styled(Box, {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  color: '$white',
  d: 'flex',
  flexDirection: 'column',
  background:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.0001) 0%, rgba(46, 51, 55, 0.7) 73.59%)',
  py: '$6',
  px: '$8',
  '@sm': {
    pt: '$4',
    px: '$4'
  }
})

export const ArticleLink = styled(Link, {
  all: 'unset',
  height: '100%',
  d: 'block',
  cursor: 'pointer',
  position: 'relative'
})

export function MustReadNewsArticleCard({ article }: { article: NewsArticle }) {
  const location = useLocation()
  const isMd = useMedia(BREAKPOINTS_QUERIES.md)

  return (
    <ArticleCard>
      <ArticleLink
        to={`/news/${article.id}`}
        state={{ from: location.pathname }}
      >
        {article.mediaType && MEDIA_TYPES.video.includes(article.mediaType) ? (
          <Video src={article.media?.url} />
        ) : (
          <LazyImage aspectRatio="2 / 1" src={article.media?.url}></LazyImage>
        )}
        <ArticleContent>
          <MustReadBadge css={{ mb: 'auto', '@lg': { mt: '$2' } }} />
          <Body7
            css={{ mb: '$2', wordBreak: 'break-word', '@lg': { mb: '$1' } }}
          >
            {article.date}
          </Body7>
          <Heading2 css={{ mb: '$2', wordBreak: 'break-word' }}>
            {article.title}
          </Heading2>
          <Body5 css={{ wordBreak: 'break-word' }}>
            <Clamp lines={isMd ? 2 : 3} maxLines={Number.MAX_SAFE_INTEGER}>
              <Box dangerouslySetInnerHTML={{ __html: article.lead }} />
            </Clamp>
          </Body5>

          <Space mt="MD" css={{ color: '$black' }}>
            <ArticleInfo article={article} />
          </Space>
        </ArticleContent>
      </ArticleLink>
    </ArticleCard>
  )
}
