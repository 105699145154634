import React from 'react'
import { Box } from '../utils'
import { MinusIcon20, Add16 } from '../icons'

export function RemoveActionButton({
  disabled = false,
  className = '',
  onClick
}: {
  disabled?: boolean
  className?: string
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
}) {
  return (
    <ActionButton
      icon={<MinusIcon20 />}
      disabled={disabled}
      onClick={onClick}
      className={className}
      wrapperCss={{
        backgroundColor: '$error',

        '&:hover, &.__hover:not(:disabled)': {
          backgroundColor: '$destructiveHover'
        }
      }}
    />
  )
}

export function AddActionButton({
  disabled = false,
  className,
  onClick
}: {
  disabled?: boolean
  className?: string
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
}) {
  return (
    <ActionButton
      icon={<Add16 />}
      disabled={disabled}
      onClick={onClick}
      className={className}
      wrapperCss={{
        backgroundColor: '$success',

        '&:hover, &.__hover:not(:disabled)': {
          backgroundColor: '$successDarken30'
        }
      }}
    />
  )
}

export function ActionButton({
  icon,
  disabled,
  wrapperCss,
  className = '',
  onClick
}: {
  icon: React.ReactNode
  disabled?: boolean
  wrapperCss?: Record<string, string | Record<string, string>>
  className?: string
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
}) {
  return (
    <Box
      className={`${disabled ? 'disabled' : undefined} ${className}`}
      onClick={onClick}
      css={{
        d: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
        transition: '$default',
        borderRadius: '50%',
        cursor: 'pointer',

        '& svg': {
          maxWidth: '14px',
          maxWHeight: '14px'
        },

        '&.disabled': {
          backgroundColor: '$neutralLighten90',
          cursor: 'auto',

          '&:hover': {
            backgroundColor: '$neutralLighten90'
          },

          '& svg': {
            fill: '$neutralLighten60',
            path: {
              fill: '$neutralLighten60'
            }
          }
        },

        ...wrapperCss
      }}
    >
      {icon}
    </Box>
  )
}
