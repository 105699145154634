import { ArticleEditor } from '../../components'
import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'
import {
  Box,
  ContainerPadding,
  Container,
  Space,
  Spinner
} from '@sefar/design-system'
import { removeTagsFromString } from '../../components/utils'
import { Params, useNavigate } from 'react-router-dom'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import { SpaceArticlesDetailsActions } from './space-article-details-actions'
import { myContentPageUrl } from '../../app'
import { useLocationStateFrom } from '../../hooks/useLocationStateFrom'
import { SpaceArticle, UploadProfiles } from '../../api'
import {
  NewsArticleSettings,
  NewsDetailsSettings
} from '../news-details/news-details-settings'

// TODO: add check that subspaceId is provided for a new article creation
export function SpaceArticleDetailsEdit({
  article,
  params,
  setArticle
}: {
  article: SpaceArticle | undefined
  params: Readonly<Params<string>>
  setArticle: Dispatch<SetStateAction<SpaceArticle>> | undefined
}) {
  const from = useLocationStateFrom([], () => `/${myContentPageUrl}`)
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
  const navigate = useNavigate()
  const [isEditorFocused, setIsEditorFocused] = useState<boolean>(false)
  const [isReadyToSave, setIsReadyToSave] = useState<boolean>(false)
  const [settings, setSettings] = useState<Partial<NewsArticleSettings>>({
    langcode: 'en'
  })
  useEffect(() => {
    if (article?.lang && article?.lang !== settings?.langcode) {
      setSettings((oldValue) => ({
        ...oldValue,
        langcode: article?.lang
      }))
    }
  }, [article?.lang])

  useEffect(() => {
    if (
      !requestInProgress &&
      article?.title &&
      article?.text &&
      removeTagsFromString(article?.text)
    ) {
      setIsReadyToSave(true)
    } else {
      setIsReadyToSave(false)
    }
  }, [requestInProgress, article])

  return (
    <>
      {requestInProgress && (
        <Space
          pt="4XL"
          css={{
            position: 'absolute',
            top: 'auto',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            background: '$scrim',
            zIndex: '$tooltip'
          }}
        >
          <Spinner />
        </Space>
      )}

      <Box css={{ flex: '1 0 0', display: 'flex', flexDirection: 'column' }}>
        <ArticleEditor
          article={article}
          isEditorFocused={isEditorFocused}
          onArticleChange={(value) =>
            setArticle((oldValue) => ({ ...oldValue, ...value }))
          }
          onEditorFocusChange={setIsEditorFocused}
          uploadProfile={UploadProfiles.spacesUploads}
          contentType="spaces"
          enableMainMedia={false}
          leadPlaceholderText="The lead text is displayed in the preview of the article. It has to win readers' attention and heart :)"
        ></ArticleEditor>

        <Space css={{ '@sm': { mb: '$3' } }}>
          <NewsDetailsSettings
            isNew={!article?.id}
            settings={settings as NewsArticleSettings}
            onSettingsUpdate={(update) =>
              setSettings((oldValue) => ({ ...oldValue, ...update }))
            }
            tools={{ langcode: true }}
          />
        </Space>

        <Box
          css={{
            display: isSm && isEditorFocused ? 'none' : 'block',
            position: 'fixed',
            bottom: '0',
            width: '100%',
            background: '$neutralLighten20',
            height: '$inlineEditingBottomToolbar',
            left: '0',
            zIndex: 100
          }}
        >
          <Container css={{ height: '100%' }}>
            <ContainerPadding css={{ height: '100%' }}>
              <SpaceArticlesDetailsActions
                article={article}
                settings={settings}
                params={params}
                onCancelButtonCLick={() => navigate(from)}
                setRequestInProgress={setRequestInProgress}
                isReadyToSave={isReadyToSave}
              />
            </ContainerPadding>
          </Container>
        </Box>
      </Box>
    </>
  )
}
