import React, { useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { AnimatePresence, motion } from 'framer-motion'
import { ScopedNotification } from '../../components'
import { Language, useLanguages } from '../../api'
import { Box, TextLink } from '@sefar/design-system'
import { useTranslate } from '../../hooks/useTranslate'

const AnimatedBox = motion(Box)

export function TranslationNotification({
  lang,
  langOrigin,
  onTranslation
}: {
  lang: string
  langOrigin: string
  onTranslation: (isOriginal: boolean) => void
}) {
  const { t } = useTranslate()
  const [enable, setEnable] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(true)
  const { languages } = useLanguages()
  const [isOriginal, setIsOriginal] = useState<boolean>(false)

  useUpdateEffect(() => {
    onTranslation(isOriginal)
    setActiveAnimation(false)
    setTimeout(() => {
      setActiveAnimation(true)
    }, 300)
  }, [isOriginal])

  return (
    <ScopedNotification>
      <AnimatePresence>
        {activeAnimation && (
          <AnimatedBox
            css={{
              d: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              textAlign: 'center'
            }}
            transition={{
              type: 'spring',
              bounce: 0,
              duration: 0.3
            }}
            initial={enable ? { y: '150%', opacity: 0 } : {}}
            animate={{ y: 0, opacity: 1 }}
            exit={enable ? { y: '-150%', opacity: 0 } : {}}
          >
            {isOriginal ? (
              <Box as="span">
                {t('field_tn_page_is_in_lng')}{' '}
                {
                  languages?.find(({ id }: Language) => id === langOrigin)
                    ?.label
                }
                .
              </Box>
            ) : (
              <Box as="span">
                {t('field_tn_page_trans_from_to', [
                  languages?.find(({ id }: Language) => id === langOrigin)
                    ?.labelEng,
                  languages?.find(({ id }: Language) => id === lang)?.labelEng
                ])}
              </Box>
            )}
            <TextLink
              variant="small"
              css={{ ml: '$1' }}
              onClick={() => {
                setEnable(true)
                setIsOriginal((prev) => !prev)
              }}
            >
              {isOriginal
                ? t('field_tn_translate_page')
                : t('field_tn_show_original')}
            </TextLink>
          </AnimatedBox>
        )}
      </AnimatePresence>
    </ScopedNotification>
  )
}
