import { Box, Button } from '@sefar/design-system'

type AmountSelectorProps = {
  value: string | number
  onChange?: (value: number) => void
}

export const AmountSelector = ({ value, onChange }: AmountSelectorProps) => {
  const onInputChange = (newValue: number) => {
    let formattedValue = newValue
    if (newValue < 1) formattedValue = 1
    if (newValue > 99999) formattedValue = 99999

    onChange?.(formattedValue)
  }

  return (
    <Box
      css={{
        d: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px $neutralLighten80 solid',
        borderRadius: '$4',
        gap: '$4'
      }}
    >
      <Button
        css={{
          color: '$primaryBlue',
          backgroundColor: 'transparent',
          fontSize: 22,
          lineHeight: 1
        }}
        onClick={() => onChange?.(value > 1 ? +value - 1 : +value)}
        icon
        noBorder
        disabled={value < 2}
        variant="transparent"
      >
        –
      </Button>
      <Box
        as="input"
        type="number"
        value={value}
        onChange={(e) => onInputChange(+e.target.value)}
        min={1}
        max={99999}
        css={{
          border: 'none',
          padding: 0,
          width: '3rem',
          outline: 'none',
          textAlign: 'center',
          '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
          },
          '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
          }
        }}
      />
      <Button
        css={{
          color: '$primaryBlue',
          backgroundColor: 'transparent',
          fontSize: 22,
          lineHeight: 1
        }}
        onClick={() => onChange?.(+value + 1)}
        icon
        noBorder
        disabled={value > 99998}
        variant="transparent"
      >
        +
      </Button>
    </Box>
  )
}
