import React, { useEffect, useState } from 'react'
import {
  Container,
  ContainerPadding,
  Heading1,
  Space,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@sefar/design-system'
import { SearchEntityTypes } from '../../api'
import { useTranslate } from '../../hooks/useTranslate'
import { useSearchParams } from 'react-router-dom'
import { MySpaceArticles } from './my-space-articles'
import { MyNewsArticles } from './my-news-articles'
import { useConfigStore } from '../../state/config'

export function MyContent() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslate()
  const { me } = useConfigStore()
  const [isNewsEditor, setIsNewsEditor] = useState<boolean>(false)

  useEffect(() => {
    const newsEditorRoles = [
      'content_editor',
      'content_reviewer',
      'administrator'
    ]
    if (me?.roles.length) {
      const rolesIntersection = me.roles.filter((value) =>
        newsEditorRoles.includes(value)
      )
      setIsNewsEditor(!!rolesIntersection.length)
    }
  }, [me?.roles])
  const currentTab =
    searchParams.get('type') ||
    (isNewsEditor ? SearchEntityTypes.News : SearchEntityTypes.Spaces)
  return (
    <Tabs
      onValueChange={(value) => {
        setSearchParams({ type: value, sort: '0' })
      }}
      value={currentTab}
      css={{ flex: 1, height: '100%' }}
    >
      <Space
        pt="LG"
        css={{
          backgroundColor: '$neutralLighten97',
          borderBottom: '1px $neutralLighten90 solid'
        }}
      >
        <ContainerPadding>
          <Container>
            <Space mb="SM">
              <Heading1>{t('field_my_contents_link')}</Heading1>
            </Space>

            <TabsList aria-label="Search entity types">
              {isNewsEditor && (
                <TabsTrigger value={SearchEntityTypes.News}>
                  {t('field_news')}
                </TabsTrigger>
              )}
              <TabsTrigger value={SearchEntityTypes.Spaces}>
                {t('field_spaces')}
              </TabsTrigger>
            </TabsList>
          </Container>
        </ContainerPadding>
      </Space>

      <ContainerPadding css={{ flexGrow: 1, d: 'flex' }}>
        <Container css={{ flexGrow: 1, d: 'flex', flexDirection: 'column' }}>
          {isNewsEditor && (
            <TabsContent
              value={SearchEntityTypes.News}
              css={{
                flexGrow: 1,
                d: 'flex',
                flexDirection: 'column'
              }}
            >
              <MyNewsArticles />
            </TabsContent>
          )}
          <TabsContent
            value={SearchEntityTypes.Spaces}
            css={{
              flexGrow: 1,
              d: 'flex',
              flexDirection: 'column'
            }}
          >
            <MySpaceArticles />
          </TabsContent>
        </Container>
      </ContainerPadding>
    </Tabs>
  )
}
