import React, { useEffect, useState } from 'react'
import { Box, Button, Spinner } from '@sefar/design-system'
import { getUrlFromSaml, isUserInNet } from '../../api'
import { useTranslate } from '../../hooks/useTranslate'
import { sessionStorageKeys } from '../../constants'

export function SamlLoginHelper() {
  const { t } = useTranslate()
  const [urlForRedirect, setUrlForRedirect] = useState('')
  const [isPending, setIsPending] = useState(true)

  const onLoginHandler = () => {
    sessionStorage.setItem(sessionStorageKeys.LOGGED_IN_BY, 'Sefar account')
    getUrlFromSaml().then((res) => setUrlForRedirect(res.redirect))
  }

  useEffect(() => {
    isUserInNet()
      .then((res) => {
        setIsPending(true)
        res.status === 200 &&
          getUrlFromSaml().then((res) => setUrlForRedirect(res.redirect))
      })
      .catch((err) => console.error(err))
      .finally(() => setIsPending(false))
  }, [])

  useEffect(() => {
    if (urlForRedirect) {
      window.location.href = urlForRedirect
    }
  }, [urlForRedirect])

  return (
    <Button
      disabled={isPending}
      data-cy="samlLoginButton"
      data-testid="samlLoginButton"
      variant="primary"
      onClick={onLoginHandler}
      size="large"
    >
      {isPending ? (
        <Spinner />
      ) : (
        <Box as="span">{t('field_login_with_sefar_acc_text')}</Box>
      )}
    </Button>
  )
}
