import { styled } from '../../../stitches.config'
import { Box } from '../utils'

function createSpaceVariant(token: string) {
  return {
    '4XL': {
      [token]: '$20',
      '@lg': { [token]: '$30' },
      '@xl': { [token]: '$40' }
    },
    '3XL': {
      [token]: '$16',
      '@lg': { [token]: '$20' },
      '@xl': { [token]: '$30' }
    },
    '2XL': {
      [token]: '$12',
      '@lg': { [token]: '$16' },
      '@xl': { [token]: '$20' }
    },
    XL: {
      [token]: '$8',
      '@lg': { [token]: '$12' },
      '@xl': { [token]: '$16' }
    },
    LG: {
      [token]: '$6',
      '@lg': { [token]: '$8' },
      '@xl': { [token]: '$12' }
    },
    MD: {
      [token]: '$4',
      '@lg': { [token]: '$6' },
      '@xl': { [token]: '$8' }
    },
    SM: {
      [token]: '$4',
      '@lg': { [token]: '$4' },
      '@xl': { [token]: '$6' }
    },
    XS: {
      [token]: '$4',
      '@lg': { [token]: '$4' },
      '@xl': { [token]: '$4' }
    },
    '2XS': {
      [token]: '$3',
      '@lg': { [token]: '$3' },
      '@xl': { [token]: '$3' }
    },
    '3XS': {
      [token]: '$2',
      '@lg': { [token]: '$2' },
      '@xl': { [token]: '$2' }
    },
    '4XS': {
      [token]: '$1',
      '@lg': { [token]: '$1' },
      '@xl': { [token]: '$1' }
    }
  }
}

export const Space = styled(Box, {
  variants: {
    m: createSpaceVariant('m'),
    mb: createSpaceVariant('mb'),
    mt: createSpaceVariant('mt'),
    ml: createSpaceVariant('ml'),
    mr: createSpaceVariant('mr'),
    mx: createSpaceVariant('mx'),
    my: createSpaceVariant('my'),

    p: createSpaceVariant('p'),
    pb: createSpaceVariant('pb'),
    pt: createSpaceVariant('pt'),
    pl: createSpaceVariant('pl'),
    pr: createSpaceVariant('pr'),
    px: createSpaceVariant('px'),
    py: createSpaceVariant('py')
  }
})
