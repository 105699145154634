import React from 'react'
import {
  Box,
  Body5,
  Button,
  Dialog,
  DialogClose,
  Space,
  TextLink,
  EditIcon16
} from '@sefar/design-system'
import { useTranslate } from '../../hooks/useTranslate'

export function ContactHrDialog() {
  const { t } = useTranslate()

  return (
    <Dialog
      title={t('field_contact_hr')}
      trigger={
        <TextLink as="div" variant="small">
          <Box as="span" css={{ mr: '$6' }} className="icon icon-left">
            <EditIcon16 />
          </Box>
          <span>{t('field_edit_profile_data_link')}</span>
        </TextLink>
      }
      footerCss={{
        '@md': {
          borderTop: 'none'
        }
      }}
      footer={
        <Space
          css={{
            d: 'flex',
            justifyContent: 'flex-end',
            w: '100%'
          }}
        >
          <Button variant="primary" as={DialogClose}>
            {t('field_okay')}
          </Button>
        </Space>
      }
    >
      <Box
        css={{
          d: 'flex',
          alignItems: 'center',
          '@md': {
            pr: '$4',
            minWidth: '480px'
          }
        }}
      >
        <Body5>{t('field_contact_hr_description')}</Body5>
      </Box>
    </Dialog>
  )
}
