import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useConfigStore } from '../../state/config'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'

import { spacesCreateArticlePageUrl, spacesPageUrl } from '../../app'

import {
  ImageToAttachToArticle,
  patchUserSpacesBookmarks,
  SPACE_ARTICLE_TYPE,
  SpacesBookmarkSimple,
  SubSpaceArticleSimple,
  updateSubspaceThumbnail,
  updateSubspaceThumbnailWithLocalFile,
  updateSubspaceTitle,
  useMe,
  User,
  useSpaces,
  useSubSpace
} from '../../api'

import {
  BackLink,
  Body5,
  Body6,
  Body7,
  Box,
  Button,
  Container,
  ContainerPadding,
  EditIcon16,
  Heading3,
  Heading5,
  LazyImage,
  ReactSelect,
  SelectOption,
  Space,
  Spinner,
  StarFilled16,
  StarOutline16
} from '@sefar/design-system'
import {
  AddNewArticleButton,
  getUserFullName,
  mixpanelAnalyticsHelper
} from '../../components'
import { AuthorCard } from '../../components/common/author'
import { useTranslate } from '../../hooks/useTranslate'
import { SpaceEditorsDialog } from './space-editors-dialog'
import { UserAvatar } from '../../components/common/user-avatar'
import {
  ArticleSortTypesEnum,
  getArticlesSearchFilterFunc,
  getArticlesSortFunc
} from './utils'
import { SearchInput } from '../../components/search/search-input'
import { Sidebar } from '../../components/sidebar'
import { useBookmarksWithDetails } from './hooks/use-bookmarks-with-details'
import { RenameSubspaceDialog } from './rename-subspace-dialog'
import { EditSubspaceImageLayout } from './edit-subspace-image-layout'
import { FileMeta } from '../news-details/helpers'

export function Card({
  id,
  title,
  date,
  onFavoriteIconClick,
  isBookmarked
}: {
  id: string
  title: string
  date: string
  onFavoriteIconClick: (
    id: string,
    isBookmarked: boolean,
    e: React.SyntheticEvent<EventTarget>
  ) => void
  isBookmarked: boolean
}) {
  return (
    <Box
      id={id}
      css={{
        d: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: '$4'
      }}
    >
      <Box css={{ d: 'flex', alignItems: 'center' }}>
        <Box>
          <Box
            as="span"
            css={{
              color: isBookmarked ? '#FFBB38' : '$neutralLighten70',
              p: '$4'
            }}
            onClickCapture={(e: React.SyntheticEvent<EventTarget>) =>
              onFavoriteIconClick(id, isBookmarked, e)
            }
          >
            {isBookmarked ? <StarFilled16 /> : <StarOutline16 />}
          </Box>
        </Box>
        <Box css={{ pr: '$4' }}>
          <Heading5>{title}</Heading5>
        </Box>
      </Box>
      <Box>
        <Body5 css={{ color: '$neutralLighten30' }}>{date}</Body5>
      </Box>
    </Box>
  )
}

const sortArticlesByField = (
  articles: SubSpaceArticleSimple[],
  sortType: SelectOption,
  fieldName: keyof SubSpaceArticleSimple
) =>
  [...articles].sort((a: SubSpaceArticleSimple, b: SubSpaceArticleSimple) => {
    if (sortType.value === 'desc') {
      if (a[fieldName] < b[fieldName]) {
        return -1
      }
      if (a[fieldName] > b[fieldName]) {
        return 1
      }
      return 0
    } else {
      if (b[fieldName] < a[fieldName]) {
        return -1
      }
      if (b[fieldName] > a[fieldName]) {
        return 1
      }
      return 0
    }
  })

export function SubSpace() {
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const { id: userId, setMe } = useConfigStore()
  const { me, mutate: mutateUser } = useMe(userId)
  const location = useLocation()
  const { t } = useTranslate()
  const [openUserSelectModal, setOpenUserSelectModalOpen] =
    useState<boolean>(false)
  const [collapsed, setCollapsed] = useState(false)
  const params = useParams()
  const {
    subspace,
    isLoading: isLoadingSubSpace,
    mutate
  } = useSubSpace(params.subspaceId ?? '')
  const { spaces, isLoading: isLoadingSpace } = useSpaces(params.spaceId)

  const sortTypesOptions = [
    {
      value: ArticleSortTypesEnum.MOST_RELEVANT,
      label: t('field_sort_most_relevant')
    },
    { value: ArticleSortTypesEnum.NAME_DESC, label: 'A - Z' },
    { value: ArticleSortTypesEnum.NAME_ASC, label: 'Z - A' },
    {
      value: ArticleSortTypesEnum.UPDATED_AT_ASC,
      label: t('field_sort_updated_asc')
    },
    {
      value: ArticleSortTypesEnum.UPDATED_AT_DESC,
      label: t('field_sort_updated_desc')
    }
  ]

  const [editImageMode, setEditImageMode] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [sortType, setSortType] = useState(sortTypesOptions[0])
  const [bookmarks, setBookmarks] = useState<SpacesBookmarkSimple[]>([])
  const { bookmarks: bookmarksWithDetails, isBookmarksLoading } =
    useBookmarksWithDetails()

  const isCurrentUserOwner = subspace?.owner?.id && subspace.owner.id === me?.id

  const isAllowedToAddArticles = useMemo(() => {
    if (subspace?.editors?.length) {
      const editorIds = subspace.editors.map(({ id }: { id: string }) => id)
      return (
        params.subspaceId && (isCurrentUserOwner || editorIds.includes(me?.id))
      )
    } else {
      return params.subspaceId && isCurrentUserOwner
    }
  }, [params.subspaceId, subspace, me?.id, isCurrentUserOwner])

  const isLoadingAll = isLoadingSubSpace && isLoadingSpace

  useEffect(() => {
    me?.spacesBookmarks && setBookmarks(me?.spacesBookmarks)
  }, [me?.id])

  useEffect(() => {
    if (subspace) {
      const ownerFullName = getUserFullName(
        subspace.owner?.firstName,
        subspace.owner?.lastName
      )
      mixpanelAnalyticsHelper().track('Subspace viewed', {
        subspaceId: subspace.id,
        subspaceTitle: subspace.title,
        subspaceOwner: ownerFullName,
        parentSpaceId: subspace.spaceId,
        subspaceLanguage: subspace.langCode
      })
    }
  }, [subspace])

  const onFavoriteIconClick = async (
    id: string,
    isBookmarked: boolean,
    e: React.SyntheticEvent<EventTarget>
  ) => {
    e.preventDefault()
    let bookmarksUpdated: SpacesBookmarkSimple[] = []
    if (isBookmarked) {
      bookmarksUpdated = bookmarks.filter(
        (item: SpacesBookmarkSimple) => item.id !== id
      )
    } else {
      bookmarksUpdated = [
        ...bookmarks,
        {
          id,
          type: SPACE_ARTICLE_TYPE
        }
      ]
    }
    setBookmarks(bookmarksUpdated)
    await patchUserSpacesBookmarks(me?.id, bookmarksUpdated)
    mutateUser()
  }

  const getIsArticleBookmarked = (article: SubSpaceArticleSimple) =>
    !!bookmarks.find((item) => item?.id === article.id)

  const onSaveSubspaceTitle = async (newTitle: string) => {
    await updateSubspaceTitle({
      subspaceId: subspace.id,
      title: newTitle
    })
    await mutate()
  }

  const onSaveSubspaceThumbnailFromLibrary = async (
    imgData: ImageToAttachToArticle
  ) => {
    setEditImageMode(false)
    await updateSubspaceThumbnail({
      subspaceId: subspace.id,
      imgData
    })
    await mutate()
  }

  const onSaveSubspaceThumbnailFromLocal = async (imgData: {
    file: File
    meta: FileMeta
  }) => {
    setEditImageMode(false)
    await updateSubspaceThumbnailWithLocalFile({
      subspaceId: subspace.id,
      subspaceLangCode: subspace.langCode,
      subspaceStatus: subspace.status,
      imgData
    })
    await mutate()
  }

  return (
    <Box
      css={{
        flexGrow: 1,
        d: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: isLoadingAll ? 'center' : 'flex-start'
      }}
    >
      <Box
        css={{
          width: '100%',
          background: '$gradientBlue',
          '@md': {
            height: '320px'
          },
          '@lg': {
            height: '380px'
          },
          '@xl': {
            height: '430px'
          },
          position: 'relative'
        }}
      >
        {!isLoadingSubSpace && subspace?.imageUrl && (
          <LazyImage
            src={subspace.imageUrl}
            aspectRatio={isSm ? '21/9' : 'auto'}
          />
        )}
        {!isLoadingSubSpace &&
          isCurrentUserOwner &&
          (editImageMode ? (
            <EditSubspaceImageLayout
              onSetImageFromMediaLibrary={onSaveSubspaceThumbnailFromLibrary}
              onSetImageFromLocal={onSaveSubspaceThumbnailFromLocal}
            />
          ) : (
            <Button
              variant="secondary"
              aria-label={t('field_subspace_edit_image')}
              css={{ position: 'absolute', top: '$4', right: '$4' }}
              onClick={() => setEditImageMode(true)}
            >
              <Box css={{ d: 'flex', mr: '$4' }}>
                <EditIcon16 />
              </Box>
              {t('field_subspace_edit_image')}
            </Button>
          ))}
      </Box>
      {isLoadingAll ? (
        <Box
          css={{
            d: 'flex',
            alignItems: 'center',
            flexGrow: 2,
            justifyContent: 'center'
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <ContainerPadding>
          <Container size="large">
            <Space mt="2XL">
              <Box css={{ d: 'flex' }}>
                <BackLink
                  as={Link}
                  paths={[t('field_spaces')]}
                  to={`/${spacesPageUrl}`}
                  css={{ mb: '$10', '@sm': { mb: '$7' } }}
                />
              </Box>

              <Box
                css={{
                  d: 'flex',
                  flexDirection: 'column',
                  '@lg': { flexDirection: 'row' }
                }}
              >
                <Sidebar
                  isCollapsed={collapsed}
                  toggleCollapse={() => setCollapsed(!collapsed)}
                  isLoading={isLoadingAll || isBookmarksLoading}
                  space={spaces?.[0]}
                  bookmarks={bookmarksWithDetails}
                />
                <Box
                  css={{
                    flex: 1,
                    d: 'flex',
                    p: '$6',
                    flexDirection: 'column',
                    justifyContent: isLoadingSubSpace ? 'center' : 'flex-start'
                  }}
                >
                  {isLoadingSubSpace ? (
                    <Spinner />
                  ) : (
                    <>
                      {subspace && (
                        <Space mb="MD">
                          <Space mb="SM" css={{ d: 'flex', gap: '$4' }}>
                            <Heading3>{subspace.title}</Heading3>
                            {isCurrentUserOwner && (
                              <RenameSubspaceDialog
                                key={subspace.title}
                                currentName={subspace.title}
                                onSave={onSaveSubspaceTitle}
                              />
                            )}
                          </Space>
                          <Space mb="SM">
                            <Box
                              css={{
                                d: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-end'
                              }}
                              mb="XS"
                            >
                              <Box css={{ d: 'flex', flexDirection: 'column' }}>
                                <Body7 css={{ marginBottom: '$1' }}>
                                  Owner
                                </Body7>
                                <Box css={{ marginLeft: '-$2' }}>
                                  <AuthorCard
                                    id={subspace?.owner?.id}
                                    image={subspace?.owner?.image}
                                    firstName={subspace?.owner?.firstName}
                                    lastName={subspace?.owner?.lastName}
                                    jobTitle={subspace?.owner?.jobTitle}
                                    status={subspace?.owner?.status}
                                  />
                                </Box>
                              </Box>
                              {subspace.editors?.length > 0 && (
                                <Box
                                  css={{
                                    d: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '$4'
                                  }}
                                >
                                  <Body7 css={{ marginBottom: '$1' }}>
                                    Editors
                                  </Body7>
                                  <Box
                                    css={{
                                      height: 48,
                                      d: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      paddingLeft: '$2'
                                    }}
                                  >
                                    {subspace?.editors?.map(
                                      (editor: User, key: number) => {
                                        if (key < 5) {
                                          return (
                                            <Box
                                              css={{ marginLeft: '-$2' }}
                                              key={key}
                                            >
                                              <UserAvatar user={editor} />
                                            </Box>
                                          )
                                        }
                                        return null
                                      }
                                    )}
                                    {subspace?.editors?.length > 5 ? (
                                      <Body6 css={{ marginLeft: '$1' }}>
                                        + {subspace?.editors?.length - 5} more
                                      </Body6>
                                    ) : null}
                                  </Box>
                                </Box>
                              )}

                              {isCurrentUserOwner && (
                                <Button
                                  css={{ marginLeft: 'auto' }}
                                  variant="secondary"
                                  size="small"
                                  onClick={() =>
                                    setOpenUserSelectModalOpen(true)
                                  }
                                >
                                  {t('field_subspace_editors_title')}
                                </Button>
                              )}
                            </Box>
                          </Space>
                          <Space mb="XS">
                            <Box
                              css={{
                                d: 'flex',
                                gap: '$4',
                                alignItems: 'center'
                              }}
                            >
                              <SearchInput
                                small
                                placeholder={t('field_search_by_title')}
                                data-cy="subspaceSearchUserInput"
                                value={searchValue}
                                onChange={setSearchValue}
                              />
                              <ReactSelect
                                value={sortType}
                                options={sortTypesOptions}
                                onChange={(option: SelectOption) =>
                                  setSortType(option)
                                }
                                placeholder={`Sort by:"${sortType.label}"`}
                                css={{ '@lg': { maxWidth: '189px' } }}
                              />
                            </Box>
                          </Space>
                          {subspace?.articles?.length > 0 &&
                            subspace.articles
                              .filter(getArticlesSearchFilterFunc(searchValue))
                              .sort(
                                getArticlesSortFunc(
                                  sortType.value,
                                  getIsArticleBookmarked
                                )
                              )
                              .map((article: SubSpaceArticleSimple) => (
                                <React.Fragment key={article.id}>
                                  {article.status && (
                                    <Space mb="3XS" key={article.id}>
                                      <Box
                                        key={subspace.id}
                                        as={Link}
                                        to={`/${spacesPageUrl}/${article.id}`}
                                        state={{ from: location.pathname }}
                                        css={{
                                          color: 'inherit',
                                          textDecoration: 'none'
                                        }}
                                      >
                                        <Card
                                          id={article.id}
                                          title={article.title}
                                          date={article.date}
                                          onFavoriteIconClick={
                                            onFavoriteIconClick
                                          }
                                          isBookmarked={getIsArticleBookmarked(
                                            article
                                          )}
                                        />
                                      </Box>
                                    </Space>
                                  )}
                                </React.Fragment>
                              ))}
                        </Space>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Space>
          </Container>
        </ContainerPadding>
      )}
      {isAllowedToAddArticles && (
        <AddNewArticleButton
          url={`/${spacesCreateArticlePageUrl}/${params.subspaceId}`}
          text={t('field_add_space_article')}
          containerSize="large"
          padding={true}
        />
      )}
      <SpaceEditorsDialog
        open={openUserSelectModal}
        setOpen={setOpenUserSelectModalOpen}
        subspace={subspace}
        refetchSubspace={mutate}
      />
    </Box>
  )
}
