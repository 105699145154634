import { createStitches } from '@stitches/react'
import type * as Stitches from '@stitches/react'
import { commonStitchesConfig as stitchesConfig } from '../../stitches.config'

export {
  commonStitchesConfig as stitchesConfig,
  BREAKPOINTS_QUERIES
} from '../../stitches.config'

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config
} = createStitches({
  prefix: 'intranet',
  ...stitchesConfig,
  theme: {
    ...stitchesConfig.theme
  }
})

export type CSS = Stitches.CSS<typeof config>
