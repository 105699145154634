import React from 'react'
import { styled } from '../../../stitches.config'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

const StyledAvatar = styled(AvatarPrimitive.Root, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  overflow: 'hidden',
  userSelect: 'none',
  size: 32,
  borderRadius: '100%',
  cursor: 'pointer',

  variants: {
    variant: {
      big: {
        size: 128,
        border: '3px solid $white',
        backgroundColor: 'transparent',

        '@lg': {
          size: 200
        }
      }
    }
  }
})

const StyledImage = styled(AvatarPrimitive.Image, {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 'inherit',
  '&:hover, &.__hover': {
    filter: 'brightness(110%)'
  },

  variants: {
    variant: {
      noHover: {
        '&:hover, &.__hover': {
          filter: 'none',
          cursor: 'auto'
        }
      }
    }
  }
})

const StyledFallback = styled(AvatarPrimitive.Fallback, {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
  color: '$white',
  fontSize: 15,
  fontWeight: 500
})

export const Avatar = StyledAvatar
export const AvatarImage = StyledImage
export const AvatarFallback = StyledFallback
