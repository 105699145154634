import React, { useEffect, useState, useRef } from 'react'
import { useMediaAssets, DamSearchFiltersType } from '../../api'
import { PreviewImage } from '../../components/preview-image'
import { Box, Spinner, Checkbox, Body7 } from '@sefar/design-system'

export const AssetCard = ({
  file,
  chosenFile,
  onAssetClick
}: {
  file: any
  chosenFile: any
  onAssetClick: (e: React.SyntheticEvent<EventTarget>) => void
}) => {
  return (
    <React.Fragment key={file.id}>
      {file.thumbnails['300px'].url && (
        <Box css={{ gap: '$2' }}>
          <Box
            css={{
              position: 'relative',
              height: '244px',
              borderRadius: '$4',
              padding: '$2',
              color: '$neutralLighten30',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '$neutralLighten93',
                color: '$neutral'
              },
              '&:focused': {
                border: '3px solid $primaryBlue'
              },
              '&.is-chosen': {
                color: '$neutral',
                border: '1px solid $primaryBlue',
                backgroundColor: '$primaryBlueLighten95',

                '.checkbox': {
                  display: 'inline-flex'
                }
              },
              '.checkbox': {
                display: 'none'
              }
            }}
            className={file.id === chosenFile?.id ? 'is-chosen' : ''}
            id={file.id}
            onClick={onAssetClick}
          >
            <Checkbox
              checked={true}
              className="checkbox"
              wrapperCss={{
                position: 'absolute',
                top: '7%',
                right: '7%',
                zIndex: '1'
              }}
              variant="inverted"
            />
            <Box
              css={{
                d: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '80%',
                mb: '$2'
              }}
            >
              <PreviewImage
                previewMedia={file.thumbnails['300px'].url}
                mediaType={file.file_properties.format_type}
                iconCentered={true}
                iconSize="64"
                wrapperCss={{
                  width: '100%'
                }}
                imageCss={{
                  aspectRatio: 'auto',
                  position: 'static',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  height: 'auto'
                }}
              />
            </Box>
            <Box
              css={{
                maxWidth: '100%',
                height: '20%',
                overflow: 'auto',
                py: '$1'
              }}
            >
              <Body7 css={{ wordBreak: 'break-word', color: 'inherit' }}>
                {file.filename}
              </Body7>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  )
}

const limit = 20

export function Gallery({
  filters,
  searchText,
  setTotalCount,
  chosenFile,
  setChosenFile,
  assetsType
}: {
  filters: DamSearchFiltersType
  searchText: string
  setTotalCount: (count: number) => void
  chosenFile: any
  setChosenFile: (file: any) => void
  assetsType?: string | Array<string>
}) {
  const [pageNum, setPageNum] = useState<number>(0)
  const [assets, setAssets] = useState([])
  const { data, isLoading, isError } = useMediaAssets(
    filters,
    searchText,
    pageNum,
    limit,
    assetsType
  )
  const [lastElement, setLastElement] = useState(null)

  // https://dev.to/hey_yogini/infinite-scrolling-in-react-with-intersection-observer-22fh
  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0]
      if (first.isIntersecting) {
        setPageNum((no) => no + 1)
      }
    })
  )

  useEffect(() => {
    const currentElement = lastElement
    const currentObserver = observer.current

    if (currentElement) {
      currentObserver.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement)
      }
    }
  }, [lastElement])

  useEffect(() => {
    if (data?.totalCount || data?.totalCount === 0) {
      setTotalCount(data?.totalCount)
    }
    if (isError) {
      console.error('Not successfully request to get media assets')
    }
  }, [data, isError])

  useEffect(() => {
    if (data?.items?.length) {
      if (!data?.offset) {
        setAssets(data.items)
      } else {
        setAssets((oldAssets: any) => [...oldAssets, ...data.items])
      }
    }
  }, [data.items])

  useEffect(() => {
    setPageNum(0)
  }, [filters, searchText])

  const onAssetClick = (e: React.SyntheticEvent<EventTarget>) => {
    const newChosenFile =
      e.currentTarget.id === chosenFile?.id
        ? undefined
        : assets?.find((asset: any) => asset.id === e.currentTarget.id)
    setChosenFile(newChosenFile)
  }

  return (
    <Box
      css={{
        d: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        height: assets?.length ? 'auto' : '100%'
      }}
    >
      <Box
        css={{
          d: 'grid',
          alignItems: 'center',
          rowGap: '$4',
          columnGap: '$4',
          gtc: 2,
          pr: '$2',
          '@md': { gtc: 3 },
          '@lg': { gtc: 4 },
          '@xl': { gtc: 5 }
        }}
      >
        {assets?.map((file: any, i: number) => {
          return i === assets.length - 1 &&
            !isLoading &&
            pageNum <= data?.totalCount / limit ? (
            <Box key={`${file.filename}-${i}`} ref={setLastElement}>
              <AssetCard
                file={file}
                chosenFile={chosenFile}
                onAssetClick={onAssetClick}
              />
            </Box>
          ) : (
            <AssetCard
              file={file}
              chosenFile={chosenFile}
              onAssetClick={onAssetClick}
              key={`${file.filename}-${i}`}
            />
          )
        })}
      </Box>
      {isLoading && (
        <Box css={{ d: 'flex', alignItems: 'center' }}>
          <Spinner />
        </Box>
      )}
    </Box>
  )
}
