import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useMedia } from 'react-use'
import {
  Accordion,
  Body5,
  Box,
  ChevronLeftDoubleIcon16,
  DashboardIcon24,
  Document24,
  StarOutline20,
  Space,
  Spinner
} from '@sefar/design-system'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { useTranslate } from '../../hooks/useTranslate'
import {
  Space as SpaceType,
  SpacesBookmark,
  SUBSPACE_TYPE,
  SubSpaceArticleSimple,
  SubSpaceSimple
} from '../../api'
import { spacesPageUrl, subSpacePageUrl } from '../../app'
import { SidebarAccordionItem } from './sidebar-accordion-item'

type Props = {
  isCollapsed: boolean
  toggleCollapse: () => void
  isLoading?: boolean
  space?: SpaceType
  bookmarks?: SpacesBookmark[]
  topSpacing?: boolean
}

export const Sidebar = ({
  isCollapsed,
  toggleCollapse,
  isLoading = false,
  space,
  bookmarks = [],
  topSpacing = false
}: Props) => {
  const { t } = useTranslate()
  const params = useParams()
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const isExpanded = !isLg || !isCollapsed
  const currentItemId = params.subspaceId ?? params.articleId

  const getIsArticleBookmarked = (article: SubSpaceArticleSimple) =>
    !!bookmarks.find((item) => item?.id === article.id)

  const getIsSubspaceBookmarked = (subspace: SubSpaceSimple) =>
    !!bookmarks.find((item) => item?.id === subspace.id)

  return (
    <Box
      css={{
        d: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flex: isExpanded ? 1 : 0,
        mt: topSpacing ? '$6' : 0,
        mb: '$6',
        p: '$4',
        minWidth: isExpanded ? 270 : 64,
        backgroundColor: '$white',
        border: '1px solid $destructiveDisabled',
        boxShadow: '$cardHover',
        '@lg': { maxWidth: 370 }
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {space && (
            <>
              <Space mb="2XS" css={{ d: 'flex', alignItems: 'center' }}>
                <Box
                  css={{
                    cursor: 'pointer',
                    margin: isExpanded ? '0 0 0 auto' : 'auto',
                    padding: '$2 $2',
                    borderRadius: '$3',
                    transform: `rotate(${isExpanded ? 0 : 180}deg)`,
                    visibility: isLg ? 'visible' : 'hidden',
                    color: '$neutral',
                    zIndex: 2,
                    '&:hover': {
                      color: '$primaryBlue',
                      backgroundColor: '$primaryBlueLighten95'
                    }
                  }}
                  onClick={() => toggleCollapse()}
                >
                  <ChevronLeftDoubleIcon16 />
                </Box>
              </Space>
              <Accordion
                css={{
                  display: isExpanded ? 'flex' : 'none',
                  flexDirection: 'column',
                  mt: '-$12',
                  gap: '$16'
                }}
                type="multiple"
                defaultValue={['field_my_favourites', 'current_space']}
              >
                <SidebarAccordionItem
                  key="field_my_favourites"
                  value="field_my_favourites"
                  title={t('field_my_favourites')}
                >
                  {bookmarks.length > 0 ? (
                    bookmarks.map((bookmark: SpacesBookmark) => (
                      <Box
                        key={bookmark.id}
                        as={Link}
                        to={
                          bookmark.type === SUBSPACE_TYPE
                            ? `/${subSpacePageUrl}/${bookmark.spaceId}/${bookmark.id}/`
                            : `/${spacesPageUrl}/${bookmark.id}/`
                        }
                        css={{
                          color: 'inherit',
                          borderRadius: '$2',
                          overflow: 'hidden',
                          textDecoration: 'none'
                        }}
                      >
                        <Box
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '$neutral',
                            p: '$2 $3',
                            backgroundColor:
                              bookmark.id === currentItemId
                                ? '$destructiveDisabled'
                                : 'initial',
                            overflow: 'hidden',
                            transition: '$default',
                            '&:hover': {
                              color: '$primaryBlue',
                              backgroundColor: '$primaryBlueLighten95',
                              borderRadius: '$2'
                            }
                          }}
                        >
                          {bookmark.type === SUBSPACE_TYPE ? (
                            <DashboardIcon24
                              width={14}
                              height={14}
                              style={{ marginRight: '8px', flexShrink: 0 }}
                            />
                          ) : (
                            <Document24
                              width={14}
                              height={14}
                              style={{ marginRight: '8px', flexShrink: 0 }}
                            />
                          )}
                          <Body5
                            css={{
                              textOverflow: 'ellipsis',
                              textWrap: 'nowrap',
                              overflow: 'hidden'
                            }}
                          >
                            {bookmark.title}
                          </Body5>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '$neutralLighten30',
                        p: '$2 $3',
                        overflow: 'hidden'
                      }}
                    >
                      <StarOutline20
                        width={16}
                        height={16}
                        style={{ marginRight: '8px', flexShrink: 0 }}
                      />
                      <Body5
                        css={{
                          textOverflow: 'ellipsis',
                          textWrap: 'nowrap',
                          overflow: 'hidden'
                        }}
                      >
                        {t('field_favourites_empty_title')}
                      </Body5>
                    </Box>
                  )}
                </SidebarAccordionItem>
                <SidebarAccordionItem
                  key="current_space"
                  value="current_space"
                  title={space?.title}
                >
                  {space?.subspaces.length > 0 &&
                    space?.subspaces.map((subspace: SubSpaceSimple) => (
                      <React.Fragment key={subspace.id}>
                        {subspace.articlesAmount > 0 && (
                          <Box
                            key={subspace.id}
                            as={Link}
                            to={`/${subSpacePageUrl}/${subspace.spaceId}/${subspace.id}/`}
                            css={{
                              color: 'inherit',
                              borderRadius: '$2',
                              overflow: 'hidden',
                              textDecoration: 'none'
                            }}
                          >
                            <Body5
                              css={{
                                color: '$neutral',
                                p: '$2 $3',
                                backgroundColor:
                                  subspace.id === currentItemId
                                    ? '$destructiveDisabled'
                                    : 'initial',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textWrap: 'nowrap',
                                transition: '$default',
                                '&:hover': {
                                  color: '$primaryBlue',
                                  backgroundColor: '$primaryBlueLighten95',
                                  borderRadius: '$2'
                                }
                              }}
                            >
                              {subspace.title}
                            </Body5>
                          </Box>
                        )}
                      </React.Fragment>
                    ))}
                </SidebarAccordionItem>
              </Accordion>
            </>
          )}
        </>
      )}
    </Box>
  )
}
