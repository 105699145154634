import React, { ComponentProps } from 'react'
import {
  Box,
  Button,
  Input,
  TextLink,
  Tooltip,
  Upload16,
  Settings20
} from '@sefar/design-system'
import { FILE_TYPE } from '../../api'
import { useTranslate } from '../../hooks/useTranslate'

const ALLOWED_FILE_FORMATS: Record<string, string> = {
  image: FILE_TYPE.Images.join(','),
  video: FILE_TYPE.Video.join(','),
  file: FILE_TYPE.Documents.join(',')
}

const getAllowedFileFormats = (allowedFileTypes: string[] | undefined) => {
  return allowedFileTypes &&
    allowedFileTypes.length &&
    Array.isArray(allowedFileTypes)
    ? allowedFileTypes.reduce(
        (res, fileType: string) =>
          ALLOWED_FILE_FORMATS[fileType]
            ? `${res}${ALLOWED_FILE_FORMATS[fileType]},`
            : res,
        ''
      )
    : `${ALLOWED_FILE_FORMATS.image},${ALLOWED_FILE_FORMATS.video},${ALLOWED_FILE_FORMATS.file}`
}

type AddMediaDialogPropsTypes = {
  id: string
  onInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  allowedFileTypes?: string[]
  text?: string
  buttonVariant?: string
  size?: string
}

const InputContent = () => {
  const { t } = useTranslate()
  return (
    <>
      <Box as="span" className="icon icon-left">
        <Upload16 />
      </Box>
      {t('field_upload_from_local')}
    </>
  )
}

export const AddMediaInput = ({
  id,
  onInputFileChange,
  allowedFileTypes,
  text: string,
  buttonVariant,
  size,
  css,
  ...props
}: AddMediaDialogPropsTypes &
  Omit<ComponentProps<typeof Input>, 'size'> &
  ComponentProps<typeof Box>) => {
  return (
    <Box css={css}>
      {buttonVariant === 'textLink' ? (
        <Box>
          <label htmlFor={id}>
            <TextLink className="no-underline">
              <InputContent />
            </TextLink>
          </label>
        </Box>
      ) : (
        <Box>
          <label htmlFor={id}>
            <Button variant="secondary" as="label" htmlFor={id} size={size}>
              <InputContent />
            </Button>
          </label>
        </Box>
      )}
      <Box
        {...props}
        containerProps={{ css: { d: 'none' } }}
        as={Input}
        id={id}
        type="file"
        accept={getAllowedFileFormats(allowedFileTypes)}
        onChange={onInputFileChange}
        required
      />
    </Box>
  )
}
