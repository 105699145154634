import { Box, Heading5 } from '@sefar/design-system'
import { PurchaseRequisitionOrder } from '../types'
import { useTranslate } from '../../../../hooks/useTranslate'
import { OrderCommentsHistoryItem } from './order-comments-history-item'

export const OrderCommentsHistory = ({
  order
}: {
  order: PurchaseRequisitionOrder
}) => {
  const { t } = useTranslate()

  if (!order.comments?.length) return null

  return (
    <Box
      css={{
        backgroundColor: '$neutralLighten97',
        mx: '-$8',
        px: '$8',
        pt: '$10',
        pb: '$14'
      }}
    >
      <Box css={{ mx: 'auto', maxWidth: '54rem' }}>
        <Heading5 css={{ mb: '$6' }}>
          {t('field_purchase_requisition_comment_from_approver')}
        </Heading5>
        <Box css={{ d: 'flex', flexDirection: 'column', gap: '$4' }}>
          {order.comments?.map((commentData) => (
            <OrderCommentsHistoryItem
              key={commentData.id}
              commentData={commentData}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}
