import React, { useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import { AddFile20, Body4, Box, Button, Heading5 } from '@sefar/design-system'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { AddMediaInput, FileInfoDialog, GalleryDialog } from '../../components'
import {
  getCountryListCategories,
  getSubCategoriesByCategory
} from '../../components/article/helpers'
import {
  FileTypeEnum,
  FileTypeHTMLFormat,
  getFileType,
  ImageToAttachToArticle,
  MediaAssetsTypes,
  preloadImageToDrupal,
  SelectedValuesType,
  transferPreloadedToDrupalImage,
  useCategories
} from '../../api'
import { fileDrop, FileMeta } from '../news-details/helpers'

type EditSubspaceImageLayoutProps = {
  onSetImageFromMediaLibrary: (imgData: ImageToAttachToArticle) => void
  onSetImageFromLocal: (data: { file: File; meta: FileMeta }) => void
}

export function EditSubspaceImageLayout({
  onSetImageFromMediaLibrary,
  onSetImageFromLocal
}: EditSubspaceImageLayoutProps) {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)

  const [isGalleryOpened, setIsGalleryOpened] = useState(false)
  const [chosenFile, setChosenFile] = useState<{
    id: string
    embeds: Record<string, { url: string }>
  }>()
  const [dragCounter, setDragCounter] = useState(0)
  const [file, setFile] = useState<File>()
  const [fileContentAsString, setFileContentAsString] = useState<string>()
  const [isUploadMediaDialogOpened, setIsUploadMediaDialogOpened] =
    useState(false)

  const { categories } = useCategories()

  const [filterCategories, setFilterCategories] = useState<SelectedValuesType>(
    []
  )
  const [filterCountries, setFilterCountries] = useState<SelectedValuesType>([])

  useEffect(() => {
    if (categories?.length) {
      setFilterCategories(
        getSubCategoriesByCategory(categories, 'news', 'Main Visual')
      )
      setFilterCountries(getCountryListCategories(categories))
    }
  }, [categories])

  const onInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget?.files?.[0]
    const type = file && (getFileType(file) as FileTypeHTMLFormat)
    if (file && type && ['image'].includes(FileTypeHTMLFormat[type])) {
      setFile(file)
      setIsUploadMediaDialogOpened(true)
      setIsGalleryOpened(false)
    }
  }

  const onFileSelected = async () => {
    if (chosenFile?.id) {
      const imgDataResponse = await preloadImageToDrupal(chosenFile.id)
      const imgData = await imgDataResponse.json()
      onSetImageFromMediaLibrary(transferPreloadedToDrupalImage(imgData))
      setIsGalleryOpened(false)
    }
  }

  return (
    <>
      <Box
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          color: '$white',
          d: 'flex',
          backgroundColor: dragCounter
            ? 'rgba(0, 0, 0, 0.75)'
            : 'rgba(0, 0, 0, 0.5)',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onDrop={(e: React.DragEvent) => {
          setDragCounter(0)
          e.preventDefault()
          const files = fileDrop(e)
          const type = files[0] && (getFileType(files[0]) as FileTypeHTMLFormat)
          if (
            files[0] &&
            type &&
            ['image'].includes(FileTypeHTMLFormat[type])
          ) {
            setFile(files[0])
            fileContentAsString && URL.revokeObjectURL(fileContentAsString)
            setFileContentAsString(URL.createObjectURL(files[0]))
            setIsUploadMediaDialogOpened(true)
          }
        }}
        onDragEnter={(e) => {
          setDragCounter((v) => v + 1)
        }}
        onDragLeave={(e) => {
          setDragCounter((v) => v - 1)
        }}
        onDragOver={(event) => {
          event.preventDefault()
        }}
      >
        <Box
          css={{
            mb: '$4',
            '@sm': {
              d: 'none'
            }
          }}
        >
          <AddFile20 />
        </Box>

        <Heading5
          css={{
            '@sm': {
              d: 'none'
            }
          }}
        >
          Drag & drop image here
        </Heading5>

        <Body4
          css={{
            mt: '$2',
            mb: '$6',
            '@sm': {
              d: 'none'
            }
          }}
        >
          or
        </Body4>

        <Box
          css={{
            d: 'flex',
            gap: '$4',
            '@sm': {
              flexDirection: 'column'
            }
          }}
        >
          <AddMediaInput
            id="main-media-file"
            onInputFileChange={onInputFileChange}
            allowedFileTypes={['image']}
          />

          <Button onClick={() => setIsGalleryOpened(true)}>
            Browse library
          </Button>
        </Box>
      </Box>

      <FileInfoDialog
        categories={filterCategories}
        countries={filterCountries}
        src={fileContentAsString}
        file={file}
        open={isUploadMediaDialogOpened}
        setOpen={setIsUploadMediaDialogOpened}
        meta={undefined}
        onInsert={(meta: FileMeta, updatedImageFile?: File) => {
          if (file) {
            onSetImageFromLocal({
              file: updatedImageFile ?? file,
              meta
            })
          }
          setIsUploadMediaDialogOpened(false)
        }}
        onCancel={() => setFile(undefined)}
      />

      <GalleryDialog
        title="Select main visual"
        open={isGalleryOpened}
        setOpenDialog={setIsGalleryOpened}
        chosenFile={chosenFile}
        setChosenFile={setChosenFile}
        filterCategories={filterCategories}
        filterCountries={filterCountries}
        allowedFormats={[FileTypeEnum.Images]}
        assetsType={[
          MediaAssetsTypes.news.key,
          MediaAssetsTypes.news.enum.mainVisual
        ]}
        footer={
          <Box
            css={{
              d: 'flex',
              justifyContent: isLg ? 'space-between' : 'flex-end'
            }}
          >
            <Box css={{ d: 'flex', alignItems: 'center' }}>
              <>
                {isLg && (
                  <AddMediaInput
                    id="main-media-file"
                    onInputFileChange={onInputFileChange}
                    allowedFileTypes={['image']}
                    size="small"
                  />
                )}
              </>
            </Box>
            <Button
              size="small"
              onClick={onFileSelected}
              disabled={!chosenFile}
            >
              Insert media
            </Button>
          </Box>
        }
      >
        {!isLg && (
          <AddMediaInput
            id="article-file"
            onInputFileChange={onInputFileChange}
            buttonVariant="textLink"
          />
        )}
      </GalleryDialog>
    </>
  )
}
