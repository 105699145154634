export enum PurchaseRequisitionType {
  All = 'all',
  Own = 'own',
  Team = 'team',
  Assigned = 'assigned'
}

export enum PurchaseRequisitionStatusType {
  All = 'ALL',
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
  Rejected = 'REJECTED',
  Rework = 'REWORK',
  Approved = 'APPROVED',
  Received = 'RECEIVED',
  Draft = 'DRAFT'
}

export const purchaseRequisitionStatusesLabels = {
  [PurchaseRequisitionStatusType.All]: 'All',
  [PurchaseRequisitionStatusType.Draft]: 'Draft',
  [PurchaseRequisitionStatusType.Created]: 'Pending Approval',
  [PurchaseRequisitionStatusType.Rework]: 'Rework',
  [PurchaseRequisitionStatusType.Approved]: 'Approved',
  [PurchaseRequisitionStatusType.Confirmed]: 'Confirmed in LN',
  [PurchaseRequisitionStatusType.Rejected]: 'Rejected',
  [PurchaseRequisitionStatusType.Received]: 'Goods received'
}

export const purchaseRequisitionTypesLabels = {
  [PurchaseRequisitionType.All]: 'All',
  [PurchaseRequisitionType.Own]: 'Added by me',
  [PurchaseRequisitionType.Team]: 'All from the team',
  [PurchaseRequisitionType.Assigned]: 'Assigned to me'
}

export const approverPurchaseRequisitionTypes = [
  PurchaseRequisitionType.All,
  PurchaseRequisitionType.Own,
  // PurchaseRequisitionType.Team,
  PurchaseRequisitionType.Assigned
]

export const nonApproverPurchaseRequisitionTypes = [
  PurchaseRequisitionType.All,
  PurchaseRequisitionType.Own
]

export const PURCHASE_REQUISITION_PAGE_PARAM_KEY = 'page'
export const PURCHASE_REQUISITION_TYPE_PARAM_KEY = 'type'
export const PURCHASE_REQUISITION_SEARCH_PARAM_KEY = 'search'
export const PURCHASE_REQUISITION_STATUS_PARAM_KEY = 'status'

export const purchaseRequisitionStatusesBadgeColors = {
  [PurchaseRequisitionStatusType.All]: 'grayed',
  [PurchaseRequisitionStatusType.Created]: 'pending',
  [PurchaseRequisitionStatusType.Confirmed]: 'pendingLight',
  [PurchaseRequisitionStatusType.Rework]: 'progressLight',
  [PurchaseRequisitionStatusType.Rejected]: 'error',
  [PurchaseRequisitionStatusType.Rework]: 'pending',
  [PurchaseRequisitionStatusType.Approved]: 'progress',
  [PurchaseRequisitionStatusType.Received]: 'success',
  [PurchaseRequisitionStatusType.Draft]: 'grayed'
} as const

export const PURCHASE_REQUISITIONS_LIST_PAGE_SIZE = 10
