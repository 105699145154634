import { SubSpaceArticleSimple } from '../../api'
import dateFns from 'date-and-time'
import { isBefore, isAfter } from 'date-fns'
import { DATE_TIME_PUBLICATION_OFFICIAL_FORMAT } from '../../components'

export enum ArticleSortTypesEnum {
  MOST_RELEVANT = 'most_relevant',
  NAME_DESC = 'name_desc',
  NAME_ASC = 'name_asc',
  UPDATED_AT_ASC = 'updatedAt_asc',
  UPDATED_AT_DESC = 'updatedAt_desc'
}

export function getArticlesSortFunc(
  sortType: ArticleSortTypesEnum,
  getIsArticleBookmarkedFunc: (article: SubSpaceArticleSimple) => boolean
) {
  if ([ArticleSortTypesEnum.MOST_RELEVANT].includes(sortType)) {
    return (a: SubSpaceArticleSimple, b: SubSpaceArticleSimple) => {
      const aIsBookmarked = getIsArticleBookmarkedFunc(a)
      const bIsBookmarked = getIsArticleBookmarkedFunc(b)
      if (aIsBookmarked > bIsBookmarked) {
        return -1
      }
      if (aIsBookmarked < bIsBookmarked) {
        return 1
      }
      return 0
    }
  }

  if (
    [ArticleSortTypesEnum.NAME_DESC, ArticleSortTypesEnum.NAME_ASC].includes(
      sortType
    )
  ) {
    return (a: SubSpaceArticleSimple, b: SubSpaceArticleSimple) => {
      const aTitle = a.title.toLowerCase().trim()
      const bTitle = b.title.toLowerCase().trim()
      if (sortType === ArticleSortTypesEnum.NAME_DESC) {
        if (aTitle < bTitle) {
          return -1
        }
        if (aTitle > bTitle) {
          return 1
        }
        return 0
      } else {
        if (bTitle < aTitle) {
          return -1
        }
        if (bTitle > aTitle) {
          return 1
        }
        return 0
      }
    }
  }

  if (
    [
      ArticleSortTypesEnum.UPDATED_AT_ASC,
      ArticleSortTypesEnum.UPDATED_AT_DESC
    ].includes(sortType)
  ) {
    return (a: SubSpaceArticleSimple, b: SubSpaceArticleSimple) => {
      const aUpdatedAtDate = dateFns.parse(
        a.date,
        DATE_TIME_PUBLICATION_OFFICIAL_FORMAT
      )
      const bUpdatedAtDate = dateFns.parse(
        b.date,
        DATE_TIME_PUBLICATION_OFFICIAL_FORMAT
      )
      if (sortType === ArticleSortTypesEnum.UPDATED_AT_DESC) {
        const aDateIsBeforeBDate = isBefore(aUpdatedAtDate, bUpdatedAtDate)
        if (aDateIsBeforeBDate) {
          return -1
        }
        const aDateIsAfterBDate = isAfter(aUpdatedAtDate, bUpdatedAtDate)
        if (aDateIsAfterBDate) {
          return 1
        }
        return 0
      } else {
        const bDateIsBeforeADate = isBefore(bUpdatedAtDate, aUpdatedAtDate)
        if (bDateIsBeforeADate) {
          return -1
        }
        const bDateIsAfterADate = isAfter(bUpdatedAtDate, aUpdatedAtDate)
        if (bDateIsAfterADate) {
          return 1
        }
        return 0
      }
    }
  }
}

export function getArticlesSearchFilterFunc(searchValue: string) {
  return (article: SubSpaceArticleSimple) =>
    article.title.toLowerCase().includes(searchValue.trim().toLowerCase())
}
