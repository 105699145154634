import React from 'react'
import { renderToString } from 'react-dom/server'
import { StaticRouter } from 'react-router-dom/server'
import Picture404 from '../../../assets/404-picture.png'
import { searchPageUrl } from '../../app'
import { Link, useLocation } from 'react-router-dom'
import {
  Body2,
  Body3,
  Box,
  Heading1,
  Space,
  ContainerPadding,
  Container,
  TextLink,
  LazyImage
} from '@sefar/design-system'
import { useTranslate } from '../../hooks/useTranslate'

export function Page404() {
  const { t } = useTranslate()
  const location = useLocation()

  return (
    <ContainerPadding
      css={{
        flex: 1,
        d: 'flex',
        flexDirection: 'column'
      }}
    >
      <Container size="small" css={{ w: '100% ' }}>
        <Space mt="2XL" mb="SM">
          <Heading1>{t('field_404_page_title')}</Heading1>
        </Space>
        <Space mb="XL">
          <Body2>{t('field_404_page_text')}</Body2>
        </Space>
      </Container>
      <Container>
        <Space mb="2XL" css={{ d: 'flex', justifyContent: 'center' }}>
          <Box css={{ maxWidth: '1040px' }}>
            <LazyImage src={Picture404} aspectRatio="auto" />
          </Box>
        </Space>
      </Container>
      <Container size="small" css={{ w: '100% ' }}>
        <Space mb="2XL">
          <Body3
            dangerouslySetInnerHTML={{
              __html: t('field_404_page_links_text', [
                renderToString(
                  <StaticRouter location={location.pathname}>
                    <TextLink as={Link} to={`/${searchPageUrl}`}>
                      {t('field_search')}
                    </TextLink>
                  </StaticRouter>
                ),
                // TODO: remove by the link to the page when in will be created
                'help & support'
              ])
            }}
          ></Body3>
        </Space>
      </Container>
    </ContainerPadding>
  )
}
