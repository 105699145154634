import {
  Body5,
  Body7,
  Box,
  Button,
  LazyImage,
  MegaphoneIcon16,
  HeartIcon16,
  ReplyIcon16,
  Space,
  TextLink,
  UnreadMark,
  ArrowRightIcon16,
  CheckmarkIcon16,
  LightBulbIcon20,
  DashboardIcon16
} from '@sefar/design-system'
import AvatarPlaceholder from '../../../assets/avatar-placeholder-blue.jpg'
import { Link, useLocation } from 'react-router-dom'
import { StaticRouter } from 'react-router-dom/server'
import { formatArticleDate } from '../../components'
import React, { ComponentProps, useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import { Notification, NotificationType, SearchEntityTypes } from '../../api'
import { useTranslate } from '../../hooks/useTranslate'

const TitleLink = ({
  children,
  ...props
}: { children: React.ReactNode } & ComponentProps<typeof Link>) => (
  <TextLink
    variant="neutral"
    as={Link}
    {...props}
    css={{
      display: 'initial',
      border: 'none',
      '&:hover': { color: '$primaryBlue', border: 'none' }
    }}
  >
    {children}
  </TextLink>
)

export function NotificationCard({
  unread,
  comment,
  commentId,
  date,
  type,
  user,
  entity,
  changeReadStatus
}: Notification & { changeReadStatus: (unread: boolean) => void }) {
  const readButtonStyles = {
    opacity: unread ? 1 : 0,
    pointerEvents: unread ? 'initial' : 'none'
  }
  const location = useLocation()
  const { t } = useTranslate()
  const articleUrl = useMemo(() => {
    switch (type) {
      case NotificationType.PRApproval:
      case NotificationType.PRStatus:
      case NotificationType.PRSyncStatus:
        switch (comment) {
          case 'CREATED:REWORK':
            return `/${entity?.type}/${entity.id}`
          default:
            return `/${entity?.type}/${entity.id}/overview`
        }
      default:
        return `/${entity?.type}/${entity.id}/overview`
    }
  }, [type, comment, entity])

  const Author = () => (
    <TitleLink
      onClick={() => changeReadStatus(false)}
      to={`/profile/${user.id}`}
    >
      {user.fullName}
    </TitleLink>
  )
  const ArticleLink = () => (
    <TitleLink
      onClick={() => changeReadStatus(false)}
      to={articleUrl}
      state={{ from: location.pathname }}
    >
      {entity?.title}
    </TitleLink>
  )

  const translatedString = useMemo(() => {
    switch (type) {
      case NotificationType.PRApproval:
      case NotificationType.PRStatus:
      case NotificationType.PRSyncStatus:
        switch (comment) {
          case 'DRAFT:CREATED':
            return t(`field_notice_pr_created`, [
              renderToString(
                <StaticRouter location={location.pathname}>
                  <Author />
                </StaticRouter>
              ),
              renderToString(
                <StaticRouter location={location.pathname}>
                  <ArticleLink />
                </StaticRouter>
              )
            ])
          case 'REWORK:CREATED':
            return t(`field_notice_pr_reworked`, [
              renderToString(
                <StaticRouter location={location.pathname}>
                  <Author />
                </StaticRouter>
              ),
              renderToString(
                <StaticRouter location={location.pathname}>
                  <ArticleLink />
                </StaticRouter>
              )
            ])
          case 'CREATED:REWORK':
            return t(`field_notice_pr_needs_rework`, [
              renderToString(
                <StaticRouter location={location.pathname}>
                  <ArticleLink />
                </StaticRouter>
              )
            ])
          case 'CREATED:APPROVED':
            return t(`field_notice_pr_sync_approved`, [
              renderToString(
                <StaticRouter location={location.pathname}>
                  <Author />
                </StaticRouter>
              ),
              renderToString(
                <StaticRouter location={location.pathname}>
                  <ArticleLink />
                </StaticRouter>
              )
            ])
          case '*:REJECTED':
            return t(`field_notice_pr_sync_rejected`, [
              renderToString(
                <StaticRouter location={location.pathname}>
                  <Author />
                </StaticRouter>
              ),
              renderToString(
                <StaticRouter location={location.pathname}>
                  <ArticleLink />
                </StaticRouter>
              )
            ])
          case 'APPROVED:CREATED':
            return t(`field_notice_pr_sync_error`, [
              renderToString(
                <StaticRouter location={location.pathname}>
                  <ArticleLink />
                </StaticRouter>
              )
            ])
        }
        break
      default:
        return t(
          `field_notice_${type?.toLowerCase()}_${entity?.type?.toLowerCase()}`,
          [
            renderToString(
              <StaticRouter location={location.pathname}>
                <Author />
              </StaticRouter>
            ),
            renderToString(
              <StaticRouter location={location.pathname}>
                <ArticleLink />
              </StaticRouter>
            )
          ]
        )
    }
  }, [type, entity.type, entity.title, location.pathname, comment])

  const Message = () => (
    <Body5
      css={{ paddingRight: '20px', '@lg': { paddingRight: 0 } }}
      dangerouslySetInnerHTML={{ __html: translatedString }}
    ></Body5>
  )

  const AvatarLink = () => (
    <Box
      css={{ cursor: 'pointer' }}
      as={Link}
      to={`/profile/${user.id}`}
      onClick={() => changeReadStatus(false)}
    >
      <LazyImage src={user.image ?? AvatarPlaceholder} aspectRatio="1 / 1" />
    </Box>
  )

  const EntityTypeIcon: Partial<Record<SearchEntityTypes, React.ReactNode>> = {
    [SearchEntityTypes.News]: <MegaphoneIcon16 />,
    [SearchEntityTypes.Spaces]: <DashboardIcon16 />,
    [SearchEntityTypes.Ideas]: <LightBulbIcon20 />,
    [SearchEntityTypes.PurchaseRequisitions]: <DashboardIcon16 />
  }

  const EntityTypeColor: Partial<Record<SearchEntityTypes, string>> = {
    [SearchEntityTypes.News]: '$primaryBlueLighten50',
    [SearchEntityTypes.Spaces]: '$purple',
    [SearchEntityTypes.Ideas]: '$orange',
    [SearchEntityTypes.PurchaseRequisitions]: '$primaryBlueLighten50'
  }

  return (
    <Box
      onClick={() => changeReadStatus(false)}
      css={{
        py: '$6',
        pl: '$10',
        pr: '$4',
        borderTop: '1px $neutralLighten90 solid',
        borderBottom: '1px $neutralLighten90 solid',
        mb: '-1px',
        backgroundColor: unread ? '$primaryBlueLighten97' : '',
        d: 'grid',
        gridTemplateColumns: '48px auto auto',
        gridTemplateRows: 'auto',
        gridTemplateAreas: ` "avatar content content"
        "avatar action read-button"`,
        columnGap: '$4',
        rowGap: '$4',
        '&:hover': {
          backgroundColor: unread ? '$primaryBlueLighten90' : ''
        },
        '& .read-button': readButtonStyles,
        '@sm': { px: '$6' },
        '@lg': {
          gridTemplateColumns: '68px auto auto',
          gridTemplateAreas: ` "avatar content read-button"
        "avatar action action"`,
          '& .read-button': {
            opacity: 0,
            pointerEvents: 'none'
          },
          '&:hover .read-button': readButtonStyles
        }
      }}
    >
      <Box
        css={{
          gridArea: 'avatar',
          position: 'relative',
          d: 'flex',
          alignItems: 'start',
          gap: '$3'
        }}
      >
        {unread && (
          <UnreadMark
            css={{ d: 'none', left: '-24px', top: 22, '@lg': { d: 'initial' } }}
          />
        )}
        <Space
          css={{
            all: 'unset',
            d: 'block',
            size: 48,
            borderRadius: '$round',
            overflow: 'hidden',
            '@lg': {
              size: 56
            }
          }}
        >
          {type === NotificationType.IdeaAssigned ||
          type === NotificationType.IdeaStatus ? (
            <Box
              css={{
                height: '100%',
                backgroundColor: '$orange',
                color: '$white',
                d: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <LightBulbIcon20 />
            </Box>
          ) : (
            <AvatarLink />
          )}
        </Space>

        <Space
          css={{
            size: 24,
            borderRadius: '$round',
            overflow: 'hidden',
            position: 'absolute',
            right: -8,
            top: 28,
            backgroundColor: EntityTypeColor[entity.type],
            color: '$white',
            d: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '@lg': {
              right: 0,
              top: 32,
              size: 32
            },
            '& svg': {
              height: 16
            },
            '@sm': {
              '& svg': {
                height: 14
              }
            }
          }}
        >
          {type === NotificationType.Like ? (
            <HeartIcon16 />
          ) : type === NotificationType.IdeaAssigned ||
            type === NotificationType.IdeaStatus ? (
            <AvatarLink />
          ) : (
            EntityTypeIcon[entity.type]
          )}
        </Space>
      </Box>

      <Box
        css={{
          gridArea: 'content',
          position: 'relative',
          color: '$neutral',
          d: 'flex',
          flexDirection: 'column'
        }}
      >
        <Message />
        <Body7 css={{ color: '$neutralLighten30', mt: '$1', mb: '$4' }}>
          {formatArticleDate(date)}
        </Body7>
        {comment && entity.type !== SearchEntityTypes.PurchaseRequisitions ? (
          <Body5>{comment}</Body5>
        ) : null}

        {unread && (
          <UnreadMark css={{ right: 0, top: 6, '@lg': { d: 'none' } }} />
        )}
      </Box>

      <TextLink
        className="no-underline"
        css={{
          gridArea: 'action'
        }}
        as={Link}
        to={{
          pathname: articleUrl,
          hash: commentId ? `#${commentId}` : ''
        }}
        state={{ from: location.pathname }}
        onClick={() => changeReadStatus(false)}
      >
        {type === NotificationType.Comment ||
        type === NotificationType.Reply ? (
          <>
            <span className="icon icon-left">
              <ReplyIcon16 />
            </span>
            {t('field_notice_act_comment')}
          </>
        ) : (
          <>
            <span className="icon icon-left">
              <ArrowRightIcon16 />
            </span>
            {t('field_notice_act_like')}
          </>
        )}
      </TextLink>

      <Box
        css={{
          gridArea: 'read-button',
          alignSelf: 'start',
          d: 'flex',
          justifyContent: 'end'
        }}
      >
        <Button
          onClick={() => changeReadStatus(false)}
          variant="secondary"
          className="read-button"
          css={{ whiteSpace: 'nowrap' }}
        >
          <Box as="span" className="icon icon-left">
            <CheckmarkIcon16 />
          </Box>
          {t('field_notice_act_read')}
        </Button>
      </Box>
    </Box>
  )
}
