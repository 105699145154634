import React from 'react'
import { Body7, Box, ClockIcon14, Tooltip } from '@sefar/design-system'
import { formatDateOfficial } from '../utils'
import { Idea, IdeaStatuses } from '../../api'
import { useTranslate } from '../../hooks/useTranslate'

export const DateOfRealization = ({ idea }: { idea?: Idea }) => {
  const { t } = useTranslate()

  if (
    !idea?.dateOfRealization ||
    idea.status !== IdeaStatuses['Implementation in Progress']
  ) {
    return null
  }

  return (
    <Tooltip
      contentProps={{ css: { padding: '$1' } }}
      text={t('field_idea_realization_date_name')}
      withArrow={false}
    >
      <Box css={{ d: 'flex', alignItems: 'center' }}>
        <ClockIcon14 />
        <Body7 css={{ pl: '$1' }}>
          {formatDateOfficial(idea.dateOfRealization)}
        </Body7>
      </Box>
    </Tooltip>
  )
}
