import { DragEvent } from 'react'

export function fileDrop(e: DragEvent): File[] {
  // Prevent default behavior (Prevent file from being opened)
  // e.preventDefault()

  // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
  return e.dataTransfer?.items
    ? [...e.dataTransfer.items]
        .filter((item) => item?.kind === 'file')
        .map((item) => item.getAsFile() as File)
    : [...(e.dataTransfer?.files ?? [])]
}

export type ArticleTexts = {
  title: string
  lead: string
  body: string
}

export type FileMeta = {
  author: string
  category: string[]
  country: string[]
  copyright?: string
  uploadedfrom: string
}
export const newlyAddedFileInitialState: FileMeta = {
  author: '',
  category: [],
  country: [],
  copyright: '',
  uploadedfrom: window.location.origin
}

export const getNewlyAddedFileMetaInitialState: () => FileMeta = () =>
  JSON.parse(JSON.stringify(newlyAddedFileInitialState))
