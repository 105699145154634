export const PURCHASE_API_BASE_URL = 'https://purchase.api.my.sefar.com/api/v1'

export const AVAILABLE_UNITS = {
  cm: 'cm',
  ea: 'stück',
  fld: 'fields',
  ft: 'feets',
  g: 'grams',
  gl: 'gallons',
  h: 'hours',
  hun: 'hundred pieces',
  kg: 'kilograms',
  km: 'kilometers',
  l: 'liters',
  lbs: 'pounds',
  m: 'meters',
  m2: 'square meters',
  ml: 'mililiters',
  par: 'pairs',
  pcs: 'pieces',
  qt: 'quarts',
  rol: 'rolls',
  set: 'sets',
  stk: 'stück',
  tau: 'thousand pieces',
  uni: 'units',
  yd: 'yard'
}
