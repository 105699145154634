import { useConfigStore } from '../state/config'
import useSWR from 'swr'
import {
  transferNewsArticles,
  NewsArticle,
  SpaceArticle,
  SubSpace,
  SubSpaceSimple,
  transferSpaceArticles,
  transferSubSpaceSimple
} from './index'
import { transferUser, User } from './user'
import { API_DOMAIN, fetcherFullRequest, init } from './common'

export const SEARCH_PAGE_LIMIT = 20

export const searchableCountriesMap: {
  [code: string]: {
    label: string
  }
} = {
  ch: {
    label: 'Switzerland'
  },
  ro: {
    label: 'Romania'
  },
  us: {
    label: 'USA'
  },
  cn: {
    label: 'China'
  },
  mx: {
    label: 'Mexico'
  },
  th: {
    label: 'Thailand'
  },
  pl: {
    label: 'Poland'
  },
  in: {
    label: 'India'
  },
  my: {
    label: 'Malaysia'
  },
  de: {
    label: 'Germany'
  },
  br: {
    label: 'Brazil'
  },
  fr: {
    label: 'France'
  },
  au: {
    label: 'Australia'
  },
  es: {
    label: 'Spain'
  },
  sg: {
    label: 'Singapore'
  },
  ca: {
    label: 'Canada'
  },
  it: {
    label: 'Italy'
  },
  nl: {
    label: 'Netherlands'
  },
  kr: {
    label: 'South Korea'
  },
  ma: {
    label: 'Morocco'
  },
  za: {
    label: 'South Africa'
  },
  uk: {
    label: 'United Kingdom'
  },
  nz: {
    label: 'New Zealand'
  },
  tr: {
    label: 'Turkey'
  },
  gb: {
    label: 'Great Britain'
  },
  hk: {
    label: 'Hong Kong'
  },
  ph: {
    label: 'Philippines'
  },
  ru: {
    label: 'Russia'
  },
  vn: {
    label: 'Vietnam'
  },
  jp: {
    label: 'Japan'
  }
}

export const newsCountriesMap: {
  [code: string]: {
    label: string
    options: string[]
  }
} = {
  ch: {
    label: 'Switzerland',
    options: ['Switzerland-Heiden/Thal', 'Switzerland-Emmenbrücke']
  },
  ro: {
    label: 'Romania',
    options: ['Romania']
  },
  us: {
    label: 'USA',
    options: ['USA']
  },
  cn: {
    label: 'China',
    options: ['China']
  },
  mx: {
    label: 'Mexico',
    options: ['Mexico']
  },
  th: {
    label: 'Thailand',
    options: ['Thailand']
  },
  pl: {
    label: 'Poland',
    options: ['Poland']
  },
  in: {
    label: 'India',
    options: ['India']
  },
  my: {
    label: 'Malaysia',
    options: ['Malaysia']
  },
  de: {
    label: 'Germany',
    options: ['Germany']
  },
  br: {
    label: 'Brazil',
    options: ['Brazil']
  },
  fr: {
    label: 'France',
    options: ['France']
  },
  au: {
    label: 'Australia',
    options: ['Australia']
  },
  es: {
    label: 'Spain',
    options: ['Spain']
  },
  sg: {
    label: 'Singapore',
    options: ['Singapore']
  },
  ca: {
    label: 'Canada',
    options: ['Canada']
  },
  it: {
    label: 'Italy',
    options: ['Italy']
  },
  nl: {
    label: 'Netherlands',
    options: ['Netherlands']
  },
  kr: {
    label: 'South Korea',
    options: ['South Korea']
  },
  ma: {
    label: 'Morocco',
    options: ['Morocco']
  },
  za: {
    label: 'South Africa',
    options: ['South Africa']
  },
  uk: {
    label: 'United Kingdom',
    options: ['United Kingdom']
  },
  nz: {
    label: 'New Zealand',
    options: ['New Zealand']
  },
  tr: {
    label: 'Turkey',
    options: ['Turkey']
  },
  gb: {
    label: 'Great Britain',
    options: ['Great Britain']
  },
  hk: {
    label: 'Hong Kong',
    options: ['Hong Kong']
  },
  ph: {
    label: 'Philippines',
    options: ['Philippines']
  },
  ru: {
    label: 'Russia',
    options: ['Russia']
  },
  vn: {
    label: 'Vietnam',
    options: ['Vietnam']
  },
  jp: {
    label: 'Japan',
    options: ['Japan']
  }
}

export const usersCountriesMap: {
  [code: string]: {
    label: string
    options: string[]
  }
} = {
  ch: {
    label: 'Switzerland',
    options: [
      'Switzerland',
      'Schweiz',
      'Romania, Switzerland',
      'Malaysia, Singapore, Switzerland',
      'Spain, Switzerland',
      'Switzerland, Thailand',
      'Switzerland, United States'
    ]
  },
  ro: {
    label: 'Romania',
    options: ['Romania', 'Italy, Romania', 'Romania, Switzerland', 'Rumänien']
  },
  us: {
    label: 'USA',
    options: ['United States', 'USA', 'Vereinigte Staaten von Amerika']
  },
  cn: {
    label: 'China',
    options: ['China', 'China, Singapore']
  },
  mx: {
    label: 'Mexico',
    options: ['Mexico', 'Mexiko']
  },
  th: {
    label: 'Thailand',
    options: ['Switzerland, Thailand', 'Thailand']
  },
  pl: {
    label: 'Poland',
    options: ['Poland', 'Polen']
  },
  in: {
    label: 'India',
    options: ['India', 'Indien']
  },
  my: {
    label: 'Malaysia',
    options: [
      'Malaysia',
      'Malaysia, Singapore',
      'Malaysia, Singapore, Switzerland'
    ]
  },
  de: {
    label: 'Germany',
    options: ['Germany']
  },
  br: {
    label: 'Brazil',
    options: ['Brazil', 'Brasilien']
  },
  fr: {
    label: 'France',
    options: ['France', 'Frankreich']
  },
  au: {
    label: 'Australia',
    options: ['Australia', 'Australia, New Zealand']
  },
  es: {
    label: 'Spain',
    options: ['Spain', 'Spain, Switzerland', 'Spanien']
  },
  sg: {
    label: 'Singapore',
    options: [
      'China, Singapore',
      'Malaysia, Singapore',
      'Malaysia, Singapore, Switzerland',
      'Singapore',
      'Singapur'
    ]
  },
  ca: {
    label: 'Canada',
    options: ['Canada']
  },
  it: {
    label: 'Italy',
    options: ['Italy', 'Italy, Romania']
  },
  nl: {
    label: 'Netherlands',
    options: ['Netherlands', 'Niederlande', 'The Netherlands']
  },
  kr: {
    label: 'South Korea',
    options: ['Korea', 'Republic of Korea (South Korea)', 'South Korea']
  },
  ma: {
    label: 'Morocco',
    options: ['Morocco']
  },
  za: {
    label: 'South Africa',
    options: ['South Africa']
  },
  uk: {
    label: 'United Kingdom',
    options: ['United Kingdom', 'Vereinigtes Königreich']
  },
  nz: {
    label: 'New Zealand',
    options: ['New Zealand', 'Neuseeland', 'Australia, New Zealand']
  },
  tr: {
    label: 'Turkey',
    options: ['Turkey']
  },
  gb: {
    label: 'Great Britain',
    options: ['Great Britain']
  },
  hk: {
    label: 'Hong Kong',
    options: []
  },
  ph: {
    label: 'Philippines',
    options: []
  },
  ru: {
    label: 'Russia',
    options: ['Russia']
  },
  vn: {
    label: 'Vietnam',
    options: []
  },
  jp: {
    label: 'Japan',
    options: []
  }
}

export enum SearchEntityTypes {
  All = '',
  News = 'news',
  People = 'people',
  Spaces = 'spaces',
  SubSpaces = 'subSpaces',
  Ideas = 'ideas',
  PurchaseRequisitions = 'purchase-requisitions'
}

export type SearchMeta = { count: number }

const getNewsCountryFilter = (countryCode: string) => {
  if (
    newsCountriesMap[countryCode] &&
    newsCountriesMap[countryCode].options.length
  ) {
    return newsCountriesMap[countryCode].options.reduce(
      (filter, searchOption, index) => {
        return (
          filter +
          `&filter[country${
            index + 1
          }][condition][path]=field_location_news&filter[country${
            index + 1
          }][condition][value]=${searchOption}&filter[country${
            index + 1
          }][condition][memberOf]=or-location-group`
        )
      },
      '&filter[or-location-group][group][conjunction]=OR'
    )
  }
  return ''
}

const getUsersCountryFilter = (countryCode: string) => {
  if (
    usersCountriesMap[countryCode] &&
    usersCountriesMap[countryCode].options.length
  ) {
    return usersCountriesMap[countryCode].options.reduce(
      (filter, searchOption, index) => {
        return (
          filter +
          `&filter[country${
            index + 1
          }][condition][path]=field_country&filter[country${
            index + 1
          }][condition][value]=${searchOption}&filter[country${
            index + 1
          }][condition][memberOf]=or-location-group`
        )
      },
      '&filter[or-location-group][group][conjunction]=OR'
    )
  }
  return ''
}

export async function saveRecentSearch(search: string) {
  const { me, setMe } = useConfigStore.getState()
  const searchResultLimit = 5
  const currentResults = me?.recentSearchResults.length
    ? me?.recentSearchResults
    : []
  const comparisionResults = currentResults.map((result) =>
    result.trim().toLowerCase()
  )
  if (
    me &&
    (!currentResults.length ||
      !comparisionResults.includes(search.trim().toLowerCase()))
  ) {
    const currentResults = me?.recentSearchResults.length
      ? me?.recentSearchResults
      : []
    if (currentResults.length + 1 > searchResultLimit) {
      currentResults.shift()
    }
    const response = await fetch(`${API_DOMAIN}/jsonapi/user/user/${me?.id}`, {
      ...init,
      headers: {
        ...init.headers,
        'Content-Type': 'application/vnd.api+json'
      },
      method: 'PATCH',
      body: JSON.stringify({
        data: {
          type: 'user--user',
          id: me?.id,
          attributes: {
            field_user_recent_search: [...currentResults, search.trim()]
          }
        }
      })
    })
    const { data } = await response.json()
    setMe({
      ...me,
      recentSearchResults: data.attributes.field_user_recent_search
    })
  }
}

export function useSearchUsers(
  query: Record<string, string | number>,
  limit: number = SEARCH_PAGE_LIMIT
) {
  const isSearchEmpty = query?.search === undefined || query?.search === ''
  const { search, page = 0, country } = query
  const searchQuery = search ? `&filter[fulltext]=${search}` : ''
  const usersCountryQuery =
    typeof country === 'string' && country ? getUsersCountryFilter(country) : ''
  const roleQuery = query?.role ? `&filter[roles]=${query?.role}` : ''
  const { data: dataPeople, error: errorPeople } = useSWR(
    !isSearchEmpty
      ? [
          `${API_DOMAIN}/api/search?filter[entity_type]=user&include=user_picture&page[offset]=${
            +page * limit
          }&page[limit]=${limit}${searchQuery}&filter[id][condition][path]=uid_1&filter[id][condition][operator]=<>&filter[id][condition][value]=0${usersCountryQuery}${roleQuery}`,
          init
        ]
      : undefined,
    fetcherFullRequest
  )

  return {
    dataPeople,
    errorPeople,
    users: dataPeople?.errors
      ? []
      : dataPeople?.data
        ? dataPeople.data.map((user: any) =>
            transferUser(user, dataPeople?.included)
          )
        : dataPeople,
    isLoading: searchQuery && !dataPeople && !errorPeople
  }
}

export function useSearch<T = NewsArticle[]>(
  query: Record<string, string>
): {
  meta: {
    [SearchEntityTypes.News]: SearchMeta
    [SearchEntityTypes.People]: SearchMeta
    [SearchEntityTypes.Spaces]: SearchMeta
    [SearchEntityTypes.SubSpaces]: SearchMeta
  }
  [SearchEntityTypes.News]: NewsArticle[]
  [SearchEntityTypes.People]: User[]
  [SearchEntityTypes.Spaces]: SpaceArticle[]
  [SearchEntityTypes.SubSpaces]: SubSpaceSimple[]
  isLoading: boolean
  isError: boolean
} {
  const isSearchEmpty = query?.search === undefined || query?.search === ''
  const { contentLang } = useConfigStore.getState()
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`
  const { search, sort, page = 0, country } = query
  const sortQuery = sort ? `&sort=${sort}` : ''
  const searchQuery = search ? `&filter[fulltext]=${search}` : ''
  const newsCountryQuery = country ? getNewsCountryFilter(country) : ''

  const { data: dataNews, error: errorNews } = useSWR(
    !isSearchEmpty
      ? [
          `${API_DOMAIN}${langQuery}/api/search?filter[type]=news&filter[status]=1&include=field_media&page[offset]=${
            +page * SEARCH_PAGE_LIMIT
          }&page[limit]=${SEARCH_PAGE_LIMIT}${sortQuery}${searchQuery}${newsCountryQuery}`,
          init
        ]
      : undefined,
    fetcherFullRequest
  )
  const { dataPeople, errorPeople, users } = useSearchUsers(query)
  const { data: dataSpaces, error: errorSpaces } = useSWR(
    !isSearchEmpty
      ? [
          `${API_DOMAIN}/api/search?include=field_space&filter[type]=space_article&filter[status]=1&page[offset]=${
            +page * SEARCH_PAGE_LIMIT
          }&page[limit]=${SEARCH_PAGE_LIMIT}${sortQuery}${searchQuery}`,
          init
        ]
      : undefined,
    fetcherFullRequest
  )
  const { data: dataSubSpaces, error: errorSubspaces } = useSWR(
    !isSearchEmpty
      ? [
          `${API_DOMAIN}/api/search?filter[type]=space&filter[status]=1&include=field_space_media&page[offset]=${
            +page * SEARCH_PAGE_LIMIT
          }&page[limit]=${SEARCH_PAGE_LIMIT}${sortQuery}${searchQuery}`,
          init
        ]
      : undefined,
    fetcherFullRequest
  )

  return {
    meta: {
      [SearchEntityTypes.News]: dataNews?.meta,
      [SearchEntityTypes.People]: dataPeople?.meta,
      [SearchEntityTypes.Spaces]: dataSpaces?.meta,
      [SearchEntityTypes.SubSpaces]: dataSubSpaces?.meta
    },
    [SearchEntityTypes.News]: dataNews?.errors
      ? []
      : dataNews?.data
        ? transferNewsArticles(dataNews.data, dataNews?.included ?? [])
        : dataNews,
    [SearchEntityTypes.People]: users,
    [SearchEntityTypes.Spaces]: dataSpaces?.errors
      ? []
      : dataSpaces?.data
        ? transferSpaceArticles(dataSpaces.data, dataSpaces?.included ?? [])
        : dataSpaces,
    [SearchEntityTypes.SubSpaces]: dataSubSpaces?.data
      ? dataSubSpaces.data.map((subSpace: SubSpace) =>
          transferSubSpaceSimple(subSpace, dataSubSpaces.included)
        )
      : [],
    isLoading:
      !isSearchEmpty &&
      ((!errorNews && !dataNews) ||
        (!errorPeople && !dataPeople) ||
        (!errorSpaces && !dataSpaces) ||
        (!errorSubspaces && !dataSubSpaces)),
    isError: errorPeople || errorNews || errorSpaces || errorSubspaces
  }
}
