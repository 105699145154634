import React, { Dispatch, SetStateAction } from 'react'
import { useContext, useState, createContext } from 'react'
import { Body5 } from '../typography'
import { styled, keyframes } from '../../../stitches.config'
import * as ToastPrimitive from '@radix-ui/react-toast'
import { ComponentProps } from 'react'
import { Box } from '../utils'
import {
  ErrorFilledIcon24,
  SuccessFilledIcon24,
  AlertFilledIcon24,
  CloseIcon16
} from '../icons'

export const NOTIFICATION_TIME = 3000
const VIEWPORT_PADDING = 25

export enum ToastTypeEnum {
  success = 'success',
  error = 'error',
  warning = 'warning'
}

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 }
})

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateX(0)' }
})

const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` }
})

const ToastViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  top: '$headerHeight',
  right: '50%',
  transform: 'translateX(50%)',
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 430,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: '$toast',
  outline: 'none'
})

const StyledToast = styled(ToastPrimitive.Root, {
  backgroundColor: '$white',
  borderRadius: '$4',
  boxShadow: '$dropdown',
  padding: '0.4375rem 1rem',
  alignItems: 'center',
  d: 'flex',
  justifyContent: 'space-around',

  button: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    transition: '$default',
    width: '34px',
    height: '34px',
    borderRadius: '$3',
    '&:hover': {
      background: 'rgba(0, 25, 42, 0.08)'
    },
    span: {
      d: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },

  variants: {
    type: {
      [ToastTypeEnum.error]: {
        backgroundColor: '$errorLighten95',
        border: '1px solid $errorLighten80',
        color: '#980731',
        svg: {
          fill: '#FF255E',
          path: {
            fill: '#FF255E'
          }
        }
      },
      [ToastTypeEnum.success]: {
        backgroundColor: '#E1FFF6',
        border: '1px solid $successLighten30',
        color: '$successDarken30',
        svg: {
          fill: '$successLighten20',
          path: {
            fill: '$successLighten20'
          }
        }
      },
      [ToastTypeEnum.warning]: {
        backgroundColor: '#FFF7E7',
        border: '1px solid $warningLighten40',
        color: '$warningDarken40',
        svg: {
          fill: '#FFBB38',
          path: {
            fill: '#FFBB38'
          }
        }
      }
    }
  },

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`
    },
    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))'
    },
    '&[data-swipe="cancel"]': {
      transform: 'translateX(0)',
      transition: 'transform 200ms ease-out'
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out`
    }
  }
})

const ToastTitle = styled(ToastPrimitive.Title, {
  gridArea: 'title',
  marginBottom: '$1',
  fontWeight: '$bold'
})

const ToastDescription = styled(ToastPrimitive.Description, {
  gridArea: 'description',
  margin: 0
})

const ToastAction = styled(ToastPrimitive.Action, {
  gridArea: 'action'
})

const ToastClose = styled(ToastPrimitive.Close, {
  gridArea: 'action'
})

export const Toast = ({
  children,
  type,
  ...props
}: ComponentProps<typeof StyledToast>) => {
  return (
    <ToastPrimitive.Provider swipeDirection="right">
      <StyledToast type={type} duration={NOTIFICATION_TIME} {...props}>
        <ToastDescription asChild>
          <>
            {type && (
              <>
                {type === ToastTypeEnum.success ? (
                  <SuccessFilledIcon24 />
                ) : type === ToastTypeEnum.error ? (
                  <ErrorFilledIcon24 />
                ) : type === ToastTypeEnum.warning ? (
                  <AlertFilledIcon24 />
                ) : (
                  <></>
                )}
              </>
            )}
            <Box css={{ padding: '0 1rem' }}>{children}</Box>
          </>
        </ToastDescription>
        <ToastAction asChild altText="Goto schedule to undo"></ToastAction>
        <ToastClose aria-label="Close">
          <span aria-hidden>
            <CloseIcon16 />
          </span>
        </ToastClose>
      </StyledToast>
      <ToastViewport />
    </ToastPrimitive.Provider>
  )
}

export type SetToastProps = {
  message: string
} & ComponentProps<typeof Toast>

const initialToast: SetToastProps = {
  message: '',
  type: ToastTypeEnum.success
}
const ToastContext =
  createContext<Dispatch<SetStateAction<SetToastProps>>>(undefined)
export default ToastContext

export function ToastContextProvider({ children }) {
  const [toast, setToast] = useState<SetToastProps>(initialToast)

  return (
    <ToastContext.Provider value={setToast}>
      {children}
      {toast?.message && (
        <Toast
          onOpenChange={(isOpen: boolean) => {
            if (!isOpen) {
              setToast(initialToast)
            }
          }}
          {...toast}
        >
          <Body5
            css={{
              color: '$neutral',
              whiteSpace: 'pre-wrap'
            }}
          >
            {toast.message}
          </Body5>
        </Toast>
      )}
    </ToastContext.Provider>
  )
}

export function useToastContext() {
  return useContext(ToastContext)
}
