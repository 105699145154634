import { ComponentProps, ReactNode, useEffect, useRef, useState } from 'react'
import { StyledComponent } from '@stitches/react/types/styled-component'
import { styled } from '../../../stitches.config'
import {
  BooleanString,
  StyledFieldsetContainer,
  StyledInputLabel,
  StyledInputMessage
} from '../input/input-utils'
import * as React from 'react'

export type TextAreaProps = Omit<
  ComponentProps<typeof StyledTextArea>,
  'error'
> & {
  label?: ReactNode | string
  error?: ReactNode | string
  info?: ReactNode | string
  containerProps?: ComponentProps<typeof StyledFieldsetContainer>
  rows?: string | null
  labelProps?: ComponentProps<typeof StyledInputLabel>
}

const StyledTextArea: StyledComponent<
  'textarea',
  { error?: BooleanString; borderless?: BooleanString },
  any,
  any
> = styled('textarea', {
  outline: 'none',
  border: '1px $neutralLighten80 solid',
  backgroundColor: '$white',
  fontSize: '$body5Xl',
  lineHeight: '$15',
  paddingRight: '$4',
  paddingLeft: '$4',
  width: '100%',
  height: '100%',
  py: '$3',
  color: '$neutral',
  transition: '$default',
  borderRadius: '$4',
  boxShadow: '$default',
  overflow: 'hidden',
  '&::placeholder': {
    color: '$neutralLighten50'
  },
  '&:hover, &:focus-within, &.__hover, &.__focus': {
    borderColor: '$neutralLighten60'
  },
  '&:focus-within, &.__focus': {
    outline: '2px $primaryBlue solid',
    borderColor: '$primaryBlue'
  },
  variants: {
    disabled: {
      true: {
        borderColor: '$neutralLighten80',
        backgroundColor: '$neutralLighten97',
        color: '$neutralLighten60',
        cursor: 'not-allowed',
        '&::placeholder': {
          color: 'neutralLighten60'
        },
        '&:hover, &:focus-within, &.__hover, &.__focus': {
          borderColor: '$neutralLighten80'
        },
        '&:focus-within, &.__focus': {
          outline: 'none'
        }
      },
      false: {}
    },
    error: {
      true: {
        borderColor: '$primaryRed',
        '&:hover, &:focus-within, &.__hover, &.__focus': {
          borderColor: '$primaryRed'
        },
        '&:focus-within, &.__focus': {
          outline: '2px $primaryRed solid'
        }
      },
      false: {}
    },
    borderless: {
      true: {
        p: 0,
        borderColor: 'transparent',
        '&:hover, &:focus-within, &.__hover, &.__focus': {
          borderColor: 'transparent'
        },
        '&:focus-within, &.__focus': {
          outline: 'none'
        }
      },
      false: {}
    }
  },
  compoundVariants: [
    {
      disabled: true,
      error: true,
      css: {
        borderColor: '$neutralLighten80',
        cursor: 'not-allowed',
        '&::placeholder': {
          color: '$neutralLighten60'
        },
        '&:hover, &:focus-within, &.__hover, &.__focus': {
          borderColor: '$neutralLighten80'
        },
        '&:focus-within, &.__focus': {
          outline: 'none'
        }
      }
    }
  ]
})

export function TextArea({
  label,
  error,
  info,
  id,
  containerProps = {},
  labelProps = {},
  rows,
  ...props
}: TextAreaProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [firstResize, setFirstResize] = useState<boolean>(false)
  useEffect(() => {
    if (label && !id) {
      console.warn(
        'Input id is undefined, you should put id if you are using label'
      )
    }
  }, [label, id])

  useEffect(() => {
    if (textAreaRef?.current && !firstResize) {
      resizeTextArea(textAreaRef?.current)
      setFirstResize(true)
    }
  }, [textAreaRef?.current])

  const resizeTextArea = (element: HTMLTextAreaElement) => {
    element.style.height = 'auto'
    const height = element.scrollHeight + 2
    element.style.height = height + 'px'
  }

  return (
    <StyledFieldsetContainer data-fi="textarea" {...containerProps}>
      {label && (
        <StyledInputLabel
          htmlFor={id}
          disabled={props.disabled}
          {...labelProps}
        >
          {label}
        </StyledInputLabel>
      )}
      <StyledTextArea
        {...props}
        error={!!error}
        disabled={props.disabled}
        ref={textAreaRef}
        id={id}
        rows={rows || 1}
        onChange={(event) => {
          resizeTextArea(event.currentTarget)
          props?.onChange && props.onChange(event)
        }}
        onLoad={(event) => resizeTextArea(event.currentTarget)}
      />
      {(info || error) && (
        <StyledInputMessage>{error || info}</StyledInputMessage>
      )}
    </StyledFieldsetContainer>
  )
}
