import { User } from '../../api'
import {
  Body7,
  Box,
  CheckmarkIcon16,
  LazyImage,
  Space
} from '@sefar/design-system'
import AvatarPlaceholder from '../../../assets/avatar-placeholder-blue.jpg'
import { UserDepartmentInfo } from '../../pages/profile/profile'

export function AssigneeUserCard({
  user,
  selected,
  onClick,
  reviewer
}: {
  user: User
  onClick: () => void
  reviewer?: boolean
  selected?: boolean
}) {
  return (
    <Box
      as="button"
      css={{
        all: 'unset',
        p: '$3',
        textDecoration: 'none',
        d: 'flex',
        alignItems: 'center',
        gap: '$2',
        color: '$neutral',
        overflow: 'hidden',
        border: selected
          ? '2px $neutralLighten10 solid'
          : '2px $neutralLighten90 solid',
        borderRadius: '$3',
        boxShadow: '$card',
        boxSizing: 'border-box',
        cursor: 'pointer',
        '& .checkbox': {
          ml: 'auto',
          size: 24,
          backgroundColor: selected ? '$neutralLighten93' : '$white',
          d: selected ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center'
        },
        '&:hover': {
          backgroundColor: '$primaryBlueLighten95',
          borderColor: '$primaryBlue',
          '& .checkbox': {
            d: 'flex'
          }
        },
        '&:focus:not(:disabled), &.__focus:not(:disabled)': {
          border: '2px solid $primaryBlue',
          outline: 'none'
        }
      }}
      onClick={onClick}
    >
      <Space
        css={{
          size: 32,
          borderRadius: '$round',
          overflow: 'hidden',
          flexBasis: 32,
          flexShrink: 0
        }}
      >
        <LazyImage src={user?.image || AvatarPlaceholder} aspectRatio="1 / 1" />
      </Space>

      <Box css={{ d: 'flex', flexDirection: 'column' }}>
        <Box css={{ d: 'flex', gap: '$2' }}>
          <Body7 fontWeight="bold">
            {user.firstName}&nbsp;{user.lastName}
          </Body7>
          {reviewer && <Body7 css={{ color: '$success' }}>Reviewer</Body7>}
        </Box>

        <Body7 css={{ color: '$neutralLighten30' }}>
          <UserDepartmentInfo user={{ ...user, department: user.jobTitle }} />
        </Body7>
      </Box>

      <Box className="checkbox">
        <CheckmarkIcon16 />
      </Box>
    </Box>
  )
}
