import {
  Body5,
  Body6,
  Box,
  Button,
  DateTimePicker,
  Dialog,
  Space
} from '@sefar/design-system'
import React, { useState } from 'react'
import { useTranslate } from '../../hooks/useTranslate'

export function ImplementationInProgressConfirmationDialog({
  open,
  setOpen,
  onConfirm
}: {
  open: boolean
  setOpen: (open: boolean) => void
  onConfirm: (dateOfRealization?: Date) => void
}) {
  const [dateOfRealization, setDateOfRealization] = useState<Date>()
  const { t } = useTranslate()

  const onClickSetInProgress = () => onConfirm(dateOfRealization)

  return (
    <Dialog
      setOpenControlledDialog={setOpen}
      open={open}
      title={t('field_idea_set_in_progress')}
      contentProps={{ css: { '@lg': { width: 550 } } }}
      footer={
        <Box css={{ d: 'flex', justifyContent: 'flex-end', gap: '$3' }}>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t('field_cancel')}
          </Button>
          <Button onClick={onClickSetInProgress} variant="primary">
            {t('field_idea_set_in_progress')}
          </Button>
        </Box>
      }
    >
      <Space mb="MD">
        <Body5>{t('field_idea_realiz_date_desc')}</Body5>
      </Space>

      <Space mb="2XS">
        <Body6 css={{ color: '$neutralLighten30' }}>
          {t('field_idea_realization_date_name')}
        </Body6>
      </Space>
      <DateTimePicker
        id="dateOfRealization"
        initialValue={dateOfRealization}
        onChange={setDateOfRealization}
        min={new Date()}
        dateOnlyMode
      />
    </Dialog>
  )
}
