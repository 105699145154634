import { PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import dateFns from 'date-and-time'
import { max } from 'date-fns'
import { isValidDate, SelectOption } from '@sefar/design-system'
import mixpanel from 'mixpanel-browser'
import { useConfigStore } from '../state/config'
import { Language } from '../api'
import { sessionStorageKeys } from '../constants'

function isDaysAgo(date: Date, numberOfDays: number): boolean {
  const ago = new Date()
  ago.setDate(ago.getDate() - numberOfDays)
  return ago.toDateString() === date.toDateString()
}

export function formatArticleDate(dateString: string, isShortVersion = false) {
  const { translations } = useConfigStore.getState()
  const date = new Date(dateString)
  const now = new Date()
  const month = date.toLocaleString('default', { month: 'long' })

  return dateFns.isSameDay(date, now)
    ? `${translations?.field_date_today}, ${dateFns.format(date, 'HH:mm')}`
    : isDaysAgo(date, 1)
      ? `${translations?.field_date_yesterday}, ${dateFns.format(date, 'HH:mm')}`
      : isDaysAgo(date, 2)
        ? `${translations?.field_date_2days_ago}, ${dateFns.format(date, 'HH:mm')}`
        : isDaysAgo(date, 3)
          ? `${translations?.field_date_3days_ago}, ${dateFns.format(date, 'HH:mm')}`
          : `${month} ${dateFns.format(date, isShortVersion ? 'DD, HH:mm' : 'DD YYYY, HH:mm')}`
}

export function ScrollToTop(props: PropsWithChildren) {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      if (location.hash) {
        document
          .getElementById(location.hash.replace('#', ''))!
          .scrollIntoView()
      }
    }, 1000)
  }, [location])

  return <>{props.children}</>
}

export const getTimeZone = () => {
  const date = new Date()
  const offset = date.getTimezoneOffset()
  return offset
}

export const getCurrentTime = () => {
  const now = new Date()
  return now.getHours() + ':' + now.getMinutes()
}

export const DATE_TIME_PUBLICATION_OFFICIAL_FORMAT = 'DD.MM.YYYY, HH:mm'

export const formatDateTimeOfficial = (dateString: string) =>
  dateFns.format(
    new Date(dateString?.replace(' ', 'T')),
    DATE_TIME_PUBLICATION_OFFICIAL_FORMAT
  )

export const formatDateOfficial = (dateString: string) =>
  dateFns.format(new Date(dateString?.replace(' ', 'T')), 'DD.MM.YYYY')

export const getTime = (dateString: string) =>
  dateFns.format(new Date(dateString?.replace(' ', 'T')), 'HH:mm')

export function convertDateToSend(date: Date): string {
  return (isValidDate(date) && `${date.toJSON().split('.')[0]}+00:00`) || ''
}

export function getDateWithoutTime(date: Date): string {
  return (isValidDate(date) && `${date.toJSON().split('T')[0]}`) || ''
}

export const getMaxDateString = (datesString: (string | undefined)[]) => {
  const filteredDatesString = datesString.filter(
    (dateString) => !!dateString
  ) as string[]
  const dates = filteredDatesString.map(
    (dateString) => new Date(dateString.replace(' ', 'T'))
  )

  if (!dates.length) {
    return undefined
  }

  const maxDate = max(dates)

  return formatDateOfficial(maxDate.toISOString())
}

export const checkFileType = (file: Blob) => {
  return file ? file?.type.split('/')[0] : ''
}

export const parseFromString = {
  array: (arr: string | any[], defaultValue = []): any[] =>
    Array.isArray(arr) ? arr : (arr && arr.split(',')) || defaultValue,
  number: (num: string | number): number | undefined =>
    num !== null && Number.isFinite(+num) ? +num : undefined,
  boolean: (bool: string | boolean): boolean | undefined =>
    bool === 'true' || bool === true
      ? true
      : bool === 'false' || bool === false
        ? false
        : undefined
}

export const isObject = (object: any) => {
  return object != null && typeof object === 'object'
}

export const isDeepEqual = (object1: any, object2: any) => {
  const objKeys1 = Object.keys(object1)
  const objKeys2 = Object.keys(object2)

  if (objKeys1.length !== objKeys2.length) return false

  for (const key of objKeys1) {
    const value1 = object1[key]
    const value2 = object2[key]

    const isObjects = isObject(value1) && isObject(value2)

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false
    }
  }
  return true
}

export function updateMediaSrc(html: string): string {
  const temp = document.createElement('div')
  temp.innerHTML = html
  temp
    .querySelectorAll<
      HTMLVideoElement | HTMLImageElement
    >('video[data-src], img[data-src]')
    ?.forEach((item) => {
      item.src = item.dataset.src || ''
      item.removeAttribute('data-src')
      item.dataset.src && URL.revokeObjectURL(item.dataset.src)
    })
  return temp.innerHTML
}
// Regular expression to identify HTML tags in
// the input string. Replacing the identified
// HTML tag with a null string.
export const removeTagsFromString = (str: string) =>
  str ? str.replace(/(<([^>]+)>)/gi, '') : str

export function mixpanelAnalyticsHelper() {
  const mixpanelToken =
    import.meta.env.VITE_MIXPANEL_TOKEN ?? '2067aa4df37d5c48ce4102a79b261806'
  mixpanel.init(mixpanelToken)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return {
    loggedInFrom: (props: object = {}) => {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      mixpanel.track(
        `Logged in from ${sessionStorage.getItem(
          sessionStorageKeys.LOGGED_IN_BY
        )}`,
        {
          Timezone: timeZone,
          ...props
        }
      )
    },
    identify: (id?: string): void => {
      mixpanel.identify(id)
    },
    track: (name: string, props: object = {}) => {
      mixpanel.track(name, {
        ...props,
        Timezone: timeZone
      })
    },
    time: (name: string) => {
      mixpanel.time_event(name)
    },
    alias: (id: string) => {
      mixpanel.alias(id)
    },
    register: (props: object) => {
      mixpanel.register(props)
    },
    set: (props: object) => {
      mixpanel.people.set(props)
    }
  }
}

export function analyticsPageTracker(nextPage = '') {
  if (nextPage) {
    mixpanelAnalyticsHelper().time(`Leaving from ${nextPage} page`)
    mixpanelAnalyticsHelper().track(`Visit ${nextPage} page`)
    return
  }
  switch (
    document.location.pathname === '/'
      ? document.referrer.split('/').pop()?.split('?').shift()
      : document.location.pathname.slice(1)
  ) {
    case 'search':
      mixpanelAnalyticsHelper().track('Leaving from search page')
      break
    case 'news':
      mixpanelAnalyticsHelper().track('Leaving from news page')
      break
    case 'profile':
      mixpanelAnalyticsHelper().track('Leaving from profile page')
      break
    case 'my-content':
      mixpanelAnalyticsHelper().track('Leaving from my content page')
      break
    case 'profile-settings':
      mixpanelAnalyticsHelper().track('Leaving from settings page')
      break
    case 'spaces':
      mixpanelAnalyticsHelper().track('Leaving from spaces page')
      break
    default:
      return null
  }
}

// https://stackoverflow.com/a/1500501
export function urlToHtmlLink(text: string): string {
  // https://stackoverflow.com/a/17773849
  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
  return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>')
}

export function getDayDaysAgo(count: number) {
  const date = new Date()
  date.setDate(date.getDate() - count)

  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export function isLast7Days(date: string): boolean {
  return (
    new Date(date?.split('T')?.[0]).getTime() -
      new Date(getDayDaysAgo(7)).getTime() >
    0
  )
}

export const prepareLanguagesForSelect = (
  languages: Language[],
  valueIsLabel = false
): SelectOption[] =>
  languages?.length
    ? languages.map((lang: Language) => ({
        value: lang.id,
        label: valueIsLabel ? lang.id.toUpperCase() : lang.label
      }))
    : []

const getCanonicalHost = (url: string) => {
  const chunks = new URL(url).hostname.split('.')
  if (chunks.length < 2) return ''
  return chunks.slice(-2).join('.')
}

export const getFaviconUrl = (siteUrl: string, size = 64) => {
  const canonicalHost = getCanonicalHost(siteUrl)
  if (!canonicalHost) return undefined
  return `https://www.google.com/s2/favicons?domain=${getCanonicalHost(
    siteUrl
  )}&sz=${size}`
}

export const getUserFullName = (firstName = '', lastName = '') =>
  `${firstName}${firstName && lastName ? ' ' : ''}${lastName}`
