import { useEffect, useState } from 'react'

export function useGet(
  parameters: undefined | string | [string, RequestInit],
  fetcher: (url: string, init?: RequestInit) => Promise<Response>,
  deps?: any[]
) {
  const [data, setData] = useState<any>()
  const [error, setError] = useState<any>()
  const [refresh, setRefresh] = useState<boolean>()

  useEffect(
    () => {
      const getData = async () => {
        const res = !parameters
          ? undefined
          : Array.isArray(parameters)
            ? await fetcher(...parameters)
            : await fetcher(parameters)
        setData(res)
      }

      try {
        getData()
      } catch (e) {
        setError(e)
      }
    },
    deps || [refresh]
  )

  const mutate = () => {
    setRefresh((oldValue) => !oldValue)
  }

  return { data, error, mutate }
}
