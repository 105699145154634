import { useNavigate } from 'react-router-dom'
import { Body5, Box, Heading1, Button } from '@sefar/design-system'
import { useTranslate } from '../../../../hooks/useTranslate'
import PurchaseReqCompletedImage from '../../../../../assets/people-box-visual.png'

export const CompletedStep = () => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  return (
    <Box
      css={{
        width: '100%',
        maxWidth: 680,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: '$10'
      }}
    >
      <img
        alt={t('field_purchase_requisition_create_completed_title')}
        style={{ maxWidth: 326 }}
        src={PurchaseReqCompletedImage}
      />
      <Heading1 css={{ color: '$neutral', mb: '$2' }}>
        {t('field_purchase_requisition_create_completed_title')}
      </Heading1>
      <Body5 css={{ mb: '$8' }}>
        {t('field_purchase_requisition_create_completed_description')}
      </Body5>
      <Button onClick={() => navigate('/purchase-requisitions')}>
        {t('field_purchase_requisition_create_completed_cta')}
      </Button>
    </Box>
  )
}
