import {
  Body7,
  Box,
  DateTimePicker,
  Input,
  ReactSelect,
  SelectOption,
  Spinner
} from '@sefar/design-system'
import { AmountSelector } from './amount-selector'
import dateFns from 'date-and-time'
import { HTMLInputTypeAttribute } from 'react'

type OrderItemEditableFieldProps = {
  itemId: number
  fieldKey: string
  label: string
  value?: string | number
  subValue?: string | number
  isEdit?: boolean
  onChange?: (
    itemId: number,
    fieldKey: string,
    fieldValue?: string | number
  ) => void
  type?: 'input' | 'date' | 'number' | 'select'
  options?: { label: string; value: string }[]
  loading?: boolean
  maxLength?: number
  inputType?: HTMLInputTypeAttribute
}

export const OrderItemEditableField = ({
  itemId,
  fieldKey,
  label,
  value = '',
  subValue = '',
  isEdit = false,
  onChange,
  options,
  type = 'input',
  loading = false,
  maxLength,
  inputType = ''
}: OrderItemEditableFieldProps) => {
  if (loading) {
    return <Spinner size="small" />
  }
  if (isEdit) {
    if (type === 'date') {
      return (
        <DateTimePicker
          id={itemId + fieldKey}
          initialValue={value ? new Date(value) : undefined}
          onChange={(newDate?: Date) =>
            onChange?.(itemId, fieldKey, newDate?.toString())
          }
          min={new Date()}
          dateOnlyMode
          autoFocus={false}
          inputStyle={{ minWidth: 160 }}
        />
      )
    }

    if (type === 'number') {
      return (
        <AmountSelector
          value={value}
          onChange={(value) => onChange?.(itemId, fieldKey, value)}
        />
      )
    }

    if (type === 'select' && options?.length) {
      const optionValue = options.find((opt) => opt.value === value)
      return (
        <ReactSelect
          css={{ width: '100%' }}
          options={options}
          value={optionValue}
          onChange={(option: SelectOption) => {
            onChange?.(itemId, fieldKey, option.value)
          }}
          placeholder={label}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
          }}
        />
      )
    }

    return (
      <Input
        placeholder={label}
        value={value}
        onChange={(e) => onChange?.(itemId, fieldKey, e.target.value)}
        maxLength={maxLength}
        type={inputType}
      />
    )
  }

  const cellHeight = 'auto'

  if (type === 'date') {
    return (
      <>
        <Box as="p" css={{ h: cellHeight, lineHeight: cellHeight }}>
          {value ? dateFns.format(new Date(value), 'DD/MM/YYYY') : '–'}
        </Box>
        {!!subValue && (
          <Body7 as="p" css={{ lineHeight: 0 }}>
            {subValue}
          </Body7>
        )}
      </>
    )
  }

  return (
    <>
      <Box as="p" css={{ h: cellHeight, lineHeight: cellHeight }}>
        {value || '–'}
      </Box>
      {!!subValue && (
        <Body7 as="p" css={{ lineHeight: 0 }}>
          {subValue}
        </Body7>
      )}
    </>
  )
}
