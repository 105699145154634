import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import { API_DOMAIN, fetcherFullRequest, init } from './common'
import { formatFileName } from '../utils/formatFileName'
import { SelectOption } from '@sefar/design-system'

export const FILE_DRUPAL_TYPE = 'file--file'
export const MEDIA_DAM_IMAGE_TYPE = 'media--acquia_dam_image_asset'
export const MEDIA_DAM_VIDEO_TYPE = 'media--acquia_dam_video_asset'

export enum FileTypeEnum {
  Images = 'Images',
  Video = 'Video',
  Documents = 'Documents'
}
export enum FileTypeHTMLFormat {
  Images = 'image',
  Video = 'video',
  Documents = 'file',
  image = 'Images',
  video = 'Video',
  file = 'Documents'
}

export enum NewsMediaAssetsTypes {
  mainVisual = 'Main Visual',
  contentAsset = 'Content Assets'
}
export enum SpacesMediaAssetsTypes {
  spaceVisual = 'Space visual',
  mainVisual = 'Article Main Visual',
  contentAsset = 'Article Content'
}
export const MediaAssetsTypes = {
  news: {
    key: 'News',
    enum: NewsMediaAssetsTypes
  },
  spaces: {
    key: 'Spaces',
    enum: SpacesMediaAssetsTypes
  }
}

// https://community.widen.com/s/article/What-are-the-supported-file-types
export const FILE_TYPE: Record<FileTypeEnum, string[]> = {
  [FileTypeEnum.Images]: [
    '.bmp',
    '.dng',
    '.eps',
    '.gif',
    '.heic',
    '.heif',
    '.jpg',
    '.jpeg',
    '.png',
    '.svg',
    '.tif',
    '.tiff',
    '.webp'
  ],
  [FileTypeEnum.Video]: ['.mp4', '.3gp', '.mov', '.webm'],
  [FileTypeEnum.Documents]: [
    '.xls',
    '.xlsx',
    '.ai',
    '.indd',
    '.key',
    '.pdf',
    '.ppt',
    '.pptm',
    '.pptx',
    '.qxd',
    '.qxp',
    '.doc',
    '.docx'
  ]
}

export function getFileType(
  file: Blob | undefined
): FileTypeEnum | FileTypeHTMLFormat | string | undefined {
  const type = file?.type?.split('/')?.[0]
  const extension = (file as File)?.name
    ?.split('.')
    ?.reverse()?.[0]
    ?.toLowerCase()
  // we need to check both file type and extension
  if (
    type === FileTypeHTMLFormat[FileTypeEnum.Video] ||
    type === FileTypeHTMLFormat[FileTypeEnum.Images]
  ) {
    return FILE_TYPE[FileTypeHTMLFormat[type]].includes(`.${extension}`)
      ? FileTypeHTMLFormat[type]
      : undefined
  } else if (FILE_TYPE.Documents.includes(`.${extension}`)) {
    return FileTypeEnum.Documents
  } else {
    // if there is no type in file
    return Object.entries(FILE_TYPE).find(([key, value]) =>
      value.includes(`.${extension}`)
    )?.[0]
  }
}
export enum UploadProfiles {
  newsUploads = 'News Uploads',
  spacesUploads = 'Spaces Uploads'
}
export const MEDIA_TYPES = {
  image: [MEDIA_DAM_IMAGE_TYPE, 'image'],
  video: [MEDIA_DAM_VIDEO_TYPE, 'video']
}

type bgImage = {
  thumbnails: {
    '2048px': string
    '600px': string
  }
  metadata: {
    localtime: string
    location: string
    photographer: string
  }
}

export function transferHomePageBgImage(data: any): bgImage {
  return {
    thumbnails: {
      '2048px': data?.thumbnails?.['2048px']?.url ?? '',
      '600px': data?.thumbnails?.['600px']?.url ?? ''
    },
    metadata: {
      localtime: data?.metadata?.fields?.localtime?.[0] ?? '',
      location: data?.metadata?.fields?.location?.[0] ?? '',
      photographer: data?.metadata?.fields?.photographer?.[0] ?? ''
    }
  }
}

export type ImageToAttachToArticle = {
  uuid: string
  vid: string
}

export type FeaturesImage = {
  image: string
  mobile: string
  url?: string
}

export function transferPreloadedToDrupalImage(
  data: any
): ImageToAttachToArticle {
  return {
    uuid: data?.uuid?.[0]?.value ?? '',
    vid: data?.vid?.[0]?.value ?? ''
  }
}

export function useHomePageBgImage(time?: string) {
  const { data, error } = useSWRImmutable(
    time
      ? [`${API_DOMAIN}/api_background/image?time=${time}`, init]
      : undefined,
    fetcherFullRequest
  )
  return {
    bgImage: data && transferHomePageBgImage(data),
    isLoading: !error && !data,
    isError: error
  }
}

export function useHomeFeaturesImages() {
  const { data, error } = useSWRImmutable(
    [
      `${API_DOMAIN}/jsonapi/node/new_features?include=field_feature_image,field_mobile_view`,
      init
    ],
    fetcherFullRequest
  )
  return {
    images:
      data &&
      data?.data?.map((item: any) => {
        const field_feature_image = data?.included?.find(
          (includedImage: any) =>
            includedImage?.id ===
            item?.relationships?.field_feature_image?.data?.id
        )?.attributes?.uri

        const field_mobile_view = data?.included?.find(
          (includedImage: any) =>
            includedImage?.id ===
            item?.relationships?.field_mobile_view?.data?.id
        )?.attributes?.uri

        return {
          image: field_feature_image ? field_feature_image.url : '',
          mobile: field_mobile_view ? field_mobile_view.url : '',
          url: item?.attributes?.field_url?.uri
            ?.replace('https://my.sefar.com', '')
            ?.replace('internal:', '')
        }
      }),
    isLoading: !error && !data,
    isError: error
  }
}

export function preloadImageToDrupal(id: string) {
  return fetch(
    `${API_DOMAIN}/acquia_dam_image_provider/asset/save?id=${id}`,
    init
  )
}

export function useCategories() {
  const { data, error } = useSWRImmutable(
    [`${API_DOMAIN}/acquia_dam/categories/get`, init],
    fetcherFullRequest
  )
  return {
    categories: data?.categories?.find(
      ({ title }: { title: string }) => title === 'mySefar'
    )?.children,
    isLoading: !error && !data,
    isError: error
  }
}

export const convertImageDataToRelationship = (uuid: string, vid: string) => {
  return {
    field_media: {
      data: {
        type: MEDIA_DAM_IMAGE_TYPE,
        id: uuid,
        meta: {
          drupal_internal__target_id: vid
        }
      }
    }
  }
}

export function uploadFileToDAM(
  file: File | null | undefined,
  fileMeta: Record<string, string>,
  profile: string,
  categoryIds: string
) {
  if (file) {
    const fileMetaPrepared: Record<string, string[]> = Object.entries(
      fileMeta
    ).reduce((res: Record<string, string[]>, [key, value]) => {
      if (value && key !== 'copyright') {
        res[key] = [value]
      }
      return res
    }, {})

    const filetype = getFileType(file)
    const formData = new FormData()

    const formattedFileName = formatFileName(file.name)

    formData.append('file', file, formattedFileName)
    formData.append('profile', profile)
    formData.append('category', categoryIds)
    formData.append(
      'metadata',
      JSON.stringify({
        fields: { ...fileMetaPrepared, filetype: [filetype] }
      })
    )

    return fetch(`${API_DOMAIN}/api/acquia/upload`, {
      ...init,
      method: 'POST',
      body: formData
    }).then((res) => res?.json())
  }
}

export type SelectedValuesType = Array<SelectOption>

export type DamSearchFiltersType = {
  fileTypes: SelectedValuesType
  countries: SelectedValuesType
  categories: SelectedValuesType
}

export function useMediaAssets(
  filters: DamSearchFiltersType,
  searchString = '%20',
  pageNum: number,
  limit: number,
  type?: string | Array<string>
) {
  const offset = pageNum * limit

  const typeString =
    type && Array.isArray(type)
      ? type.reduce(
          (res, item, i) =>
            `${res}${item}${i < type.length - 1 ? ' AND ' : ''}`,
          ''
        )
      : type
  const typePreparedString = typeString
    ? `&type=${encodeURIComponent(typeString)}`
    : ''

  let fileTypeFilterString = ''
  filters.fileTypes.forEach(
    (item: SelectOption) =>
      (fileTypeFilterString += fileTypeFilterString
        ? `,${item.label}`
        : item.label)
  )
  const fileTypesEncoded =
    fileTypeFilterString ?? encodeURIComponent(fileTypeFilterString)

  let countryFilterString = ''
  filters.countries.forEach((item: SelectOption) => {
    countryFilterString += countryFilterString ? `,${item.label}` : item.label
  })
  const countriesEncoded =
    countryFilterString ?? encodeURIComponent(countryFilterString)

  let categoryFilterString = ''
  filters.categories.forEach(
    (item: SelectOption) =>
      (categoryFilterString += categoryFilterString
        ? `,${item.label}`
        : item.label)
  )
  const categoriesEncoded = encodeURIComponent(categoryFilterString)
  const url = `${API_DOMAIN}/api_connector/get?query=${searchString}&cat=${categoriesEncoded}&filetype=${fileTypesEncoded}&country=${countriesEncoded}&limit=${limit}&offset=${offset}${typePreparedString}`

  const { data, error } = useSWR([url, init], fetcherFullRequest)

  return {
    data: {
      facets: data?.facets,
      items: data?.items,
      totalCount: data?.total_count,
      offset: data?.offset
    },
    isLoading: !data,
    isError: error
  }
}

export function useFilePreview(id?: string) {
  const url = `${API_DOMAIN}/acquia_dam/asset/get?ids=${id}`
  const { data: response, error } = useSWR(
    [id ? url : null, init],
    fetcherFullRequest
  )
  return {
    data: id ? response?.data?.[id] : null,
    isLoading: !response,
    isError: error
  }
}
