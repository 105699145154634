import { useEffect, useState } from 'react'

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export function useMobile(): boolean {
  const [mobile, setMobile] = useState<boolean>(false)

  useEffect(() => setMobile(detectMob()), [])

  return mobile
}
