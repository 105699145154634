import { Body6, Container, ContainerPadding } from '@sefar/design-system'
import React from 'react'

export function ScopedNotification({ children }: React.PropsWithChildren<any>) {
  return (
    <ContainerPadding css={{ backgroundColor: '$primaryBlueLighten97' }}>
      <Container>
        <Body6
          css={{
            py: 12,
            d: 'flex',
            justifyContent: 'center'
          }}
        >
          {children}
        </Body6>
      </Container>
    </ContainerPadding>
  )
}
