import React, { useState } from 'react'
import { Box, Body5, Button } from '@sefar/design-system'
import { AmountSelector } from './amount-selector'
import { CURRENCY } from '../consts'
import { useOrderState } from '../hooks/use-order-state'
import { SearchItemResult } from '../types'
import { useTranslate } from '../../../../hooks/useTranslate'
import { useSupplierByProductId } from '../../api'

export const SearchItem = ({ details }: { details: SearchItemResult }) => {
  const { t } = useTranslate()
  const [amount, setAmount] = useState(1)
  const {
    order: { purchaseRequisitionItems },
    setItems
  } = useOrderState()
  const { data: productSuppliers } = useSupplierByProductId(details.code)
  const onAddItem = () => {
    setItems([
      ...purchaseRequisitionItems,
      {
        id: details.id || new Date().getTime(),
        code: details.code,
        productId: details.id,
        itemDescription: details.description || '',
        currency: details.currency || CURRENCY,
        price: details.price ? parseFloat(details.price).toFixed(2) : undefined,
        supplierItemId: details.buyFromBP ?? '',
        supplierItemNo: '',
        supplier: details.buyFromBPDesc ?? '',
        unit: details.uom || 'ea',
        quantity: amount
      }
    ])
  }

  return (
    <Box
      css={{
        d: 'flex',
        alignItems: 'center',
        gap: '$4',
        py: '$3',
        borderBottom: '1px $neutralLighten90 solid',
        whiteSpace: 'nowrap'
      }}
    >
      <Box css={{ w: '17.75rem' }}>
        <Body5
          fontWeight="bold"
          css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {details.description || '–'}
        </Body5>
        <Body5 css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {details.buyFromBPDesc || '–'}
        </Body5>
      </Box>
      <Box css={{ w: '13.25rem' }}>
        <Body5 css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {t('field_purchase_order_search_supplier')}:{' '}
          {productSuppliers?.[details.code]?.[0]?.buyFromBPItemCode || '–'}
        </Body5>
        <Body5 css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {t('field_purchase_order_search_item_number')}: {details.code || '–'}
        </Body5>
      </Box>
      <Box css={{ d: 'flex', ml: 'auto', gap: '$2' }}>
        <Box css={{ w: '5.75rem' }}>
          <Body5
            fontWeight="bold"
            css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {details.currency || CURRENCY} {details.price || '–'}
          </Body5>
          <Body5 css={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {details.uom || ''}
          </Body5>
        </Box>
        <AmountSelector value={amount} onChange={setAmount} />
        <Button onClick={onAddItem}>
          {t('field_purchase_order_search_add')}
        </Button>
      </Box>
    </Box>
  )
}
