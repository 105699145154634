import { styled } from '../../../stitches.config'
import { Box } from '../utils'

export const Grid = styled('div', {
  boxSizing: 'border-box',
  d: 'grid',
  height: '100%',
  width: '100%',
  gtc: '12',
  rowGap: '$2',
  columnGap: '$2',
  '@md': {
    columnGap: '$8'
  },
  '@xl': {
    columnGap: '$10'
  }
})

export const ContainerPadding = styled('section', {
  boxSizing: 'border-box',
  width: '100%',
  px: '$3',
  '@xxs': {
    px: '$6'
  },
  '@lg': {
    px: '$8'
  },
  '@xl': {
    px: '$10'
  }
})

export const Container = styled(Box, {
  $$containerMd: '576px',
  $$containerLg: '860px',
  $$containerXl: '1040px',
  $$containerSmallLg: '780px',
  $$containerLargeXXL: '1360px',

  maxWidth: '100%',
  m: '0 auto',
  '@md': {
    maxWidth: '$$containerMd'
  },
  '@lg': {
    maxWidth: '$$containerLg'
  },
  '@xl': {
    maxWidth: '$$containerXl'
  },

  variants: {
    size: {
      small: {
        '@lg': {
          maxWidth: '$$containerSmallLg'
        }
      },
      medium: {
        '@xl': {
          maxWidth: '$$containerXl'
        }
      },
      large: {
        '@xxl': {
          maxWidth: '$$containerLargeXXL'
        }
      }
    },
    adaptiveTablet: {
      true: {
        '@md': {
          maxWidth: '100%'
        },
        '@lg': {
          maxWidth: '$$containerLg'
        },
        '@xl': {
          maxWidth: '$$containerXl'
        }
      }
    }
  }
})
