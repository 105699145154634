import { Badge, Box, Body5, Body7, Spinner } from '@sefar/design-system'
import { StatusChangeComment } from '../types'
import {
  purchaseRequisitionStatusesBadgeColors,
  purchaseRequisitionStatusesLabels,
  PurchaseRequisitionStatusType
} from '../../consts'
import { useUserByEmployeeId } from '../../../../api'
import { formatDateTimeOfficial } from '../../../../components'
import { UserAvatar } from '../../../../components/common/user-avatar'

export const OrderCommentsHistoryItem = ({
  commentData
}: {
  commentData: StatusChangeComment
}) => {
  const { user: employeeData, isLoading: isLoadingEmployee } =
    useUserByEmployeeId(commentData.employeeId)

  const purchaseRequisitionStatus =
    commentData.status as PurchaseRequisitionStatusType

  return (
    <Box
      css={{
        d: 'flex',
        backgroundColor: '$white',
        borderRadius: '$6',
        p: '$4'
      }}
    >
      <Box css={{ flex: 1, p: '$2' }}>
        <Box css={{ d: 'flex', alignItems: 'center', gap: '$2' }}>
          {isLoadingEmployee ? (
            <Spinner size="small" css={{ mx: '$2' }} />
          ) : (
            <UserAvatar user={employeeData} />
          )}
          <Box>
            <Body7 fontWeight="bold">
              {employeeData?.firstName || ' '} {employeeData?.lastName || ''}
            </Body7>
            <Body7 css={{ color: '$neutralLighten20' }}>
              {formatDateTimeOfficial(commentData.createdAt)}
            </Body7>
          </Box>
        </Box>
        <Body5 css={{ mt: '$4', wordBreak: 'break-word' }}>
          {commentData.text}
        </Body5>
      </Box>
      <Box>
        <Badge
          status={
            purchaseRequisitionStatusesBadgeColors[purchaseRequisitionStatus]
          }
        >
          {purchaseRequisitionStatusesLabels[purchaseRequisitionStatus]}
        </Badge>
      </Box>
    </Box>
  )
}
