import { styled } from '../../../stitches.config'
import React, { useState } from 'react'
import type * as Stitches from '@stitches/react'

export const Box = styled('div', {
  boxSizing: 'border-box',
  transition: '$default'
})

export const Label = styled('label', {
  color: '$neutral',
  fontSize: '$body5Xl',
  pl: '$3',
  lineHeight: '$15',

  '&[data-disabled=true], &.disabled': {
    cursor: 'not-allowed',
    color: '$neutralLighten50'
  }
})

export const isImage = (url: string) =>
  /\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(url.split('?')[0]) ||
  url.startsWith('data:image') ||
  url.startsWith('blob:') ||
  url.includes('/thumbnail/')

export function LazyImage({
  aspectRatio,
  src,
  css = {},
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement> & {
  aspectRatio: string
  css?: Stitches.CSS
}) {
  const [isLoaded, setIsLoaded] = useState(false)
  return (
    <>
      {src && isImage(src) ? (
        <Box
          as="img"
          loading="lazy"
          onLoad={() => setIsLoaded(true)}
          src={src}
          css={{
            display: 'block',
            objectFit: 'cover',
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            opacity: isLoaded ? 1 : 0,
            transition: '.2s all',
            aspectRatio,
            ...css
          }}
          {...props}
        />
      ) : (
        <Box
          css={{
            width: '100%',
            height: '100%',
            paddingBottom: '56.25%',
            backgroundColor: '$neutralLighten97',
            ...css
          }}
        />
      )}
    </>
  )
}

export const Video = ({
  css,
  ...props
}: React.ImgHTMLAttributes<HTMLVideoElement> & { css?: Stitches.CSS }) => {
  return (
    <>
      {props?.src && (
        <Box
          as="video"
          controls
          css={{
            display: 'block',
            objectFit: 'cover',
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            transition: '.2s all',
            ...css
          }}
          {...props}
        />
      )}
    </>
  )
}

export const UnreadMark = styled('div', {
  backgroundColor: '$primaryBlue',
  size: 12,
  borderRadius: '$round',
  position: 'absolute'
})
