import React, { ComponentProps, ReactNode, useState } from 'react'
import { styled } from '../../../stitches.config'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { AnimatePresence, motion } from 'framer-motion'
import { Body5Styles } from '../typography'

const contentStyles = {
  minWidth: 220,
  backgroundColor: '$white',
  border: '1px solid $neutralLighten90',
  borderRadius: '$4',
  boxShadow: '$dropdown',
  py: '$2',
  overflow: 'hidden'
}

export const StyledContent = styled(DropdownMenuPrimitive.Content, {
  ...contentStyles,
  zIndex: '$dropdown'
})

const StyledSubContent = styled(DropdownMenuPrimitive.SubContent, {
  ...contentStyles
})

function SubContent(props) {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledSubContent {...props} />
    </DropdownMenuPrimitive.Portal>
  )
}

const itemStyles = {
  all: 'unset',
  boxSizing: 'border-box',
  color: '$neutral',
  display: 'flex',
  alignItems: 'center',
  padding: '$2 $6',
  position: 'relative',
  userSelect: 'none',

  '&[data-disabled]': {
    backgroundColor: '$neutralLighten93',
    pointerEvents: 'none'
  },

  '&[data-highlighted]': {
    backgroundColor: '$neutralLighten93'
  },
  '&.active': {
    backgroundColor: '$primaryBlueLighten90',
    borderLeft: '3px $primaryBlueLighten50 solid',
    paddingLeft: '21px'
  }
}

const StyledItem = styled(DropdownMenuPrimitive.Item, {
  ...itemStyles,
  ...Body5Styles,
  variants: {
    active: {
      true: {
        backgroundColor: '$primaryBlueLighten90',
        borderLeft: '3px $primaryBlueLighten50 solid',
        paddingLeft: '21px'
      }
    }
  }
})
const StyledSubTrigger = styled(DropdownMenuPrimitive.SubTrigger, {
  ...itemStyles
})
const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: '$neutralLighten93',
  my: '$1'
})

export const StyledDropdownMenu = styled(DropdownMenuPrimitive.Root)
export const DropdownMenuPortal = DropdownMenuPrimitive.Portal
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger
export const DropdownMenuItem = StyledItem
export const DropdownMenuSeparator = StyledSeparator
export const DropdownMenuSub = DropdownMenuPrimitive.Sub
export const DropdownMenuSubTrigger = StyledSubTrigger
export const DropdownMenuSubContent = SubContent

const AnimatedDropdownMenuContent = motion(StyledContent)

export function DropdownMenu({
  trigger,
  content,
  contentProps,
  onOpenChange,
  ...props
}: Omit<ComponentProps<typeof StyledDropdownMenu>, 'open'> & {
  trigger: (isOpen: boolean) => ReactNode
  content: (setOpen: (isOpen: boolean) => void) => ReactNode
  contentProps?: ComponentProps<typeof AnimatedDropdownMenuContent>
}) {
  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <StyledDropdownMenu
      open={isOpen}
      onOpenChange={(value) => {
        onOpenChange && onOpenChange(value)
        setOpen(value)
      }}
      {...props}
    >
      <DropdownMenuTrigger asChild>{trigger(isOpen)}</DropdownMenuTrigger>
      <AnimatePresence>
        {isOpen && (
          <DropdownMenuPortal forceMount>
            <AnimatedDropdownMenuContent
              forceMount
              sideOffset={5}
              align={'end'}
              {...contentProps}
              transition={{
                type: 'linear',
                bounce: 0,
                duration: 0.2,
                ease: 'easeInOut'
              }}
              initial={{ height: 0, opacity: 0, y: '-25%' }}
              animate={{ height: 'auto', opacity: 1, y: '0%' }}
              exit={{
                opacity: 0,
                height: 0,
                paddingTop: 0,
                paddingBottom: 0,
                y: '-25%',
                transition: {
                  duration: 0.15
                }
              }}
            >
              {content(setOpen)}
            </AnimatedDropdownMenuContent>
          </DropdownMenuPortal>
        )}
      </AnimatePresence>
    </StyledDropdownMenu>
  )
}
