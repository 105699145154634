import React, { useRef, useState } from 'react'
import { useMedia } from 'react-use'
import { Navigation, Pagination } from 'swiper'
import {
  Body7,
  Box,
  Button,
  Carousel,
  CarouselSlide,
  ChevronLeftIcon16,
  ChevronRightIcon16
} from '@sefar/design-system'
import { MustReadNewsArticleCard } from './must-read-news-card'
import { NewsArticle } from '../../api'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'

export const MustReadNewsRow = ({
  mustReadNews = []
}: {
  mustReadNews?: NewsArticle[]
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const carouselPrevBtnRef = useRef(null)
  const carouselNextBtnRef = useRef(null)

  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)

  const showPagination = mustReadNews.length > 1

  if (!mustReadNews.length) return null

  return (
    <Box css={{ mt: '$22' }}>
      <Carousel
        css={{
          '&.swiper': {
            px: '24px',
            '@md': { px: 'calc((100% - 576px) / 2)' },
            '@lg': { px: 'calc((100% - 780px) / 2)' }
          },
          '& .swiper-wrapper': { pb: '$8', '@lg': { pb: '$4' } },
          '& .swiper-pagination': { bottom: '0 !important' },
          '& .swiper-pagination-bullet': { background: '$neutral' }
        }}
        navigation={{
          prevEl: carouselPrevBtnRef.current,
          nextEl: carouselNextBtnRef.current
        }}
        onSlideChange={(e) => setCurrentSlideIndex(e.activeIndex)}
        spaceBetween={24}
        slidesPerView="auto"
        pagination={!isLg && showPagination && { clickable: true }}
        modules={[Navigation, Pagination]}
      >
        {mustReadNews?.map((article: NewsArticle) => (
          <CarouselSlide
            key={article.id}
            css={{
              w: '100% !important',
              '@md': { maxWidth: '576px' },
              '@lg': { w: '780px !important', maxWidth: 'none' }
            }}
          >
            <Box id={article.id}>
              <MustReadNewsArticleCard key={article.id} article={article} />
            </Box>
          </CarouselSlide>
        ))}
      </Carousel>
      <Box
        css={{
          d: isLg && showPagination ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Button variant="secondary" icon ref={carouselPrevBtnRef}>
          <ChevronLeftIcon16 />
        </Button>
        <Body7 css={{ color: '$neutralLighten20', mx: '$5' }}>
          {currentSlideIndex + 1} / {mustReadNews.length}
        </Body7>
        <Button variant="secondary" icon ref={carouselNextBtnRef}>
          <ChevronRightIcon16 />
        </Button>
      </Box>
    </Box>
  )
}
