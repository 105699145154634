import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo
} from 'react'
import { Idea, IdeaLocs, User } from '../../api'
import {
  Box,
  Container,
  Heading1,
  Heading5,
  Body2,
  Body4,
  Body6,
  ReactSelect,
  Input,
  TextArea,
  Button,
  Tag,
  SelectOption
} from '@sefar/design-system'
import { useTranslate } from '../../hooks/useTranslate'
import { languageOptions } from './idea-details'
import SelectUser from '../../components/select-user-input'
import { useConfigStore } from '../../state/config'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'

function Section({
  title,
  description,
  wrapperCss,
  children
}: {
  title?: string
  description?: string
  wrapperCss?: Record<string, string>
  children?: React.ReactNode
}) {
  return (
    <Box css={{ mb: '$12', ...wrapperCss }}>
      {title && <Heading5 css={{ mb: '$6' }}>{title}</Heading5>}
      {description && (
        <Body6
          css={{
            mb: '$2',
            color: '$neutralLighten30'
          }}
        >
          {description}
        </Body6>
      )}
      {children}
    </Box>
  )
}

export function BottomSection({
  title,
  description,
  children
}: {
  title: string
  description?: string
  children?: React.ReactNode
}) {
  return (
    <Box css={{ mb: '$12' }}>
      <Box css={{ mb: '$6' }}>
        {title && <Heading5 css={{ mb: '$2' }}>{title}</Heading5>}
        {description && <Body4>{description}</Body4>}
      </Box>
      {children}
    </Box>
  )
}

export function IdeaDetailsEdit({
  idea,
  lang,
  setIdea,
  setRequestInProgress,
  onCategoryTagClick,
  onSubmitClick,
  categories
}: {
  idea: Idea
  lang: string
  setIdea: Dispatch<SetStateAction<Idea>>
  setRequestInProgress: (status: boolean) => void
  onCategoryTagClick: (cat: string) => void
  onSubmitClick: () => void
  categories: Record<string, string> | undefined
}) {
  const { t } = useTranslate()
  const { me } = useConfigStore()
  const [isReadyToSend, setIsReadyToSend] = useState<boolean>(false)

  useEffect(() => {
    if (idea?.title && idea?.improvement && idea?.problem) {
      setIsReadyToSend(true)
    } else {
      setIsReadyToSend(false)
    }
  }, [idea])

  const setIdeaField = (field: string, value: any) => {
    setIdea((old: Idea) => ({ ...old, [field]: value }))
  }

  const defaultLocation = useMemo<string[] | undefined>(() => {
    const userLocation = me?.locationCode as string
    for (const locOption of IdeaLocs) {
      if (locOption.value.includes(userLocation)) {
        return locOption.value
      }
    }
    return undefined
  }, [me, IdeaLocs])

  const currentLocation = useMemo(() => {
    if (idea?.location) {
      for (const locOption of IdeaLocs) {
        if (isEqual(sortBy(locOption.value), sortBy(idea.location))) {
          return locOption
        }
      }
    } else if (defaultLocation) {
      for (const locOption of IdeaLocs) {
        if (isEqual(sortBy(locOption.value), sortBy(defaultLocation))) {
          return locOption
        }
      }
    } else {
      return undefined
    }
  }, [defaultLocation, IdeaLocs, idea.location])

  return (
    <Container size="small" css={{ '@lg': { maxWidth: '680px' } }}>
      <Heading1 css={{ mb: '$6' }}>{t('field_idea_new_idea')}</Heading1>
      <Body2>{t('field_idea_new_idea_descr')}</Body2>

      <Box css={{ mt: '$30' }}>
        <Section
          title={`${t('field_idea_form_lang')}*`}
          description={`${t('field_idea_form_lang_descr')}*`}
          wrapperCss={{ mb: '$18' }}
        >
          <ReactSelect
            value={
              languageOptions.find(
                (option: SelectOption) => option.value === idea?.lang
              ) || languageOptions[0]
            }
            options={languageOptions}
            id="language"
            css={{ width: '100%' }}
            onChange={(option: SelectOption) =>
              setIdeaField('lang', option.value)
            }
          />
        </Section>
        <Section
          title={`${t('field_idea_form_location')}*`}
          description={`${t('field_idea_form_location_descr')}*`}
          wrapperCss={{ mb: '$18' }}
        >
          <ReactSelect
            value={currentLocation}
            options={IdeaLocs}
            id="location"
            css={{ width: '100%' }}
            onChange={(option: SelectOption) =>
              setIdeaField('location', option.value)
            }
          />
        </Section>
        <Section title={`${t('field_idea_form_title_and_descr')}*`}>
          <Input
            label={`${t('field_idea_form_title_label')}*`}
            id="title_of_idea"
            placeholder={`${t('field_idea_form_title_plchldr')}`}
            value={idea?.title || ''}
            onChange={(e) => setIdeaField('title', e.target.value)}
          />
        </Section>
        <Section>
          <TextArea
            value={idea?.problem}
            label={`${t('field_idea_form_problem_label')}*`}
            id="problem"
            name="problem"
            placeholder={`${t('field_idea_form_common_plchldr')}`}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setIdeaField('problem', e.target.value)
            }
            css={{ minHeight: '147px' }}
          />
        </Section>
        <Section>
          <TextArea
            value={idea?.improvement}
            label={`${t('field_idea_form_improve_label')}*`}
            id="look"
            name="look"
            placeholder={`${t('field_idea_form_common_plchldr')}`}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setIdeaField('improvement', e.target.value)
            }
            css={{ minHeight: '147px' }}
          />
        </Section>
        <Section>
          <TextArea
            value={idea?.aimsBenefits}
            label={`${t('field_idea_form_aims_label')}`}
            id="aim"
            name="aim"
            placeholder={`${t('field_idea_form_common_plchldr')}`}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setIdeaField('aimsBenefits', e.target.value)
            }
            css={{ minHeight: '147px' }}
          />
        </Section>
        <Section>
          <TextArea
            value={idea?.involved}
            label={`${t('field_idea_form_who_label')}`}
            id="involved"
            name="involved"
            placeholder={`${t('field_idea_form_common_plchldr')}`}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setIdeaField('involved', e.target.value)
            }
            css={{ minHeight: '147px' }}
          />
        </Section>
        <Section
          title={`${t('field_idea_form_implementer')}`}
          description={`${t('field_idea_form_implementer_desc')}`}
        >
          <SelectUser
            user={idea.implementer}
            setUser={(implementer: User) => {
              setIdeaField('implementer', implementer)
            }}
            dialogProps={{
              title: 'Assign to Implementer',
              description:
                'Select the Implementer who will review the idea and will be in charge of implementation.',
              saveButtonLabel: 'Assign to Implementer'
            }}
          />
        </Section>
        <Box>
          <BottomSection
            title={`${t('field_idea_form_category')}`}
            description={`${t('field_idea_form_category_descr')}`}
          >
            <Box
              css={{
                d: 'flex',
                gap: '$2',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              {categories &&
                Object.keys(categories)?.map((category: string) => (
                  <Tag
                    key={category}
                    onClick={() => onCategoryTagClick(category)}
                    className={
                      idea?.categories?.includes(category) ? 'active' : ''
                    }
                    css={{ cursor: 'pointer' }}
                  >
                    {categories[category]}
                  </Tag>
                ))}
            </Box>
          </BottomSection>
          <BottomSection
            title={`${t('field_idea_form_privacy')}`}
            description={`${t('field_idea_form_privacy_descr')}`}
          />
        </Box>
        <Box css={{ my: '$16' }}>
          <Button
            size="large"
            onClick={onSubmitClick}
            disabled={!isReadyToSend}
          >
            {t('field_submit')}
          </Button>
        </Box>
      </Box>
    </Container>
  )
}
