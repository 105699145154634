import useSWRImmutable from 'swr/immutable'
import { API_DOMAIN, fetcherFullRequest, init } from './common'

export function useTranslations(lang: string) {
  const langUrlPart = lang && lang !== 'en' ? `/${lang}` : ''
  const { data, error } = useSWRImmutable(
    [
      `${API_DOMAIN}${langUrlPart}/api_translations/interface_translations`,
      init
    ],
    fetcherFullRequest
  )

  return {
    translations: data,
    isLoading: !error && !data,
    isError: error
  }
}
