import * as React from 'react'
import { ComponentProps, ReactNode, useEffect } from 'react'
import { styled } from '../../../stitches.config'
import { StyledComponent } from '@stitches/react/types/styled-component'
import {
  StyledInputMessage,
  StyledFieldsetContainer,
  BooleanString,
  StyledInputLabel
} from './input-utils'

export type InputProps = Omit<
  ComponentProps<typeof StyledInput>,
  'startEnhancer'
> & {
  label?: ReactNode | string
  startEnhancer?: ReactNode | string
  endEnhancer?: ReactNode | string
  error?: ReactNode | string
  info?: ReactNode | string
  containerProps?: ComponentProps<typeof StyledFieldsetContainer>
  inputContainerProps?: ComponentProps<typeof StyledInputContainer>
  labelProps?: ComponentProps<typeof StyledInputLabel>
  enhancerProps?: ComponentProps<typeof StyledEnhancer>
}

export const StyledInputContainer: StyledComponent<
  'div',
  { error?: BooleanString; disabled?: BooleanString },
  any,
  any
> = styled('div', {
  display: 'flex',
  width: '100%',
  transition: '$default',
  border: '1px $neutralLighten80 solid',
  borderRadius: '$4',
  boxShadow: '$default',
  backgroundColor: '$white',
  color: '$neutral',
  '&:hover, &:focus-within, &.__hover, &.__focus': {
    borderColor: '$neutralLighten60',
    backgroundColor: '$neutralLighten97',
    '& div': {
      color: '$neutral'
    }
  },
  '&:focus-within, &.__focus': {
    outline: '1px $primaryBlue solid',
    borderColor: '$primaryBlue',
    backgroundColor: '$neutralLighten97'
  },
  variants: {
    error: {
      true: {
        borderColor: '$primaryRed',
        '&:hover, &:focus-within, &.__hover, &.__focus': {
          borderColor: '$primaryRed'
        },
        '&:focus-within, &.__focus': {
          outline: '1px $primaryRed solid'
        }
      },
      false: {}
    },
    disabled: {
      true: {
        borderColor: '$neutralLighten80',
        backgroundColor: '$neutralLighten97',
        color: '$neutralLighten60',
        cursor: 'not-allowed',
        '&:hover, &:focus-within, &.__hover, &.__focus': {
          borderColor: '$neutralLighten80'
        },
        '&:focus-within, &.__focus': {
          outline: 'none'
        }
      },
      false: {}
    }
  },
  compoundVariants: [
    {
      disabled: true,
      error: true,
      css: {
        color: '$neutralLighten60',
        cursor: 'not-allowed',
        '&:hover, &:focus-within, &.__hover, &.__focus': {
          borderColor: '$neutralLighten80'
        },
        '&:focus-within, &.__focus': {
          outline: 'none'
        }
      }
    }
  ]
})
export const StyledEnhancer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: '$neutralLighten50',
  padding: '$1 $4',
  '@md': {
    py: '$2'
  }
})
export const StyledInput: StyledComponent<
  'input',
  {
    endEnhancer?: BooleanString
    startEnhancer?: BooleanString
  },
  any,
  any
> = styled('input', {
  outline: 'none',
  border: 0,
  backgroundColor: 'transparent',
  width: '100%',
  px: '$4',
  fontSize: '$body5Xl',
  lineHeight: '$15',
  py: '$2',
  color: 'inherit',
  '&::placeholder': {
    color: '$neutralLighten50'
  },
  variants: {
    startEnhancer: {
      true: {
        paddingLeft: 0
      },
      false: {}
    },
    endEnhancer: {
      true: {
        paddingRight: 0
      },
      false: {}
    },
    disabled: {
      true: {
        pointerEvents: 'none',
        '&::placeholder': {
          color: '$neutralLighten60',
          textOverflow: 'ellipsis'
        }
      },
      false: {}
    }
  }
})

export const Input: React.FC<InputProps> = React.forwardRef(
  (
    {
      label,
      startEnhancer,
      endEnhancer,
      inputContainerProps = {},
      containerProps = {},
      error,
      info,
      type = 'text',
      id,
      labelProps = {},
      enhancerProps = {},
      ...props
    },
    ref
  ) => {
    useEffect(() => {
      if (label && !id) {
        console.warn(
          'Input id is undefined, you should put id if you are using label'
        )
      }
    }, [label, id])
    return (
      <StyledFieldsetContainer data-fi="input" {...containerProps}>
        {label && (
          <StyledInputLabel
            htmlFor={id}
            disabled={props.disabled}
            {...labelProps}
          >
            {label}
          </StyledInputLabel>
        )}
        <StyledInputContainer
          {...inputContainerProps}
          error={!!error}
          disabled={props.disabled}
        >
          {startEnhancer && (
            <StyledEnhancer {...enhancerProps}>{startEnhancer}</StyledEnhancer>
          )}
          <StyledInput
            {...props}
            ref={ref}
            id={id}
            startEnhancer={!!startEnhancer}
            endEnhancer={!!startEnhancer}
            type={type}
          />
          {endEnhancer && (
            <StyledEnhancer {...enhancerProps}>{endEnhancer}</StyledEnhancer>
          )}
        </StyledInputContainer>
        {(info || error) && (
          <StyledInputMessage>{error || info}</StyledInputMessage>
        )}
      </StyledFieldsetContainer>
    )
  }
)
