import React, { ReactNode, useEffect, useState } from 'react'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Body5,
  Body7,
  Box,
  ContainerPadding,
  DropdownMenuItem,
  DropdownMenuSeparator,
  Heading5,
  DashboardIcon24,
  SearchIcon24,
  DropdownMenu,
  Button,
  AlarmBellIcon24,
  Badge,
  LightBulbIcon20
} from '@sefar/design-system'
import { styled } from '../../../../../stitches.config'
import Logo from '../../assets/logo.svg?react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AvatarPlaceholder from '../../assets/avatar-placeholder-blue.jpg'
import {
  clearLocalStorage,
  isRegularUser,
  logout,
  resetPendingCount
} from '../api'
import { useScrollDirection } from '../hooks/useScrollDirection'
import { useTranslate } from '../hooks/useTranslate'
import { useConfigStore } from '../state/config'
import { analyticsPageTracker, mixpanelAnalyticsHelper } from './utils'
import { ideasPageUrl, profilePageUrl, spacesPageUrl } from '../app'

const HeaderLogo = styled(Logo, {
  height: 26
})

const HeaderStyled = styled('div', {
  d: 'flex',
  alignItems: 'center',
  py: '$4',
  backgroundColor: '$white',
  color: '$black',
  height: '$headerHeight',
  boxSizing: 'border-box',
  zIndex: '30',
  position: 'sticky',
  top: '0px',
  transition: 'all 300ms ease-in-out',

  '&.home': {
    backgroundColor: 'transparent',
    color: '$white'
  },

  '&.filled': {
    backgroundColor: '$white',
    color: '$black'
  }
})

export const HeaderNavigatorContainerSmall = styled('nav', {
  width: 'calc(100% - 32px)',
  boxSizing: 'border-box',
  d: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  backdropFilter: 'blur(15px)',
  borderRadius: 99,
  position: 'fixed',
  bottom: 16,
  left: 16,
  transition: '.3s background-color ease-in-out',
  px: '$8',
  '@md': {
    px: '$30'
  },
  '@lg': {
    d: 'none'
  }
})
const HeaderNavigatorContainerLarge = styled('nav', {
  d: 'none',
  mr: 'auto',
  '@lg': {
    flex: 1,
    mx: 'auto',
    d: 'flex',
    gap: '$4',
    maxWidth: '1100px'
  }
})
export const HeaderNavigatorLink = styled(Link, {
  textDecoration: 'none',
  boxSizing: 'border-box',
  transition: '$default',
  d: 'inline-block',
  color: '$black',
  cursor: 'pointer',
  position: 'relative',
  borderBottom: '4px transparent solid',
  pb: '$3',
  mb: '-$4',
  '&:hover': {
    '@lg': {
      borderColor: '$black'
    }
  },
  '@lg': {
    d: 'flex',
    alignItems: 'center',
    height: 48,
    px: '$4'
  },
  variants: {
    disabled: {
      true: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
        color: '$neutralLighten30'
      },
      false: {}
    },
    largeFont: {
      true: {
        '& > div': {
          fontSize: '$body4 !important'
        }
      }
    },
    bold: {
      true: {
        fontWeight: 700
      }
    },
    active: {
      true: {
        borderColor: '$black'
      },
      false: {}
    },
    activeWhite: {
      true: {
        borderColor: '$white'
      },
      false: {}
    },
    isWhite: {
      true: {
        color: '$white',
        '&:hover': {
          '@lg': {
            borderColor: '$white'
          }
        }
      }
    }
  }
})

export type HeaderNavigatorTab = {
  label: string
  path: string
  icon: ReactNode
  disabled: boolean
  onClick: () => void
}

const analyticsTrackerSelector = (route: string) => {
  if (route === '/') {
    mixpanelAnalyticsHelper().track('Leaving from home page')
  } else {
    analyticsPageTracker()
  }
}

const analyticDropDownItemHandler = (page: string, route: string) => {
  analyticsTrackerSelector(route)
  analyticsPageTracker(page)
}

function HeaderDropdownMenu() {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslate()

  const { me: user, reset, isSpaceEditor } = useConfigStore()

  const logoutAsync = async () => {
    await logout()
    reset()
    clearLocalStorage()
    navigate('/login')
  }

  return (
    <DropdownMenu
      trigger={() => (
        <Avatar data-cy="headerUserAvatar">
          <AvatarImage src={user?.image || AvatarPlaceholder} alt="avatar" />
          <AvatarFallback delayMs={600}>AM</AvatarFallback>
        </Avatar>
      )}
      content={(setOpen: (isOpen: boolean) => void) => (
        <>
          <AvatarLink
            to="/profile"
            onClick={() => {
              setOpen(false)
              analyticDropDownItemHandler('profile', location.pathname)
            }}
          >
            <DropdownMenuItem
              active={location.pathname === `${profilePageUrl}`}
            >
              <Body5 as="span" data-cy="headerMyProfileButton">
                {t('field_my_profile_link')}
              </Body5>
            </DropdownMenuItem>
          </AvatarLink>

          {!isRegularUser(user) || isSpaceEditor ? (
            <AvatarLink
              to="/my-content"
              onClick={() => {
                setOpen(false)
                analyticDropDownItemHandler('my content', location.pathname)
              }}
            >
              <DropdownMenuItem active={location.pathname === '/my-content'}>
                <Body5 as="span" data-cy="headerMyContentButton">
                  {t('field_my_contents_link')}
                </Body5>
              </DropdownMenuItem>
            </AvatarLink>
          ) : null}

          <AvatarLink
            to="/profile-settings"
            onClick={() => {
              setOpen(false)
              analyticDropDownItemHandler('settings', location.pathname)
            }}
          >
            <DropdownMenuItem
              active={location.pathname === '/profile-settings'}
            >
              <Body5 as="span" data-cy="headerSettingsButton">
                {t('field_settings_link')}
              </Body5>
            </DropdownMenuItem>
          </AvatarLink>

          <DropdownMenuSeparator />

          <DropdownMenuItem
            onClick={() => {
              setOpen(false)
              logoutAsync()
            }}
          >
            <Body5 as="span" data-cy="headerLogoutButton">
              {t('field_logout_link')}
            </Body5>
          </DropdownMenuItem>
        </>
      )}
    />
  )
}

export function HeaderNavigator({
  isHomePage = false,
  isScrollPositionOnTop = false,
  hideIcons = false
}) {
  const { t } = useTranslate()

  const location = useLocation()
  const [activeTabPath, setActiveTabPath] = useState<string>(location?.pathname)

  const tabs: HeaderNavigatorTab[] = [
    {
      label: t('field_home'),
      path: '/',
      icon: <SearchIcon24 />,
      disabled: false,
      onClick: () => {
        analyticDropDownItemHandler('home', location.pathname)
      }
    },
    {
      label: t('field_spaces'),
      path: `/${spacesPageUrl}`,
      icon: <DashboardIcon24 />,
      disabled: false,
      onClick: () => {
        analyticDropDownItemHandler('spaces', location.pathname)
      }
    },
    {
      label: t('field_ideas'),
      path: `/${ideasPageUrl}`,
      icon: <LightBulbIcon20 />,
      disabled: false,
      onClick: () => {
        analyticDropDownItemHandler('ideas', location.pathname)
      }
    }
  ]

  useEffect(() => {
    if (location?.pathname) {
      setActiveTabPath(location?.pathname)
    }
  }, [location?.pathname])

  return (
    <>
      {tabs.map((tab) => (
        <HeaderNavigatorLink
          to={tab.disabled ? 'javascript:void(0)' : tab.path}
          key={tab.path}
          data-cy={`header${tab.label}NavButton`}
          disabled={tab.disabled}
          largeFont={hideIcons}
          bold={hideIcons && activeTabPath === tab.path}
          active={!hideIcons && activeTabPath === tab.path}
          activeWhite={
            !hideIcons &&
            activeTabPath === tab.path &&
            isHomePage &&
            isScrollPositionOnTop
          }
          isWhite={isHomePage && isScrollPositionOnTop}
          onClick={tab.onClick}
        >
          <Body7
            css={{
              d: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '$1',
              '@lg': {
                d: 'none'
              }
            }}
          >
            {!hideIcons && (
              <Box css={{ size: 24, '& svg': { size: 24 } }}>{tab.icon}</Box>
            )}
            {tab.label}
          </Body7>
          <Heading5
            css={{
              d: 'none',
              '@lg': {
                d: 'block'
              }
            }}
          >
            {tab.label}
          </Heading5>
        </HeaderNavigatorLink>
      ))}
    </>
  )
}

export const AvatarLink = styled(Link, {
  textDecoration: 'none',
  color: 'inherit',
  cursor: 'pointer',
  d: 'block',
  flex: 1
})

export const Header = () => {
  const { pendingMessagesCount } = useConfigStore()
  const location = useLocation()
  const [, scrollYPosition] = useScrollDirection()
  const isHomePage = location?.pathname === '/'
  const isScrollPositionOnTop = scrollYPosition === 0

  return (
    <HeaderStyled
      className={`${isHomePage ? 'home' : ''} ${!isScrollPositionOnTop ? 'filled' : ''}`}
    >
      <ContainerPadding
        css={{
          flex: 1,
          d: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '$16'
        }}
      >
        <Box
          as={Link}
          to="/"
          css={{
            lineHeight: 0,
            pr: '$10',
            py: 1,
            color: isHomePage && isScrollPositionOnTop ? '$white' : '$black'
          }}
        >
          <HeaderLogo />
        </Box>
        <HeaderNavigatorContainerLarge>
          <HeaderNavigator
            isHomePage={isHomePage}
            isScrollPositionOnTop={isScrollPositionOnTop}
          />
        </HeaderNavigatorContainerLarge>

        <Box css={{ d: 'flex', alignItems: 'center', gap: '$6' }}>
          <Button
            as={Link}
            variant="transparent"
            to="/notifications"
            noBorder="true"
            className="icon only-icon"
            css={{
              color: isHomePage && isScrollPositionOnTop ? '$white' : '$black',
              position: 'relative',
              overflow: 'initial'
            }}
            onClick={() => {
              analyticDropDownItemHandler('notifications', location.pathname)
              if (pendingMessagesCount) {
                resetPendingCount()
              }
            }}
          >
            <AlarmBellIcon24 />
            {pendingMessagesCount ? (
              <Badge
                status="warning"
                css={{
                  position: 'absolute',
                  top: 0,
                  left: 20,
                  fontWeight: '$normal'
                }}
              >
                {pendingMessagesCount}
              </Badge>
            ) : (
              <></>
            )}
          </Button>
          <HeaderDropdownMenu />
        </Box>
      </ContainerPadding>
    </HeaderStyled>
  )
}
