import {
  Box,
  Button,
  DateRangeDateOutput,
  Tooltip,
  ToastTypeEnum,
  useToastContext
} from '@sefar/design-system'
import React, { useEffect, useState } from 'react'
import { convertDateToSend, updateMediaSrc } from '../../components'
import {
  ChannelTypeEnum,
  NewsArticle,
  patchNewsArticle,
  ReviewStateEnum,
  USER_TYPE
} from '../../api'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES, styled } from '../../../../../../stitches.config'
import { myContentPageUrl } from '../../app'
import { useNavigate } from 'react-router-dom'
import { useConfigStore } from '../../state/config'
import { ActionsMenuDropdown } from '../../components/article/actions-menu-dropdown'
import { NewsArticleSettings } from './news-details-settings'
import { useLocationStateFrom } from '../../hooks/useLocationStateFrom'
import { SendMessageDialog } from '../../components/dialogs/send-message-dialog'

export const MANDATORY_FIELDS_INFO =
  'Please add all mandatory information: title, lead, main visual.'
const REVIEW_PERMISSION = 'reviewer_access'
const StyledSettingsButton = styled('button', {
  d: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  color: '$white'
})

export function saveNewsArticle(
  article: NewsArticle,
  settings: NewsArticleSettings,
  publishStatus?: boolean,
  publicationDates?: DateRangeDateOutput,
  sendToReview?: boolean,
  sendBackToAuthor?: boolean,
  feedBackMessage?: string,
  reviewIsDone?: boolean,
  reviewNote?: string,
  onArticleChange?: () => void
) {
  const publishDate: Record<string, string | null> = publicationDates?.start
    ? {
        publish_on: convertDateToSend(publicationDates.start)
      }
    : publicationDates?.start === null
      ? { publish_on: null }
      : {}
  const unPublishDate: Record<string, string | null> = publicationDates?.end
    ? {
        unpublish_on: convertDateToSend(
          publishStatus === false && !publicationDates.end
            ? new Date()
            : publicationDates.end
        )
      }
    : publicationDates?.end === null
      ? { unpublish_on: null }
      : {}
  const publishAttributes: Record<string, boolean> =
    publishStatus !== undefined
      ? { status: publishStatus }
      : !article?.id
        ? { status: false }
        : {}

  let reviewAttributes = sendBackToAuthor
    ? {
        field_review_state: ReviewStateEnum['Review with feedback'] as string
      }
    : sendToReview
      ? {
          field_review_state: ReviewStateEnum['On review'] as string,
          field_sent_to_review: convertDateToSend(new Date())
        }
      : article?.reviewState === ReviewStateEnum['On review'] && reviewIsDone
        ? {
            field_review_state: ReviewStateEnum.Reviewed as string
          }
        : {}

  if (
    article?.reviewState === ReviewStateEnum['On review'] &&
    publishStatus === true
  ) {
    reviewAttributes = {
      field_review_state: ReviewStateEnum.Reviewed as string
    }
  }

  const { category, copyright, country, ...meta } = article?.media?.meta || {}
  const {
    langcode,
    field_news_category,
    field_location,
    channelType,
    field_news_author,
    field_news_must_read,
    field_news_must_read_reset_time
  } = settings

  const authorRelationships = field_news_author?.id
    ? {
        field_news_author: {
          data: {
            type: USER_TYPE,
            id: field_news_author?.id
          }
        }
      }
    : {}

  return patchNewsArticle(
    article.id,
    langcode,
    article?.media?.file,
    meta,
    {
      title: article.title,
      field_lead: {
        value: article.lead
      },
      field_text: {
        value: updateMediaSrc(article.text)
      },
      field_news_review_feedback: feedBackMessage,
      field_news_note_for_review: reviewNote,
      langcode,
      field_location:
        channelType === ChannelTypeEnum.global
          ? ChannelTypeEnum.global
          : field_location,
      field_news_category,
      field_news_must_read,
      field_news_must_read_reset_time,
      ...publishDate,
      ...unPublishDate,
      ...publishAttributes,
      ...reviewAttributes,

      category: [...(category || []), ...(country || [])]?.toString()
    },
    article?.media?.attachmentInfo,
    authorRelationships
  ).then((res) => {
    res.json()
    onArticleChange?.()
  })
}

export function NewsDetailsActions({
  article,
  settings,
  onCancelButtonCLick,
  setRequestInProgress,
  isReadyToSave,
  onArticleChange
}: {
  article: NewsArticle
  settings: NewsArticleSettings
  onCancelButtonCLick: () => void
  setRequestInProgress: (requestInProgress: boolean) => void
  isReadyToSave: boolean
  onArticleChange?: () => Promise<void>
}) {
  const from = useLocationStateFrom([], () => `/${myContentPageUrl}`)
  const setToast = useToastContext()
  const { me } = useConfigStore()
  const navigate = useNavigate()
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)

  const [publicationDates, setPublicationDates] =
    useState<DateRangeDateOutput>()
  const [canPublish, setCanPublish] = useState<boolean>(false)

  const onHtmlSave = (
    publishStatus?: boolean,
    sendToReview?: boolean,
    sendBackToAuthor?: boolean,
    feedBackMessage?: string,
    reviewIsDone?: boolean,
    reviewNote?: string
  ) => {
    setRequestInProgress(true)

    saveNewsArticle(
      article,
      settings,
      publishStatus,
      publicationDates,
      sendToReview,
      sendBackToAuthor,
      feedBackMessage,
      reviewIsDone,
      reviewNote
    )
      .then(async (res) => {
        if (res?.errors?.length) {
          setToast({
            message: 'Some error occurred. Please try again later.',
            type: ToastTypeEnum.error
          })
        } else {
          setToast({
            message: sendToReview
              ? 'Your article has been sent for review. The reviewer who will work on this will get in touch with you soon.'
              : article?.id
                ? `The article has been ${
                    publishStatus !== undefined
                      ? publishStatus
                        ? 'published'
                        : 'unpublished'
                      : 'updated'
                  } successfully!`
                : 'The article has been successfully created!',
            type: ToastTypeEnum.success
          })

          await onArticleChange?.()

          navigate(from)
        }
      })
      .finally(() => {
        setRequestInProgress(false)
      })
  }

  const onSendBackToReviewerClick = (message: string) => {
    onHtmlSave(undefined, undefined, true, message)
  }

  const onReviewDoneClick = () => {
    onHtmlSave(undefined, undefined, undefined, undefined, true)
  }

  useEffect(() => {
    setCanPublish(
      settings?.channelType !== ChannelTypeEnum.global ||
        me?.permissions?.includes(REVIEW_PERMISSION)
    )
  }, [me?.permissions, settings?.channelType])

  useEffect(() => {
    setPublicationDates({
      start: article?.publishDate ? new Date(article?.publishDate) : undefined,
      end: article?.unpublishDate ? new Date(article?.unpublishDate) : undefined
    })
  }, [article?.unpublishDate, article?.publishDate])

  return (
    <Box
      css={{
        d: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '$4',
        height: '100%'
      }}
    >
      {!isSm && (
        <Tooltip
          contentProps={{ sideOffset: 1 }}
          text={MANDATORY_FIELDS_INFO}
          active={!isReadyToSave}
        >
          <Button
            data-cy={
              article?.status ? 'SavePublishNewsButton' : 'SaveCloseNewsButton'
            }
            variant={article?.status ? 'primary' : 'secondary'}
            onClick={() => onHtmlSave()}
            disabled={!isReadyToSave}
          >
            {article?.status ? 'Save & Publish' : 'Save & Close'}
          </Button>
        </Tooltip>
      )}

      <Button variant="secondary" onClick={onCancelButtonCLick}>
        Discard changes
      </Button>

      {canPublish ? (
        <Box css={{ d: 'flex' }}>
          <Tooltip
            contentProps={{ sideOffset: 1 }}
            text={MANDATORY_FIELDS_INFO}
            active={!isReadyToSave}
          >
            <Button
              variant={article?.status ? 'secondary' : 'primary'}
              disabled={isSm ? false : !isReadyToSave}
              data-cy={`${!article?.status ? 'publish' : 'unpublish'}Button`}
              onClick={() => {
                if (isReadyToSave) {
                  onHtmlSave(!article?.status)
                } else if (isSm) {
                  setToast({
                    type: ToastTypeEnum.warning,
                    message: MANDATORY_FIELDS_INFO
                  })
                }
              }}
            >
              {!article?.status ? 'Publish' : 'Unpublish'}
            </Button>
          </Tooltip>

          <ActionsMenuDropdown
            article={article}
            isReadyToSave={isReadyToSave}
            onChangePublicationDates={setPublicationDates}
            onSendBackToReviewerClick={onSendBackToReviewerClick}
            onReviewDoneClick={onReviewDoneClick}
          />
        </Box>
      ) : (
        !canPublish &&
        !article?.status &&
        settings?.channelType === ChannelTypeEnum.global && (
          <>
            {!isReadyToSave ? (
              <Button
                data-cy="sendToReviewerButton"
                variant="primary"
                onClick={() =>
                  setToast({
                    type: ToastTypeEnum.warning,
                    message: MANDATORY_FIELDS_INFO
                  })
                }
              >
                Send to Review
              </Button>
            ) : (
              <SendMessageDialog
                onSetMessage={(message) =>
                  onHtmlSave(
                    false,
                    true,
                    undefined,
                    undefined,
                    undefined,
                    message
                  )
                }
                description="Add your note to the reviewer of the article."
                saveButtonLabel="Send to Review"
                textAreaProps={{
                  placeholder: 'Add your note to the reviewer of the article.'
                }}
                dialogProps={{
                  title: 'Send to review',
                  trigger: (
                    <Button data-cy="sendToReviewerButton" variant="primary">
                      Send to Reviewer
                    </Button>
                  )
                }}
              ></SendMessageDialog>
            )}
          </>
        )
      )}
    </Box>
  )
}
