import { styled } from '../../../stitches.config'
import { ComponentProps } from 'react'
import { Spinner } from '../spinner'

const smallButtonStyles = {
  fontSize: '$button1',
  padding: '0.4375rem 1rem'
}

const mediumButtonStyles = {
  fontSize: '$button1',
  padding: '0.625rem 1.46875rem'
}

const largeButtonStyles = {
  fontSize: '$button1Lg',
  padding: '0.875rem 1.5rem'
}

export const Button = styled('button', {
  boxSizing: 'border-box',
  d: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '0.5rem',
  borderRadius: '$3',
  border: 'none',
  cursor: 'pointer',
  lineHeight: '$12',
  fontWeight: '$bold',
  boxShadow: '$default',
  transition: '$default',
  overflowX: 'hidden',
  maxWidth: '100%',

  '&.only-icon': {
    padding: '0.5625rem',
    height: 'auto',
    borderRadius: '$2',

    '&.round': {
      borderRadius: '$round'
    }
  },

  '& .icon': {
    color: '$white',
    d: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '1rem',
    height: '1rem',

    svg: {
      transition: '$default',
      g: {
        transition: '$default'
      }
    }
  },

  '& .icon-left': {
    mr: '0.9rem'
  },

  '& .icon-right': {
    ml: '0.9rem'
  },

  '&:disabled': {
    cursor: 'not-allowed'
  },
  '&.__small': smallButtonStyles,
  '&.__medium': smallButtonStyles,
  '&.__large': largeButtonStyles,
  variants: {
    variant: {
      primary: {
        color: '$white',
        backgroundColor: '$primaryBlue',
        '&:hover:not(:disabled), &.__hover:not(:disabled)': {
          backgroundColor: '$primaryBlueDarken30'
        },
        '&:focus:not(:disabled), &.__focus:not(:disabled)': {
          backgroundColor: '$primaryBlueDarken30',
          outline: '2px $primaryBlueLighten30 solid'
        },
        '&:active, &.__active': {
          backgroundColor: '$neutralLighten10'
        },
        '&:disabled': {
          color: '$neutralLighten60',
          backgroundColor: '$neutralLighten90',
          cursor: 'auto',
          '& .icon': {
            color: '$neutralLighten60'
          }
        },
        '& .icon': {
          color: '$white',
          lineHeight: '10px'
        }
      },
      secondary: {
        color: '$neutral',
        border: '1px solid $neutralLighten80',
        backgroundColor: '$white',

        '&:hover:not(:disabled), &.__hover:not(:disabled)': {
          color: '$primaryBlue',
          backgroundColor: '$primaryBlueLighten90',
          border: '1px solid $primaryBlueLighten30',
          '& .icon': {
            color: '$primaryBlue'
          }
        },
        '&:focus:not(:disabled), &.__focus:not(:disabled)': {
          outline: '2px $primaryBlueLighten30 solid'
        },
        '&:active, &.__active': {
          color: '$neutral',
          border: '1px solid $neutralLighten10'
        },
        '&:disabled': {
          color: '$neutralLighten60',
          '& .icon': {
            color: '$neutralLighten60'
          }
        },
        '& .icon': {
          color: '$neutral',
          lineHeight: '10px'
        }
      },
      'primary-destructive': {
        color: '$white',
        border: '1px solid $error',
        backgroundColor: '$error',

        '&:hover:not(:disabled), &.__hover:not(:disabled)': {
          color: '$white',
          backgroundColor: '$destructiveHover',
          border: '1px solid $destructiveHover'
        },
        '&:focus:not(:disabled), &.__focus:not(:disabled)': {
          backgroundColor: '$error',
          outline: '2px $errorLighten80 solid'
        },

        '&:active, &.__active': {
          color: '$neutralLighten93',
          backgroundColor: '$destructivePressed',
          border: '1px solid $destructivePressed'
        },
        '&:disabled': {
          color: '$neutralLighten60',
          backgroundColor: '$destructiveDisabled',
          border: '1px solid $destructiveDisabled'
        }
      },
      'secondary-destructive': {
        color: '$neutral',
        border: '1px solid $neutralLighten80',
        backgroundColor: '$white',

        '&:hover:not(:disabled), &.__hover:not(:disabled)': {
          color: '$destructiveHover',
          backgroundColor: '$errorLighten90',
          border: '1px solid $destructiveHover'
        },
        '&:focus:not(:disabled), &.__focus:not(:disabled)': {
          backgroundColor: '$white',
          border: '1px solid $destructiveHover',
          outline: '2px $errorLighten80 solid'
        },

        '&:active, &.__active': {
          color: '$neutral',
          backgroundColor: '$white',
          border: '1px solid $neutralLighten10'
        },
        '&:disabled': {
          color: '$neutralLighten60',
          backgroundColor: '$white',
          border: '1px solid $neutralLighten80'
        }
      },
      'secondary-inverted': {
        border: '1px solid $white',
        boxShadow: '0 2px 4px 0 rgba(0,25,42,0.08)',
        backgroundColor: 'rgba(0, 0,0, 0.5)',

        '&:hover:not(:disabled), &.__hover:not(:disabled), &:active, &.__active':
          {
            backgroundColor: 'rgba(0, 0,0, 0.6)'
          },
        '&:focus:not(:disabled), &.__focus:not(:disabled)': {
          outline: '2px $primaryBlueLighten30 solid'
        },
        '&:disabled': {
          color: '$neutralLighten60',
          '& .icon': {
            color: '$neutralLighten60'
          }
        },
        '& .icon': {
          lineHeight: '10px',
          color: '$white'
        }
      },
      transparent: {}
    },
    size: {
      small: smallButtonStyles,
      medium: mediumButtonStyles,
      large: largeButtonStyles
    },
    icon: {
      true: {
        padding: '0.5625rem',
        height: 'auto',
        borderRadius: '$2'
      },
      false: {}
    },
    noBorder: {
      true: {
        borderColor: 'transparent',
        boxShadow: 'none',
        '&:hover:not(:disabled), &.__hover:not(:disabled), &:active:not(:disabled), &.__active:not(:disabled)':
          {
            borderColor: 'transparent'
          },
        '&:focus:not(:disabled), &.__focus:not(:disabled)': {
          borderColor: 'transparent'
        }
      },
      false: {}
    }
  },

  defaultVariants: {
    variant: 'primary',
    size: 'medium',
    icon: false
  }
})

const FloatingButtonStyled = styled('button', {
  boxSizing: 'border-box',
  d: 'flex',
  gap: '$2',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  cursor: 'pointer',
  color: '$white',
  backgroundColor: '$primaryBlue',
  borderRadius: 32,
  padding: '$5',
  fontSize: '$button1',
  fontWeight: 'bold',
  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.16)',
  '& .button-text': {
    d: 'none'
  },
  '@media (hover: hover) and (pointer: fine)': {
    '&:hover': {
      '& .button-text': {
        d: 'block'
      }
    }
  }
})
const PlusIcon = styled('span', {
  fontSize: 36,
  lineHeight: '24px',
  fontWeight: '$light'
})

export function FloatingButton(
  props: ComponentProps<typeof FloatingButtonStyled>
) {
  return (
    <FloatingButtonStyled {...props}>
      <PlusIcon>+</PlusIcon>
      <span className="button-text">{props.children}</span>
    </FloatingButtonStyled>
  )
}

export const ButtonWithLoading = ({
  children,
  loading,
  ...rest
}: { loading?: boolean } & ComponentProps<typeof Button>) => {
  return (
    <Button css={{ px: '$9', position: 'relative' }} {...rest} icon>
      {loading && (
        <Spinner
          css={{
            position: 'absolute',
            left: '$3',
            '& circle': {
              stroke: '$white'
            }
          }}
          size="small"
        />
      )}
      {children}
    </Button>
  )
}
