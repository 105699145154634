import useSWRImmutable from 'swr/immutable'
import { API_DOMAIN, fetcherFullRequest, init } from './common'

export function useCountries() {
  const { data, error } = useSWRImmutable(
    [`${API_DOMAIN}/news_api/countries`, init],
    fetcherFullRequest
  )

  return {
    countries: data && Object.values(data),
    isLoading: !error && !data,
    isError: error
  }
}
