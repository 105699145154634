import {
  Box,
  Button,
  Container,
  ContainerPadding,
  Heading1,
  ReactSelect,
  SelectOption,
  Space,
  Tabs,
  TabsList,
  TabsTrigger,
  Heading5,
  ToastTypeEnum,
  useToastContext,
  useGlobalSpinner,
  FilterUnreadIcon16,
  CheckmarkIcon16,
  TabsBadge
} from '@sefar/design-system'
import {
  changeNotificationReadStatus,
  useNotifications,
  NotificationType,
  SearchEntityTypes,
  Notification
} from '../../api'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { NotificationCard } from './notification-card'
import { useMedia, useUpdateEffect } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { isLast7Days } from '../../components'
import { searchParamsNames } from '../my-content/my-content-filters'
import { useConfigStore } from '../../state/config'
import { useTranslate } from '../../hooks/useTranslate'

export function Notifications() {
  const { t } = useTranslate()
  const { pendingMessagesCount } = useConfigStore()
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const setGlobalSpinner = useGlobalSpinner()
  const setToast = useToastContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const { notifications: all, isLoading, mutate } = useNotifications()
  const [notifications, setNotifications] = useState<Notification[]>(all)
  const NOTIFICATION_TYPES = [
    { label: 'All notification types', value: NotificationType.All },
    {
      label: t('field_notice_type_comment'),
      value: NotificationType.Comment
    },
    {
      label: t('field_notice_type_reply'),
      value: NotificationType.Reply
    },
    { label: t('field_notice_type_like'), value: NotificationType.Like },
    { label: t('field_notice_type_idea'), value: NotificationType.Idea },
    {
      label: t('field_notice_type_idea_status'),
      value: NotificationType.IdeaStatus
    },
    {
      label: t('field_notice_type_assigned'),
      value: NotificationType.IdeaAssigned
    }
  ]
  useEffect(() => {
    setNotifications(
      all?.sort(
        (a, b) => new Date(b?.date).getTime() - new Date(a?.date).getTime()
      )
    )
  }, [all?.length])

  useEffect(() => {
    setGlobalSpinner({ show: isLoading })
  }, [isLoading])

  useUpdateEffect(() => {
    mutate()
  }, [pendingMessagesCount])

  const markAsRead = (notification: Notification) =>
    notification.unread &&
    changeNotificationReadStatus(notification.id, notification.user.nid, true)
      .then(() =>
        setNotifications((oldValue) =>
          oldValue.map((n) =>
            notification.id === n.id ? { ...n, unread: false } : n
          )
        )
      )
      .catch(() => {
        setToast({
          message: t('field_error_common_toast_text'),
          type: ToastTypeEnum.error
        })
      })

  const markAllAsRead = () => {
    setGlobalSpinner({ show: true })

    Promise.all(
      notifications.filter((n) => n.unread).map((n) => markAsRead(n))
    ).finally(() => setGlobalSpinner({ show: false }))
  }

  const filterNotifications = (
    notifications: Notification[] = [],
    onlyLast7Days: boolean
  ): Notification[] => {
    const articleType = searchParams.get('type')
    const type = searchParams.get('nType')
    const unreadOnly = +searchParams.get(searchParamsNames.unread)

    return notifications?.filter(
      (notification: Notification) =>
        (!articleType || notification?.entity?.type === articleType) &&
        (!type || notification.type === type) &&
        (!unreadOnly || notification.unread) &&
        onlyLast7Days === isLast7Days(notification.date)
    )
  }

  const filterUnreadByEntityType = (
    notifications: Notification[] = [],
    type: SearchEntityTypes
  ) => {
    return notifications?.filter(
      (notification: Notification) =>
        notification.unread && (!type || notification?.entity?.type === type)
    )
  }

  const NotificationUnreadEntityCounterBadge = (type: SearchEntityTypes) =>
    filterUnreadByEntityType(notifications, type).length ? (
      <TabsBadge>
        {filterUnreadByEntityType(notifications, type).length}
      </TabsBadge>
    ) : (
      <></>
    )

  return (
    <Tabs
      onValueChange={(value) => {
        setSearchParams({ type: value })
      }}
      value={searchParams.get('type') || SearchEntityTypes.All}
      css={{ flex: 1, height: '100%' }}
    >
      <Space
        pt="LG"
        css={{
          backgroundColor: '$neutralLighten97',
          borderBottom: '1px $neutralLighten90 solid'
        }}
      >
        <ContainerPadding>
          <Container>
            <Space mb="SM">
              <Heading1>{t('field_notice')}</Heading1>
            </Space>

            <TabsList aria-label="Search entity types">
              <TabsTrigger value={SearchEntityTypes.All}>
                {t('field_all')}
                {NotificationUnreadEntityCounterBadge(SearchEntityTypes.All)}
              </TabsTrigger>
              <TabsTrigger value={SearchEntityTypes.News}>
                {t('field_news')}
                {NotificationUnreadEntityCounterBadge(SearchEntityTypes.News)}
              </TabsTrigger>
              <TabsTrigger value={SearchEntityTypes.Spaces}>
                {t('field_spaces')}
                {NotificationUnreadEntityCounterBadge(SearchEntityTypes.Spaces)}
              </TabsTrigger>
              <TabsTrigger value={SearchEntityTypes.Ideas}>
                {t('field_ideas')}
                {NotificationUnreadEntityCounterBadge(SearchEntityTypes.Ideas)}
              </TabsTrigger>
            </TabsList>
          </Container>
        </ContainerPadding>
      </Space>

      <Container
        css={{
          flexGrow: 1,
          d: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Space
          css={{
            d: 'flex',
            gap: '$2',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
          mt="XL"
          as={!isLg && ContainerPadding}
        >
          <Box css={{ order: 1, '@sm': { flexGrow: 1 } }}>
            <ReactSelect
              value={
                NOTIFICATION_TYPES.find(
                  ({ value }) => searchParams.get('nType') === value
                ) || NOTIFICATION_TYPES[0]
              }
              options={NOTIFICATION_TYPES}
              onChange={(option: SelectOption) => {
                searchParams.set('nType', option.value)
                setSearchParams(searchParams)
              }}
              placeholder="Types"
            />
          </Box>

          {notifications?.filter((n) => n.unread)?.length ? (
            <>
              <Button
                css={{
                  ml: 'auto',
                  order: 2,
                  '@sm': { ml: 'initial', order: 3 }
                }}
                variant="secondary"
                onClick={() => markAllAsRead()}
              >
                <Box as="span" className="icon icon-left">
                  <CheckmarkIcon16 />
                </Box>
                {t('field_notice_mark_all_as_read')}
              </Button>
              <Button
                css={{
                  order: 3,
                  '@sm': { order: 2 },
                  whiteSpace: 'nowrap',
                  '&:hover:not(:disabled), &.__active': {
                    borderColor: '$neutralLighten10',
                    backgroundColor: 'initial',
                    color: '$neutral',
                    '& .icon': {
                      color: '$neutral'
                    }
                  }
                }}
                className={`${
                  searchParams.get(searchParamsNames.unread) === '1'
                    ? '__active'
                    : ''
                } ${isLg ? '' : 'only-icon'}`}
                variant={'secondary'}
                onClick={() => {
                  searchParams.set(
                    searchParamsNames.unread,
                    (+searchParams.get(searchParamsNames.unread) + 1) % 2
                  )
                  setSearchParams(searchParams)
                }}
              >
                <Box as="span" className={`icon ${isLg ? 'icon-left' : ''}`}>
                  <FilterUnreadIcon16 />
                </Box>
                {isLg ? t('field_notice_only_unread') : ''}
              </Button>
            </>
          ) : (
            <></>
          )}
        </Space>

        {filterNotifications(notifications, true)?.length ? (
          <>
            <Space mt="LG" mb="SM" as={!isLg && ContainerPadding}>
              <Heading5>{t('field_notice_last_seven')}</Heading5>
            </Space>

            {filterNotifications(notifications, true).map(
              (notification: Notification) => (
                <NotificationCard
                  key={notification.id}
                  changeReadStatus={() => markAsRead(notification)}
                  {...notification}
                ></NotificationCard>
              )
            )}
          </>
        ) : (
          <></>
        )}

        {filterNotifications(notifications, false)?.length ? (
          <>
            <Space mt="LG" mb="SM" as={!isLg && ContainerPadding}>
              <Heading5>{t('field_notice_older')}</Heading5>
            </Space>

            {filterNotifications(notifications, false).map(
              (notification: Notification) => (
                <NotificationCard
                  key={notification.id}
                  changeReadStatus={() => markAsRead(notification)}
                  {...notification}
                ></NotificationCard>
              )
            )}
          </>
        ) : (
          <></>
        )}
      </Container>
    </Tabs>
  )
}
