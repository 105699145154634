import { MediaAssetsTypes } from '../../api'

export const getSubCategoriesByCategory = (
  categories: any,
  contentType: keyof typeof MediaAssetsTypes,
  category: string
) => {
  return categories
    ?.find(
      ({ title }: { title: string }) =>
        title === MediaAssetsTypes[contentType].key
    )
    ?.children?.find(({ title }: { title: string }) => title === category)
    ?.children?.map(({ uuid: value, title: label }) => ({ label, value }))
}

export const getCountryListCategories = (categories: any) => {
  return categories
    ?.find(({ title }: { title: string }) => title === 'Country')
    .children?.map(({ uuid: value, title: label }) => ({ label, value }))
}
