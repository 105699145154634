export const formatFileName = (fileName: string) => {
  const initialFileNameChunks = fileName.split('.')
  const fileNameExtension =
    initialFileNameChunks[initialFileNameChunks.length - 1]
  const fileNameWithoutExtension = initialFileNameChunks
    .slice(0, initialFileNameChunks.length - 1)
    .join('.')
  const timestamp = new Date().getTime()

  return `${fileNameWithoutExtension}_${timestamp}.${fileNameExtension}`
}
