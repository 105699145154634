import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Badge,
  Box,
  Body5,
  Body7,
  Button,
  DropdownMenu,
  DropdownMenuItem,
  EllipsisHorizontalIcon20,
  FeedbackIcon16,
  Tooltip,
  Spinner
} from '@sefar/design-system'
import { CommentsDialog } from './dialogs/comments-dialog'
import {
  purchaseRequisitionStatusesBadgeColors,
  purchaseRequisitionStatusesLabels,
  PurchaseRequisitionStatusType
} from '../consts'
import { PurchaseRequisitionOrder } from '../create/types'
import { TD, TR } from '../../my-content/common'
import { useMe, useUserByEmployeeId } from '../../../api'
import { purchaseRequisitionsPageUrl } from '../../../app'
import {
  formatDateOfficial,
  getMaxDateString,
  getTime
} from '../../../components'
import { UserAvatar } from '../../../components/common/user-avatar'
import { useTranslate } from '../../../hooks/useTranslate'

export const OrdersTableItem = ({
  order,
  onDelete,
  onStartApproval,
  onApprove,
  onReject,
  onToBeReviewed
}: {
  order: PurchaseRequisitionOrder
  onDelete: (id: string) => void
  onStartApproval: (id: string) => void
  onApprove: (id: string) => void
  onReject: (id: string) => void
  onToBeReviewed: (id: string) => void
}) => {
  const [showComments, setShowComments] = useState(false)

  const {
    id,
    createdDate,
    purchaseRequisitionItems,
    buyerInternalContact,
    approver,
    requester,
    status,
    comments
  } = order
  const { user: requesterData, isLoading: isLoadingRequester } =
    useUserByEmployeeId(requester)
  const { user: approverData, isLoading: isLoadingApprover } =
    useUserByEmployeeId(approver)
  const supplier = purchaseRequisitionItems?.[0]?.supplier
  const purchaseRequisitionStatus = status as PurchaseRequisitionStatusType
  const itemConfirmedDeliveryDates = purchaseRequisitionItems?.map(
    (purchaseRequisitionItem) =>
      purchaseRequisitionItem.changedDeliveryDate ??
      purchaseRequisitionItem.confirmedDeliveryDate
  )
  const latestDeliveryDate = getMaxDateString(itemConfirmedDeliveryDates)

  const { t } = useTranslate()
  const navigate = useNavigate()
  const { me } = useMe()
  const currentEmployeeId = me?.employeeid
  const isCurrentUserAdmin =
    me?.roles?.length && me.roles.includes('pr_administrator')

  const isCurrentUserRequester =
    (requesterData?.employeeid &&
      currentEmployeeId === requesterData?.employeeid) ||
    isCurrentUserAdmin
  const isCurrentUserApprover =
    (approverData?.employeeid &&
      currentEmployeeId === approverData?.employeeid) ||
    isCurrentUserAdmin

  const isCurrentUserAuthor =
    (order?.author && currentEmployeeId === order?.author) || isCurrentUserAdmin

  const isRowClickable = isCurrentUserApprover || isCurrentUserRequester

  const onRowClick = () => {
    if (isRowClickable) {
      navigate(`/${purchaseRequisitionsPageUrl}/${id}/overview`)
    }
  }
  const showRequesterEditAction =
    (isCurrentUserRequester || isCurrentUserAuthor) &&
    [
      PurchaseRequisitionStatusType.Draft,
      PurchaseRequisitionStatusType.Rework
    ].includes(purchaseRequisitionStatus)
  const showRequesterDeleteAction =
    (isCurrentUserRequester || isCurrentUserAuthor) &&
    [
      PurchaseRequisitionStatusType.Draft,
      PurchaseRequisitionStatusType.Created,
      PurchaseRequisitionStatusType.Rework
    ].includes(purchaseRequisitionStatus)
  const showStartApprovalAction =
    (isCurrentUserRequester || isCurrentUserAuthor) &&
    [PurchaseRequisitionStatusType.Draft].includes(purchaseRequisitionStatus)
  const showApproverActions =
    isCurrentUserApprover &&
    [
      PurchaseRequisitionStatusType.Created,
      PurchaseRequisitionStatusType.Rework
    ].includes(purchaseRequisitionStatus)
  const showToBeReviewedAction =
    isCurrentUserApprover &&
    [PurchaseRequisitionStatusType.Created].includes(purchaseRequisitionStatus)
  const showMenuButton =
    showRequesterEditAction ||
    showRequesterDeleteAction ||
    showStartApprovalAction ||
    showApproverActions ||
    showToBeReviewedAction

  return (
    <TR
      key={id}
      css={{
        borderBottom: '1px solid $neutralLighten80',
        h: '5rem',
        cursor: isRowClickable ? 'pointer' : 'default'
      }}
      onClick={onRowClick}
    >
      <TD>
        <>
          <Body5 fontWeight="bold">{supplier || '–'}</Body5>
          <Box css={{ d: 'flex', gap: '$3' }}>
            <Badge
              status={
                purchaseRequisitionStatusesBadgeColors[
                  purchaseRequisitionStatus
                ]
              }
            >
              {purchaseRequisitionStatusesLabels[purchaseRequisitionStatus]}
            </Badge>
            {!!comments?.length && (
              <>
                <Tooltip
                  contentProps={{ css: { padding: '$1' } }}
                  text={t('field_feedback')}
                  withArrow={false}
                  active={!showComments}
                >
                  <Box
                    css={{
                      d: 'flex',
                      alignItems: 'center',
                      gap: '$1',
                      cursor: 'pointer',
                      '&:hover': {
                        color: '$primaryBlue'
                      }
                    }}
                    onClick={() => setShowComments(true)}
                  >
                    <FeedbackIcon16 />
                    <Body7>{comments.length}</Body7>
                  </Box>
                </Tooltip>
                <Box onClick={(e) => e.stopPropagation()}>
                  <CommentsDialog
                    isOpen={showComments}
                    comments={comments}
                    onClose={() => setShowComments(false)}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      </TD>
      <TD>
        <Body5>{latestDeliveryDate || '–'}</Body5>
      </TD>
      <TD>
        <>
          <Body5>{createdDate ? formatDateOfficial(createdDate) : '–'}</Body5>
          <Body7>{createdDate ? getTime(createdDate) : '–'}</Body7>
        </>
      </TD>
      <TD css={{ textAlign: 'center' }}>
        {isLoadingRequester ? (
          <Spinner size="medium" />
        ) : (
          <UserAvatar user={requesterData} />
        )}
      </TD>
      <TD css={{ textAlign: 'center' }}>
        {isLoadingApprover ? (
          <Spinner size="medium" />
        ) : (
          <UserAvatar user={approverData} />
        )}
      </TD>
      <TD>{id || '–'}</TD>
      <TD>{order?.lnId || '–'}</TD>
      <TD onClick={(e) => e.stopPropagation()} css={{ cursor: 'default' }}>
        {showMenuButton && (
          <DropdownMenu
            trigger={() => (
              <Button
                icon
                noBorder
                variant="transparent"
                css={{
                  backgroundColor: 'transparent',
                  outline: 'none'
                }}
              >
                <EllipsisHorizontalIcon20 />
              </Button>
            )}
            content={(setOpen: (isOpen: boolean) => void) => (
              <>
                {showRequesterDeleteAction && (
                  <DropdownMenuItem
                    onClick={() => {
                      setOpen(false)
                      id && onDelete(id)
                    }}
                  >
                    <Body5 as="span">
                      {t('field_purchase_requisition_actions_delete')}
                    </Body5>
                  </DropdownMenuItem>
                )}
                {showRequesterEditAction && (
                  <Link
                    to={`/${purchaseRequisitionsPageUrl}/${id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <DropdownMenuItem>
                      <Body5 as="span">
                        {t('field_purchase_requisition_actions_edit')}
                      </Body5>
                    </DropdownMenuItem>
                  </Link>
                )}
                {showStartApprovalAction && (
                  <DropdownMenuItem
                    onClick={() => {
                      setOpen(false)
                      id && onStartApproval(id)
                    }}
                  >
                    <Body5 as="span">
                      {t('field_purchase_requisition_actions_start_approval')}
                    </Body5>
                  </DropdownMenuItem>
                )}
                {showApproverActions && (
                  <>
                    <DropdownMenuItem
                      onClick={() => {
                        setOpen(false)
                        id && onApprove(id)
                      }}
                    >
                      <Body5 as="span">
                        {t('field_purchase_requisition_actions_approve')}
                      </Body5>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        setOpen(false)
                        id && onReject(id)
                      }}
                    >
                      <Body5 as="span">
                        {t('field_purchase_requisition_actions_reject')}
                      </Body5>
                    </DropdownMenuItem>
                  </>
                )}
                {showToBeReviewedAction && (
                  <DropdownMenuItem
                    onClick={() => {
                      setOpen(false)
                      id && onToBeReviewed(id)
                    }}
                  >
                    <Body5 as="span">
                      {t('field_purchase_requisition_actions_to_be_reviewed')}
                    </Body5>
                  </DropdownMenuItem>
                )}
              </>
            )}
          />
        )}
      </TD>
    </TR>
  )
}
