import React, { useState } from 'react'
import {
  useToastContext,
  Box,
  Body7,
  Pagination,
  Space,
  Spinner,
  ToastTypeEnum
} from '@sefar/design-system'
import { ApproveConfirmationDialog } from './dialogs/approve-confirmation-dialog'
import { DeleteConfirmationDialog } from './dialogs/delete-confirmation-dialog'
import { RejectConfirmationDialog } from './dialogs/reject-confirmation-dialog'
import { StartApprovalConfirmationDialog } from './dialogs/start-approval-confirmation-dialog'
import { ToBeReviewedConfirmationDialog } from './dialogs/to-be-reviewed-confirmation-dialog'
import { OrdersTableItem } from './orders-table-item'
import { purchaseApi } from '../api'
import {
  PURCHASE_REQUISITIONS_LIST_PAGE_SIZE,
  PurchaseRequisitionStatusType
} from '../consts'
import { PurchaseRequisitionOrder } from '../create/types'
import { Table, TH, TR } from '../../my-content/common'
import { useMe } from '../../../api'
import { useTranslate } from '../../../hooks/useTranslate'
import { useConfigStore } from '../../../state/config'

export const OrdersTable = ({
  orders,
  amount,
  currentPage,
  onChangePage,
  refreshData,
  isLoading = false
}: {
  orders: PurchaseRequisitionOrder[]
  amount: number
  currentPage: number
  onChangePage: (page: number) => void
  refreshData: () => void
  isLoading?: boolean
}) => {
  const setToast = useToastContext()
  const { t } = useTranslate()
  const { translations } = useConfigStore()

  const { me } = useMe()
  const currentEmployeeId = me?.employeeid

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<string>()
  const [startApprovalConfirmationOpen, setStartApprovalConfirmationOpen] =
    useState<string>()
  const [approveConfirmationOpen, setApproveConfirmationOpen] =
    useState<string>()
  const [rejectConfirmationOpen, setRejectConfirmationOpen] = useState<string>()
  const [toBeReviewedConfirmationOpen, setToBeReviewedConfirmationOpen] =
    useState<string>()

  const onConfirmDeletion = async () => {
    if (deleteConfirmationOpen) {
      try {
        await purchaseApi.deletePurchaseRequisition(deleteConfirmationOpen)
        refreshData()
      } catch (e) {
        setToast({
          message: t('field_error_common_toast_text'),
          type: ToastTypeEnum.error
        })
      }
    }
  }

  const onSendForReview = async () => {
    if (startApprovalConfirmationOpen) {
      try {
        await purchaseApi.changeStatusOrderRequest(
          startApprovalConfirmationOpen,
          PurchaseRequisitionStatusType.Created
        )
        refreshData()
      } catch (e) {
        setToast({
          message: t('field_error_common_toast_text'),
          type: ToastTypeEnum.error
        })
      }
    }
  }

  const onApprove = async (comment: string) => {
    if (approveConfirmationOpen) {
      try {
        const orderOldComments = orders.find(
          (order) => order.id === approveConfirmationOpen
        )?.comments
        await purchaseApi.changeStatusOrderRequest(
          approveConfirmationOpen,
          PurchaseRequisitionStatusType.Approved,
          orderOldComments,
          comment,
          currentEmployeeId
        )
        refreshData()
      } catch (e) {
        setToast({
          message: t('field_error_common_toast_text'),
          type: ToastTypeEnum.error
        })
      }
    }
  }

  const onReject = async (comment: string) => {
    if (rejectConfirmationOpen) {
      try {
        const orderOldComments = orders.find(
          (order) => order.id === rejectConfirmationOpen
        )?.comments
        await purchaseApi.changeStatusOrderRequest(
          rejectConfirmationOpen,
          PurchaseRequisitionStatusType.Rejected,
          orderOldComments,
          comment,
          currentEmployeeId
        )
        refreshData()
      } catch (e) {
        setToast({
          message: t('field_error_common_toast_text'),
          type: ToastTypeEnum.error
        })
      }
    }
  }

  const onToBeReviewed = async (comment: string) => {
    if (toBeReviewedConfirmationOpen) {
      try {
        const orderOldComments = orders.find(
          (order) => order.id === toBeReviewedConfirmationOpen
        )?.comments
        await purchaseApi.changeStatusOrderRequest(
          toBeReviewedConfirmationOpen,
          PurchaseRequisitionStatusType.Rework,
          orderOldComments,
          comment,
          currentEmployeeId
        )
        refreshData()
      } catch (e) {
        setToast({
          message: t('field_error_common_toast_text'),
          type: ToastTypeEnum.error
        })
      }
    }
  }

  if (isLoading) {
    return <Spinner css={{ my: '$4', h: '3.375rem' }} />
  }

  return (
    <Box css={{ maxWidth: '100%' }}>
      <Box css={{ overflowX: 'auto', pb: '$10' }}>
        <Table css={{ borderCollapse: 'collapse' }}>
          <thead>
            <TR header>
              <TH css={{ width: 296 }}>
                <Body7>Supplier</Body7>
              </TH>
              <TH css={{ width: 172 }}>
                <Body7>Latest delivery date</Body7>
              </TH>
              <TH css={{ width: 172 }}>
                <Body7>Submitted on</Body7>
              </TH>
              <TH css={{ width: 8 }}>
                <Body7>Requester</Body7>
              </TH>
              <TH css={{ width: 8 }}>
                <Body7>Approver</Body7>
              </TH>
              <TH css={{ width: 88 }}>
                <Body7>ID</Body7>
              </TH>
              <TH css={{ width: 88 }}>
                <Body7>Purchase req order</Body7>
              </TH>
              <TH css={{ width: 88 }} />
            </TR>
          </thead>
          <tbody>
            {orders.map((order) => (
              <OrdersTableItem
                key={order.id}
                order={order}
                onDelete={setDeleteConfirmationOpen}
                onStartApproval={setStartApprovalConfirmationOpen}
                onApprove={setApproveConfirmationOpen}
                onReject={setRejectConfirmationOpen}
                onToBeReviewed={setToBeReviewedConfirmationOpen}
              />
            ))}
          </tbody>
        </Table>
        <Space mt="LG" css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Pagination
            currentPage={currentPage}
            count={amount}
            limit={PURCHASE_REQUISITIONS_LIST_PAGE_SIZE}
            onChange={(value) => {
              onChangePage(value)
            }}
            translations={translations}
          />
        </Space>
      </Box>
      <DeleteConfirmationDialog
        isOpen={!!deleteConfirmationOpen}
        onDelete={onConfirmDeletion}
        onClose={() => setDeleteConfirmationOpen(undefined)}
      />
      <StartApprovalConfirmationDialog
        isOpen={!!startApprovalConfirmationOpen}
        onSubmit={onSendForReview}
        onClose={() => setStartApprovalConfirmationOpen(undefined)}
      />
      <ApproveConfirmationDialog
        isOpen={!!approveConfirmationOpen}
        onSubmit={onApprove}
        onClose={() => setApproveConfirmationOpen(undefined)}
      />
      <RejectConfirmationDialog
        isOpen={!!rejectConfirmationOpen}
        onSubmit={onReject}
        onClose={() => setRejectConfirmationOpen(undefined)}
      />
      <ToBeReviewedConfirmationDialog
        isOpen={!!toBeReviewedConfirmationOpen}
        onSubmit={onToBeReviewed}
        onClose={() => setToBeReviewedConfirmationOpen(undefined)}
      />
    </Box>
  )
}
