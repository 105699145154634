import React, { useState } from 'react'
import { Language, useLanguages } from '../../api'
import {
  Body5,
  Box,
  Button,
  Dialog,
  DialogClose,
  RadioGroup,
  RadioItem,
  Space,
  TextLink
} from '@sefar/design-system'

export function LanguageDialog({
  selectedLang,
  onUpdate
}: {
  selectedLang: string
  onUpdate: (lang: string) => void
}) {
  const [dialogLang, setDialogLang] = useState<string>(selectedLang)
  const { languages } = useLanguages()

  return (
    <Dialog
      title="Language"
      trigger={<TextLink>{selectedLang ? 'Edit' : 'Select'}</TextLink>}
      footer={
        <Space css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => onUpdate(dialogLang)} as={DialogClose}>
            Save
          </Button>
        </Space>
      }
      onOpenChange={() => setDialogLang(selectedLang)}
    >
      <Space
        css={{
          maxHeight: '100%',
          pr: '$4',
          mb: '$4',
          color: '$neutral'
        }}
      >
        <Body5>In which language are you writing your article?</Body5>
      </Space>

      <RadioGroup
        id="article language"
        value={dialogLang}
        onValueChange={setDialogLang}
        aria-label="article language"
        css={{ d: 'flex', gap: '$2' }}
      >
        <Box
          css={{
            d: 'grid',
            gtc: 2,
            rowGap: '$4',
            columnGap: '$12',
            width: '100%',
            '@md': { gtc: 3 }
          }}
        >
          {languages?.map(({ id, labelEng }: Language) => (
            <RadioItem key={id} id={id} value={id} label={labelEng} />
          ))}
        </Box>
      </RadioGroup>
    </Dialog>
  )
}
