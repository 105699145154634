import { styled } from '../../../stitches.config'

export const TextLinkStyles = {
  color: '$primaryBlue',
  transition: '$default',
  fontSize: '$button2Xl',
  lineHeight: '$12',
  cursor: 'pointer',
  d: 'inline-flex',
  alignItems: 'center',
  borderBottom: '1px solid transparent',
  fontWeight: '$bold',
  textDecoration: 'none',

  '&:hover, &.__hover': {
    borderBottom: '1px solid $primaryBlueDarken30'
  },

  '&.no-underline': {
    borderBottom: 'none'
  },

  '&:hover, &.__hover, &:focus, &.__focus, &:active, &.__active': {
    color: '$primaryBlueDarken30',

    '& .icon': {
      svg: {
        g: {
          fill: '$primaryBlueDarken30'
        },
        fill: '$primaryBlueDarken30',
        path: {
          fill: '$primaryBlueDarken30'
        }
      }
    }
  },

  '&.disabled': {
    color: '$neutralLighten60',
    pointerEvents: 'none',

    '& .icon': {
      svg: {
        g: {
          fill: '$neutralLighten60'
        },
        fill: '$neutralLighten60',
        path: {
          fill: '$neutralLighten60'
        }
      }
    }
  },

  '& .icon': {
    color: '$primaryBlue',
    d: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1px',

    svg: {
      transition: '$default',
      fill: '$primaryBlue',
      g: {
        transition: '$default',
        fill: '$primaryBlue'
      },
      path: {
        transition: '$default',
        fill: '$primaryBlue'
      }
    }
  },

  '& .icon-left': {
    mr: '0.5rem'
  },

  '& .icon-right': {
    ml: '0.5rem'
  },

  variants: {
    variant: {
      inline: {
        fontWeight: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit'
      },
      small: {
        fontSize: '$body6',
        lineHeight: '$14'
      },
      neutral: {
        color: '$neutral',
        lineHeight: '$14',

        '&:hover, &.__hover': {
          borderBottom: '1px solid $neutral'
        },

        '&:hover, &.__hover, &:focus, &.__focus, &:active, &.__active': {
          color: '$neutral'
        },

        '& .icon': {
          svg: {
            g: {
              fill: '$neutral'
            },
            fill: '$neutral',
            path: {
              fill: '$neutral'
            }
          }
        }
      }
    }
  }
}

export const TextLink = styled('a', TextLinkStyles)
