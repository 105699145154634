import { useConfigStore } from '../../state/config'
import {
  addTranslations,
  isTrashLanguage,
  Language,
  useLanguages
} from '../../api'
import React, { useEffect, useState } from 'react'
import {
  Body5,
  Box,
  Button,
  Checkbox,
  Dialog,
  Grid,
  Line,
  RadioGroup,
  RadioItem,
  Space,
  ToastTypeEnum,
  useToastContext
} from '@sefar/design-system'

export function TranslateDialog({
  article,
  onTranslate,
  openDialog,
  setOpenDialog
}) {
  const { contentLang: contentLangConfig } = useConfigStore()
  const originalLang: string =
    article &&
    (Object.values(article).filter(
      (article) => !isTrashLanguage(article?.content_translation_source)
    )[0]?.content_translation_source ||
      Object.entries(article).find(([, article]) =>
        isTrashLanguage(article?.content_translation_source)
      )?.[0] ||
      contentLangConfig)
  const setToast = useToastContext()
  const { languages } = useLanguages()
  const [requestedTranslations, setRequestedTranslations] = useState()
  const [id, setId] = useState<string>()

  useEffect(() => {
    if (article) {
      setRequestedTranslations(
        languages?.reduce((res, language: Language) => {
          res[language.id] = !!article?.[language.id]
          return res
        }, {})
      )

      setId(Object.values(article)[0].drupal_internal__nid)
    }
  }, [article])

  const createTranslations = (publish: boolean) => {
    const automatic = languages
      .filter(
        (language: Language) =>
          requestedTranslations[language.id] === 'automatic'
      )
      .map((language: Language) => language.id)
    const manual = languages
      .filter(
        (language: Language) => requestedTranslations[language.id] === 'manual'
      )
      .map((language: Language) => language.id)

    addTranslations(id, automatic, manual, publish)
      .then(() => {
        onTranslate && onTranslate()
        setToast({
          message: `Translation${
            automatic.length + manual.length > 1 ? 's' : ''
          } created successfully!`,
          type: ToastTypeEnum.success
        })
        setOpenDialog(false)
      })
      .catch(() => {
        setToast({
          message: 'Some error occurred. Please try again later!',
          type: ToastTypeEnum.error
        })
      })
  }

  return (
    <Dialog
      title="Translations"
      setOpenControlledDialog={setOpenDialog}
      open={openDialog}
      footer={
        <Space css={{ d: 'flex', justifyContent: 'flex-end', gap: '$3' }}>
          <Button
            onClick={() => createTranslations(false)}
            disabled={
              requestedTranslations &&
              Object.values(requestedTranslations).every(
                (v) => v === true || v === false
              )
            }
          >
            Create translations
          </Button>
        </Space>
      }
      contentProps={{ css: { maxWidth: 640 } }}
    >
      <Space
        css={{
          maxHeight: '100%',
          pr: '$4',
          color: '$neutral'
        }}
      >
        <Body5>
          Please choose all the languages you want your article to be
          automatically translated to or create a manual translation for.
        </Body5>
        <Line pb="MD" />
        <Box css={{ overflowY: 'auto' }}>
          {languages?.map((language: Language) => {
            const existingTranslation = article?.[language.id]
            return (
              <Box
                key={language.id}
                css={{
                  d: 'flex',
                  gap: '$3',
                  borderBottom: '1px solid $neutralLighten90',
                  py: '$4',
                  px: '$1'
                }}
              >
                <Grid css={{ alignItems: 'center', rowGap: '$4' }}>
                  <Box
                    css={{
                      gc: existingTranslation ? '1 / span 7' : '1 / span 12',
                      gcMd: '1 / span 5',
                      d: 'flex',
                      gap: '$2'
                    }}
                  >
                    {existingTranslation ? (
                      <Checkbox checked={true} disabled />
                    ) : (
                      <Checkbox
                        onCheckedChange={(value) =>
                          setRequestedTranslations((prevState) => ({
                            ...prevState,
                            [language.id]: value
                          }))
                        }
                        checked={!!requestedTranslations?.[language.id]}
                      />
                    )}
                    <Body5>
                      {language.label} - {language.labelEng}
                      {originalLang === language.id ? ' (Original)' : ''}
                    </Body5>
                  </Box>
                  {existingTranslation ? (
                    <Body5 css={{ gc: '8 / span 5', gcMd: '6 / span 8' }}>
                      {originalLang === language.id
                        ? 'manual'
                        : existingTranslation.field_translation_type ||
                          'automatic'}
                    </Body5>
                  ) : (
                    requestedTranslations?.[language.id] && (
                      <Box css={{ gc: '1 / span 12', gcMd: '6 / span 8' }}>
                        <RadioGroup
                          id={`${id}${language.id}`}
                          onValueChange={(value) =>
                            setRequestedTranslations((prevState) => ({
                              ...prevState,
                              [language.id]: value
                            }))
                          }
                          aria-label="Translation type"
                          css={{ d: 'flex', gap: '$2' }}
                        >
                          <RadioItem
                            value="manual"
                            id={`manual${id}${language.id}`}
                            label="Manual"
                          ></RadioItem>
                          <RadioItem
                            value="automatic"
                            id={`automatic${id}${language.id}`}
                            label="Automatical"
                          ></RadioItem>
                        </RadioGroup>
                      </Box>
                    )
                  )}
                </Grid>
              </Box>
            )
          })}
        </Box>
      </Space>
    </Dialog>
  )
}
