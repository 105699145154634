import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, CloseIcon20 } from '@sefar/design-system'
import { CloseConfirmationDialog } from './close-confirmation-dialog'
import { purchaseRequisitionsPageUrl } from '../../../../app'
import { useOrderState } from '../hooks/use-order-state'

export const Heading = ({
  noConfirmation = false
}: {
  noConfirmation?: boolean
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const { order } = useOrderState()
  const onCloseButtonClick = () => {
    if (noConfirmation) {
      navigate(`/${purchaseRequisitionsPageUrl}`)
    } else {
      setIsConfirmationOpen(true)
    }
  }

  return (
    <Box css={{ h: '5rem', zIndex: 3 }}>
      <Box
        css={{
          position: 'fixed',
          top: '$8',
          right: '$8',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        {!location.pathname.includes('overview') && (
          <Button
            as={Link}
            to={`/${purchaseRequisitionsPageUrl}/${order.id}/overview`}
            target="_blank"
            css={{ textDecoration: 'none' }}
          >
            Open Overview
          </Button>
        )}

        <Box
          onClick={onCloseButtonClick}
          css={{
            d: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '3rem',
            height: '3rem',
            borderRadius: '$2',
            border: '1px solid $neutralLighten80',
            cursor: 'pointer',
            color: 'inherit',
            backgroundColor: '$white',
            ml: '$4'
          }}
        >
          <CloseIcon20 />
        </Box>
      </Box>
      <CloseConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
      />
    </Box>
  )
}
