import { Avatar, AvatarImage, Body5, Body6, Box } from '@sefar/design-system'
import { useElementSize } from '../../hooks/useElementSize'
import WebexIcon from '../../../assets/Webex.png'
import {
  useListWebexUsers,
  useWebexAuth,
  webexStatusIcons,
  webexStatusTranslations
} from '../../api/webex'
import { useTranslate } from '../../hooks/useTranslate'
import AvatarPlaceholder from '../../../assets/avatar-placeholder-blue.jpg'

const WebexWidget = ({ email }: { email?: string }) => {
  const [widgetContainerRef, { width: widgetWidth }] = useElementSize()
  const [innerElementRef, { width: innerWidth }] = useElementSize()
  const { isAuth, authLink } = useWebexAuth()
  const { users } = useListWebexUsers({ email })
  const { t } = useTranslate()
  const userStatus: keyof typeof webexStatusTranslations = users?.[0]?.status
  const statusTranslation: string = webexStatusTranslations[userStatus]
  const chatLink = `webexteams://im?email=${email}`
  return (isAuth && !users?.length) || !email ? null : (
    <a
      href={isAuth ? chatLink : authLink}
      style={{ textDecoration: 'none', color: 'initial' }}
    >
      <Box
        ref={widgetContainerRef}
        css={{
          backgroundColor: '#EDEEF1',
          borderRadius: 32,
          height: 54,
          boxSizing: 'border-box',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover .webex-tooltip': {
            width: widgetWidth - 14
          },
          '&:hover .webex-cta': {
            transform: 'translateX(0px)'
          }
        }}
      >
        {isAuth ? (
          users?.[0] ? (
            <Box
              css={{
                display: 'flex',
                alignSelf: 'stretch',
                justifyContent: 'start',
                alignItems: 'center',
                padding: '$2 $4'
              }}
            >
              <Box
                css={{
                  alignSelf: 'stretch',
                  alignItems: 'center',
                  position: 'relative'
                }}
              >
                <Avatar css={{ width: 36, height: 36 }}>
                  <AvatarImage
                    src={users?.[0]?.avatar || AvatarPlaceholder}
                    alt="webex-avatar"
                  />
                </Avatar>
                <Box
                  css={{
                    position: 'absolute',
                    right: '-4px',
                    bottom: '0',
                    borderRadius: '50%',
                    backgroundColor: '#FFFFFF',
                    width: 14,
                    height: 14,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <img src={webexStatusIcons[userStatus]} alt={userStatus} />
                </Box>
              </Box>
              <Box css={{ alignSelf: 'stretch', pl: '$3' }}>
                <Body6 fontWeight="bold">{users[0]?.displayName}</Body6>
                <Body6>
                  {typeof statusTranslation === 'string' &&
                    t(statusTranslation)}
                </Body6>
              </Box>
            </Box>
          ) : null
        ) : (
          <Box
            css={{
              display: 'flex',
              alignSelf: 'stretch',
              flexGrow: 1,
              alignItems: 'center',
              padding: '$2 $6'
            }}
          >
            <Body6 fontWeight="bold">{t('field_webex_availability')}</Body6>
          </Box>
        )}

        <Box
          className="webex-tooltip"
          css={{
            backgroundColor: '#FFFFFF',
            borderRadius: 32,
            position: 'absolute',
            right: 7,
            width: 40,
            height: 40,
            transition: 'width .3s ease-in-out',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            '& img': {
              width: 24,
              flexShrink: 0
            }
          }}
        >
          <Box
            ref={innerElementRef}
            className="webex-cta"
            css={{
              transform: `translateX(${innerWidth / 2 - 12}px)`,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <img src={WebexIcon} alt="webex-icon" />
            <Body5
              fontWeight="bold"
              css={{
                color: '$neutral',
                marginLeft: '$2',
                whiteSpace: 'nowrap'
              }}
            >
              {isAuth ? t('field_webex_open') : t('field_webex_connect')}
            </Body5>
          </Box>
        </Box>
      </Box>
    </a>
  )
}

export default WebexWidget
