import {
  Body5,
  Box,
  Button,
  Dialog,
  DialogClose,
  EditIcon16,
  Input,
  Space,
  TextLink,
  ToastTypeEnum,
  useToastContext
} from '@sefar/design-system'
import React, { useRef, useState } from 'react'
import { patchUser } from '../../api'
import { useConfigStore } from '../../state/config'
import { useTranslate } from '../../hooks/useTranslate'

export function EditNicknameDialog({ nickname }: { nickname?: string }) {
  const setToast = useToastContext()
  const { t } = useTranslate()
  const [value, setValue] = useState(nickname)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const closeDialogRef = useRef<HTMLButtonElement>(null)
  const { setMe, me } = useConfigStore()

  const updateNickname = () => {
    setIsLoading(true)
    patchUser(me?.id, {
      field_nickname: value
    })
      .then((res) => {
        if (res?.errors?.length) {
          setToast({
            message: t('field_error_common_toast_text'),
            type: ToastTypeEnum.error
          })
        } else {
          setToast({
            message: t('field_nickname_update_success'),
            type: ToastTypeEnum.success
          })

          setMe({
            ...me,
            nickname: value
          })
          closeDialogRef?.current?.click()
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Dialog
      title={
        nickname ? t('field_edit_nickname_link') : t('field_add_nickname_link')
      }
      onOpenChange={(open) => setValue(!open ? '' : nickname)}
      contentProps={{ css: { width: 500 } }}
      trigger={
        <TextLink
          variant="small"
          css={{ mt: '$2', mb: '$6' }}
          data-cy="profileEditNicknameButton"
        >
          <Box as="span" className="icon icon-left">
            <EditIcon16 />
          </Box>
          <span>
            {nickname
              ? t('field_edit_nickname_link')
              : t('field_add_nickname_link')}
          </span>
        </TextLink>
      }
      footer={
        <Space
          css={{
            d: 'flex',
            justifyContent: 'flex-end',
            gap: '$2',
            w: '100%'
          }}
        >
          <Button
            ref={closeDialogRef}
            variant="secondary"
            as={DialogClose}
            data-cy="profileCancelEditingButton"
          >
            {t('field_cancel')}
          </Button>
          <Button
            disabled={isLoading}
            onClick={updateNickname}
            variant="primary"
            data-cy="profileSaveEditingButton"
          >
            {t('field_save')}
          </Button>
        </Space>
      }
    >
      <Box
        css={{
          d: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '$4',
          '@md': {
            pr: '$4'
          }
        }}
      >
        <Body5 css={{ mb: '$6' }}>
          {!nickname
            ? t('field_add_nickname_description')
            : t('field_edit_nickname_description')}
        </Body5>

        <Input
          label={t('field_nickname')}
          id="nickname"
          data-cy="profileNicknameInput"
          placeholder={
            nickname
              ? t('field_edit_nickname_link')
              : t('field_add_nickname_link')
          }
          value={value}
          onChange={(e) => setValue(e.target.value)}
          containerProps={{ css: { maxWidth: 300, mb: '$8' } }}
        />
      </Box>
    </Dialog>
  )
}
