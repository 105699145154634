import React, { useMemo, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Navigation } from 'swiper'
import {
  patchUserSpacesBookmarks,
  useSpaces,
  Space as SpaceType,
  SubSpace as SubSpaceType,
  SubSpaceSimple as SubSpaceSimpleType,
  SubSpaceArticleSimple as SubSpaceArticleSimpleType,
  SpacesBookmarkSimple,
  SUBSPACE_TYPE,
  SPACE_ARTICLE_TYPE,
  SpacesBookmark,
  useMe,
  getSpaceArticleById,
  transferArticleSimple
} from '../../api'
import { Link } from 'react-router-dom'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import {
  Box,
  Heading3,
  Body4,
  Body6,
  Body7,
  Container,
  ContainerPadding,
  Grid,
  Space,
  Spinner,
  LazyImage,
  Carousel,
  CarouselSlide,
  CarouselNavigationPrev,
  CarouselNavigationNext,
  StarFilled20,
  StarOutline20,
  Document24,
  Heading5
} from '@sefar/design-system'

import { useConfigStore } from '../../state/config'
import { SearchInput } from '../../components/search/search-input'
import { useMedia } from 'react-use'
import { subSpacePageUrl, searchPageUrl, spacesPageUrl } from '../../app'
import { useTranslate } from '../../hooks/useTranslate'
import { FavouriteSpacesEmptyState } from './favourite-spaces-empty-state'

function BookmarkArticleCard({
  id,
  title,
  spaceTitle,
  subspaceTitle,
  date,
  onFavoriteIconClick
}: {
  id: string
  title: string
  spaceId: string
  subSpaceId: string
  spaceTitle: string | undefined
  subspaceTitle: string
  date: string
  onFavoriteIconClick: (
    id: string,
    isBookmarked: boolean,
    e: React.SyntheticEvent<EventTarget>
  ) => void
}) {
  return (
    <Box
      id={id}
      css={{
        d: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: '$4 $4 $3 $4',
        boxShadow: '0px 4px 8px 0 RGBA(0, 0, 0, 0.04)',
        backgroundColor: '$white',
        cursor: 'pointer',
        height: '186px',
        border: '1px solid $neutralLighten90',
        borderRadius: '$2',

        '&:hover': {
          boxShadow: '0px 8px 16px -4px RGBA(0, 0, 0, 0.16)'
        }
      }}
    >
      <Box>
        <Space mb="3XS">
          <Document24 style={{ height: '20px' }} />
        </Space>
        {spaceTitle && subspaceTitle && (
          <Space mb="3XS">
            <Body7 css={getTruncatedTextStyles(2, 16)}>
              {`${spaceTitle} / ${subspaceTitle}`}
            </Body7>
          </Space>
        )}
        <Space mb="3XS">
          <Body4 fontWeight="bold" css={getTruncatedTextStyles(2)}>
            {title}
          </Body4>
        </Space>
      </Box>

      <Box
        css={{
          d: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Body6>{date}</Body6>
        </Box>
        <Box
          onClick={(e: React.SyntheticEvent<EventTarget>) => {
            e.stopPropagation()
            onFavoriteIconClick(id, true, e)
          }}
          css={{
            d: 'flex',
            alignItems: 'flex-end',
            px: '$4',
            mr: '-$4'
          }}
        >
          <Box as="span" css={{ color: '#FFBB38' }}>
            <StarFilled20 />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const getTruncatedTextStyles = (linesAmount = 3, lineHeight = 21) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': linesAmount,
  height: lineHeight * linesAmount
})

export function Card({
  id,
  title,
  imageUrl,
  onFavoriteIconClick,
  isBookmarked
}: {
  id: string
  title: string
  imageUrl: string
  onFavoriteIconClick: (
    id: string,
    isBookmarked: boolean,
    e: React.SyntheticEvent<EventTarget>
  ) => void
  isBookmarked: boolean
}) {
  return (
    <Box
      id={id}
      css={{
        boxShadow: '0px 4px 8px 0 RGBA(0, 0, 0, 0.04)',
        backgroundColor: '$white',
        cursor: 'pointer',
        height: '186px',
        border: '1px solid $neutralLighten90',
        borderRadius: '$2',
        display: 'flex',
        flexDirection: 'column',

        '&:hover': {
          boxShadow: '0px 8px 16px -4px RGBA(0, 0, 0, 0.16)'
        }
      }}
    >
      <Box
        css={{
          background: '$gradientBlue',
          aspectRatio: '21/9',
          position: 'relative'
        }}
      >
        {imageUrl && (
          <LazyImage
            src={imageUrl}
            aspectRatio="21/9"
            css={{ position: 'absolute', inset: 0 }}
          />
        )}
      </Box>
      <Box
        css={{
          d: 'flex',
          justifyContent: 'space-between',
          px: '$4',
          py: '$3',
          flex: 1
        }}
      >
        <Body4 fontWeight="bold" css={getTruncatedTextStyles(3)}>
          {title}
        </Body4>
        <Box
          onClickCapture={(e: React.SyntheticEvent<EventTarget>) =>
            onFavoriteIconClick(id, isBookmarked, e)
          }
          css={{
            d: 'flex',
            alignItems: 'flex-end',
            pt: '1.5rem',
            px: '$4',
            mr: '-$4'
          }}
        >
          <Box
            as="span"
            css={{ color: isBookmarked ? '#FFBB38' : '$neutralLighten70' }}
          >
            {isBookmarked ? <StarFilled20 /> : <StarOutline20 />}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export function Spaces() {
  const { id: userId, setMe, contentLang } = useConfigStore()
  const { me, mutate: mutateUser } = useMe(userId)
  const navigate = useNavigate()
  const { t } = useTranslate()
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const { spaces, isLoading } = useSpaces()
  const [searchText, setSearchText] = useState('')
  const [isBookmarksLoading, setIsBookmarksLoading] = useState(true)
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false)
  const [bookmarks, setBookmarks] = useState<SpacesBookmark[]>([])
  const bookmarksMainSectionRef = useRef<HTMLDivElement>(null)
  const [bookmarkNavigationRefs, setBookmarkNavigationRefs] = useState({
    prev: null,
    next: null
  })

  const refsById = useMemo(() => {
    const refs = {}
    spaces &&
      spaces.length &&
      spaces.forEach((space: SpaceType) => {
        refs[space.id] = {}
        refs[space.id]['prev'] = React.createRef<HTMLDivElement>(null)
        refs[space.id]['next'] = React.createRef<HTMLDivElement>(null)
      })
    refs.bookmarks = {
      prev: React.createRef<HTMLDivElement>(null),
      next: React.createRef<HTMLDivElement>(null)
    }
    return refs
  }, [spaces, bookmarks, isLoading])

  useEffect(() => {
    const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        if (isSearchInputFocused) {
          moveToSearchPage()
        }
      }
    }

    if (searchText && isSearchInputFocused) {
      document.addEventListener('keydown', keyDownHandler)
    } else {
      document.removeEventListener('keydown', keyDownHandler)
    }

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [searchText, isSearchInputFocused])

  useEffect(() => {
    if (isLoading) return
    setIsBookmarksLoading(true)
    // Get articles data for bookmarks
    const promises = me?.spacesBookmarks?.map(
      (bookmark: SpacesBookmarkSimple) => {
        return getSpaceArticleById(bookmark.id)
      }
    )
    // TODO: possibly recreate it to one request for array of articles by array of IDs
    // instead of getting articles one by one
    Promise.all(promises)
      .then(function (results) {
        const articles = results.map((res) => transferArticleSimple(res?.data))

        // Set user's bookmarks for articles and spaces
        me?.spacesBookmarks &&
          spaces?.length &&
          setBookmarks(
            me?.spacesBookmarks.map((bookmark: SpacesBookmarkSimple) => {
              return bookmark.type == SUBSPACE_TYPE
                ? getSpaceBookmarkInfo(bookmark.id, spaces)
                : getSpaceArticleBookmarkInfo(bookmark.id, articles, spaces)
            })
          )
        setIsBookmarksLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setIsBookmarksLoading(false)
      })
  }, [me?.id, spaces?.length])

  useEffect(() => {
    setMe(me)
  }, [me?.spacesBookmarks?.length])

  const prevNextBtnsSliderStyles = {
    height: '100%',
    d: isSm ? 'none' : 'flex',
    alignItems: 'center',
    top: 0,
    '&.swiper-button-disabled': {
      d: 'none'
    },
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover:not(.swiper-button-disabled)': {
        backgroundColor: 'rgba(255,255,255,0)'
      }
    }
  }

  const additionalPadding = '35px'
  const prevBtnSliderStyles = {
    ...prevNextBtnsSliderStyles,
    paddingRight: additionalPadding,
    background:
      'linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);'
  }

  const nextBtnSliderStyles = {
    ...prevNextBtnsSliderStyles,
    paddingLeft: additionalPadding,
    background:
      'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%)'
  }

  const prevNextBtnsBookmarksSliderStyles = {
    border: '1px solid $neutralLighten80',
    d: isSm ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    top: '50%',
    backgroundColor: '$white',
    '&.swiper-button-disabled': {
      d: 'none'
    },
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover:not(.swiper-button-disabled)': {
        backgroundColor: '$white'
      }
    }
  }

  const prevBtnBookmarksSliderStyles = {
    ...prevNextBtnsBookmarksSliderStyles,
    transform: 'translateY(-50%) translateX(-50%)'
  }

  const nextBtnBookmarksSliderStyles = {
    ...prevNextBtnsBookmarksSliderStyles,
    transform: 'translateY(-50%) translateX(50%)'
  }

  const moveToSearchPage = () => {
    searchText?.length > 2 &&
      navigate(`/${searchPageUrl}?type=spaces&search=${searchText}`)
  }

  const getSpaceBookmarkInfo = (id: string, spaces: SpaceType[]) => {
    const subSpace = spaces
      .map((space: SpaceType) => space.subspaces)
      .flat()
      .find((subspace: SubSpaceSimpleType) => subspace.id === id)
    return {
      ...subSpace,
      spaceTitle: spaces.find(
        (space: SpaceType) => space.id === subSpace?.spaceId
      )?.title,
      type: SUBSPACE_TYPE
    }
  }

  const getSpaceArticleBookmarkInfo = (
    id: string,
    articles: SubSpaceArticleSimpleType[],
    spaces: SpaceType[]
  ) => {
    const article = articles.find((article) => article.id === id)
    const subspace = spaces
      .map((space: SpaceType) => space.subspaces)
      .flat()
      .find(
        (subspace: SubSpaceSimpleType) => subspace.id === article?.subspaceId
      )
    const space = spaces.find((space) => space.id === subspace?.spaceId)
    return {
      ...article,
      subspaceTitle: subspace?.title,
      spaceId: space?.id,
      spaceTitle: space?.title,
      type: SPACE_ARTICLE_TYPE
    }
  }

  const prepareBookmarksForPatch = (
    bookmarks: SpacesBookmark[]
  ): SpacesBookmarkSimple[] =>
    bookmarks.map((bookmark: SpacesBookmark) => ({
      id: bookmark.id,
      type: bookmark.type
    }))

  const onBookmarkArticleFavoriteIconClick = async (
    id: string,
    _: boolean,
    e: React.SyntheticEvent<EventTarget>
  ) => {
    e.preventDefault()
    const bookmarksUpdated = bookmarks.filter(
      (item: SpacesBookmarkSimple) => item.id !== id
    )
    setBookmarks(bookmarksUpdated)
    await patchUserSpacesBookmarks(me?.id, bookmarksUpdated)
    mutateUser()
  }

  const onFavoriteIconClick = async (
    id: string,
    isBookmarked: boolean,
    e: React.SyntheticEvent<EventTarget>
  ) => {
    e.preventDefault()
    let bookmarksUpdated: SpacesBookmark[] = []
    if (isBookmarked) {
      bookmarksUpdated = bookmarks.filter(
        (item: SpacesBookmark) => item.id !== id
      )
    } else {
      bookmarksUpdated = [...bookmarks, getSpaceBookmarkInfo(id, spaces)]
    }
    setBookmarks(bookmarksUpdated)
    await patchUserSpacesBookmarks(
      me?.id,
      prepareBookmarksForPatch(bookmarksUpdated)
    )
    mutateUser()
  }

  const scrollToMainSection = () => {
    bookmarksMainSectionRef.current?.scrollIntoView()
  }

  useEffect(() => {
    if (!isBookmarksLoading && !isLoading && bookmarks.length) {
      setBookmarkNavigationRefs({
        prev: refsById.bookmarks.prev.current,
        next: refsById.bookmarks.next.current
      })
    }
  }, [isBookmarksLoading, isLoading, bookmarks.length])

  return (
    <Box
      css={{
        d: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Box css={{ width: '100%' }}>
            <Box
              css={{
                maxHeight: isBookmarksLoading ? '1px' : '300px',
                overflow: 'hidden',
                opacity: isBookmarksLoading ? '0' : '1',
                backgroundColor: '$primaryBlueLighten95',
                py: isBookmarksLoading ? '0' : '$8',
                transition: '$default'
              }}
            >
              <Heading5 css={{ ml: '$10', mb: '$6' }}>
                {t('field_spaces_all_favourites')}
              </Heading5>
              {bookmarks.length ? (
                <ContainerPadding>
                  <Container size="large">
                    <Box css={{ position: 'relative' }}>
                      <Carousel
                        loop={false}
                        spaceBetween={20}
                        slidesPerView={1}
                        pagination={false}
                        breakpoints={{
                          480: {
                            slidesPerView: 2
                          },
                          640: {
                            slidesPerView: 3
                          },
                          768: {
                            slidesPerView: 4
                          },
                          1024: {
                            slidesPerView: 5
                          },
                          1280: {
                            slidesPerView: 6
                          }
                        }}
                        navigation={{
                          prevEl: bookmarkNavigationRefs.prev,
                          nextEl: bookmarkNavigationRefs.next
                        }}
                        preventClicksPropagation={false}
                        modules={[Navigation]}
                      >
                        {bookmarks.map((bookmark: SpacesBookmark) => (
                          <CarouselSlide key={bookmark.id}>
                            <Box
                              key={bookmark.id}
                              as={Link}
                              to={
                                bookmark.type === SPACE_ARTICLE_TYPE
                                  ? `/${spacesPageUrl}/${bookmark.id}/`
                                  : `/${subSpacePageUrl}/${bookmark.spaceId}/${bookmark.id}/`
                              }
                              css={{
                                color: 'inherit',
                                textDecoration: 'none'
                              }}
                            >
                              {bookmark.type === SPACE_ARTICLE_TYPE ? (
                                <BookmarkArticleCard
                                  id={bookmark.id}
                                  title={bookmark.title}
                                  spaceTitle={bookmark?.spaceTitle}
                                  subspaceTitle={bookmark?.subspaceTitle}
                                  onFavoriteIconClick={
                                    onBookmarkArticleFavoriteIconClick
                                  }
                                  date={bookmark?.date}
                                />
                              ) : (
                                <Card
                                  id={bookmark.id}
                                  title={bookmark.title}
                                  imageUrl={bookmark.imageUrl}
                                  onFavoriteIconClick={onFavoriteIconClick}
                                  isBookmarked={true}
                                />
                              )}
                            </Box>
                          </CarouselSlide>
                        ))}
                      </Carousel>
                      <CarouselNavigationPrev
                        css={prevBtnBookmarksSliderStyles}
                        ref={refsById.bookmarks.prev}
                      />
                      <CarouselNavigationNext
                        css={nextBtnBookmarksSliderStyles}
                        ref={refsById.bookmarks.next}
                      />
                    </Box>
                  </Container>
                </ContainerPadding>
              ) : (
                <FavouriteSpacesEmptyState
                  scrollToMainSection={scrollToMainSection}
                />
              )}
            </Box>

            <Box
              css={{
                d: 'flex',
                alignItems: 'center',
                height: '231px',
                backgroundColor: '$neutralLighten97',
                borderBottom: '1px $neutralLighten90 solid'
              }}
              ref={bookmarksMainSectionRef}
            >
              <ContainerPadding>
                <Container>
                  <Grid>
                    <Box
                      css={{
                        d: 'flex',
                        alignItems: 'center',
                        gc: '1 / span 12',
                        gcLg: '2 / span 10'
                      }}
                    >
                      <SearchInput
                        placeholderTextLg={t('field_search_input_spaces_text')}
                        placeholderTextSm={t('field_search_input_text_sm')}
                        onChange={(value: string) => {
                          setSearchText(value)
                        }}
                        onSearchIconClick={moveToSearchPage}
                        onBlur={() => setIsSearchInputFocused(false)}
                        onFocus={() => setIsSearchInputFocused(true)}
                      />
                    </Box>
                  </Grid>
                </Container>
              </ContainerPadding>
            </Box>
          </Box>
          <ContainerPadding>
            <Container size="large">
              <Space mt="2XL">
                {spaces &&
                  spaces?.length > 0 &&
                  spaces.map((space: SpaceType) => (
                    <Space mb="XL" key={space.id}>
                      <Space mb="MD">
                        <Heading3>{space.title}</Heading3>
                      </Space>
                      {space.subspaces && space.subspaces.length > 0 && (
                        <Carousel
                          loop={false}
                          spaceBetween={20}
                          slidesPerView={1}
                          pagination={false}
                          breakpoints={{
                            480: {
                              slidesPerView: 2
                            },
                            640: {
                              slidesPerView: 3
                            },
                            768: {
                              slidesPerView: 4
                            },
                            1024: {
                              slidesPerView: 5
                            }
                          }}
                          navigation={{
                            prevEl: refsById[space.id].prev.current,
                            nextEl: refsById[space.id].next.current
                          }}
                          onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl =
                              refsById[space.id].prev.current
                            swiper.params.navigation.nextEl =
                              refsById[space.id].next.current
                          }}
                          modules={[Navigation]}
                        >
                          {space.subspaces.map(
                            (subspace: SubSpaceSimpleType) => (
                              <>
                                {subspace.articlesAmount > 0 && (
                                  <CarouselSlide key={subspace.id}>
                                    <Box
                                      key={subspace.id}
                                      as={Link}
                                      to={`/${subSpacePageUrl}/${subspace.spaceId}/${subspace.id}/`}
                                      css={{
                                        color: 'inherit',
                                        textDecoration: 'none'
                                      }}
                                    >
                                      <Card
                                        id={subspace.id}
                                        title={subspace?.title}
                                        imageUrl={subspace?.imageUrl}
                                        onFavoriteIconClick={
                                          onFavoriteIconClick
                                        }
                                        isBookmarked={
                                          !!bookmarks.find(
                                            (item) => item?.id === subspace.id
                                          )
                                        }
                                      />
                                    </Box>
                                  </CarouselSlide>
                                )}
                              </>
                            )
                          )}
                          <CarouselNavigationPrev
                            css={{
                              ...prevBtnSliderStyles
                            }}
                            ref={refsById[space.id].prev}
                          />
                          <CarouselNavigationNext
                            css={{
                              ...nextBtnSliderStyles
                            }}
                            ref={refsById[space.id].next}
                          />
                        </Carousel>
                      )}
                    </Space>
                  ))}
              </Space>
            </Container>
          </ContainerPadding>
        </>
      )}
    </Box>
  )
}
