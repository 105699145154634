import React, { useEffect, useState } from 'react'
import {
  Body4,
  Body5,
  Box,
  Container,
  ContainerPadding,
  Heading3,
  Grid,
  ReactSelect,
  SelectOption,
  useToastContext,
  ToastTypeEnum
} from '@sefar/design-system'
import { prepareLanguagesForSelect } from '../../components/utils'
import { patchUser, useLanguages, Language } from '../../api'
import { useConfigStore } from '../../state/config'
import { useDebounce } from 'react-use'
import { useTranslate } from '../../hooks/useTranslate'

export const getLanguageLabelByValue = (languages: Language[], lang: string) =>
  languages?.find((language) => language.id === lang)?.label

export function ProfileSettings() {
  const { contentLang, setContentLang, me } = useConfigStore()
  const { t } = useTranslate()
  const { languages } = useLanguages()
  const [selectedContentLang, setSelectedContentLang] = useState<SelectOption>()
  const setToast = useToastContext()
  const [disabled, setDisabled] = useState<boolean>(false)

  useDebounce(
    async () => {
      if (selectedContentLang && selectedContentLang?.value !== contentLang) {
        setDisabled(true)
        const oldLang = contentLang
        setContentLang(selectedContentLang?.value)

        const res = await patchUser(me?.id, {
          preferred_langcode: {
            value: selectedContentLang.value
          }
        })

        if (res?.errors?.length) {
          setToast({
            message: t('field_error_common_toast_text'),
            type: ToastTypeEnum.error
          })
          setContentLang(oldLang)
        } else {
          setTimeout(() => {
            setToast({
              message: t('field_settings_cont_lang_updated'),
              type: ToastTypeEnum.success
            })
          }, 200)
        }

        setDisabled(false)
      }
    },
    300,
    [selectedContentLang]
  )

  useEffect(() => {
    if (
      contentLang &&
      contentLang !== selectedContentLang?.value &&
      getLanguageLabelByValue(languages, contentLang)
    ) {
      setSelectedContentLang({
        value: contentLang,
        label: getLanguageLabelByValue(languages, contentLang) as string
      })
    }
  }, [contentLang, languages?.length])

  return (
    <Box css={{ d: 'flex', flexDirection: 'column', height: '100%', flex: 1 }}>
      <ContainerPadding>
        <Container>
          <Grid>
            <Box
              css={{
                gc: '2 / span 10',
                gcSm: '1 / span 12',
                d: 'flex',
                flexDirection: 'column'
              }}
            >
              <Heading3 css={{ mt: '$12' }}>
                {t('field_language_settings')}
              </Heading3>
              <Box
                css={{
                  borderBottom: '1px $neutralLighten90 solid',
                  mt: '$6',
                  mb: '$12'
                }}
              ></Box>
              <Body4 fontWeight="bold" css={{ mb: '$4' }}>
                {t('field_my_content_lang')}
              </Body4>
              <Body5 css={{ mb: '$6' }}>
                {t('field_content_language_descripti')}
              </Body5>
            </Box>
          </Grid>
          <Grid>
            <Box
              data-cy="languageSelect"
              css={{
                gc: '2 / span 12',
                gcSm: '1 / span 12',
                d: 'flex'
              }}
            >
              {languages?.length ? (
                <ReactSelect
                  isDisabled={disabled}
                  value={selectedContentLang}
                  options={prepareLanguagesForSelect(languages)}
                  onChange={(option: SelectOption) =>
                    setSelectedContentLang(option)
                  }
                  placeholder={t('field_prod_set_select_lang_text')}
                />
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Container>
      </ContainerPadding>
    </Box>
  )
}
