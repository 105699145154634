import React from 'react'
import { Body7, Box, ChatIcon14 } from '@sefar/design-system'

export const CommentsCounter = ({
  count = 0,
  small = false,
  onClick
}: {
  count: number
  small?: boolean
  onClick?: () => void
}) => (
  <Box
    css={{
      d: 'flex',
      gap: small ? '$1' : '$2',
      alignItems: 'center',
      cursor: onClick ? 'pointer' : undefined,
      height: '100%',
      '&:hover': {
        '& svg': {
          fill: '$primaryBlue',
          '& path': {
            fill: '$primaryBlue'
          }
        }
      },
      '& svg': {
        transition: '$default',
        '& path': {
          transition: '$default'
        }
      }
    }}
    onClick={onClick}
  >
    <ChatIcon14 />
    <Body7>{count}</Body7>
  </Box>
)
