import {
  Body5,
  Body7,
  Box,
  Dialog,
  FeedbackIcon16,
  Space,
  Tooltip
} from '@sefar/design-system'
import { useTranslate } from '../../../../hooks/useTranslate'

export const NotePreview = ({ note }: { note?: string }) => {
  const { t } = useTranslate()
  return (
    <Box>
      <Dialog
        trigger={
          <Box
            css={{
              pointerEvents: note ? 'auto' : 'none',
              cursor: note ? 'pointer' : 'default'
            }}
          >
            <Tooltip
              text={t('field_purchase_requisition_note')}
              withArrow={false}
              contentProps={{ css: { padding: '$1' } }}
            >
              <Box
                css={{
                  d: 'flex',
                  alignItems: 'center',
                  gap: '$1',
                  '&:hover': {
                    '& svg': {
                      stroke: '$primaryBlue',
                      '& path': {
                        stroke: '$primaryBlue'
                      }
                    }
                  },
                  '& svg': {
                    transition: '$default',
                    '& path': {
                      transition: '$default'
                    }
                  }
                }}
              >
                <FeedbackIcon16 />
                <Body7>{note ? 1 : 0}</Body7>
              </Box>
            </Tooltip>
          </Box>
        }
        title={'Feedback'}
        contentProps={{ css: { '@lg': { width: 550 } } }}
      >
        <Space mb="SM">
          <Body5>{note}</Body5>
        </Space>
      </Dialog>
    </Box>
  )
}
