import { ItemsStep } from './steps/items'
import { DetailsStep } from './steps/details'
import { OverviewStep } from './steps/overview'
import { CompletedStep } from './steps/completed'

export enum PurchaseRequisitionStepsEnum {
  ITEMS = 'items',
  DETAILS = 'details',
  OVERVIEW = 'overview',
  COMPLETED = 'completed'
}
export const DEFAULT_STEP = PurchaseRequisitionStepsEnum.ITEMS
export const MAP_QUERY_PARAM_TO_COMPONENT = {
  [PurchaseRequisitionStepsEnum.ITEMS]: ItemsStep,
  [PurchaseRequisitionStepsEnum.DETAILS]: DetailsStep,
  [PurchaseRequisitionStepsEnum.OVERVIEW]: OverviewStep,
  [PurchaseRequisitionStepsEnum.COMPLETED]: CompletedStep
}
export const STEPS_ORDER = [
  PurchaseRequisitionStepsEnum.ITEMS,
  PurchaseRequisitionStepsEnum.DETAILS,
  PurchaseRequisitionStepsEnum.OVERVIEW,
  PurchaseRequisitionStepsEnum.COMPLETED
]
export const MAP_STEP_NAME_TO_LABEL_KEY = {
  [PurchaseRequisitionStepsEnum.ITEMS]: 'field_purchase_item',
  [PurchaseRequisitionStepsEnum.DETAILS]: 'field_purchase_details',
  [PurchaseRequisitionStepsEnum.OVERVIEW]: 'field_purchase_overview',
  [PurchaseRequisitionStepsEnum.COMPLETED]: 'field_purchase_finished'
}

export const CURRENCY = 'CHF'
