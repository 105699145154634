import { Box, Button, Dialog, TextArea } from '@sefar/design-system'
import React, { useState } from 'react'
import { useTranslate } from '../../hooks/useTranslate'

export function FeedbackDialog({
  open,
  setOpen,
  onFeedbackSubmit,
  initialMessage = ''
}: {
  open: boolean
  setOpen: (open: boolean) => void
  onFeedbackSubmit: (feedBackMessage: string) => void
  initialMessage?: string
}) {
  const { t } = useTranslate()
  const [feedBackMessage, setFeedBackMessage] = useState<string>(initialMessage)

  return (
    <Dialog
      onOpenChange={() => {
        setFeedBackMessage(initialMessage)
      }}
      setOpenControlledDialog={setOpen}
      open={open}
      title={t('field_idea_edit_feedback')}
      contentProps={{ css: { '@lg': { width: 550 } } }}
      footer={
        <Box css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={!feedBackMessage}
            onClick={() => onFeedbackSubmit(feedBackMessage)}
            variant="primary"
          >
            {t('field_idea_edit_feedback')}
          </Button>
        </Box>
      }
    >
      <TextArea
        value={feedBackMessage}
        id="feedback"
        name="feedback"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setFeedBackMessage(e.target.value)
        }
        css={{ minHeight: '200px' }}
      />
    </Dialog>
  )
}
