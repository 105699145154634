import { useEffect, useRef, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useDebounce } from 'react-use'
import {
  useToastContext,
  Box,
  Tabs,
  TabsList,
  TabsTrigger,
  ToastTypeEnum,
  Heading1,
  Space,
  Container,
  ContainerPadding,
  ReactSelect,
  SelectOption
} from '@sefar/design-system'
import { purchaseApi } from './api'
import { OrdersTable } from './components/orders-table'
import {
  approverPurchaseRequisitionTypes,
  nonApproverPurchaseRequisitionTypes,
  PURCHASE_REQUISITION_PAGE_PARAM_KEY,
  PURCHASE_REQUISITION_SEARCH_PARAM_KEY,
  PURCHASE_REQUISITION_STATUS_PARAM_KEY,
  PURCHASE_REQUISITION_TYPE_PARAM_KEY,
  purchaseRequisitionStatusesLabels,
  PurchaseRequisitionStatusType,
  PurchaseRequisitionType,
  purchaseRequisitionTypesLabels
} from './consts'
import { useMe, USER_ROLES } from '../../api'
import { createPurchaseRequisitionPageUrl } from '../../app'
import { AddNewArticleButton } from '../../components'
import { SearchInput } from '../../components/search/search-input'
import { useTranslate } from '../../hooks/useTranslate'

export const PurchaseRequisitionsList = () => {
  const setToast = useToastContext()
  const { t } = useTranslate()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const currentPage = Number(searchParams.get('page')) ?? 0
  const searchValue = searchParams.get('search') ?? ''
  const selectedStatus = searchParams.get('status')
  const selectedType = searchParams.get('type') ?? 'all'

  const skippedFirstDebounceRef = useRef(false)

  const [isLoading, setIsLoading] = useState(false)
  const [listItems, setListItems] = useState([])
  const [itemsAmount, setItemsAmount] = useState(0)
  const { me } = useMe()
  const currentEmployeeId = me?.employeeid

  const isCurrentUserApprover = me.roles.includes(USER_ROLES.approver)
  const availablePurchaseRequisitionTypes = isCurrentUserApprover
    ? approverPurchaseRequisitionTypes
    : nonApproverPurchaseRequisitionTypes

  const statusOptions = Object.entries(purchaseRequisitionStatusesLabels).map(
    ([key, value]) => ({ label: value, value: key })
  )
  const currentStatusOption = statusOptions.find(
    (statusOption) => statusOption.value === selectedStatus
  )
  const loadPurchaseRequisitions = async (resetPage = false) => {
    if (resetPage) {
      searchParams.delete('page')
      setSearchParams(searchParams)
    }

    const [page, type, search, status] = [
      PURCHASE_REQUISITION_PAGE_PARAM_KEY,
      PURCHASE_REQUISITION_TYPE_PARAM_KEY,
      PURCHASE_REQUISITION_SEARCH_PARAM_KEY,
      PURCHASE_REQUISITION_STATUS_PARAM_KEY
    ].map((paramKey) => searchParams.get(paramKey))

    try {
      setIsLoading(true)
      const res = await purchaseApi.getPurchaseRequisitionsList(
        Number(page),
        search,
        status === PurchaseRequisitionStatusType.All ? '' : status,
        type === PurchaseRequisitionType.Own ? currentEmployeeId : '',
        type === PurchaseRequisitionType.Assigned ? currentEmployeeId : ''
      )
      setListItems(res?.data?.hits || [])
      setItemsAmount(res?.data?.totalHits?.value || 0)
    } catch (e) {
      setToast({
        message: t('field_error_common_toast_text'),
        type: ToastTypeEnum.error
      })
      console.error('Failed to load purchase requisitions list', e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadPurchaseRequisitions()
  }, [location])

  useDebounce(
    () => {
      if (!skippedFirstDebounceRef.current) {
        skippedFirstDebounceRef.current = true
        return
      }
      loadPurchaseRequisitions(true)
    },
    1000,
    [searchValue]
  )
  useEffect(() => {
    loadPurchaseRequisitions()
  }, [selectedStatus, selectedType])

  return (
    <Box css={{ d: 'flex', flexDirection: 'column', flex: 1 }}>
      <Tabs
        onValueChange={(value) => {
          searchParams.set('type', value)
          searchParams.delete('page')
          setSearchParams(searchParams)
        }}
        value={selectedType}
      >
        <Space
          css={{
            pt: '$19',
            backgroundColor: '$neutralLighten97',
            borderBottom: '1px $neutralLighten90 solid'
          }}
        >
          <ContainerPadding>
            <Container>
              <Box css={{ mb: '$19' }}>
                <Heading1>{t('field_purchase_requisitions_title')}</Heading1>
              </Box>
              <TabsList aria-label={t('field_purchase_requisitions_title')}>
                {availablePurchaseRequisitionTypes.map(
                  (purchaseRequisitionType) => (
                    <TabsTrigger
                      key={purchaseRequisitionType}
                      value={purchaseRequisitionType}
                    >
                      {purchaseRequisitionTypesLabels[purchaseRequisitionType]}
                    </TabsTrigger>
                  )
                )}
              </TabsList>
            </Container>
          </ContainerPadding>
        </Space>
      </Tabs>

      <Space css={{ d: 'flex', flexDirection: 'column', flex: 1 }}>
        <ContainerPadding css={{ flex: 1 }}>
          <Container>
            <Box css={{ maxWidth: '50rem', d: 'flex', py: '$12', gap: '$4' }}>
              <SearchInput
                small
                placeholder={t(
                  'field_purchase_requisitions_search_placeholder'
                )}
                value={searchValue}
                onChange={(value: string) => {
                  searchParams.set('search', value)
                  setSearchParams(searchParams)
                }}
                containerProps={{ css: { flexShrink: 2 } }}
              />
              <ReactSelect
                css={{ width: '16rem' }}
                value={currentStatusOption}
                options={statusOptions}
                onChange={(option: SelectOption) => {
                  searchParams.set('status', option.value)
                  searchParams.delete('page')
                  setSearchParams(searchParams)
                }}
                placeholder={t(
                  'field_purchase_requisitions_status_filter_placeholder'
                )}
              />
            </Box>
            <OrdersTable
              orders={listItems}
              amount={itemsAmount}
              currentPage={currentPage}
              onChangePage={(page: number) => {
                searchParams.set('page', page.toString())
                setSearchParams(searchParams)
                loadPurchaseRequisitions()
              }}
              refreshData={loadPurchaseRequisitions}
              isLoading={isLoading}
            />
          </Container>
        </ContainerPadding>
        <AddNewArticleButton
          url={`/${createPurchaseRequisitionPageUrl}`}
          text={t('field_purchase_add_order')}
        />
      </Space>
    </Box>
  )
}
