import React, { ComponentProps } from 'react'
import { styled } from '../../../../../stitches.config'
import Select, { components } from 'react-select'
import { Checkbox } from '../checkbox/checkbox'
import { Body5, Body4 } from '../typography'
import { Box } from '../utils'

const hoveredStyles = {
  border: '1px solid $neutralLighten60'
}

const focusedStyles = {
  border: '1px solid $primaryBlue'
}

const StyledSelect = styled(Select, {
  width: '320px',
  '@sm': {
    w: '100%'
  },
  maxWidth: '100%',
  fontSize: '$body5Xl',
  lineHeight: '$15',

  '& .react-select__menu': {
    zIndex: '$select',

    '&.checkbox-mode': {
      py: '$2'
    }
  },
  '&.disabled': {
    pointerEvents: 'none',
    '& .react-select__control': {
      border: '1px solid $neutralLighten80',
      color: '$neutralLighten60',

      '& .react-select__single-value': {
        color: '$neutralLighten60'
      },

      '& .react-select__dropdown-indicator': {
        svg: {
          fill: '$neutralLighten50',
          stroke: '$neutralLighten50'
        }
      }
    }
  },

  '&.__hover': {
    '& .react-select__control': {
      ...hoveredStyles
    }
  },

  '&.__focus': {
    '& .react-select__control': {
      ...focusedStyles
    }
  },

  '& .react-select__control': {
    d: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    color: '$neutral',
    border: '1px solid $neutralLighten80',
    padding: '0 $4',
    borderRadius: '$2',

    '&:hover': {
      ...hoveredStyles
    },
    '&:focused': {
      ...focusedStyles
    },
    '& .react-select__value-container': {
      padding: 0,

      '& .react-select__single-value': {
        marginLeft: 0
      },

      '& .react-select__input-container': {
        opacity: 0,
        '&. react-select__input': {
          opacity: 0
        }
      }
    },
    '& .react-select__indicator-separator': {
      d: 'none'
    },
    '& .react-select__dropdown-indicator': {
      color: 'currentColor',
      padding: 0,
      svg: {
        height: '18px',
        width: '18px',
        transition: '$default',
        fill: 'currentColor',
        stroke: 'currentColor',

        path: {
          fill: 'currentColor'
        }
      }
    },
    '&--menu-is-open': {
      border: '1px solid $primaryBlueLighten50',
      '& .react-select__dropdown-indicator': {
        svg: {
          transform: 'rotate(180deg)'
        }
      }
    }
  },

  '& .react-select__option': {
    backgroundColor: 'transparent',
    borderRadius: 0,
    '&.checkbox-mode': {
      p: '$2 $6'
    },
    '&:hover': {
      backgroundColor: '$neutralLighten93'
    },
    '&:active': {
      backgroundColor: '$primaryBlueLighten95'
    },
    '&--is-selected': {
      '&:not(.checkbox-mode)': {
        borderLeft: '3px solid $primaryBlueLighten50',
        backgroundColor: '$primaryBlueLighten95 !important',
        color: 'inherit'
      }
    }
  },

  '& .react-select__placeholder': {
    whiteSpace: 'nowrap'
  }
})

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <Checkbox
        checked={props.isSelected}
        onChange={() => null}
        label={props.label}
      />
    </components.Option>
  )
}

// Solutions inspired by this discussion
// https://github.com/JedWatson/react-select/issues/2790
const MultiValue = (props: any) => {
  const countToShow = props.selectProps.countToShow ?? 1
  return (
    <>
      {props.index === 0 ? (
        <components.SingleValue {...props}>
          <Body4>{props.data.label}</Body4>
        </components.SingleValue>
      ) : props.index < countToShow ? (
        <components.SingleValue {...props}>
          <Body4>, {props.data.label}</Body4>
        </components.SingleValue>
      ) : props.index === countToShow ? (
        <components.SingleValue {...props}>
          <Body5>
            &nbsp;{`+${props.selectProps.value.length - countToShow}`}
          </Body5>
        </components.SingleValue>
      ) : (
        <></>
      )}
    </>
  )
}

export type SelectOption = {
  value: string
  label: string
}

export const ReactSelect = ({
  withCheckbox = false,
  selectedValuesCountToShow = 1,
  ...props
}) => {
  return (
    <>
      {withCheckbox ? (
        <StyledSelect
          classNamePrefix="checkbox-mode react-select"
          components={{
            Option,
            MultiValue
          }}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          countToShow={selectedValuesCountToShow}
          isMulti
          {...props}
        />
      ) : (
        <StyledSelect classNamePrefix="react-select" {...props} />
      )}
    </>
  )
}
