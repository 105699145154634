import { Avatar, AvatarImage, Tooltip } from '@sefar/design-system'
import AvatarPlaceholder from '../../../assets/avatar-placeholder-blue.jpg'
import React from 'react'
import { User } from '../../api'

export function UserAvatar({
  user,
  placeholder
}: {
  user?: Partial<User>
  placeholder?: string
}) {
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`
  return (
    <Tooltip text={fullName} active={!!fullName.trim()} withArrow={false}>
      <Avatar>
        <AvatarImage
          src={user?.image || placeholder || AvatarPlaceholder}
          alt="avatar"
        />
      </Avatar>
    </Tooltip>
  )
}
