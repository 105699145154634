import { Body3, Box } from '@sefar/design-system'

type CardsRadioGroupProps = {
  options: {
    label: string
    value: string
  }[]
  value?: string
  onChange: (v: string) => void
}

export const CardsRadioGroup = ({
  options = [],
  value,
  onChange
}: CardsRadioGroupProps) => {
  return (
    <Box css={{ mx: '-$1', d: 'flex', flexDirection: 'row' }}>
      {options.map((opt) => (
        <Box
          key={opt.value}
          css={{
            mx: '$1',
            h: 64,
            flex: '1 1 0',
            d: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid $neutralLighten60',
            borderRadius: 8,
            backgroundColor: opt.value === value ? '$primaryBlue' : '#FFFFFF',
            transition: 'background-color .3s',
            cursor: 'pointer'
          }}
          onClick={() => {
            onChange(opt.value)
          }}
        >
          <Body3
            css={{
              fontWeight: 500,
              color: opt.value === value ? '#FFFFFF' : 'inherit'
            }}
          >
            {opt.label}
          </Body3>
        </Box>
      ))}
    </Box>
  )
}
