import { TD, TR } from '../my-content/common'
// @ts-ignore
import Clamp from 'react-multiline-clamp'
import {
  Badge,
  Body5,
  Body7,
  Box,
  Dialog,
  DropdownMenu,
  DropdownMenuItem,
  EllipsisHorizontalIcon20,
  FeedbackIcon16,
  Space,
  ToastTypeEnum,
  Tooltip,
  UnreadMark,
  useGlobalSpinner,
  useToastContext
} from '@sefar/design-system'
import {
  formatDateOfficial,
  formatDateTimeOfficial,
  getTime,
  SelectUserDialog
} from '../../components'
import {
  assignImplementor,
  changeIdeaFeedback,
  changeIdeaRealisationDate,
  changeIdeaStatus,
  Idea,
  IdeaGroupStatus,
  IdeaStatuses,
  IdeaStatusesTranslations,
  setIdeaNotConsidered
} from '../../api/ideas'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { ideasPageUrl, profilePageUrl } from '../../app'
import AvatarPlaceholderGray from '../../../assets/avatar-placeholder.jpg'
import React, { useEffect, useState } from 'react'
import { isIdeaManager, User } from '../../api'
import { useConfigStore } from '../../state/config'
import { useTranslate } from '../../hooks/useTranslate'
import { NotConsideredDialog } from './not-considered-dialog'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { useMedia } from 'react-use'
import { AssessDialog } from './assess-dialog'
import { ImplementedConfirmationDialog } from './implemented-confirmation-dialog'
import { ImplementationInProgressConfirmationDialog } from './implementation-in-progress-confirmation-dialog'
import { UserAvatar } from '../../components/common/user-avatar'
import {
  likeCounterTableStyles,
  LikesCounter
} from '../../components/article/article-info'
import { DateOfRealization } from '../../components/ideas/date-of-realization'
import { mapIdeaStatusesToBadgeColors } from '../../components/ideas/utils'
import { CommentsCounter } from '../../components/common/comments-counter'
import { RealisationDateDialog } from './realisation-date-dialog'
import { FeedbackDialog } from './feedback-dialog'

export function IdeaRow({ idea, mutate }: { idea: Idea; mutate: () => void }) {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const { t } = useTranslate()
  const setToast = useToastContext()
  const setGlobalSpinner = useGlobalSpinner()
  const { me } = useConfigStore()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [isManager, setIsManager] = useState<boolean>(false)
  const [openImplementerDialog, setOpenImplementerDialog] = useState(false)
  const [openNotConsideredDialog, setOpenNotConsideredDialog] = useState(false)
  const [openAssessDialog, setOpenAssessDialog] = useState(false)
  const [openRealisationDateDialog, setOpenRealisationDateDialog] =
    useState(false)
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)

  const [
    openImplementedConfirmationDialog,
    setOpenImplementedConfirmationDialog
  ] = useState(false)
  const [
    openImplementationInProgressConfirmationDialog,
    setOpenImplementationInProgressConfirmationDialog
  ] = useState(false)

  useEffect(() => {
    setIsManager(isIdeaManager(me))
  }, [me?.id])

  const onSuccess = (message: string) => {
    setGlobalSpinner({ show: true })
    setTimeout(() => {
      mutate()
      setGlobalSpinner({ show: false })
      setToast({
        type: ToastTypeEnum.success,
        message: message
      })
    }, 3000)
  }

  const onError = () => {
    setToast({
      message: t('field_error_common_toast_text'),
      type: ToastTypeEnum.error
    })
  }

  const Actions =
    isManager &&
    (!searchParams.get('type') ||
      searchParams.get('type') === IdeaGroupStatus.New) ? (
      <DropdownMenu
        trigger={() => (
          <Box
            css={{
              cursor: 'pointer',
              transform: 'rotate(90deg)',
              '@lg': { transform: 'none' }
            }}
          >
            <EllipsisHorizontalIcon20 />
          </Box>
        )}
        content={(setOpen) => (
          <>
            <DropdownMenuItem
              onClick={(e) => {
                setOpen(false)
                setOpenImplementerDialog(true)
              }}
            >
              Assign to Implementer
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={(e) => {
                setOpen(false)
                setOpenNotConsideredDialog(true)
              }}
            >
              Set to not considered
            </DropdownMenuItem>
          </>
        )}
        contentProps={{
          align: 'center',
          side: 'top'
        }}
      />
    ) : ((idea.implementer?.id === me?.id &&
        idea.status !== IdeaStatuses.Rejected) ||
        isManager) &&
      searchParams.get('type') === IdeaGroupStatus.Progress ? (
      <DropdownMenu
        trigger={() => (
          <Box
            css={{
              cursor: 'pointer',
              transform: 'rotate(90deg)',
              '@lg': { transform: 'none' }
            }}
          >
            <EllipsisHorizontalIcon20 />
          </Box>
        )}
        content={(setOpen) => (
          <>
            {isManager && (
              <>
                <DropdownMenuItem
                  onClick={(e) => {
                    setOpen(false)
                    setOpenImplementerDialog(true)
                  }}
                >
                  Change Implementer
                </DropdownMenuItem>
                {/* {idea.status === IdeaStatuses['Implementation in Progress'] && (
                  <>
                    <DropdownMenuItem
                      onClick={(e) => {
                        setOpen(false)
                        setOpenFeedbackDialog(true)
                      }}
                    >
                      {t('field_idea_edit_feedback')}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={(e) => {
                        setOpen(false)
                        setOpenRealisationDateDialog(true)
                      }}
                    >
                      {t('field_idea_realization_date')}
                    </DropdownMenuItem>
                  </>
                )} */}
              </>
            )}
            {idea.implementer?.id === me?.id && (
              <>
                {idea.status === IdeaStatuses['Review Pending'] && (
                  <DropdownMenuItem
                    onClick={(e) => {
                      setOpen(false)
                      navigate(`/${ideasPageUrl}/${idea.id}/validate`)
                    }}
                  >
                    {t('field_idea_assess')}
                  </DropdownMenuItem>
                )}
                {idea.status === IdeaStatuses.Approved && (
                  <DropdownMenuItem
                    onClick={(e) => {
                      setOpen(false)
                      setOpenImplementationInProgressConfirmationDialog(true)
                    }}
                  >
                    {t('field_idea_in_progress')}
                  </DropdownMenuItem>
                )}
                {idea.status === IdeaStatuses['Implementation in Progress'] && (
                  <DropdownMenuItem
                    onClick={(e) => {
                      setOpen(false)
                      setOpenImplementedConfirmationDialog(true)
                    }}
                  >
                    {t('field_idea_implemented')}
                  </DropdownMenuItem>
                )}
              </>
            )}
          </>
        )}
        contentProps={{
          align: 'center',
          side: 'top',
          css: { maxWidth: 220 }
        }}
      />
    ) : undefined

  const Title = (
    <Box css={{ position: 'relative' }}>
      {isLg && (
        <UnreadMark
          css={{
            top: '25%',
            left: 0,
            opacity:
              idea.implementer?.id === me?.id &&
              idea.status === IdeaStatuses['Review Pending']
                ? 1
                : 0
          }}
        />
      )}

      <Box
        css={{
          pl: isLg ? '$5' : 0,
          d: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline'
        }}
      >
        <Body5
          fontWeight="bold"
          as={Link}
          to={`/${ideasPageUrl}/${idea.id}`}
          css={{
            textDecoration: 'none',
            color: '$neutral',
            position: 'relative'
          }}
        >
          {idea.title}
        </Body5>

        <Box
          css={{
            d: 'flex',
            gap: '$1',
            flexDirection: 'column',
            '@lg': { flexDirection: 'row' }
          }}
        >
          {searchParams.get('type') &&
            searchParams.get('type') !== IdeaGroupStatus.New && (
              <Badge status={mapIdeaStatusesToBadgeColors[idea.status]}>
                {t(IdeaStatusesTranslations[idea.status])}
              </Badge>
            )}
          <DateOfRealization idea={idea} />
        </Box>
      </Box>
    </Box>
  )

  const Feedback = (
    <Box>
      <Dialog
        trigger={
          <Box
            css={{
              pointerEvents: idea?.feedback ? 'auto' : 'none',
              cursor: idea?.feedback ? 'pointer' : 'default'
            }}
          >
            <Tooltip
              text={t('field_feedback')}
              withArrow={false}
              contentProps={{ css: { padding: '$1' } }}
            >
              <Box
                css={{
                  d: 'flex',
                  alignItems: 'center',
                  gap: '$1',
                  '&:hover': {
                    '& svg': {
                      stroke: '$primaryBlue',
                      '& path': {
                        stroke: '$primaryBlue'
                      }
                    }
                  },
                  '& svg': {
                    transition: '$default',
                    '& path': {
                      transition: '$default'
                    }
                  }
                }}
              >
                <FeedbackIcon16 />
                <Body7>{idea?.feedback ? 1 : 0}</Body7>
              </Box>
            </Tooltip>
          </Box>
        }
        title={'Feedback'}
        contentProps={{ css: { '@lg': { width: 550 } } }}
      >
        <Space mb="SM">
          <Body5>{idea?.feedback}</Body5>
        </Space>
      </Dialog>
    </Box>
  )
  const CommentCounterWithTooltip = (
    <Tooltip
      text={t('field_comments_count_of_comments')}
      withArrow={false}
      contentProps={{ css: { padding: '$1' } }}
    >
      <Box
        css={{
          textDecoration: 'none',
          color: '$neutral',
          position: 'relative',
          cursor: 'pointer'
        }}
        as={Link}
        to={`/${ideasPageUrl}/${idea.id}#comments-section`}
      >
        <CommentsCounter count={idea?.commentCount} small />
      </Box>
    </Tooltip>
  )

  const Author =
    !idea.anonymous || isManager ? (
      <Link to={`/${profilePageUrl}/${idea.author?.id}`}>
        <UserAvatar user={idea.author}></UserAvatar>
      </Link>
    ) : (
      <></>
    )

  const Implementer = (
    <Box
      as={Link}
      css={
        !idea.implementer?.id
          ? {
              pointerEvents: 'none',
              d: 'inline-flex',
              border: '1px $neutralLighten60 dashed',
              borderRadius: '$round'
            }
          : idea.implementer?.id === me?.id
            ? {
                d: 'inline-block',
                position: 'relative',
                zIndex: 0,
                '&::after': {
                  d: 'block',
                  content: '""',
                  size: 36,
                  background:
                    'linear-gradient(90deg, $primaryRed 0%, $primaryBlue 100%)',
                  borderRadius: '$round',
                  position: 'absolute',
                  top: -2,
                  left: -2,
                  zIndex: -1
                }
              }
            : {}
      }
      to={`/${profilePageUrl}/${idea.implementer?.id}`}
    >
      <UserAvatar
        user={idea.implementer}
        placeholder={AvatarPlaceholderGray}
      ></UserAvatar>
    </Box>
  )

  return (
    <TR
      hover={idea.implementer?.id !== me?.id}
      css={{
        '& td': {
          borderBottom: '1px solid $neutralLighten80'
        },
        backgroundColor:
          idea.implementer?.id === me?.id ? '$primaryBlueLighten95' : '',
        '&:hover': {
          backgroundColor:
            idea.implementer?.id === me?.id ? '$primaryBlueLighten90' : ''
        }
      }}
    >
      {isLg ? (
        <>
          <TD>
            <LikesCounter
              article={idea}
              likeProps={{ css: likeCounterTableStyles }}
            />
          </TD>
          <TD>{Title}</TD>
          <TD>
            <Body5>{formatDateOfficial(idea.date)}</Body5>
            <Body7 css={{ color: '$neutralLighten30' }}>
              {getTime(idea.date)}
            </Body7>
          </TD>
          <TD>{Author}</TD>
          <TD>{Implementer}</TD>
        </>
      ) : (
        <>
          <TD>
            <Box css={{ mb: '$1' }}>
              <Clamp lines={3} maxLines={Number.MAX_SAFE_INTEGER}>
                {Title}
              </Clamp>
            </Box>
            <Body7 css={{ color: '$neutralLighten30', mb: '$3' }}>
              {formatDateTimeOfficial(idea.date)}
            </Body7>
          </TD>
          <TD css={{ width: 80 }}>
            <Box css={{ d: 'flex', position: 'relative' }}>
              {Author}
              <Box css={{ position: 'relative', left: '-10%' }}>
                {Implementer}
              </Box>
            </Box>
          </TD>
        </>
      )}
      <TD>
        <Box css={{ d: 'flex', alignItems: 'center', gap: '$4' }}>
          {Feedback}
          {CommentCounterWithTooltip}
          {Actions}
        </Box>
      </TD>

      <SelectUserDialog
        title="Assign to Implementer"
        description="Select the Implementer who will review the idea and will be in charge of implementation."
        saveButtonLabel="Assign to Implementer"
        open={openImplementerDialog}
        setOpen={setOpenImplementerDialog}
        onSetImplementer={(implementer: User) => {
          assignImplementor(idea.id, implementer.id, idea.lang)
            .then((res) => {
              if (res.errors?.length) {
                throw new Error(res.errors[0].detail)
              }
              onSuccess('Implementer assigned successfully')
            })
            .catch(onError)
            .finally(() => setOpenImplementerDialog(false))
        }}
      />

      <NotConsideredDialog
        open={openNotConsideredDialog}
        setOpen={setOpenNotConsideredDialog}
        onSetNotConsidered={(feedBackMessage: string) =>
          setIdeaNotConsidered(idea.id, feedBackMessage, idea.lang)
            .then((res) => {
              if (res.errors?.length) {
                throw new Error(res.errors[0].detail)
              }
              onSuccess('Successfully set idea to not considered')
            })
            .catch(onError)
            .finally(() => setOpenNotConsideredDialog(false))
        }
      />

      <AssessDialog
        open={openAssessDialog}
        setOpen={setOpenAssessDialog}
        onAssess={(
          feedBackMessage: string,
          status: IdeaStatuses,
          dateOfRealization?: Date
        ) =>
          changeIdeaStatus(
            idea.id,
            status,
            idea.lang,
            feedBackMessage,
            dateOfRealization
          )
            .then((res) => {
              if (res.errors?.length) {
                throw new Error(res.errors[0].detail)
              }
              onSuccess(t('field_idea_assess_success'))
            })
            .catch(onError)
            .finally(() => setOpenAssessDialog(false))
        }
      />

      <ImplementationInProgressConfirmationDialog
        open={openImplementationInProgressConfirmationDialog}
        setOpen={setOpenImplementationInProgressConfirmationDialog}
        onConfirm={(dateOfRealization) =>
          changeIdeaStatus(
            idea.id,
            IdeaStatuses['Implementation in Progress'],
            idea.lang,
            undefined,
            dateOfRealization
          )
            .then(() => onSuccess(t('field_idea_in_progress_success')))
            .catch(onError)
            .finally(() =>
              setOpenImplementationInProgressConfirmationDialog(false)
            )
        }
      />
      <ImplementedConfirmationDialog
        open={openImplementedConfirmationDialog}
        setOpen={setOpenImplementedConfirmationDialog}
        onConfirm={(feedbackMessage: string) =>
          changeIdeaStatus(
            idea.id,
            IdeaStatuses['Implemented'],
            idea.lang,
            feedbackMessage
          )
            .then(() => onSuccess(t('field_idea_implemented_success')))
            .catch(onError)
            .finally(() => setOpenImplementedConfirmationDialog(false))
        }
      />
      <RealisationDateDialog
        open={openRealisationDateDialog}
        setOpen={setOpenRealisationDateDialog}
        onConfirm={(dateOfRealization) => {
          changeIdeaRealisationDate(idea.id, idea.lang, dateOfRealization)
            .then(() => onSuccess(t('field_idea_realization_date_scss')))
            .catch(onError)
            .finally(() => setOpenRealisationDateDialog(false))
        }}
      />
      <FeedbackDialog
        open={openFeedbackDialog}
        setOpen={setOpenFeedbackDialog}
        initialMessage={idea.feedback}
        onFeedbackSubmit={(feedBackMessage: string) => {
          changeIdeaFeedback(idea.id, idea.lang, feedBackMessage)
            .then(() => onSuccess(t('field_idea_edit_feedback_success')))
            .catch(onError)
            .finally(() => setOpenFeedbackDialog(false))
        }}
      />
    </TR>
  )
}
