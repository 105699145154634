import React, { ComponentProps, ReactNode, useRef } from 'react'
import { styled, keyframes } from '../../../stitches.config'
import type * as Stitches from '@stitches/react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Button } from '../button/button'
import { CloseIcon16 } from '../icons'
import { Heading5 } from '../typography'
import { Space } from '../space'
import { useClickAway } from 'react-use'
import { DialogContent } from '@radix-ui/react-dialog'

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
})

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' }
})

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: '$scrim',
  position: 'fixed',
  zIndex: '$dialog',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  }
})

const StyledContent = styled(DialogPrimitive.Content, {
  d: 'flex',
  zIndex: '$dropdown',
  flexDirection: 'column',
  backgroundColor: '$white',
  boxShadow: '$default',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '100vw',
  maxWidth: '100vw',
  minHeight: 'var(--app-height)',
  maxHeight: 'var(--app-height)',
  '@md': {
    borderRadius: '$4',
    minWidth: 400,
    maxWidth: '90vw',
    minHeight: 'auto',
    maxHeight: 'calc(100vh - 128px)' //3XL
  },
  '@lg': {
    maxHeight: 'calc(100vh - 160px)' //3XL
  },
  '@xl': {
    maxHeight: 'calc(100vh - 240px)' //3XL
  },
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
  },
  '&:focus': { outline: 'none' }
})

const StyledTitle = styled(DialogPrimitive.Title, {
  margin: 0,
  color: '$neutral'
})

const StyledDescription = styled(DialogPrimitive.Description, {
  my: '$3',
  color: '$neutral'
})

const DialogTrigger = DialogPrimitive.Trigger
export const DialogClose = styled(DialogPrimitive.Close)
export const DialogTitle = StyledTitle
export const DialogDescription = StyledDescription

export function Dialog({
  trigger,
  title,
  footer,
  footerCss,
  children,
  contentProps,
  setOpenControlledDialog,
  innerContentCss,
  closeButtonProps,
  ...props
}: ComponentProps<typeof DialogPrimitive.Root> & {
  trigger?: ReactNode
  footer?: ReactNode
  footerCss?: Stitches.CSS
  title?: string
  closeButtonProps?: Omit<ComponentProps<typeof Button>, 'children'>
  contentProps?: Omit<
    ComponentProps<typeof DialogContent> & { css: Stitches.CSS },
    'children'
  >
  setOpenControlledDialog?: (open: boolean) => void
  innerContentCss?: Stitches.CSS
}) {
  const dialogContentRef = useRef(null)
  useClickAway(dialogContentRef, (e) => {
    if (e.target?.dataset?.state === 'open') {
      setOpenControlledDialog && setOpenControlledDialog(false)
    }
  })

  return (
    <DialogPrimitive.Root {...props}>
      {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogPrimitive.Portal>
        <StyledOverlay />
        <StyledContent ref={dialogContentRef} {...contentProps}>
          <Space
            p="SM"
            css={{
              gap: '$4',
              d: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px $neutralLighten90 solid'
            }}
          >
            {title && <DialogTitle as={Heading5}>{title}</DialogTitle>}

            <DialogClose asChild>
              <Button
                onClick={() =>
                  setOpenControlledDialog && setOpenControlledDialog(false)
                }
                variant="secondary"
                noBorder={true}
                icon={true}
                aria-label="Close"
                {...closeButtonProps}
              >
                <CloseIcon16 />
              </Button>
            </DialogClose>
          </Space>

          <Space
            px="SM"
            css={{
              flex: 1,
              overflowY: 'auto',
              py: '$12',
              '@md': { py: '$6' },
              ...innerContentCss
            }}
          >
            {children}
          </Space>

          {footer && (
            <Space
              p="SM"
              css={{
                p: '$6',
                borderTop: '1px $neutralLighten90 solid',
                ...(footerCss ?? {})
              }}
            >
              {footer}
            </Space>
          )}
        </StyledContent>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}
