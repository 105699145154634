import React, { useState } from 'react'
import { User } from '../../api'
import { TextLink } from '@sefar/design-system'
import { SelectUserDialog } from '../../components'

export function NewsAuthorDialog({
  onUpdate
}: {
  onUpdate: (user: User) => void
}) {
  const [openImplementerDialog, setOpenImplementerDialog] = useState(false)

  return (
    <>
      <TextLink onClick={() => setOpenImplementerDialog(true)}>Edit</TextLink>
      <SelectUserDialog
        title="Author"
        description="Edit the author ot the article. If you don't change it, the creator of the article will be displayed as the author."
        open={openImplementerDialog}
        setOpen={setOpenImplementerDialog}
        onSetImplementer={(implementer: User) => {
          onUpdate(implementer)
          setOpenImplementerDialog(false)
        }}
      />
    </>
  )
}
