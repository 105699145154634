import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Body5, Button, Dialog, Space } from '@sefar/design-system'
import { useOrderState } from '../hooks/use-order-state'
import { purchaseRequisitionsPageUrl } from '../../../../app'
import { useTranslate } from '../../../../hooks/useTranslate'

export function CloseConfirmationDialog({
  isOpen = false,
  onClose
}: {
  isOpen?: boolean
  onClose: () => void
}) {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const { reset } = useOrderState()

  const onConfirm = () => {
    navigate(`/${purchaseRequisitionsPageUrl}`)
    reset()
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      title={t('field_purchase_close_confirmation_title')}
      footer={
        <Space
          css={{
            d: 'flex',
            justifyContent: 'flex-end',
            w: '100%',
            gap: '$2'
          }}
        >
          <Button variant="secondary" onClick={onClose}>
            {t('field_cancel')}
          </Button>
          <Button onClick={onConfirm}>{t('field_confirm')}</Button>
        </Space>
      }
      innerContentCss={{ maxWidth: '32rem' }}
    >
      <Body5>{t('field_purchase_close_confirmation_body')}</Body5>
    </Dialog>
  )
}
