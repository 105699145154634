import React, { useEffect } from 'react'
import { useMedia } from 'react-use'
import { Box, Carousel, CarouselSlide, Spinner } from '@sefar/design-system'
import { NewsArticleHomePreview } from './news-article-home-preview'
import { NewsArticle, useSpotlightNews } from '../../api'
import { BREAKPOINTS_QUERIES, styled } from '../../../../../../stitches.config'

export function HomeNewsPreview({
  setExcluded
}: {
  setExcluded: (exc: string[]) => void
}) {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)

  const { spotlightArticles } = useSpotlightNews()
  const firstArticle = spotlightArticles[0]
  useEffect(() => {
    if (spotlightArticles?.length) {
      const excludedNewsIds = []
      excludedNewsIds.push(
        ...spotlightArticles.map((article) => article.drupalInternalNid)
      )
      setExcluded(excludedNewsIds)
    }
  }, [spotlightArticles?.length, setExcluded])

  if (isLg) {
    return (
      <PreviewContainer
        css={{
          d: 'flex',
          flexDirection: 'column',
          position: 'relative',
          zIndex: 2,
          h: '100%',
          maxHeight: '700px',
          transition: 'none'
        }}
      >
        {!firstArticle ? (
          <Spinner css={{ flex: 1, '& circle': { stroke: '$white' } }} />
        ) : (
          <Box
            css={{
              d: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'hidden'
            }}
          >
            <Box
              css={{
                flex: 1,
                minHeight: '180px',
                pb: '30px',
                overflow: 'hidden'
              }}
            >
              <NewsArticleHomePreview
                key={firstArticle?.id}
                article={firstArticle}
                isLarge
              />
            </Box>
            <Box
              css={{
                d: 'flex',
                gap: '$5',
                h: '135px',
                pt: '30px',
                borderTop: '1px rgba(255, 255, 255, 0.5) solid',
                '@media screen and (max-height: 768px)': {
                  maxHeight: 'calc(100% - 120px - 9vh)'
                }
              }}
            >
              {spotlightArticles.slice(1, 3).map((article: NewsArticle) => (
                <NewsArticleHomePreview key={article.id} article={article} />
              ))}
            </Box>
          </Box>
        )}
      </PreviewContainer>
    )
  }

  const mobileSecondSlideNewsList = spotlightArticles.slice(1, 3)

  return (
    <Carousel
      centeredSlides
      spaceBetween={16}
      slidesPerView={1.17}
      pagination={{ clickable: true }}
      css={{ w: '100%', height: '55vh' }}
    >
      <CarouselSlide>
        <PreviewContainer
          css={{
            d: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: '0',
            h: '100%'
          }}
        >
          {!firstArticle ? (
            <Spinner css={{ '& circle': { stroke: '$white' } }} />
          ) : (
            <NewsArticleHomePreview
              key={firstArticle?.id}
              article={firstArticle}
              isLarge
              isMobile
            />
          )}
        </PreviewContainer>
      </CarouselSlide>
      <CarouselSlide>
        <PreviewContainer
          css={{
            d: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '$5',
            h: '100%'
          }}
        >
          {!firstArticle ? (
            <Spinner css={{ '& circle': { stroke: '$white' } }} />
          ) : (
            mobileSecondSlideNewsList.map((article: NewsArticle) => (
              <NewsArticleHomePreview
                key={article.id}
                article={article}
                isMobile
              />
            ))
          )}
        </PreviewContainer>
      </CarouselSlide>
    </Carousel>
  )
}

const PreviewContainer = styled(Box, {
  gc: '1 / span 12',
  color: '$white',
  borderRadius: 12,
  padding: '30px',
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(30px)',
  transition: 'none !important'
})
