import React, { useEffect, useState } from 'react'
import {
  Body5,
  Box,
  Button,
  ChevronDownIcon16,
  DateRange,
  DateRangeDateOutput,
  Dialog,
  DropdownMenu,
  DropdownMenuItem,
  Label,
  Space,
  Switch,
  TextArea
} from '@sefar/design-system'
import dateFns from 'date-and-time'
import { NewsArticle, SpaceArticle, ReviewStateEnum } from '../../api'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { SendMessageDialog } from '../dialogs/send-message-dialog'

export function ActionsMenuDropdown({
  article,
  isReadyToSave,
  onChangePublicationDates,
  onSendBackToReviewerClick,
  onReviewDoneClick
}: {
  article: SpaceArticle | NewsArticle
  isReadyToSave: boolean
  onChangePublicationDates: (dates: DateRangeDateOutput) => void
  onSendBackToReviewerClick?: (feedback: string) => void
  onReviewDoneClick?: () => void
}) {
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const [openDateDialog, setOpenDateDialog] = useState<boolean>(false)
  const [openMessageDialog, setOpenMessageDialog] = useState<boolean>(false)
  const [endDateEnabled, setEndDateEnabled] = useState<boolean>(false)
  const [publicationDates, setPublicationDates] =
    useState<DateRangeDateOutput>()

  useEffect(() => {
    setPublicationDates({
      start: article?.publishDate ? new Date(article?.publishDate) : undefined,
      end: article?.unpublishDate ? new Date(article?.unpublishDate) : undefined
    })
  }, [article?.unpublishDate, article?.publishDate])

  return (
    <>
      <DropdownMenu
        trigger={() => (
          <Button
            disabled={isSm ? false : !isReadyToSave}
            variant={article?.status ? 'secondary' : 'primary'}
            icon={true}
            className="icon only-icon"
            data-cy="primaryArticleButtonDropdown"
            css={{
              pointerEvents: !isReadyToSave ? 'none' : 'unset'
            }}
          >
            <ChevronDownIcon16 />
          </Button>
        )}
        content={(setOpen) => (
          <>
            {publicationDates?.start && (
              <DropdownMenuItem
                onClick={(e) => {
                  setPublicationDates({ ...publicationDates, start: null })
                  onChangePublicationDates({
                    ...publicationDates,
                    start: null
                  })
                }}
              >
                <Body5 as="span">
                  Cancel publication on{' '}
                  {dateFns.format(publicationDates?.start, 'DD/MM/YYYY, HH:mm')}
                </Body5>
              </DropdownMenuItem>
            )}
            {publicationDates?.end && (
              <DropdownMenuItem
                onClick={(e) => {
                  setPublicationDates({ ...publicationDates, end: null })
                  onChangePublicationDates({
                    ...publicationDates,
                    end: null
                  })
                }}
              >
                <Body5 as="span">
                  Cancel unpublish on{' '}
                  {dateFns.format(publicationDates?.end, 'DD/MM/YYYY, HH:mm')}
                </Body5>
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              onClick={(e) => {
                setOpen(false)
                setOpenDateDialog(true)
              }}
            >
              <Body5 as="span">
                {!article?.status
                  ? 'Set publication date'
                  : 'Set end of publication'}
              </Body5>
            </DropdownMenuItem>

            {article?.reviewState === ReviewStateEnum['On review'] && (
              <>
                <DropdownMenuItem
                  onClick={(e) => {
                    setOpen(false)
                    setOpenMessageDialog(true)
                  }}
                  data-cy="sendBackButton"
                >
                  <Body5 as="span">Send back to author</Body5>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={onReviewDoneClick}
                  data-cy="completeReviewButton"
                >
                  <Body5 as="span">Complete Review</Body5>
                </DropdownMenuItem>
              </>
            )}
          </>
        )}
        contentProps={{ align: 'center', side: 'top' }}
      />

      <Dialog
        setOpenControlledDialog={setOpenDateDialog}
        open={openDateDialog}
        title={`Set ${article?.status ? 'unpublication' : 'Publication'} Date`}
        footer={
          <Box css={{ d: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={
                endDateEnabled
                  ? article?.status
                    ? !publicationDates?.end
                    : !(publicationDates?.end && publicationDates?.start)
                  : !publicationDates?.start
              }
              onClick={() => {
                publicationDates && onChangePublicationDates(publicationDates)
                setOpenDateDialog(false)
              }}
              variant="primary"
            >
              Save
            </Button>
          </Box>
        }
      >
        <DateRange
          publicationDates={publicationDates}
          initialEnd={
            (article?.unpublishDate && new Date(article?.unpublishDate)) ||
            undefined
          }
          initialStart={
            (article?.publishDate && new Date(article?.publishDate)) ||
            undefined
          }
          startDateEnabled={!article?.status}
          endDateEnabled={endDateEnabled || article?.status}
          onChange={setPublicationDates}
        />

        {!article?.status && (
          <Space mt="MD" mb="XS" css={{ d: 'flex', alignItems: 'center' }}>
            <Switch
              id="end-date-switcher"
              checked={endDateEnabled}
              onCheckedChange={setEndDateEnabled}
            ></Switch>
            <Label htmlFor="end-date-switcher">
              Expiration date (unpublish)
            </Label>
          </Space>
        )}
      </Dialog>

      <SendMessageDialog
        onSetMessage={onSendBackToReviewerClick}
        dialogProps={{
          open: openMessageDialog,
          setOpenControlledDialog: setOpenMessageDialog,
          title: 'Send back to Author'
        }}
        description="Add your feedback to the author of the article."
        saveButtonLabel="Send to Author"
        textAreaProps={{
          placeholder: 'Add your feedback to the author of the article.'
        }}
      ></SendMessageDialog>
    </>
  )
}
