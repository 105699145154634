import { IdeaStatuses } from '../../api'

export const mapIdeaStatusesToBadgeColors = {
  [IdeaStatuses['Not Considered']]: 'grayed',
  [IdeaStatuses['Review Pending']]: 'pendingLight',
  [IdeaStatuses.New]: 'pendingLight',
  [IdeaStatuses.Approved]: 'success',
  [IdeaStatuses.Implemented]: 'success',
  [IdeaStatuses.Rewarded]: 'success',
  [IdeaStatuses.Rejected]: 'error',
  [IdeaStatuses['Implementation in Progress']]: 'progressLight'
} as const
