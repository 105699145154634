import {
  Body5,
  Box,
  Button,
  Dialog,
  DialogClose,
  Space,
  TextArea
} from '@sefar/design-system'
import React, { useState } from 'react'

export function SendMessageDialog({
  description,
  saveButtonLabel = 'Send',
  onSetMessage,
  textAreaProps,
  dialogProps
}: {
  description?: string
  saveButtonLabel?: string
  onSetMessage?: (message: string) => void
  textAreaProps?: React.ComponentProps<typeof TextArea>
  dialogProps?: React.ComponentProps<typeof Dialog>
}) {
  const [feedBackMessage, setFeedBackMessage] = useState<string>('')

  return (
    <Dialog
      contentProps={{ css: { '@lg': { width: 550 } } }}
      footer={
        <Box css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Button
            as={DialogClose}
            onClick={() => {
              onSetMessage && onSetMessage(feedBackMessage)
            }}
            variant="primary"
            data-cy="submitMessageButton"
          >
            {saveButtonLabel}
          </Button>
        </Box>
      }
      {...dialogProps}
    >
      {description && (
        <Space mb="MD">
          <Body5>{description}</Body5>
        </Space>
      )}

      <TextArea
        value={feedBackMessage}
        label="Comments"
        id="feedback"
        data-cy="feedbackMessage"
        name="feedback"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setFeedBackMessage(e.target.value)
        }
        css={{ minHeight: '200px' }}
        {...textAreaProps}
      />
    </Dialog>
  )
}
