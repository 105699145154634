import { Body5 } from '../typography'
import { styled } from '../../../stitches.config'

export const Tag = styled(Body5, {
  d: 'inline-block',
  color: '$neutral',
  backgroundColor: '$neutralLighten93',
  py: '$2',
  px: '$3',
  borderRadius: '$3',
  transition: '$default',
  '&:hover': {
    backgroundColor: '$destructiveDisabled'
  },
  '&.active': {
    backgroundColor: '$neutralLighten10',
    color: '$white'
  }
})
