import { MutableRefObject, useEffect, useState } from 'react'

type TContainerSize = {
  width: number
  height: number
}

export const useContainerSize = (
  containerRef: MutableRefObject<HTMLDivElement | null>,
  isHidden = false
) => {
  const [containerSize, setContainerSize] = useState<TContainerSize>({
    width: 0,
    height: 0
  })

  useEffect(() => {
    if (!isHidden && containerRef.current) {
      const observer = new ResizeObserver((entries) => {
        const elSizes = entries[0].target.getBoundingClientRect()
        setContainerSize({
          width: elSizes.width,
          height: elSizes.height
        })
      })
      observer.observe(containerRef.current)
      return () => observer.disconnect()
    }
  }, [isHidden])

  return containerSize
}
