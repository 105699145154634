import React from 'react'
import { LazyImage, Box, PlayVideoEnabled } from '@sefar/design-system'
import { MEDIA_TYPES } from '../../api/media'

export function PreviewImage({
  previewMedia,
  mediaType,
  iconSize = '24',
  iconCentered = false,
  wrapperCss = {},
  imageCss = {},
  iconStyles = {}
}: {
  previewMedia: string | undefined
  mediaType?: string | undefined
  iconSize?: string
  iconCentered?: boolean
  wrapperCss?: Record<string, string>
  imageCss?: Record<string, string>
  iconStyles?: Record<string, string>
}) {
  return (
    <Box
      css={{
        position: 'relative',
        width: '100%',
        height: '100%',
        d: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...wrapperCss
      }}
    >
      <LazyImage
        src={previewMedia}
        aspectRatio="1 / 1"
        css={{
          borderRadius: '$4',
          ...imageCss
        }}
      />
      {mediaType && MEDIA_TYPES.video.includes(mediaType) && (
        <Box
          as={PlayVideoEnabled}
          css={{
            position: 'absolute',
            left: iconCentered ? '50%' : '3%',
            bottom: iconCentered ? '50%' : '2%',
            transform: `translateY(${iconCentered ? '50%' : 0}) translateX(${
              iconCentered ? '-50%' : 0
            })`,
            width: `${iconSize}px`,
            height: `${iconSize}px`,
            ...iconStyles
          }}
        />
      )}
    </Box>
  )
}
