import { Body5, Box, Heading5 } from '@sefar/design-system'
import { PurchaseRequisitionOrder } from '../types'
import { useTranslate } from '../../../../hooks/useTranslate'
import { useConfigStore } from '../../../../state/config'
import { USER_ROLES } from '../../../../api'

export const OrderLNCommentsHistory = ({
  order
}: {
  order: PurchaseRequisitionOrder
}) => {
  const { t } = useTranslate()
  const { me } = useConfigStore()

  if (!order.lnComments?.length || !me?.roles?.includes(USER_ROLES.approver))
    return null

  return (
    <Box
      css={{
        backgroundColor: '$neutralLighten97',
        mx: '-$8',
        px: '$8',
        pt: '$10',
        pb: '$14'
      }}
    >
      <Box css={{ mx: 'auto', maxWidth: '54rem' }}>
        <Heading5 css={{ mb: '$6' }}>
          {t('field_purchase_requisition_comment_from_ln')}
        </Heading5>
        <Box css={{ d: 'flex', flexDirection: 'column', gap: '$4' }}>
          {order.lnComments?.map((commentData) => (
            <Box
              css={{
                d: 'flex',
                backgroundColor: '$white',
                borderRadius: '$6',
                p: '$4'
              }}
            >
              <Box css={{ flex: 1, p: '$2' }}>
                <Body5 css={{ wordBreak: 'break-word' }}>
                  {commentData.text}
                </Body5>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
