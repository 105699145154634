import {
  Badge,
  Body4,
  Body5,
  Body7,
  Box,
  ChevronDownIcon16,
  Dialog,
  DropdownMenu,
  DropdownMenuItem,
  FeedbackIcon16,
  Space,
  Spinner,
  Tooltip
} from '@sefar/design-system'
import {
  Idea,
  IdeaGroupStatus,
  IdeaStatusesTranslations,
  useIdeas
} from '../../api'
import { useTranslate } from '../../hooks/useTranslate'
import { useConfigStore } from '../../state/config'
import MyIdeasIcon from '../../../assets/MyIdeas.png'
import { Link } from 'react-router-dom'
import { ideasPageUrl, profilePageUrl } from '../../app'
import { TR, TD } from '../my-content/common'
import {
  likeCounterTableStyles,
  LikesCounter
} from '../../components/article/article-info'
import { DateOfRealization } from '../../components/ideas/date-of-realization'
import { mapIdeaStatusesToBadgeColors } from '../../components/ideas/utils'
import { UserAvatar } from '../../components/common/user-avatar'
import { CommentsCounter } from '../../components/common/comments-counter'
import { useMemo, useState } from 'react'
import { sub, getUnixTime, startOfYear, startOfMonth } from 'date-fns'

const TopIdeas = () => {
  const { me } = useConfigStore.getState()
  const { t } = useTranslate()
  const periodOptions = useMemo(
    () => ({
      week: t('field_idea_week'),
      month: t('field_idea_month'),
      year: t('field_idea_year')
    }),
    [t]
  )
  const [period, setPeriod] = useState<'week' | 'month' | 'year'>('month')
  const createdAfterTimestamp = useMemo(() => {
    switch (period) {
      case 'week':
        return getUnixTime(sub(new Date(), { days: 7 }))
      case 'month':
        return getUnixTime(startOfMonth(new Date()))
      case 'year':
        return getUnixTime(startOfYear(new Date()))
    }
  }, [period])
  const { ideas, isLoading } = useIdeas({
    limit: 3,
    createdAfter: createdAfterTimestamp,
    mostLiked: true,
    type: IdeaGroupStatus.Progress
  })
  return (
    <Box
      css={{
        backgroundColor: '$white',
        height: 256,
        border: '1px solid #EFEFEF',
        borderRadius: '$2',
        p: '$4',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box css={{ display: 'flex', alignItems: 'center', mb: '$2' }}>
        <Body4 fontWeight="bold" css={{ mr: '$4' }}>
          {t('field_idea_top_ideas')}
        </Body4>
        <DropdownMenu
          trigger={() => (
            <Box
              css={{
                cursor: 'pointer',
                fontWeight: '$semiBold',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {periodOptions[period]}
              <ChevronDownIcon16 />
            </Box>
          )}
          content={(setOpen) => (
            <>
              <DropdownMenuItem
                onClick={(e) => {
                  setOpen(false)
                  setPeriod('week')
                }}
              >
                {periodOptions['week']}
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={(e) => {
                  setOpen(false)
                  setPeriod('month')
                }}
              >
                {periodOptions['month']}
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={(e) => {
                  setOpen(false)
                  setPeriod('year')
                }}
              >
                {periodOptions['year']}
              </DropdownMenuItem>
            </>
          )}
          contentProps={{
            css: { maxWidth: 220 }
          }}
        />
      </Box>
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          flexDirection: 'column'
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : ideas?.length ? (
          <table style={{ width: '100%', height: 1 }}>
            {ideas.map((idea: Idea) => (
              <TR
                hover={idea.implementer?.id !== me?.id}
                css={{
                  backgroundColor:
                    idea.implementer?.id === me?.id
                      ? '$primaryBlueLighten95'
                      : '',
                  '&:hover': {
                    backgroundColor:
                      idea.implementer?.id === me?.id
                        ? '$primaryBlueLighten90'
                        : ''
                  },
                  height: '100%',
                  width: '100%'
                }}
              >
                <TD
                  css={{
                    paddingTop: '$2',
                    paddingBottom: '$2',
                    height: '100%',
                    verticalAlign: 'bottom'
                  }}
                >
                  <LikesCounter
                    article={idea}
                    likeProps={{ css: likeCounterTableStyles }}
                  />
                </TD>
                <TD
                  css={{
                    paddingTop: '$2',
                    paddingBottom: '$2',
                    height: '100%',
                    verticalAlign: 'bottom'
                  }}
                >
                  <Box css={{ position: 'relative' }}>
                    <Box
                      css={{
                        d: 'flex',
                        flexDirection: 'column',
                        alignItems: 'baseline'
                      }}
                    >
                      <Body5
                        fontWeight="bold"
                        as={Link}
                        to={`/${ideasPageUrl}/${idea.id}`}
                        css={{
                          textDecoration: 'none',
                          color: '$neutral',
                          position: 'relative'
                        }}
                      >
                        {idea.title}
                      </Body5>
                      <Box
                        css={{
                          d: 'flex',
                          gap: '$1',
                          flexDirection: 'column',
                          '@lg': { flexDirection: 'row' }
                        }}
                      >
                        <Badge
                          status={mapIdeaStatusesToBadgeColors[idea.status]}
                        >
                          {t(IdeaStatusesTranslations[idea.status])}
                        </Badge>
                        <DateOfRealization idea={idea} />
                      </Box>
                    </Box>
                  </Box>
                </TD>
                <TD
                  css={{
                    paddingTop: '$2',
                    paddingBottom: '$2',
                    height: '100%',
                    verticalAlign: 'bottom'
                  }}
                >
                  <Link
                    to={`/${profilePageUrl}/${idea.author?.id}`}
                    style={{
                      display: 'flex',
                      height: '100%',
                      alignItems: 'center',
                      textDecoration: 'none',
                      color: 'inherit'
                    }}
                  >
                    <UserAvatar user={idea.author} />
                    <Box
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: '$3',
                        lineHeight: '$1'
                      }}
                    >
                      <Body5>{idea.author.firstName}</Body5>
                      <Body5>{idea.author.lastName}</Body5>
                    </Box>
                  </Link>
                </TD>
                <TD
                  css={{
                    paddingTop: '$2',
                    paddingBottom: '$2',
                    height: '100%',
                    verticalAlign: 'bottom',
                    '@sm': { display: 'none' }
                  }}
                >
                  <Box
                    css={{
                      d: 'flex',
                      alignItems: 'center',
                      gap: '$4',
                      height: '100%'
                    }}
                  >
                    <Box>
                      <Dialog
                        trigger={
                          <Box
                            css={{
                              pointerEvents: idea?.feedback ? 'auto' : 'none',
                              cursor: idea?.feedback ? 'pointer' : 'default'
                            }}
                          >
                            <Tooltip
                              text={t('field_feedback')}
                              withArrow={false}
                              contentProps={{ css: { padding: '$1' } }}
                            >
                              <Box
                                css={{
                                  d: 'flex',
                                  alignItems: 'center',
                                  gap: '$1',
                                  '&:hover': {
                                    '& svg': {
                                      stroke: '$primaryBlue',
                                      '& path': {
                                        stroke: '$primaryBlue'
                                      }
                                    }
                                  },
                                  '& svg': {
                                    transition: '$default',
                                    '& path': {
                                      transition: '$default'
                                    }
                                  }
                                }}
                              >
                                <FeedbackIcon16 />
                                <Body7>{idea?.feedback ? 1 : 0}</Body7>
                              </Box>
                            </Tooltip>
                          </Box>
                        }
                        title={'Feedback'}
                        contentProps={{ css: { '@lg': { width: 550 } } }}
                      >
                        <Space mb="SM">
                          <Body5>{idea?.feedback}</Body5>
                        </Space>
                      </Dialog>
                    </Box>
                    <Tooltip
                      text={t('field_comments_count_of_comments')}
                      withArrow={false}
                      contentProps={{ css: { padding: '$1' } }}
                    >
                      <Box
                        css={{
                          textDecoration: 'none',
                          color: '$neutral',
                          position: 'relative',
                          cursor: 'pointer'
                        }}
                        as={Link}
                        to={`/${ideasPageUrl}/${idea.id}#comments-section`}
                      >
                        <CommentsCounter count={idea?.commentCount} small />
                      </Box>
                    </Tooltip>
                  </Box>
                </TD>
              </TR>
            ))}
          </table>
        ) : (
          <img src={MyIdeasIcon} alt="my-ideas" />
        )}
      </Box>
    </Box>
  )
}

export default TopIdeas
