import {
  Body5,
  Box,
  Button,
  Dialog,
  Space,
  TextArea
} from '@sefar/design-system'
import React, { useState } from 'react'

export function NotConsideredDialog({
  open,
  setOpen,
  onSetNotConsidered
}: {
  open: boolean
  setOpen: (open: boolean) => void
  onSetNotConsidered: (feedBackMessage: string) => void
}) {
  const [feedBackMessage, setFeedBackMessage] = useState<string>('')

  return (
    <Dialog
      onOpenChange={() => {
        setFeedBackMessage('')
      }}
      setOpenControlledDialog={setOpen}
      open={open}
      title={'Idea not considered: give feedback'}
      contentProps={{ css: { '@lg': { width: 550 } } }}
      footer={
        <Box css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={!feedBackMessage}
            onClick={() => onSetNotConsidered(feedBackMessage)}
            variant="primary"
          >
            Set idea to not considered
          </Button>
        </Box>
      }
    >
      <Space mb="MD">
        <Body5>
          Give a brief explanation why this idea can not be considered.
        </Body5>
      </Space>

      <TextArea
        value={feedBackMessage}
        label="Explain your decision*"
        id="feedback"
        name="feedback"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setFeedBackMessage(e.target.value)
        }
        css={{ minHeight: '200px' }}
      />
    </Dialog>
  )
}
