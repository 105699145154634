import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { Space, Dialog, Box, Body6 } from '@sefar/design-system'
import { useDebounce } from 'react-use'
import { Gallery } from './gallery'
import { DamFilters } from './filters'
import {
  DamSearchFiltersType,
  SelectedValuesType,
  FileTypeEnum
} from '../../api'

type GalleryDialogPropsTypes = {
  title?: string
  setOpenDialog: (prop: boolean) => void
  open: boolean
  chosenFile?: any
  setChosenFile?: (file: any) => void
  filterCategories: SelectedValuesType
  filterCountries: SelectedValuesType
  allowedFormats?: FileTypeEnum[]
  assetsType?: string | Array<string>
  footer?: ReactNode
  children?: ReactNode
}

export const filtersDefaultState = {
  fileTypes: [],
  countries: [],
  categories: []
}

export const GalleryDialog = ({
  title,
  setOpenDialog,
  open,
  chosenFile,
  setChosenFile,
  filterCategories,
  filterCountries,
  allowedFormats,
  assetsType,
  footer,
  children
}: GalleryDialogPropsTypes) => {
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined)
  const [filters, setFilters] =
    useState<DamSearchFiltersType>(filtersDefaultState)
  const [searchText, setSearchText] = useState<string>('')
  const [debouncedSearchText, setDebouncedSearchText] = useState<string>('')

  useDebounce(
    () => {
      setDebouncedSearchText(searchText)
    },
    500,
    [searchText]
  )

  useEffect(() => {
    if (!open && setChosenFile) {
      setChosenFile(undefined)
    }
  }, [open])

  return (
    <Dialog
      title={title || 'Select media'}
      setOpenControlledDialog={setOpenDialog}
      open={open}
      contentProps={{
        css: {
          width: '100%',
          overflow: 'hidden',
          height: '100vh',
          minHeight: 'auto',
          '@md': {
            maxHeight: 'calc(100vh - 60px)'
          },
          '@xxl': {
            maxWidth: '1300px',
            maxHeight: 'calc(100vh - 80px)',
            minHeight: '600px'
          }
        }
      }}
      footer={footer}
      innerContentCss={{
        pt: '$4',
        pb: '$2',
        overflow: 'hidden',
        '@lg': {
          py: '$6'
        }
      }}
      footerCss={{ maxHeight: '10%' }}
    >
      {children}
      <DamFilters
        filters={filters}
        setFilters={setFilters}
        setSearchText={setSearchText}
        searchText={searchText}
        filterCategories={filterCategories}
        filterCountries={filterCountries}
        allowedFormats={allowedFormats}
      />
      {(totalCount || totalCount === 0) && (
        <Space mb="XS">
          <Body6>
            {totalCount} results for "{debouncedSearchText}"
          </Body6>
        </Space>
      )}
      <Box
        css={{
          height: '100%',
          maxHeight: '75%',
          overflowY: 'auto'
        }}
      >
        <Gallery
          filters={filters}
          searchText={debouncedSearchText}
          setTotalCount={setTotalCount}
          chosenFile={chosenFile}
          setChosenFile={setChosenFile}
          assetsType={assetsType}
        />
      </Box>
    </Dialog>
  )
}
