import React, { ComponentProps } from 'react'
import { Box } from '../utils'
import { ChevronLeftIcon14 } from '../icons'
import { styled } from '../../../stitches.config'
import { Body6Styles } from '../typography'

const BackLinkWrapper = styled('a', {
  ...Body6Styles,
  textDecoration: 'none',
  outlineColor: '$primaryBlue',
  d: 'inline-flex',
  gap: '$1',
  alignItems: 'center',
  color: '$neutralLighten30',
  p: '$1 $2 $1 $1',
  cursor: 'pointer',
  borderRadius: '$3',

  '&:hover, &.__hover': {
    color: '$neutral',
    backgroundColor: '$neutralLighten93'
  },
  '&:focus, &.__focus': {
    color: '$neutral',
    outline: '2px $primaryBlue solid'
  }
})
export function BackLink({
  paths,
  ...props
}: ComponentProps<typeof BackLinkWrapper> & { paths: string[] }) {
  return (
    <BackLinkWrapper {...props}>
      <ChevronLeftIcon14></ChevronLeftIcon14>
      <Box
        css={{
          d: 'flex',
          gap: '$1'
        }}
      >
        {paths?.map((path, index) =>
          index + 1 === paths?.length ? (
            <Box key={index}>{path} </Box>
          ) : (
            <Box key={index}>{path} /</Box>
          )
        )}
      </Box>
    </BackLinkWrapper>
  )
}
