import { useEffect, useState } from 'react'

export enum ScrollDirectionEnum {
  Up = 'Up',
  Down = 'Down'
}

const SCROLL_MIN_STEP = 2

// check scroll direction (down or up) on window scroll
export function useScrollDirection() {
  const [scrollDirection, setScrollDirection] =
    useState<ScrollDirectionEnum | null>(null)
  const [currentScrollY, setCurrentScrollY] = useState<number>(window.scrollY)

  useEffect(() => {
    let lastScrollY = window.pageYOffset

    const updateScrollDirection = () => {
      setCurrentScrollY(window.scrollY)
      const scrollY = window.pageYOffset
      const direction =
        scrollY > lastScrollY
          ? ScrollDirectionEnum.Down
          : ScrollDirectionEnum.Up
      if (
        direction !== scrollDirection &&
        Math.abs(scrollY - lastScrollY) > SCROLL_MIN_STEP
      ) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }
    window.addEventListener('scroll', updateScrollDirection, true) // add event listener

    return () => {
      window.removeEventListener('scroll', updateScrollDirection, true) // clean up
    }
  }, [scrollDirection])

  return [scrollDirection, currentScrollY]
}
