import React, { useState } from 'react'
import { Body5, Button, Dialog, TextArea, Space } from '@sefar/design-system'
import { useTranslate } from '../../../../hooks/useTranslate'

export function RejectConfirmationDialog({
  isOpen = false,
  onSubmit,
  onClose
}: {
  isOpen?: boolean
  onSubmit: (comment: string) => void
  onClose: () => void
}) {
  const [comment, setComment] = useState('')
  const { t } = useTranslate()

  const onConfirm = () => {
    onSubmit(comment)
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      closeButtonProps={{ onClick: onClose }}
      title={t('field_purchase_requisition_actions_reject')}
      footer={
        <Space
          css={{
            d: 'flex',
            justifyContent: 'flex-end',
            w: '100%',
            gap: '$2'
          }}
        >
          <Button onClick={onConfirm}>
            {t('field_purchase_requisition_actions_reject')}
          </Button>
        </Space>
      }
      innerContentCss={{ maxWidth: '32rem' }}
    >
      <Body5>{t('field_purchase_requisition_reject_message')}</Body5>
      <TextArea
        label={t('field_purchase_requisition_confirmation_comment')}
        value={comment}
        onChange={({ currentTarget }) => setComment(currentTarget.value)}
        containerProps={{ css: { mt: '$4' } }}
        css={{ resize: 'none', minHeight: '9rem' }}
      />
    </Dialog>
  )
}
