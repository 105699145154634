import { Body4, Box } from '@sefar/design-system'
import { MAP_STEP_NAME_TO_LABEL_KEY, STEPS_ORDER } from '../consts'
import { useOrderSteps } from '../hooks/use-order-steps'
import { useTranslate } from '../../../../hooks/useTranslate'

export const StepsProgress = () => {
  const { t } = useTranslate()
  const { currentStepIndex } = useOrderSteps()
  const stepsAmount = STEPS_ORDER.length
  const progress = currentStepIndex / (stepsAmount - 1)

  return (
    <Box
      css={{
        position: 'relative',
        w: '45rem',
        maxWidth: '100%',
        mb: '$16',
        d: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Box
        css={{
          position: 'absolute',
          top: '1rem',
          left: 0,
          mx: '2.5rem',
          w: 'calc(100% - 5rem)',
          h: '0.125rem',
          backgroundColor: '$neutralLighten80',
          overflow: 'hidden'
        }}
      >
        <Box
          css={{
            w: `${progress * 100}%`,
            h: '100%',
            backgroundColor: '$primaryBlue'
          }}
        />
      </Box>
      {STEPS_ORDER.map((step, index) => (
        <Box css={{ w: '5rem', zIndex: 1 }} key={step}>
          <Box
            css={{
              w: '2rem',
              mx: 'auto',
              h: '2rem',
              d: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              backgroundColor:
                index <= currentStepIndex
                  ? '$primaryBlue'
                  : '$neutralLighten80',
              color: '$white'
            }}
          >
            {index + 1}
          </Box>
          <Body4
            css={{
              mt: '$1',
              textAlign: 'center',
              color: index <= currentStepIndex ? '$black' : '$neutralLighten80'
            }}
          >
            {t(MAP_STEP_NAME_TO_LABEL_KEY[step])}
          </Body4>
        </Box>
      ))}
    </Box>
  )
}
