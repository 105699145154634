import React, { useEffect, useState } from 'react'
import Logo from '../../../assets/logo.svg?react'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import AuthCode from 'react-auth-code-input'
import {
  AlertFilledIcon24,
  Body5,
  Body6,
  Body7,
  Box,
  Button,
  Heading5,
  Space,
  ContainerPadding,
  TextLink,
  ArrowLeftIcon16,
  ReactSelect,
  SelectOption
} from '@sefar/design-system'
import { useMedia } from 'react-use'
import {
  mixpanelAnalyticsHelper,
  prepareLanguagesForSelect
} from '../../components'
import { useDebounce } from 'react-use'
import {
  useMe,
  clearLocalStorage,
  loginByPhone,
  authorizeByPhone,
  useLanguages
} from '../../api'
import { useNavigate } from 'react-router-dom'
import { SamlLoginHelper } from './samlLoginHelper'
import { useConfigStore } from '../../state/config'
import { useTranslate } from '../../hooks/useTranslate'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import { Card } from '../../components/common/card'
import { sessionStorageKeys } from '../../constants'

const SMS_CODE_LENGTH = 6

const DEFAULT_LANG_OPTION = {
  value: 'en',
  label: 'EN'
}

export function Login() {
  const { languages } = useLanguages()
  const { t } = useTranslate()
  const { contentLang, setContentLang } = useConfigStore()
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const { setId } = useConfigStore()
  const [isError] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [phoneAuthToken, setPhoneAuthToken] = useState<string>('')
  const [isEnterCodeStep, setIsEnterCodeStep] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const [seconds, setSeconds] = useState(0)
  const [selectedContentLang, setSelectedContentLang] =
    useState<SelectOption>(DEFAULT_LANG_OPTION)
  const navigate = useNavigate()
  const { me, isError: isMeError, isLoading } = useMe()

  useDebounce(
    async () => {
      if (selectedContentLang && selectedContentLang?.value !== contentLang) {
        const oldLang = contentLang
        setContentLang(selectedContentLang?.value)
      }
    },
    300,
    [selectedContentLang]
  )

  useEffect(() => {
    if (!isLoading) {
      if (me?.id) {
        navigate('/')
      } else {
        clearLocalStorage()
      }
    }
  }, [me, isMeError, isLoading])

  const onSendCodeBtnClick = () => {
    if (phoneNumber) {
      let preparedPhoneNumber = phoneNumber
      if (phoneNumber.substring(0, 1) !== '+') {
        preparedPhoneNumber = `+${phoneNumber}`
      }
      sessionStorage.setItem(sessionStorageKeys.LOGGED_IN_BY, 'Phone number')
      loginByPhone(preparedPhoneNumber).then((res) => {
        if (res.token) {
          setPhoneAuthToken(res.token)
          setIsEnterCodeStep(true)
          setSeconds(59)
        }
      })
    }
  }

  const onLoginByPhoneBtnClick = () => {
    code &&
      code.length === SMS_CODE_LENGTH &&
      phoneAuthToken &&
      authorizeByPhone(phoneAuthToken, code).then((res) => {
        if (res?.csrf_token && res?.current_user?.uid) {
          const id = res.current_user.uid
          setId(id)
          mixpanelAnalyticsHelper().alias(id)
          navigate(`/?uid=${res.current_user.uid}`)
        }
      })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])
  return (
    <Box
      css={{
        backgroundColor: '$neutral',
        flex: 1,
        d: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {languages?.length && (
        <Box
          css={{
            top: 0,
            p: '$6',
            d: 'flex',
            w: '100%',
            justifyContent: isSm ? 'space-between' : 'flex-end',
            '@md': {
              position: 'absolute'
            }
          }}
        >
          {isSm && (
            <Box
              css={{
                maxWidth: '176px',
                width: '100%',
                d: 'flex',
                height: 48,
                mb: '$12',
                mr: '$3',
                color: '$white',
                '@xxs': {
                  maxWidth: '150px'
                }
              }}
            >
              <Logo />
            </Box>
          )}
          <ReactSelect
            value={selectedContentLang}
            options={prepareLanguagesForSelect(languages, true)}
            onChange={(option: SelectOption) => setSelectedContentLang(option)}
            css={{
              w: '96px',
              h: '40px',
              mb: '$3',
              '@sm': {
                w: '77px'
              }
            }}
            placeholder={t('field_prod_set_select_lang_text')}
          />
        </Box>
      )}
      <ContainerPadding
        css={{
          backgroundColor: '$neutral',
          flex: 1,
          d: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box css={{ maxWidth: 440, width: '100%' }}>
          {!isSm && (
            <Box
              css={{
                mb: '$12',
                d: 'flex',
                justifyContent: 'center',
                height: 48,
                color: '$white',
                '@md': {
                  mt: '$8',
                  mb: '$16'
                }
              }}
            >
              <Logo />
            </Box>
          )}

          <Card css={{ backgroundColor: 'white' }}>
            <Box css={{ px: '$6', py: '$12', '@md': { px: '$12' } }}>
              {isEnterCodeStep ? (
                <Box
                  css={{
                    d: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    '& .authCodeWrapper': {
                      d: 'flex',
                      gap: '$5',
                      '@sm': {
                        gap: '$3'
                      },
                      '@xs': {
                        gap: '$2'
                      },
                      '& .authCodeInput': {
                        size: 40,
                        border: '1px solid $neutralLighten80',
                        borderRadius: '$3',
                        caretColor: 'transparent',
                        textAlign: 'center',

                        '@xs': {
                          size: 35
                        },

                        '@xxs': {
                          width: '30px'
                        }
                      }
                    }
                  }}
                >
                  <Space mt="SM">
                    <Heading5>Enter Code</Heading5>
                  </Space>
                  <Space mt="LG">
                    <AuthCode
                      allowedCharacters="numeric"
                      onChange={(code) => setCode(code)}
                      inputClassName="authCodeInput"
                      containerClassName="authCodeWrapper"
                    />
                  </Space>
                  <Space mt="3XS" mb="XS" css={{ alignSelf: 'flex-start' }}>
                    {seconds ? (
                      <Body6>
                        {t('field_login_time_remaining')}: {seconds}
                      </Body6>
                    ) : (
                      <Body6>{t('field_login_no_code_question')}</Body6>
                    )}
                  </Space>
                  <Space mb="LG" css={{ w: '100%' }}>
                    <Button
                      data-cy="loginButton"
                      size="large"
                      css={{ w: '100%' }}
                      disabled={code.length < SMS_CODE_LENGTH}
                      onClick={onLoginByPhoneBtnClick}
                    >
                      {t('field_login_login_btn')}
                    </Button>
                  </Space>

                  <Space mb="LG" css={{ transition: '$default' }}>
                    <TextLink
                      data-cy="sendNewCodeButton"
                      className={`no-underline ${seconds ? 'disabled' : ''}`}
                      onClick={onSendCodeBtnClick}
                    >
                      {t('field_login_send_new_code')}
                    </TextLink>
                  </Space>

                  <TextLink
                    css={{ alignSelf: 'flex-start' }}
                    onClick={() => setIsEnterCodeStep(false)}
                  >
                    <span className="icon icon-left">
                      <ArrowLeftIcon16></ArrowLeftIcon16>
                    </span>
                    <span>{t('field_login_back_to_start')}</span>
                  </TextLink>
                </Box>
              ) : (
                <>
                  {isError && (
                    <Box
                      css={{
                        backgroundColor: '$errorLighten95',
                        py: '$3',
                        px: '$4',
                        mb: '$3',
                        d: 'flex',
                        gap: '$4',
                        alignItems: 'center'
                      }}
                    >
                      <Box css={{ color: '$errorLighten25' }}>
                        <AlertFilledIcon24 />
                      </Box>

                      <Body5 css={{ color: '$error' }}>
                        {t('field_login_error_msg')}
                      </Body5>
                    </Box>
                  )}

                  <Box
                    css={{
                      d: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <Box>
                      <SamlLoginHelper />
                    </Box>
                    <Space
                      my="MD"
                      css={{
                        position: 'relative',
                        width: '100%',
                        d: 'flex',
                        justifyContent: 'center',

                        '&:after, &:before': {
                          content: '',
                          position: 'absolute',
                          height: '1px',
                          background: '$neutralLighten90',
                          width: '42%',
                          top: '50%'
                        },

                        '&:after': {
                          left: 0
                        },
                        '&:before': {
                          right: 0
                        }
                      }}
                    >
                      <Body5>{t('field_or')}</Body5>
                    </Space>
                    <Space mb="MD" css={{ textAlign: 'center' }}>
                      <Heading5 css={{ mb: '$2' }}>
                        {t('field_login_login_with_phone')}
                      </Heading5>
                      <Body6 css={{ '@md': { px: '$2' } }}>
                        {t('field_login_no_worries_msg')}
                      </Body6>
                    </Space>

                    <Space mb="LG" css={{ width: '100%' }}>
                      <Body6 css={{ color: '$neutralLighten30', mb: '$2' }}>
                        {t('field_login_form_phone_number')}*
                      </Body6>
                      <ReactPhoneInput
                        country={'ch'}
                        inputStyle={{ width: '100%' }}
                        value={phoneNumber}
                        onChange={(number: string) => setPhoneNumber(number)}
                      />
                    </Space>

                    <Button
                      variant="secondary"
                      data-cy="PhoneLoginButton"
                      css={{ w: '100%' }}
                      disabled={!phoneNumber}
                      onClick={onSendCodeBtnClick}
                    >
                      {t('field_login_form_send_code')}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Card>

          <Body7 css={{ color: '$white', my: '$6' }}>
            *{t('field_login_notice_phone_msg')}
          </Body7>
        </Box>
      </ContainerPadding>
    </Box>
  )
}
