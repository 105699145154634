import React, { useEffect, useMemo, useRef } from 'react'
import {
  ArrowRightIcon16,
  Body4,
  Body5,
  Body3,
  Box,
  Container,
  ContainerPadding,
  Document24,
  Grid,
  MegaphoneIcon16,
  DashboardIcon16,
  PeopleIcon16,
  SearchNoResultsIcon48,
  ReactSelect,
  Space,
  Spinner,
  Tabs,
  TabsContent,
  TextLink,
  Pagination,
  ClockIcon14
} from '@sefar/design-system'
import {
  NewsArticle,
  SpaceArticle,
  SEARCH_PAGE_LIMIT,
  SearchEntityTypes,
  User,
  searchableCountriesMap,
  SubSpaceSimple
} from '../../api'
import { newsPageUrl, spacesPageUrl, subSpacePageUrl } from '../../app'
import {
  ArticleSearchResultCard,
  PeopleSearchResultCard,
  SearchTabList,
  SubSpaceSearchResultCardSmall,
  useSearchLogic
} from './search-utils'

import { SearchInput } from '../../components/search/search-input'
import { useTranslate } from '../../hooks/useTranslate'
import { useConfigStore } from '../../state/config'

type sortingOption = {
  value: string
  label: string
}

function onlyOne(a: any, b: any, c: any) {
  if (a && !b && !c) return true
  if (!a && b && !c) return true
  if (!a && !b && c) return true
  return false
}

type CountryFilterOption = {
  label: string
  value: string
}

export function Search() {
  const {
    news,
    meta,
    isLoading,
    people,
    spaces,
    subSpaces,
    totalCount,
    searchParamsUi,
    changeSearchParamsUi,
    changeType,
    resetSearchParams
  } = useSearchLogic()
  const { t } = useTranslate()
  const { translations, me } = useConfigStore()
  const searchInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (searchInputRef.current && !isLoading) {
      searchInputRef.current.focus()
    }
  }, [isLoading])
  const sortingOptions = [
    { value: '', label: t('field_sort_most_relevant') },
    { value: '-published_at', label: t('field_sort_latest') },
    { value: 'published_at', label: t('field_sort_oldest') }
  ]
  const searchableCountriesOptions = useMemo(() => {
    return Object.keys(searchableCountriesMap).map((code) => {
      return {
        label: searchableCountriesMap[code].label,
        value: code
      }
    })
  }, [])

  const getSearchParamLabelByValue = (value: string) =>
    sortingOptions?.find((option) => option.value === value)?.label

  const isOnlyOneCategoryResult = onlyOne(
    news?.length,
    people?.length,
    spaces?.length || subSpaces?.length
  )
  const visibleResults = isOnlyOneCategoryResult ? 20 : 5
  const emptyState = useMemo(() => {
    return (
      <Space
        mt={
          !searchParamsUi.search && me?.recentSearchResults?.length
            ? 'MD'
            : '3XL'
        }
        css={{
          d: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: '$neutral',
          height: '100%'
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : !searchParamsUi.search && me?.recentSearchResults?.length ? (
          <Box
            css={{
              alignSelf: 'stretch',
              flexGrow: 1
            }}
          >
            <Body3 fontWeight="bold" css={{ mb: '$2' }}>
              {t('field_recent_search_results')}
            </Body3>
            <Box
              css={{
                d: 'flex',
                flexDirection: 'column-reverse'
              }}
            >
              {me?.recentSearchResults.map((result, index) => (
                <Box
                  css={{
                    d: 'flex',
                    alignItems: 'center',
                    py: '$5',
                    borderBottom:
                      index !== 0
                        ? '1px solid $neutralLighten90'
                        : '1px solid transparent',
                    transition: 'opacity .3s',
                    opacity: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      opacity: 0.5
                    }
                  }}
                  onClick={() => {
                    changeSearchParamsUi({ search: result, page: 0 })
                  }}
                >
                  <ClockIcon14
                    width={24}
                    height={24}
                    style={{ color: '#00192A' }}
                  />
                  <Body4 fontWeight="bold" css={{ ml: '$4' }}>
                    {result}
                  </Body4>
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <>
            <Space mb="SM">
              <SearchNoResultsIcon48 />
            </Space>
            <Body4>{t('field_no_results_found')}</Body4>
          </>
        )}
      </Space>
    )
  }, [isLoading, searchParamsUi.search, me])

  const visiblePeopleInCombinedResults = people?.slice(0, visibleResults)
  const isVisiblePeopleSeeAll =
    visiblePeopleInCombinedResults?.length < meta?.people?.count

  const visibleNewsInCombinedResults = news?.slice(0, visibleResults)
  const isVisibleNewsSeeAll =
    visibleNewsInCombinedResults?.length < meta?.news?.count

  const visibleSubSpacesInCombinedResults = subSpaces?.slice(0, visibleResults)
  const isVisibleSubSpacesSeeAll =
    visibleSubSpacesInCombinedResults?.length < meta?.subSpaces?.count

  const visibleSpacesInCombinedResults = spaces?.slice(0, visibleResults)
  const isVisibleSpacesSeeAll =
    visibleSpacesInCombinedResults?.length < meta?.spaces?.count

  const paginationVisibilityByTab = {
    [SearchEntityTypes.People]:
      meta?.[SearchEntityTypes.People]?.count > SEARCH_PAGE_LIMIT,
    [SearchEntityTypes.News]:
      meta?.[SearchEntityTypes.News]?.count > SEARCH_PAGE_LIMIT,
    [SearchEntityTypes.Spaces]:
      meta?.[SearchEntityTypes.SubSpaces]?.count > SEARCH_PAGE_LIMIT ||
      meta?.[SearchEntityTypes.Spaces]?.count > SEARCH_PAGE_LIMIT
  }

  return (
    <Box css={{ d: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
      <Tabs value={searchParamsUi.type} onValueChange={changeType}>
        <Space
          pt="LG"
          css={{
            backgroundColor: '$neutralLighten97',
            borderBottom: '1px $neutralLighten90 solid'
          }}
        >
          <ContainerPadding>
            <Container>
              <Grid>
                <Box css={{ gc: '1 / span 12', gcLg: '2 / span 10' }}>
                  <Space mb="MD">
                    <SearchInput
                      ref={searchInputRef}
                      value={searchParamsUi.search}
                      onChange={(value: string) => {
                        changeSearchParamsUi({ search: value, page: 0 })
                      }}
                      disabled={isLoading}
                      placeholderTextLg={t('field_search_input_text')}
                      placeholderTextSm={t('field_search_input_text_sm')}
                    />
                  </Space>

                  <SearchTabList
                    meta={meta}
                    totalCount={totalCount}
                  ></SearchTabList>
                </Box>
              </Grid>
            </Container>
          </ContainerPadding>
        </Space>
        <ContainerPadding css={{ flex: 1 }}>
          <Container
            css={{
              mb: '$8',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Grid>
              <Box css={{ gc: '1 / span 12', gcLg: '2 / span 10' }}>
                <>
                  <TabsContent value={SearchEntityTypes.All}>
                    <Space
                      mt="LG"
                      mb="SM"
                      css={{
                        d: 'flex',
                        flexWrap: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        gap: '$6',
                        '@md': {
                          flexDirection: 'row',
                          alignItems: 'center'
                        }
                      }}
                    >
                      <Box
                        css={{
                          d: 'flex',
                          alignItems: 'center',
                          flexGrow: 1
                        }}
                      >
                        {searchParamsUi.search && (
                          <Body5 css={{ color: '$neutral' }}>
                            {totalCount} {t('field_results_for')}{' '}
                            <Body5 as="span" fontWeight="bold">
                              "{searchParamsUi.search}"
                            </Body5>
                          </Body5>
                        )}
                        <Body5
                          onClick={resetSearchParams}
                          as="span"
                          fontWeight="bold"
                          css={{
                            color: '$primaryBlue',
                            transition: 'opacity .3s',
                            cursor: 'pointer',
                            ml: 'auto',
                            opacity: 1,
                            '&:hover': {
                              opacity: 0.5
                            }
                          }}
                        >
                          {t('field_clear_search_filters')}
                        </Body5>
                      </Box>
                      <Box css={{ d: 'flex', alignItems: 'center' }}>
                        <ReactSelect
                          value={
                            typeof searchParamsUi.country === 'string' &&
                            searchableCountriesMap[searchParamsUi.country]
                              ? {
                                  value: searchParamsUi.country,
                                  label:
                                    searchableCountriesMap[
                                      searchParamsUi.country
                                    ].label
                                }
                              : {
                                  label: 'Global',
                                  value: ''
                                }
                          }
                          css={{ maxWidth: 180 }}
                          onChange={(country: CountryFilterOption) =>
                            changeSearchParamsUi({ country: country.value })
                          }
                          options={searchableCountriesOptions}
                        />
                      </Box>
                    </Space>
                    {!isLoading && totalCount ? (
                      <>
                        {visiblePeopleInCombinedResults?.length ? (
                          <>
                            <Space
                              mt="LG"
                              pb="SM"
                              css={{
                                borderBottom: '1px $neutralLighten90 solid',
                                d: 'flex',
                                alignItems: 'center',
                                gap: '$3'
                              }}
                            >
                              <PeopleIcon16></PeopleIcon16>
                              <Body4 css={{ color: '$neutral' }}>
                                {t('field_people')}
                              </Body4>
                            </Space>
                            {visiblePeopleInCombinedResults.map(
                              (user: User, index, peopleArr) => (
                                <PeopleSearchResultCard
                                  user={user}
                                  key={user.id}
                                  hideBorder={
                                    !isVisiblePeopleSeeAll &&
                                    index === peopleArr.length - 1
                                  }
                                />
                              )
                            )}
                            {isVisiblePeopleSeeAll && (
                              <Space mt="MD">
                                <TextLink
                                  onClick={() =>
                                    changeType(SearchEntityTypes.People)
                                  }
                                >
                                  <span>
                                    {t('field_see_all')}{' '}
                                    {t('field_people').toLowerCase()}
                                  </span>
                                  <span className="icon icon-right">
                                    <ArrowRightIcon16 />
                                  </span>
                                </TextLink>
                              </Space>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {visibleNewsInCombinedResults?.length ? (
                          <>
                            <Space
                              mt="LG"
                              pb="SM"
                              css={{
                                borderBottom: '1px $neutralLighten90 solid',
                                d: 'flex',
                                alignItems: 'center',
                                gap: '$3'
                              }}
                            >
                              <MegaphoneIcon16></MegaphoneIcon16>
                              <Body4 css={{ color: '$neutral' }}>
                                {t('field_news')}
                              </Body4>
                            </Space>
                            {visibleNewsInCombinedResults.map(
                              (article: NewsArticle, index, newsArr) => (
                                <ArticleSearchResultCard
                                  article={article}
                                  link={`/${newsPageUrl}/${article.id}`}
                                  key={article.id}
                                  hideBorder={
                                    !isVisibleNewsSeeAll &&
                                    index === newsArr.length - 1
                                  }
                                />
                              )
                            )}
                            {isVisibleNewsSeeAll && (
                              <Space mt="MD">
                                <TextLink
                                  onClick={() =>
                                    changeType(SearchEntityTypes.News)
                                  }
                                >
                                  <span>
                                    {t('field_see_all')}{' '}
                                    {t('field_news').toLowerCase()}
                                  </span>
                                  <span className="icon icon-right">
                                    <ArrowRightIcon16 />
                                  </span>
                                </TextLink>
                              </Space>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {visibleSubSpacesInCombinedResults?.length ? (
                          <>
                            <Space
                              mt="LG"
                              pb="SM"
                              css={{
                                borderBottom: '1px $neutralLighten90 solid',
                                d: 'flex',
                                alignItems: 'center',
                                gap: '$3'
                              }}
                            >
                              <DashboardIcon16></DashboardIcon16>
                              <Body4 css={{ color: '$neutral' }}>
                                {t('field_spaces')}
                              </Body4>
                            </Space>
                            {visibleSubSpacesInCombinedResults.map(
                              (
                                subSpace: SubSpaceSimple,
                                index,
                                subSpacesArr
                              ) => (
                                <SubSpaceSearchResultCardSmall
                                  subSpace={subSpace}
                                  link={`/${subSpacePageUrl}/${subSpace.spaceId}/${subSpace.id}`}
                                  key={subSpace.id}
                                  hideBorder={
                                    !isVisibleSubSpacesSeeAll &&
                                    subSpaces.length >=
                                      meta?.subSpaces?.count &&
                                    index === subSpacesArr.length - 1
                                  }
                                />
                              )
                            )}
                            {isVisibleSubSpacesSeeAll && (
                              <Space mt="MD">
                                <TextLink
                                  onClick={() =>
                                    changeType(SearchEntityTypes.Spaces)
                                  }
                                >
                                  <span>
                                    {t('field_see_all')}{' '}
                                    {t('field_spaces').toLowerCase()}
                                  </span>
                                  <span className="icon icon-right">
                                    <ArrowRightIcon16 />
                                  </span>
                                </TextLink>
                              </Space>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {visibleSpacesInCombinedResults?.length ? (
                          <>
                            <Space
                              mt="LG"
                              pb="SM"
                              css={{
                                borderBottom: '1px $neutralLighten90 solid',
                                d: 'flex',
                                alignItems: 'center',
                                gap: '$3'
                              }}
                            >
                              <Document24 width={16} height={16} />
                              <Body4 css={{ color: '$neutral' }}>
                                {t('field_spaces_articles')}
                              </Body4>
                            </Space>
                            {visibleSpacesInCombinedResults.map(
                              (article: SpaceArticle, index, spacesArr) => (
                                <ArticleSearchResultCard
                                  article={article}
                                  link={`/${spacesPageUrl}/${article.id}`}
                                  key={article.id}
                                  hideBorder={
                                    !isVisibleSpacesSeeAll &&
                                    index === spacesArr.length - 1
                                  }
                                />
                              )
                            )}
                            {isVisibleSpacesSeeAll && (
                              <Space mt="MD">
                                <TextLink
                                  onClick={() =>
                                    changeType(SearchEntityTypes.Spaces)
                                  }
                                >
                                  <span>
                                    {t('field_see_all')}{' '}
                                    {t('field_spaces_articles').toLowerCase()}
                                  </span>
                                  <span className="icon icon-right">
                                    <ArrowRightIcon16 />
                                  </span>
                                </TextLink>
                              </Space>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      emptyState
                    )}
                  </TabsContent>

                  <TabsContent value={SearchEntityTypes.People}>
                    <Space
                      mt="LG"
                      mb="SM"
                      css={{
                        d: 'flex',
                        flexWrap: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        gap: '$6',
                        '@md': {
                          flexDirection: 'row',
                          alignItems: 'center'
                        }
                      }}
                    >
                      <Box
                        css={{ d: 'flex', alignItems: 'center', flexGrow: 1 }}
                      >
                        {searchParamsUi.search && (
                          <Body5 css={{ color: '$neutral' }}>
                            {meta?.[SearchEntityTypes.People]?.count || 0}{' '}
                            {t('field_results_for')}{' '}
                            <Body5 as="span" fontWeight="bold">
                              "{t('field_people')}"
                            </Body5>
                          </Body5>
                        )}
                        <Body5
                          onClick={resetSearchParams}
                          as="span"
                          fontWeight="bold"
                          css={{
                            color: '$primaryBlue',
                            transition: 'opacity .3s',
                            cursor: 'pointer',
                            ml: 'auto',
                            opacity: 1,
                            '&:hover': {
                              opacity: 0.5
                            }
                          }}
                        >
                          {t('field_clear_search_filters')}
                        </Body5>
                      </Box>
                      <Box css={{ d: 'flex', alignItems: 'center' }}>
                        <ReactSelect
                          value={
                            typeof searchParamsUi.country === 'string' &&
                            searchableCountriesMap[searchParamsUi.country]
                              ? {
                                  value: searchParamsUi.country,
                                  label:
                                    searchableCountriesMap[
                                      searchParamsUi.country
                                    ].label
                                }
                              : {
                                  label: 'Global',
                                  value: ''
                                }
                          }
                          css={{ maxWidth: 180 }}
                          onChange={(country: CountryFilterOption) =>
                            changeSearchParamsUi({ country: country.value })
                          }
                          options={searchableCountriesOptions}
                        />
                      </Box>
                    </Space>

                    {!isLoading && totalCount && people
                      ? people.map((user: User, index, peopleArr) => (
                          <PeopleSearchResultCard
                            user={user}
                            key={user.id}
                            hideBorder={index === peopleArr.length - 1}
                          />
                        ))
                      : emptyState}

                    {paginationVisibilityByTab[SearchEntityTypes.People] && (
                      <Space
                        mt="MD"
                        css={{ d: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Pagination
                          currentPage={+searchParamsUi.page}
                          count={meta?.[SearchEntityTypes.People]?.count || 0}
                          limit={SEARCH_PAGE_LIMIT}
                          onChange={(value) =>
                            changeSearchParamsUi({ page: value })
                          }
                          translations={translations}
                        ></Pagination>
                      </Space>
                    )}
                  </TabsContent>

                  <TabsContent value={SearchEntityTypes.News}>
                    <Space
                      mt="LG"
                      mb="SM"
                      css={{
                        d: 'flex',
                        flexWrap: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        gap: '$6',
                        '@md': {
                          flexDirection: 'row',
                          alignItems: 'center'
                        }
                      }}
                    >
                      <Box
                        css={{ d: 'flex', alignItems: 'center', flexGrow: 1 }}
                      >
                        {searchParamsUi.search && (
                          <Body5 css={{ color: '$neutral' }}>
                            {meta?.[SearchEntityTypes.News]?.count || 0}{' '}
                            {t('field_results_for')}{' '}
                            <Body5 as="span" fontWeight="bold">
                              "{t('field_news')}"
                            </Body5>
                          </Body5>
                        )}
                        <Body5
                          onClick={resetSearchParams}
                          as="span"
                          fontWeight="bold"
                          css={{
                            color: '$primaryBlue',
                            transition: 'opacity .3s',
                            cursor: 'pointer',
                            ml: 'auto',
                            opacity: 1,
                            '&:hover': {
                              opacity: 0.5
                            }
                          }}
                        >
                          {t('field_clear_search_filters')}
                        </Body5>
                      </Box>

                      <Box css={{ d: 'flex' }}>
                        <ReactSelect
                          value={{
                            value: searchParamsUi.sort,
                            label: getSearchParamLabelByValue(
                              searchParamsUi.sort
                            )
                          }}
                          css={{ maxWidth: 180 }}
                          onChange={(sort: sortingOption) =>
                            changeSearchParamsUi({ sort: sort.value })
                          }
                          options={sortingOptions}
                        />
                        <ReactSelect
                          value={
                            typeof searchParamsUi.country === 'string' &&
                            searchableCountriesMap[searchParamsUi.country]
                              ? {
                                  value: searchParamsUi.country,
                                  label:
                                    searchableCountriesMap[
                                      searchParamsUi.country
                                    ].label
                                }
                              : {
                                  label: 'Global',
                                  value: ''
                                }
                          }
                          css={{ maxWidth: 180, ml: '$4' }}
                          onChange={(country: CountryFilterOption) =>
                            changeSearchParamsUi({ country: country.value })
                          }
                          options={searchableCountriesOptions}
                        />
                      </Box>
                    </Space>
                    {!isLoading && totalCount && news
                      ? news.map((article: NewsArticle, index, newsArr) => (
                          <ArticleSearchResultCard
                            article={article}
                            link={`/${newsPageUrl}/${article.id}`}
                            key={article.id}
                            hideBorder={index === newsArr.length - 1}
                          />
                        ))
                      : emptyState}
                    {paginationVisibilityByTab[SearchEntityTypes.News] && (
                      <Space
                        mt="MD"
                        css={{ d: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Pagination
                          currentPage={+searchParamsUi.page}
                          count={meta?.[SearchEntityTypes.News]?.count || 0}
                          limit={SEARCH_PAGE_LIMIT}
                          onChange={(value) =>
                            changeSearchParamsUi({ page: value })
                          }
                          translations={translations}
                        ></Pagination>
                      </Space>
                    )}
                  </TabsContent>

                  <TabsContent value={SearchEntityTypes.Spaces}>
                    {isLoading ? (
                      <Space mt="3XL">
                        <Spinner />
                      </Space>
                    ) : (
                      <Box>
                        <Space
                          mt="LG"
                          mb="SM"
                          css={{
                            d: 'flex',
                            flexWrap: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            gap: '$6',
                            '@md': {
                              flexDirection: 'row',
                              alignItems: 'center'
                            }
                          }}
                        >
                          <Box
                            css={{
                              d: 'flex',
                              alignItems: 'center',
                              flexGrow: 1
                            }}
                          >
                            {searchParamsUi.search && (
                              <Body5 css={{ color: '$neutral' }}>
                                {meta?.[SearchEntityTypes.Spaces]?.count +
                                  meta?.[SearchEntityTypes.SubSpaces]?.count ||
                                  0}{' '}
                                {t('field_results_for')}{' '}
                                <Body5 as="span" fontWeight="bold">
                                  "{searchParamsUi.search}"
                                </Body5>{' '}
                                {t('field_results_in')} {t('field_spaces')}
                              </Body5>
                            )}
                            <Body5
                              onClick={resetSearchParams}
                              as="span"
                              fontWeight="bold"
                              css={{
                                color: '$primaryBlue',
                                transition: 'opacity .3s',
                                cursor: 'pointer',
                                ml: 'auto',
                                opacity: 1,
                                '&:hover': {
                                  opacity: 0.5
                                }
                              }}
                            >
                              {t('field_clear_search_filters')}
                            </Body5>
                          </Box>

                          <Box css={{ d: 'flex' }}>
                            <ReactSelect
                              value={{
                                value: searchParamsUi.sort,
                                label: getSearchParamLabelByValue(
                                  searchParamsUi.sort
                                )
                              }}
                              css={{ maxWidth: 180 }}
                              onChange={(sort: sortingOption) =>
                                changeSearchParamsUi({ sort: sort.value })
                              }
                              options={sortingOptions}
                            />
                          </Box>
                        </Space>
                        <Space
                          mt="LG"
                          pb="SM"
                          css={{
                            borderBottom: '1px $neutralLighten90 solid',
                            d: subSpaces?.length ? 'flex' : 'none',
                            alignItems: 'center',
                            gap: '$3'
                          }}
                        >
                          <DashboardIcon16></DashboardIcon16>
                          <Body4 css={{ color: '$neutral' }}>
                            {t('field_spaces')}
                          </Body4>
                        </Space>
                        {!subSpaces?.length && !spaces?.length && emptyState}
                        {totalCount && subSpaces
                          ? subSpaces.map(
                              (
                                subSpace: SubSpaceSimple,
                                index,
                                subSpacesArr
                              ) => (
                                <SubSpaceSearchResultCardSmall
                                  subSpace={subSpace}
                                  link={`/${subSpacePageUrl}/${subSpace.spaceId}/${subSpace.id}`}
                                  key={subSpace.id}
                                  hideBorder={index === subSpacesArr.length - 1}
                                />
                              )
                            )
                          : null}
                        <Space
                          mt="LG"
                          pb="SM"
                          css={{
                            borderBottom: '1px $neutralLighten90 solid',
                            d: spaces?.length ? 'flex' : 'none',
                            alignItems: 'center',
                            gap: '$3'
                          }}
                        >
                          <Document24 width={16} height={16} />
                          <Body4 css={{ color: '$neutral' }}>
                            {t('field_spaces_articles')}
                          </Body4>
                        </Space>
                        {totalCount && spaces
                          ? spaces.map(
                              (article: SpaceArticle, index, spacesArr) => (
                                <ArticleSearchResultCard
                                  article={article}
                                  link={`/${spacesPageUrl}/${article.id}`}
                                  key={article.id}
                                  hideBorder={index === spacesArr.length - 1}
                                />
                              )
                            )
                          : null}
                        {paginationVisibilityByTab[
                          SearchEntityTypes.Spaces
                        ] && (
                          <Space
                            mt="MD"
                            css={{ d: 'flex', justifyContent: 'flex-end' }}
                          >
                            <Pagination
                              currentPage={+searchParamsUi.page}
                              count={Math.max(
                                meta?.[SearchEntityTypes.SubSpaces]?.count || 0,
                                meta?.[SearchEntityTypes.Spaces]?.count || 0
                              )}
                              limit={SEARCH_PAGE_LIMIT}
                              onChange={(value) =>
                                changeSearchParamsUi({ page: value })
                              }
                              translations={translations}
                            ></Pagination>
                          </Space>
                        )}
                      </Box>
                    )}
                  </TabsContent>
                </>
              </Box>
            </Grid>
          </Container>
        </ContainerPadding>
      </Tabs>
    </Box>
  )
}
