import React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import * as Stitches from '@stitches/react'
import { styled, keyframes } from '../../../stitches.config'
import { ChevronRightIcon16 } from '../icons'
import { Space } from '../space'

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' }
})

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 }
})

export const Accordion = styled(AccordionPrimitive.Root, {})

export const AccordionItem = styled(AccordionPrimitive.Item, {
  overflow: 'hidden',
  marginTop: 1,

  '&:first-child': {
    marginTop: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },

  '&:last-child': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4
  },

  '&:focus-within': {
    position: 'relative',
    zIndex: 1
  }
})

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: 'unset',
  display: 'flex'
})

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  p: '$4',
  pr: 0,
  backgroundColor: 'transparent',
  flex: 1,
  display: 'flex',
  gap: '$2',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: '$default',
  boxSizing: 'border-box',
  border: '1px solid transparent',
  '&:hover': {
    backgroundColor: '$primaryBlueLighten97',
    borderColor: '$primaryBlueLighten30'
  }
})

export const AccordionContent = styled(AccordionPrimitive.Content, {
  overflow: 'hidden',
  p: '$4',
  pb: '$6',

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`
  }
})

const StyledChevron = styled(ChevronRightIcon16, {
  color: '$neutral',
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '[data-state=open] &': { transform: 'rotate(90deg)' }
})

export const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  AccordionPrimitive.AccordionTriggerProps & {
    css?: Stitches.CSS
    headerCSS?: Stitches.CSS
  }
>(({ children, headerCSS, ...props }, forwardedRef) => (
  <StyledHeader css={headerCSS}>
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
      <Space mr="SM" css={{ lineHeight: 0 }}>
        <StyledChevron aria-hidden />
      </Space>
    </StyledTrigger>
  </StyledHeader>
))
