import AvatarPlaceholder from '../../../assets/avatar-placeholder-blue.jpg'
import { Link } from 'react-router-dom'
import { Box, CloseIcon16, Body7, LazyImage } from '@sefar/design-system'
import { User } from '../../api'

export function UserWithDelete({
  user,
  onDelete
}: {
  user: User
  onDelete?: (user: User) => void
}) {
  if (!user) return null
  const { status, image, firstName, lastName, jobTitle, country } = user
  const textColorStrong = {
    color: status ? '$neutral' : '$neutralLighten60'
  }
  const textColorWeek = {
    color: status ? '$neutralLighten30' : '$neutralLighten60'
  }
  return (
    <Box
      as={status ? Link : 'div'}
      css={{
        all: 'unset',
        d: 'inline-flex',
        gap: '$2',
        alignItems: 'center',
        cursor: status ? 'pointer' : 'auto',
        transition: '$default',
        p: '$2',
        borderRadius: '$3',
        '&:hover': {
          '& .delete-icon': {
            opacity: 100
          },
          backgroundColor: status ? '$primaryBlueLighten95' : 'none'
        }
      }}
    >
      <Box
        css={{
          size: 32,
          borderRadius: '$round',
          overflow: 'hidden',
          opacity: status ? 1 : 0.5,
          flexShrink: 0
        }}
      >
        <LazyImage src={image || AvatarPlaceholder} aspectRatio="1 / 1" />
      </Box>
      <Box>
        <Body7 fontWeight="bold" css={{ ...textColorStrong }}>
          {firstName} {lastName}
          {country && <>, Sefar {country}</>}
        </Body7>
        <Body7 css={{ ...textColorWeek }}>{jobTitle}</Body7>
      </Box>
      <Box
        css={{
          p: '$2',
          borderRadius: '$4',
          '&:hover': {
            backgroundColor: status ? '$primaryBlueLighten90' : 'none'
          }
        }}
        onClick={() => {
          if (onDelete) {
            onDelete(user)
          }
        }}
      >
        <Box as={CloseIcon16} className="delete-icon" css={{ opacity: 0 }} />
      </Box>
    </Box>
  )
}
