import {
  Body4,
  Box,
  Container,
  ContainerPadding,
  Heading3
} from '@sefar/design-system'

export function NewsEditorNote({ note }: { note?: string }) {
  if (!note) return null
  return (
    <Box
      css={{
        backgroundColor: '$neutralLighten97',
        padding: '$8 0'
      }}
    >
      <Container>
        <ContainerPadding>
          <Box css={{ padding: '0 $4' }}>
            <Heading3
              css={{
                mb: '$2'
              }}
            >
              Editor's Note
            </Heading3>
            <Body4>{note}</Body4>
          </Box>
        </ContainerPadding>
      </Container>
    </Box>
  )
}
