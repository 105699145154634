import React, { useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { Box, Body6, Heading1, Spinner } from '@sefar/design-system'
import { OrderItemsTable } from '../components/order-items-table'
import { SearchItem } from '../components/search-item'
import { CURRENCY } from '../consts'
import { useOrderState } from '../hooks/use-order-state'
import { PurchaseRequisitionOrderItem, SearchItemResult } from '../types'
import { useLnItemsSearch } from '../../api'
import { SearchInput } from '../../../../components/search/search-input'
import { useTranslate } from '../../../../hooks/useTranslate'

export const ItemsStep = () => {
  const { t } = useTranslate()
  const [searchValue, setSearchValue] = useState('')
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchResults, setSearchResults] = useState<SearchItemResult[]>([])
  const {
    order: { purchaseRequisitionItems },
    setItems
  } = useOrderState()
  const { data: itemsResponse } = useLnItemsSearch({ q: searchValue })
  const searchWrapperRef = useRef<HTMLDivElement>(null)
  useClickAway(searchWrapperRef, () => setIsSearchFocused(false))

  const onChangeSearchValue = (value: string) => {
    setSearchValue(value)
  }
  useEffect(() => {
    if (itemsResponse?.hits && itemsResponse?.hits.length) {
      setSearchResults(itemsResponse.hits)
    }
  }, [itemsResponse])
  const onChangeItem = (
    itemId: number,
    fieldKey?: string,
    fieldValue?: string | number,
    setItem?: Partial<PurchaseRequisitionOrderItem>
  ) => {
    const updatedOrderItems = [...purchaseRequisitionItems]
    const itemToChangeIndex = updatedOrderItems.findIndex(
      (orderItem) => orderItem.id === itemId
    )
    if (itemToChangeIndex >= 0) {
      if (setItem) {
        updatedOrderItems[itemToChangeIndex] = {
          ...updatedOrderItems[itemToChangeIndex],
          ...setItem
        }
      } else if (fieldKey) {
        updatedOrderItems[itemToChangeIndex] = {
          ...updatedOrderItems[itemToChangeIndex],
          [fieldKey]: fieldValue
        }
      }

      setItems(updatedOrderItems)
    }
  }

  const onAddItem = () => {
    setItems([
      ...purchaseRequisitionItems,
      {
        id: new Date().getTime(),
        currency: CURRENCY,
        itemDescription: '',
        price: undefined,
        supplier: '',
        supplierItemId: '',
        unit: 'ea',
        quantity: 1
      }
    ])
  }

  const onDeleteItem = (deleteId: number) => {
    setItems(purchaseRequisitionItems.filter((item) => item.id !== deleteId))
  }

  return (
    <Box css={{ w: '100%', px: '$8' }}>
      <Heading1 css={{ textAlign: 'center' }}>
        {t('field_purchase_order_title')}
      </Heading1>
      <Box
        css={{ w: '100%', maxWidth: '57rem', mx: 'auto', mt: '$9', mb: '$15' }}
      >
        <Body6 css={{ mb: '$3', color: '$neutralLighten25' }}>
          {t('field_purchase_order_search_title')}
        </Body6>
        <Box css={{ position: 'relative' }} ref={searchWrapperRef}>
          <Box css={{ position: 'relative', zIndex: 4 }}>
            <SearchInput
              small
              placeholder={t('field_purchase_order_search_placeholder')}
              value={searchValue}
              onChange={onChangeSearchValue}
              onFocus={() => setIsSearchFocused(true)}
            />
          </Box>
          <Box
            css={{
              position: 'absolute',
              top: '-$5',
              left: '-$3',
              d: isSearchFocused && searchValue ? 'block' : 'none',
              w: 'calc(100% + 1.5rem)',
              pb: '$4',
              zIndex: 3,
              backgroundColor: '$white',
              boxShadow: '$dropdown',
              borderRadius: '$12',
              overflow: 'hidden'
            }}
          >
            <Box
              css={{
                w: '100%',
                h: 1,
                mt: '$20',
                backgroundColor: '$neutralLighten90'
              }}
            />
            <Box css={{ maxHeight: '13.125rem', overflow: 'auto', px: '$9' }}>
              {isLoading ? (
                <Spinner css={{ mt: '$4', h: '3.375rem' }} />
              ) : (
                searchResults.map((itemDetails) => (
                  <SearchItem key={itemDetails.id} details={itemDetails} />
                ))
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <OrderItemsTable
        orderItems={purchaseRequisitionItems}
        isEdit
        onChangeItem={onChangeItem}
        onAddItem={onAddItem}
        onDeleteItem={onDeleteItem}
      />
    </Box>
  )
}
