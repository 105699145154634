import { useNewsCategories } from '../../api'
import React, { useState } from 'react'
import {
  Body5,
  Button,
  Checkbox,
  Dialog,
  DialogClose,
  Space,
  TextLink
} from '@sefar/design-system'

export function NewsCategoriesDialog({
  selectedCategories,
  onUpdate
}: {
  selectedCategories: string[]
  onUpdate: (categories: string[]) => void
}) {
  const { categories } = useNewsCategories()
  const [dialogCategories, setDialogCategories] = useState<string[]>(
    selectedCategories || []
  )

  return (
    <Dialog
      onOpenChange={(open) => {
        if (!open) {
          setDialogCategories([])
        } else {
          setDialogCategories(selectedCategories || [])
        }
      }}
      title="Categories"
      trigger={
        <TextLink>{dialogCategories?.length ? 'Select' : 'Edit'}</TextLink>
      }
      footer={
        <Space css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => onUpdate(dialogCategories)} as={DialogClose}>
            Save
          </Button>
        </Space>
      }
    >
      <Space
        css={{
          maxHeight: '100%',
          pr: '$4',
          mb: '$4',
          color: '$neutral'
        }}
      >
        <Body5>To which categories does your article relate?</Body5>
      </Space>

      <Space
        mt="SM"
        css={{
          d: 'grid',
          gtc: 1,
          rowGap: '$4',
          columnGap: '$12',
          width: '100%',
          '@md': {
            gtc: 2
          }
        }}
      >
        {categories &&
          Object.entries(categories).map(([category, label]: string[]) => (
            <Checkbox
              key={category}
              checked={dialogCategories?.includes(category)}
              onCheckedChange={(checked) =>
                setDialogCategories((prevState) =>
                  checked
                    ? [...prevState, category]
                    : prevState.filter((item) => item !== category)
                )
              }
              label={label}
            />
          ))}
      </Space>
    </Dialog>
  )
}
