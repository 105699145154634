import axios from 'axios'
import Cookies from 'js-cookie'
import qs from 'qs'
import { useLocation } from 'react-router-dom'
import { useCookie } from 'react-use'
import useSWR from 'swr'
import AwayIcon from '../../assets/away.svg'
import ActiveIcon from '../../assets/active.svg'
import OutOfOfficeIcon from '../../assets/out-of-office.svg'
import PresentingIcon from '../../assets/presenting.svg'
import VideoIcon from '../../assets/video.svg'
import DoNotDisturbIcon from '../../assets/busy.svg'
import CalendarMeetingIcon from '../../assets/calendar-meeting.svg'

const webexApi = axios.create({
  baseURL: 'https://webexapis.com/v1'
})

export const webexStatusIcons = {
  active: ActiveIcon,
  call: VideoIcon,
  DoNotDisturb: DoNotDisturbIcon,
  inactive: AwayIcon,
  meeting: CalendarMeetingIcon,
  OutOfOffice: OutOfOfficeIcon,
  pending: AwayIcon,
  presenting: PresentingIcon,
  unknown: AwayIcon
}

export const webexStatusTranslations = {
  active: 'field_webex_active',
  call: 'field_webex_call',
  DoNotDisturb: 'field_webex_donotdisturb',
  inactive: 'field_webex_incative',
  meeting: 'field_webex_meeting',
  OutOfOffice: 'field_webex_outofoffice',
  pending: 'field_webex_pending',
  presenting: 'field_webex_presenting',
  unknown: 'field_webex_unknown'
}

export const WEBEX_CLIENT_ID =
  import.meta.env.WEBEX_CLIENT_ID ??
  'Ce8204ddcfa7d8cb7e7afbd6e5dcad576efa92eb0213fcd111c4b0e7592298e20'

export const WEBEX_CLIENT_SECRET =
  import.meta.env.WEBEX_CLIENT_SECRET ??
  '90deb8aa8b5e1d1e9a6beb760a5d0bb017203f67f28680b0b53a9fabf06fe804'

export const WEBEX_REDIRECT_URI = `${window.location.origin}/profile`

type AccessTokenResponse = {
  access_token: string
  expires_in: number //seconds
  refresh_token: string
  refresh_token_expires_in: number //seconds
}

const WEBEX_ACCESS_TOKEN_COOKIE = 'webex_access_token'
const WEBEX_REFRESH_TOKEN_COOKIE = 'webex_refresh_token'

const getWebexAuthLink = (state: string) => {
  return `https://webexapis.com/v1/authorize?${qs.stringify({
    client_id: WEBEX_CLIENT_ID,
    response_type: 'code',
    redirect_uri: WEBEX_REDIRECT_URI,
    scope:
      'spark:memberships_read spark:kms meeting:schedules_read spark:people_read spark:rooms_read spark:teams_read spark:organizations_read meeting:schedules_write spark:team_memberships_read',
    state
  })}`
}

export const WEBEX_AUTH_LINK = `https://webexapis.com/v1/authorize?${qs.stringify(
  {
    client_id: WEBEX_CLIENT_ID,
    response_type: 'code',
    redirect_uri: WEBEX_REDIRECT_URI,
    scope:
      'spark:memberships_read spark:kms meeting:schedules_read spark:people_read spark:rooms_read spark:teams_read spark:organizations_read meeting:schedules_write spark:team_memberships_read'
  }
)}`

export const initWebexAuthTokens = async (code: string) => {
  const { data } = await axios.post<AccessTokenResponse>(
    'https://webexapis.com/v1/access_token',
    {
      grant_type: 'authorization_code',
      client_id: WEBEX_CLIENT_ID,
      client_secret: WEBEX_CLIENT_SECRET,
      code,
      redirect_uri: WEBEX_REDIRECT_URI
    }
  )
  if (data.access_token && data.refresh_token) {
    Cookies.set(WEBEX_ACCESS_TOKEN_COOKIE, data.access_token)
    Cookies.set(WEBEX_REFRESH_TOKEN_COOKIE, data.refresh_token)
    return data
  }
}

const refreshWebexAccessToken = async (refresh_token: string) => {
  const { data } = await axios.post<AccessTokenResponse>(
    'https://webexapis.com/v1/access_token',
    {
      grant_type: 'refresh_token',
      client_id: WEBEX_CLIENT_ID,
      client_secret: WEBEX_CLIENT_SECRET,
      refresh_token
    }
  )

  if (data.access_token && data.refresh_token) {
    Cookies.set(WEBEX_ACCESS_TOKEN_COOKIE, data.access_token)
    Cookies.set(WEBEX_REFRESH_TOKEN_COOKIE, data.refresh_token)
    return data
  }
}

webexApi.interceptors.request.use(
  async (config) => {
    const access_token = Cookies.get(WEBEX_ACCESS_TOKEN_COOKIE)
    config.headers.set({
      Authorization: `Bearer ${access_token}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    })
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

webexApi.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refresh_token = Cookies.get(WEBEX_REFRESH_TOKEN_COOKIE)
      if (refresh_token) {
        const tokenResponse = await refreshWebexAccessToken(refresh_token)
        if (tokenResponse) {
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + tokenResponse.access_token
        }
      }
      return webexApi(originalRequest)
    }
    return Promise.reject(error)
  }
)

export const useListWebexUsers = ({ email }: { email?: string }) => {
  const { data: response, error } = useSWR<any>(
    email ? `/people?email=${email}` : null,
    webexApi.get
  )
  return {
    users: response?.data?.items,
    isLoading: !error && !response,
    isError: error
  }
}

export const useWebexAuth = () => {
  const [token] = useCookie(WEBEX_ACCESS_TOKEN_COOKIE)
  const location = useLocation()
  return {
    isAuth: !!token,
    authLink: getWebexAuthLink(JSON.stringify({ returnUrl: location.pathname }))
  }
}
