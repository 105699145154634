import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export function useLocationStateFrom(
  deps: any[] = [],
  getDefaultValue?: (deps: any[]) => string
): string {
  const location = useLocation()
  const [from, setFrom] = useState<string>('')

  useEffect(() => {
    if (!from) {
      setFrom(
        location.state?.from || (getDefaultValue && getDefaultValue(deps)) || ''
      )
    }
  }, deps)

  return from
}
