import {
  Add16,
  Body5,
  Box,
  ChevronUpIcon16,
  Container,
  ContainerPadding,
  DropdownMenu,
  DropdownMenuItem,
  Space,
  Tabs,
  TabsList,
  TabsTrigger
} from '@sefar/design-system'
import { styled } from '@stitches/react'
import { MouseEvent, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMedia, useUpdateEffect } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import {
  ALL_COUNTRIES_REGION,
  ChannelTypeEnum,
  isUserEditor,
  useCountries,
  useMe,
  useNewsCategories
} from '../../api'
import { newsCreatePageUrl } from '../../app'
import { localStorageKeys, sessionStorageKeys } from '../../constants'
import { useTranslate } from '../../hooks/useTranslate'

const StyledChevronUpIcon16 = styled(ChevronUpIcon16, {})

export type NewsFilters = {
  category?: string[]
  country?: string
  region?: string
  categoryExclude?: string[]
}

type Props = {
  onFiltersChange: (filters: NewsFilters) => void
  onFiltersInit?: (filters: NewsFilters) => void
  filters: NewsFilters
}

const HomeFilters = ({ onFiltersChange, filters, onFiltersInit }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { countries } = useCountries()
  const { categories } = useNewsCategories()
  const ALL_CATEGORIES: [string, string] = ['', 'All Categories']
  const { me } = useMe()

  useEffect(() => {
    const initCountry =
      localStorage.getItem(localStorageKeys.NEWS_CHOSEN_COUNTRY) || ''
    const currentSearch = searchParams.get('region')
    const currentCategories = searchParams.getAll('category')
    const initialFilters = {
      ...filters
    }
    initialFilters.country = initCountry

    if (currentSearch) {
      initialFilters.region = currentSearch
    } else {
      initialFilters.region = ChannelTypeEnum.all
    }
    if (currentCategories?.length) {
      initialFilters.category = currentCategories
    }
    if (onFiltersInit) {
      onFiltersInit(initialFilters)
    }
  }, [])

  useUpdateEffect(() => {
    const currentRegion = filters.region
    if (currentRegion === ChannelTypeEnum.all) {
      searchParams.delete('region')
    } else if (currentRegion) {
      // searchParams.append('region', currentRegion)
    }
  }, [searchParams, filters.region])

  const { t } = useTranslate()
  const navigate = useNavigate()
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)

  return (
    <Box
      css={{
        width: '100%',
        h: 61,
        backgroundColor: '$white',
        position: 'sticky',
        top: 64,
        borderTop: '1px solid #EFEFEF',
        borderBottom: '1px solid #EFEFEF',
        zIndex: 10
      }}
    >
      <ContainerPadding>
        <Container size="medium">
          <Tabs
            value={filters.region}
            onValueChange={(value) => {
              if (Object.values(countries || {})?.includes(value)) {
                onFiltersChange({ ...filters, country: value })
                localStorage.setItem(
                  localStorageKeys.NEWS_CHOSEN_COUNTRY,
                  value
                )
              }
              onFiltersChange({ ...filters, region: value })
              sessionStorage.setItem(
                sessionStorageKeys.NEWS_CHOSEN_REGION,
                value
              )
              if (value) {
                searchParams.set('region', value)
              } else {
                onFiltersChange({
                  ...filters,
                  region: value,
                  category: []
                })
                searchParams.delete('region')
                searchParams.delete('category')
              }
              setSearchParams(searchParams)
            }}
          >
            <TabsList
              aria-label="News target area"
              css={{ d: 'flex', justifyContent: 'center' }}
            >
              <TabsTrigger
                css={{
                  height: 44,
                  '&::after': {
                    bottom: 0
                  },
                  mr: '$3'
                }}
                data-cy="NewsTabAll"
                value={ChannelTypeEnum.all}
              >
                My News
              </TabsTrigger>
              <TabsTrigger
                css={{
                  height: 44,
                  '&::after': {
                    bottom: 0
                  },
                  mr: '$3'
                }}
                data-cy="NewsTabGlobal"
                value={ChannelTypeEnum.global}
              >
                {t('field_news_tab_global')}
              </TabsTrigger>
              <Box
                css={{
                  d: 'flex',
                  '&:hover > div': {
                    color: '$neutral',
                    backgroundColor: '$primaryBlueLighten95',
                    '& .tab-badge': {
                      backgroundColor: '$primaryBlue'
                    }
                  }
                }}
              >
                <TabsTrigger
                  data-cy="NewsTabSelectedCountry"
                  id="country-tab-trigger"
                  // @ts-ignore
                  value={filters.country}
                  disabled={!filters.country}
                  css={{
                    height: 44,
                    position: 'relative',
                    mr: 0,

                    '&::after': {
                      bottom: 0
                    },
                    '@xs': {
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'flex-start'
                    },
                    '@xxs': {}
                  }}
                >
                  {isLg
                    ? filters.country || t('field_select_country')
                    : t('field_country')}
                  {/* {filters.country &&
                  filters.country !== ALL_COUNTRIES_REGION ? (
                    <Box
                      css={{
                        position: 'absolute',
                        right: -5,
                        top: 15,
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: 'red'
                      }}
                    />
                  ) : null} */}
                </TabsTrigger>

                <DropdownMenu
                  trigger={(isOpen) => (
                    <Space
                      data-cy="NewsTabSelect"
                      onClick={(event) => event.stopPropagation()}
                      css={{
                        cursor: 'pointer',
                        height: 60,
                        d: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Box
                        css={{
                          h: 44,
                          px: '$4',
                          d: 'flex',
                          alignItems: 'center',
                          '@lg': {
                            borderLeft: '1px solid $neutralLighten80'
                          }
                        }}
                      >
                        <StyledChevronUpIcon16
                          onClick={(event: MouseEvent<HTMLOrSVGElement>) =>
                            event.stopPropagation()
                          }
                          css={{
                            transform: isOpen
                              ? 'translateY(9%)'
                              : 'rotate(180deg)'
                          }}
                        />
                      </Box>
                    </Space>
                  )}
                  content={() =>
                    countries &&
                    [ALL_COUNTRIES_REGION, ...countries].map(
                      (country: string, index: number) => (
                        <DropdownMenuItem
                          key={country}
                          data-cy={`NewsTabOption-${index}`}
                          onClick={() => {
                            onFiltersChange({
                              ...filters,
                              country,
                              region: country
                            })
                            localStorage.setItem(
                              localStorageKeys.NEWS_CHOSEN_COUNTRY,
                              country
                            )
                            sessionStorage.setItem(
                              sessionStorageKeys.NEWS_CHOSEN_REGION,
                              country
                            )
                            searchParams.set('region', country)
                            setSearchParams(searchParams)
                          }}
                          active={filters.country === country}
                        >
                          {country}
                        </DropdownMenuItem>
                      )
                    )
                  }
                  contentProps={{
                    align: 'center',
                    side: 'bottom',
                    css: { maxHeight: 200, overflowY: 'auto' }
                  }}
                />
              </Box>

              <DropdownMenu
                trigger={(isOpen) => (
                  <Space
                    onClick={(event) => event.stopPropagation()}
                    css={{
                      d: 'flex',
                      flexDirection: 'row',
                      '&:hover > div': {
                        color: '$neutral',
                        backgroundColor: '$primaryBlueLighten95'
                      },
                      ml: 'auto',
                      cursor: 'pointer',
                      '@lg': {
                        ml: 0
                      }
                    }}
                  >
                    <Box
                      css={{
                        d: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        '@lg': {
                          p: '$3 $3',
                          ml: '$2'
                        },
                        height: 60,
                        position: 'relative'
                      }}
                    >
                      <Body5
                        fontWeight="bold"
                        css={{
                          color: '$neutralLighten30',
                          fontSize: '$body5Xl'
                        }}
                      >
                        {t('field_news_categories_text')}
                      </Body5>
                      {/* {filters.category?.length ? (
                        <Box
                          css={{
                            position: 'absolute',
                            right: 5,
                            top: 15,
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: 'red'
                          }}
                        />
                      ) : null} */}
                    </Box>
                    <Box css={{ height: 60, d: 'flex', alignItems: 'center' }}>
                      <Box
                        css={{
                          '@lg': {
                            borderLeft: '1px solid $neutralLighten80',
                            px: '$4'
                          },
                          height: 44,
                          d: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}
                      >
                        <StyledChevronUpIcon16
                          onClick={(event: MouseEvent<HTMLOrSVGElement>) =>
                            event.stopPropagation()
                          }
                          css={{
                            transform: isOpen
                              ? 'translateY(9%)'
                              : 'rotate(180deg)'
                          }}
                        />
                      </Box>
                    </Box>
                  </Space>
                )}
                content={() =>
                  categories &&
                  [ALL_CATEGORIES, ...Object.entries(categories)].map(
                    ([category, label]: [string, any]) => (
                      <DropdownMenuItem
                        key={category}
                        onClick={() => {
                          const baseFilters = { ...filters }
                          if (!filters.country || !filters.region) {
                            baseFilters.region = ChannelTypeEnum.global
                            searchParams.set('region', ChannelTypeEnum.global)
                          }
                          let newCategories: string[]
                          if (
                            filters.category?.length &&
                            filters.category.includes(category)
                          ) {
                            newCategories = filters.category.filter(
                              (selectedCategory) =>
                                category !== selectedCategory
                            )
                          } else {
                            newCategories = filters.category?.length
                              ? [...filters.category, category]
                              : [category]
                          }
                          searchParams.delete('category')
                          if (category !== ALL_CATEGORIES[0]) {
                            for (const category of newCategories) {
                              searchParams.append('category', category)
                            }
                            setSearchParams(searchParams)
                            onFiltersChange({
                              ...baseFilters,
                              category: newCategories
                            })
                          } else {
                            setSearchParams(searchParams)
                            onFiltersChange({
                              ...baseFilters,
                              category: []
                            })
                          }
                        }}
                        active={filters.category?.includes(category)}
                      >
                        {label}
                      </DropdownMenuItem>
                    )
                  )
                }
                contentProps={{
                  align: 'center',
                  side: 'bottom',
                  css: { maxHeight: 200, overflowY: 'auto' }
                }}
              />
              {isUserEditor(me) && (
                <Space
                  onClick={(event) => {
                    event.stopPropagation()
                    navigate(`/${newsCreatePageUrl}?editMode=true`)
                  }}
                  css={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    p: '$2 $3',
                    mr: '$4',
                    mb: '$4',
                    ml: 'auto',
                    cursor: 'pointer',
                    d: 'none',
                    '@lg': {
                      d: 'flex'
                    }
                  }}
                >
                  <Box css={{ mr: '$2', svg: { w: '12px', height: '12px' } }}>
                    <Add16
                      onClick={(event: MouseEvent<HTMLOrSVGElement>) =>
                        event.stopPropagation()
                      }
                    />
                  </Box>
                  <Body5
                    fontWeight="bold"
                    css={{ color: '$neutralLighten30', pr: '$4' }}
                  >
                    {t('field_add_news')}
                  </Body5>
                </Space>
              )}
            </TabsList>
          </Tabs>
        </Container>
      </ContainerPadding>
    </Box>
  )
}

export default HomeFilters
