import React, { useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import { Box, Space, ReactSelect, Dialog, Button } from '@sefar/design-system'
import {
  DamSearchFiltersType,
  SelectedValuesType,
  FileTypeEnum
} from '../../api'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import { SearchInput } from '../../components/search/search-input'
import { filtersDefaultState } from './gallery-dialog'
import { isDeepEqual } from '../../components/utils'

const fileTypeOptions = [
  { value: FileTypeEnum.Images, label: FileTypeEnum.Images },
  { value: FileTypeEnum.Video, label: FileTypeEnum.Video },
  { value: FileTypeEnum.Documents, label: FileTypeEnum.Documents }
]

export const DamFiltersSelects = ({
  localFilters,
  setLocalFilters,
  filterCategories,
  filterCountries,
  allowedFormats
}: {
  localFilters: DamSearchFiltersType
  setLocalFilters: (filters: DamSearchFiltersType) => void
  filterCategories: SelectedValuesType
  filterCountries: SelectedValuesType
  allowedFormats?: FileTypeEnum[]
}) => {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)

  const setFilter = (filterName: string, values: SelectedValuesType) =>
    setLocalFilters((oldFilters: DamSearchFiltersType) => ({
      ...oldFilters,
      [filterName]: values
    }))

  const fileTypesOptionsAllowed = allowedFormats
    ? allowedFormats.map((format: string) => ({ value: format, label: format }))
    : fileTypeOptions

  return (
    <>
      <Box
        css={{
          mb: '$8',
          '@lg': {
            width: '25%',
            mr: '1rem'
          }
        }}
      >
        <ReactSelect
          value={localFilters.fileTypes}
          options={fileTypesOptionsAllowed}
          onChange={(values: SelectedValuesType) =>
            setFilter('fileTypes', values)
          }
          placeholder="All file types"
          isMulti
          withCheckbox
          selectedValuesCountToShow={2}
        />
      </Box>
      <Box
        css={{
          mb: '$8',
          '@lg': {
            width: '25%',
            mr: '1rem'
          }
        }}
      >
        <ReactSelect
          value={localFilters.categories}
          options={filterCategories}
          placeholder="All categories"
          isMulti
          withCheckbox
          selectedValuesCountToShow={1}
          onChange={(values: SelectedValuesType) =>
            setFilter('categories', values)
          }
        />
      </Box>
      <Box
        css={{
          mb: '$8',
          '@lg': {
            width: '25%'
          }
        }}
      >
        <ReactSelect
          value={localFilters.countries}
          options={filterCountries}
          onChange={(values: SelectedValuesType) =>
            setFilter('countries', values)
          }
          placeholder="All countries"
          isMulti
          withCheckbox
          selectedValuesCountToShow={1}
        />
      </Box>
    </>
  )
}

export const DamFiltersSelectsMemoized = React.memo(DamFiltersSelects)

export const DamFilters = ({
  filters,
  setFilters,
  setSearchText,
  searchText,
  filterCategories,
  filterCountries,
  allowedFormats
}: {
  filters: DamSearchFiltersType
  setFilters: (filters: DamSearchFiltersType) => void
  setSearchText: (value: string) => void
  searchText: string
  filterCategories: SelectedValuesType
  filterCountries: SelectedValuesType
  allowedFormats?: FileTypeEnum[]
}) => {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const [open, setOpen] = useState(false)
  const [localFiltersIsSet, setLocalFiltersIsSet] = useState(false)
  const [localFilters, setLocalFilters] =
    useState<DamSearchFiltersType>(filtersDefaultState)

  useEffect(() => {
    // set outside filters only on desktop on
    // any change of localFilters
    // (on mobile it works only on click by Filter button)
    if (isLg) {
      setOutsideFilters()
    }
    if (
      localFilters.fileTypes.length ||
      localFilters.countries.length ||
      localFilters.categories.length
    ) {
      setLocalFiltersIsSet(true)
    } else {
      setLocalFiltersIsSet(false)
    }
  }, [localFilters])

  const resetFilters = () => {
    setFilters(filtersDefaultState)
    setLocalFilters(filtersDefaultState)
  }

  // set outside filters from local filters
  const setOutsideFilters = () => {
    setFilters({
      fileTypes: localFilters.fileTypes,
      countries: localFilters.countries,
      categories: localFilters.categories
    })
  }

  const onFilterBtnClick = () => {
    // set outside filters from local filters
    setOutsideFilters()
    // close filters popup
    setOpen(false)
  }

  return (
    <Box>
      <Space my="LG" css={{ '@lg': { mb: '$4', mt: '$2' } }}>
        <SearchInput
          value={searchText}
          onChange={(value: string) => {
            setSearchText(value)
          }}
          disabled={false}
          placeholder="Search by keywords, people, documents, topics..."
          withOptions={true}
          onCancel={() => setSearchText('')}
          onOptionsClick={() => setOpen(true)}
        />
      </Space>

      {isLg ? (
        <Box css={{ d: 'flex' }}>
          <DamFiltersSelectsMemoized
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
            filterCategories={filterCategories}
            filterCountries={filterCountries}
            allowedFormats={allowedFormats}
          />
        </Box>
      ) : (
        <Dialog
          title="Filter"
          setOpenControlledDialog={setOpen}
          open={open}
          footer={
            <Space
              css={{
                d: 'flex',
                justifyContent: 'space-between',
                gap: '$3',
                w: '100%'
              }}
            >
              <Button
                variant="secondary"
                onClick={resetFilters}
                disabled={!localFiltersIsSet}
              >
                Reset all
              </Button>
              <Button
                variant="primary"
                onClick={onFilterBtnClick}
                disabled={
                  !localFiltersIsSet || isDeepEqual(localFilters, filters)
                }
              >
                Filter
              </Button>
            </Space>
          }
        >
          <Box css={{ d: 'flex', flexDirection: 'column' }}>
            <DamFiltersSelects
              localFilters={localFilters}
              setLocalFilters={setLocalFilters}
              filterCategories={filterCategories}
              filterCountries={filterCountries}
              allowedFormats={allowedFormats}
            />
          </Box>
        </Dialog>
      )}
    </Box>
  )
}
