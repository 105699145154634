import { Box, CloseIcon20 } from '@sefar/design-system'
import {
  findChildrenInRange,
  mergeAttributes,
  Node,
  nodeInputRule,
  Tracker
} from '@tiptap/core'
import {
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer
} from '@tiptap/react'
import { MouseEventHandler, useCallback } from 'react'
// https://tiptap.dev/experiments/figure

export enum ImageOrientation {
  horizontal = 'horizontal',
  vertical = 'vertical'
}
export interface FigureOptions {
  HTMLAttributes: Record<string, any>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    figure: {
      /**
       * Add a figure element
       */
      setFigure: (options: {
        src: string
        alt?: string
        title?: string
        id?: string
        'data-src'?: string
        'data-orientation'?: ImageOrientation
        caption?: string
      }) => ReturnType
    }
  }
}

export const inputRegex = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/

const FigureComponent = ({ ...props }) => {
  const onDelete: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      props.deleteNode()
    },
    [props.deleteNode]
  )
  const imgAttrs = props?.node?.attrs ?? {}
  return (
    <NodeViewWrapper
      as="figure"
      contentEditable
      className="image-figure__wrapper"
    >
      <img {...imgAttrs} />
      <NodeViewContent as="figcaption" />
      <Box
        as="span"
        className="delete-image-figure icon icon-right"
        onClick={onDelete}
        css={{
          position: 'absolute',
          right: '-40px',
          top: '0',
          transition: 'all .3s ease-in-out',
          color: 'inherit',
          cursor: 'pointer',
          '&:hover': {
            transform: 'scale(1.2)'
          },
          '&:hover path': {
            stroke: 'red'
          }
        }}
      >
        <CloseIcon20 width={20} height={20} />
      </Box>
    </NodeViewWrapper>
  )
}

export const Figure = Node.create<FigureOptions>({
  name: 'figure',

  addOptions() {
    return {
      HTMLAttributes: {}
    }
  },

  group: 'block',

  content: 'inline*',

  draggable: true,

  isolating: true,

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (element) =>
          element.querySelector('img')?.getAttribute('src')
      },

      alt: {
        default: null,
        parseHTML: (element) =>
          element.querySelector('img')?.getAttribute('alt')
      },

      title: {
        default: null,
        parseHTML: (element) =>
          element.querySelector('img')?.getAttribute('title')
      },

      id: {
        default: null,
        parseHTML: (element) => element.querySelector('img')?.getAttribute('id')
      },

      'data-src': {
        default: null
      },

      'data-orientation': {
        default: null,
        parseHTML: (element) =>
          element.querySelector('img')?.getAttribute('data-orientation')
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'figure',
        contentElement: 'figcaption'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'figure',
      this.options.HTMLAttributes,
      [
        'img',
        mergeAttributes(HTMLAttributes, {
          draggable: false,
          contenteditable: false
        })
      ],
      ['figcaption', 0]
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(FigureComponent)
  },

  addCommands() {
    return {
      setFigure:
        ({ caption, ...attrs }) =>
        ({ chain }) => {
          return (
            chain()
              .focus()
              .insertContent({
                type: this.name,
                attrs,
                content: caption ? [{ type: 'text', text: caption }] : []
              })
              // set cursor at end of caption field
              .command(({ tr, commands }) => {
                const { doc, selection } = tr
                const beforeImagePosition = selection.to - 2
                if (beforeImagePosition > 0) {
                  // if possible to move cursor before image - do it
                  return commands.setTextSelection(
                    doc.resolve(beforeImagePosition).end()
                  )
                } else {
                  // if impossible to move cursor before image (start of the document case) - set cursor after image
                  return commands.insertContentAt(selection.to + 1, '<p></p>')
                }
              })
              .run()
          )
        }
    }
  }
})
