import { createStitches } from '@stitches/react'
import type * as Stitches from '@stitches/react'
import { commonStitchesConfig as stitchesConfig } from '../../stitches.config'

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config
} = createStitches({
  prefix: 'sefar',
  ...stitchesConfig,
  theme: {
    ...stitchesConfig.theme,
    colors: {
      ...stitchesConfig.theme.colors
    },
    fonts: {
      ...stitchesConfig.theme.fonts
    },
    fontSizes: {
      ...stitchesConfig.theme.fontSizes
    },
    lineHeights: {
      ...stitchesConfig.theme.lineHeights
    },
    fontWeights: {
      ...stitchesConfig.theme.fontWeights
    }
  }
})

export type CSS = Stitches.CSS<typeof config>
