import React, { useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import {
  AddFile20,
  Body4,
  Box,
  Button,
  EditIcon20,
  Heading5,
  LazyImage,
  Space,
  Video,
  ToastTypeEnum,
  useToastContext
} from '@sefar/design-system'
import {
  AddMediaInput,
  checkFileType,
  FileInfoDialog,
  GalleryDialog
} from '../../components'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import {
  FileTypeHTMLFormat,
  getFileType,
  preloadImageToDrupal,
  transferPreloadedToDrupalImage,
  SelectedValuesType,
  FileTypeEnum,
  MediaAssetsTypes,
  MainMedia
} from '../../api'
import {
  getSubCategoriesByCategory,
  getCountryListCategories
} from '../../components/article/helpers'
import { fileDrop, FileMeta } from './helpers'

export function NewsMainMedia({
  mainMedia,
  onMainMediaChange,
  categories
}: {
  mainMedia?: MainMedia
  onMainMediaChange: (mainMedia: Partial<MainMedia>) => void
  categories: any
}) {
  const setToast = useToastContext()
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const [dragCounter, setDragCounter] = useState<number>(0)
  const [isGalleryOpened, setIsGalleryOpened] = useState(false)
  const [isUploadMediaDialogOpened, setIsUploadMediaDialogOpened] =
    useState(false)
  const [file, setFile] = useState<File>(mainMedia?.file)
  const [filterCategories, setFilterCategories] = useState<SelectedValuesType>(
    []
  )
  const [filterCountries, setFilterCountries] = useState<SelectedValuesType>([])
  const [fileContentAsString, setFileContentAsString] = useState<string>()
  const [mediaType, setMediaType] = useState<string>('image')
  const [imageToPreloadToDrupal, setImageToPreloadToDrupal] =
    useState<string>('')
  const [chosenFile, setChosenFile] = useState<any>(undefined)

  const onFileSelected = () => {
    if (chosenFile && chosenFile.id) {
      setImageToPreloadToDrupal(chosenFile.id)
      const mediaType = chosenFile?.file_properties?.format_type || 'image'
      setMediaType(mediaType)
      if (mediaType === 'video') {
        onMainMediaChange({
          url: chosenFile?.embeds?.original?.url.split('?')?.[0]
        })
      } else {
        onMainMediaChange({ url: chosenFile?.embeds['640px-landscape'].url })
      }
      setIsGalleryOpened(false)
    }
  }

  useEffect(() => {
    if (imageToPreloadToDrupal) {
      preloadImageToDrupal(imageToPreloadToDrupal)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          onMainMediaChange({
            attachmentInfo: transferPreloadedToDrupalImage(data)
          })
        })
        .catch(() => {
          setToast({
            message: 'Some error occurred. Please choose image again!',
            type: ToastTypeEnum.error
          })
        })
    }
  }, [imageToPreloadToDrupal])

  useEffect(() => {
    if (categories && categories.length) {
      setFilterCategories(
        getSubCategoriesByCategory(categories, 'news', 'Main Visual')
      )
      setFilterCountries(getCountryListCategories(categories))
    }
  }, [categories])

  useEffect(() => {
    return () => {
      // clean up temporary file on unmount to avoid memory leak
      if (mainMedia?.url) {
        URL.revokeObjectURL(mainMedia?.url)
      }
    }
  }, [])

  const onInputFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget?.files?.[0]
    const type = file && getFileType(file)
    if (file && type && ['image', 'video'].includes(FileTypeHTMLFormat[type])) {
      setFile(file)
      setMediaType(checkFileType(file))
      setIsUploadMediaDialogOpened(true)
      setIsGalleryOpened(false)
    }
  }

  return (
    <>
      <Space
        mt="MD"
        data-cy="mediaInput"
        onDrop={(e) => {
          setDragCounter(0)
          e.preventDefault()
          const files = fileDrop(e)
          const type = files[0] && getFileType(files[0])
          if (
            files[0] &&
            type &&
            ['image', 'video'].includes(FileTypeHTMLFormat[type])
          ) {
            setFile(files[0])
            URL.revokeObjectURL(fileContentAsString)
            setFileContentAsString(URL.createObjectURL(files[0]))
            setIsUploadMediaDialogOpened(true)
          }
        }}
        onDragEnter={(e) => {
          setDragCounter((v) => v + 1)
        }}
        onDragLeave={(e) => {
          setDragCounter((v) => v - 1)
        }}
        onDragOver={(event) => {
          event.preventDefault()
        }}
      >
        <Box
          css={{
            position: 'relative',
            border: '2px dashed',
            borderRadius: '$3',
            overflow: 'hidden',
            height: mainMedia?.url ? 'auto' : '250px',
            color: dragCounter
              ? '$primaryBlue'
              : mainMedia?.url
                ? '$white'
                : '$neutral',
            borderColor: dragCounter
              ? '$primaryBlueLighten30'
              : mainMedia?.url
                ? 'transparent'
                : '$neutralLighten60',
            background: dragCounter
              ? '$primaryBlueLighten97'
              : mainMedia?.url
                ? 'transparent'
                : '$neutralLighten97',
            '&:hover .actions': {
              d: mainMedia?.url && dragCounter ? 'none' : 'flex'
            }
          }}
        >
          {mainMedia?.url && (
            <>
              {mediaType === 'video' ? (
                <Video src={mainMedia?.url} />
              ) : (
                <LazyImage aspectRatio="2 / 1" src={mainMedia?.url} />
              )}
            </>
          )}
          {mainMedia?.url && (
            <>
              <Box
                css={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '$scrim',
                  opacity: 0.6,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translateY(-50%) translateX(-50%)',
                  d: 'none',
                  transition: '$default',
                  pointerEvents: 'none'
                }}
                className="actions"
              ></Box>
              <Box
                css={{
                  position: 'absolute',
                  top: '16px',
                  right: '16px',
                  d: 'none',
                  gap: '$3',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                className="actions"
              >
                {/*<Button*/}
                {/*  onClick={() => {}}*/}
                {/*  variant="secondary"*/}
                {/*  noBorder={true}*/}
                {/*  icon={true}*/}
                {/*  aria-label="Delete Media"*/}
                {/*>*/}
                {/*  <DeleteIcon20 />*/}
                {/*</Button>*/}
                {file && (
                  <Button
                    onClick={() => {
                      setFileContentAsString(mainMedia?.url)
                      setIsUploadMediaDialogOpened(true)
                    }}
                    variant="secondary"
                    noBorder={true}
                    icon={true}
                    aria-label="Edit Media"
                  >
                    <EditIcon20 />
                  </Button>
                )}
              </Box>
            </>
          )}
          <Box
            css={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateY(-50%) translateX(-50%)',
              d: mainMedia?.url ? 'none' : 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            className="actions"
          >
            <Box
              css={{
                mb: '$4',
                '@sm': {
                  d: 'none'
                }
              }}
            >
              <AddFile20 />
            </Box>

            <Heading5
              css={{
                '@sm': {
                  d: 'none'
                }
              }}
            >
              Drag and drop image or video here
            </Heading5>

            {!dragCounter && (
              <>
                <Body4
                  css={{
                    mt: '$2',
                    mb: '$6',
                    '@sm': {
                      d: 'none'
                    }
                  }}
                >
                  or
                </Body4>

                <Box
                  css={{
                    d: 'flex',
                    gap: '$4',
                    '@sm': {
                      flexDirection: 'column'
                    }
                  }}
                >
                  <AddMediaInput
                    id="main-media-file"
                    onInputFileChange={onInputFileChange}
                    allowedFileTypes={['image', 'video']}
                  />

                  <Button onClick={() => setIsGalleryOpened(true)}>
                    Browse library
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Space>

      <FileInfoDialog
        categories={filterCategories}
        countries={filterCountries}
        src={fileContentAsString}
        file={file}
        open={isUploadMediaDialogOpened}
        setOpen={setIsUploadMediaDialogOpened}
        meta={file === mainMedia?.file ? mainMedia?.meta : undefined}
        onInsert={(meta: FileMeta, updatedImageFile?: File) => {
          if (file) {
            onMainMediaChange({
              file: updatedImageFile ?? file,
              meta,
              url: URL.createObjectURL(updatedImageFile ?? file)
            })
            setMediaType(checkFileType(file))
          }
          setIsUploadMediaDialogOpened(false)
        }}
        onCancel={() => setFile(mainMedia?.file)}
      />

      <GalleryDialog
        title="Select main visual"
        open={isGalleryOpened}
        setOpenDialog={setIsGalleryOpened}
        chosenFile={chosenFile}
        setChosenFile={setChosenFile}
        filterCategories={filterCategories}
        filterCountries={filterCountries}
        allowedFormats={[FileTypeEnum.Images, FileTypeEnum.Video]}
        assetsType={[
          MediaAssetsTypes.news.key,
          MediaAssetsTypes.news.enum.mainVisual
        ]}
        footer={
          <Box
            css={{
              d: 'flex',
              justifyContent: isLg ? 'space-between' : 'flex-end'
            }}
          >
            <Box css={{ d: 'flex', alignItems: 'center' }}>
              <>
                {isLg && (
                  <AddMediaInput
                    id="main-media-file"
                    onInputFileChange={onInputFileChange}
                    allowedFileTypes={['image', 'video']}
                    size="small"
                  />
                )}
              </>
            </Box>
            <Button
              size="small"
              onClick={onFileSelected}
              disabled={!chosenFile}
            >
              Insert media
            </Button>
          </Box>
        }
      >
        {!isLg && (
          <AddMediaInput
            id="article-file"
            onInputFileChange={onInputFileChange}
            buttonVariant="textLink"
          />
        )}
      </GalleryDialog>
    </>
  )
}
