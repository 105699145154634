import {
  ArrowRightIcon16,
  Body2,
  Body3,
  Body4,
  Body5,
  Body6,
  Box,
  Heading2,
  Heading3,
  Heading4,
  Spinner,
  TextLink
} from '@sefar/design-system'
import { useNewsInfinite } from '../../api/news'
import { CSS } from '@stitches/react'
import {
  analyticsPageTracker,
  ArticleLink,
  mixpanelAnalyticsHelper
} from '../../components'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'

type Props = {
  css?: CSS
  exclude?: string[]
  onNavigate?: () => void
}

const HomeItUpdates = ({ css, exclude = [], onNavigate }: Props) => {
  const { news, isLoading } = useNewsInfinite({
    category: ['IT Updates'],
    limit: 6,
    exclude
  })
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const TitleComponent = isLg ? Heading3 : Heading4
  return (
    <Box css={css}>
      <Box
        css={{
          d: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <TitleComponent css={{ mb: '$8' }}>IT updates</TitleComponent>
        <Body4 css={{ mt: isLg ? '$2' : undefined }}>
          <TextLink
            css={{
              fontSize: '$body4',
              lineHeight: '$8'
            }}
            className="no-underline"
            onClick={onNavigate}
          >
            <Box as="span" css={{ mr: '$2' }}>
              See all IT news
            </Box>
            <ArrowRightIcon16 />
          </TextLink>
        </Body4>
      </Box>
      <Box
        css={{
          d: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          my: '-$8',
          '@lg': { m: '-$8' }
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          news.map((article) => (
            <Box
              css={{
                flexBasis: '100%',
                py: '$4',
                '@md': { flexBasis: '50%' },
                '@lg': { flexBasis: '33.33%', p: '$4' }
              }}
            >
              <ArticleLink
                to={`/news/${article.id}`}
                onClick={() => {
                  mixpanelAnalyticsHelper().track('Leaving from home page')
                  analyticsPageTracker('news')
                }}
                css={{
                  flexBasis: '100%',
                  py: '$4',
                  '@md': { flexBasis: '50%' },
                  '@lg': { flexBasis: '33.33%', p: '$4' }
                }}
              >
                <Body5
                  css={{
                    color: 'rgba(0,0,0,0.5)',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                    overflow: 'hidden'
                  }}
                >
                  {article.shortDate}
                </Body5>
                <Body3
                  css={{
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical'
                  }}
                >
                  {article.title}
                </Body3>
              </ArticleLink>
            </Box>
          ))
        )}
      </Box>
    </Box>
  )
}

export default HomeItUpdates
