import { createStitches } from '@stitches/react'

export const BREAKPOINTS_QUERIES = {
  xxs: `(max-width: 340px)`,
  xs: `(max-width: 420px)`,
  sm: `(max-width: 560px)`,
  md: `(min-width: 561px)`,
  lg: `(min-width: 861px)`,
  xl: `(min-width: 1101px)`,
  xxl: `(min-width: 1350px)`
}
export const commonStitchesConfig = {
  media: BREAKPOINTS_QUERIES,
  theme: {
    colors: {
      primaryBlue: '#0073BE',
      primaryBlueDarken30: '#005084',
      primaryBlueLighten30: '#4D9DD1',
      primaryBlueLighten50: '#0096F8',
      primaryBlueLighten70: '#B3D5EB',
      primaryBlueLighten90: '#CCEBFF',
      primaryBlueLighten95: '#E4F4FF',
      primaryBlueLighten97: '#EEF8FF',
      primaryRed: '#E32127',
      skyBlue: '#4EABE2',
      skyBlueLighten25: '#7AC0E9',
      skyBlueLighten50: '#A6D5F0',
      skyBlueLighten75: '#D2E9F7',
      navyBlue: '#004471',
      navyBlueLighten25: '#407294',
      navyBlueLighten50: '#80A1B8',
      navyBlueLighten75: '#BFD0DB',
      orange: '#F18429',
      orangeLighten25: '#F4A25E',
      orangeLighten50: '#F8C194',
      orangeLighten75: '#FBE0C9',
      burgundy: '#881317',
      burgundyLighten25: '#A54E51',
      burgundyLighten50: '#C3898B',
      burgundyLighten75: '#E1C3C4',
      purple: '#8385FF',
      purpleLighten25: '#A2A3FF',
      purpleLighten50: '#C1C2FF',
      purpleLighten75: '#DFE0FF',
      black: '#000000',
      white: '#FFFFFF',
      neutral: '#00192A',
      neutralLighten10: '#1A3041',
      neutralLighten20: '#334757',
      neutralLighten25: '#405261',
      neutralLighten30: '#4D5E6C',
      neutralLighten50: '#808C96',
      neutralLighten60: '#99A3AB',
      neutralLighten70: '#B3BAC1',
      neutralLighten80: '#CCD1D6',
      neutralLighten90: '#E6E8EE',
      neutralLighten93: '#EDEEF3',
      neutralLighten97: '#F7F7FA',
      error: '#DB0B47',
      errorLighten25: '#FF2D64',
      errorLighten80: '#FFC6D7',
      errorLighten90: '#FBE7EC',
      errorLighten95: '#FFEDF2',
      destructiveHover: '#980731',
      destructivePressed: '#6D0523',
      destructiveDisabled: '#E6E8EC',
      success: '#00845D',
      successDarken30: '#005C40',
      successLighten20: '#00E29F',
      successLighten30: '#00F6AB',
      successLighten80: '#CCFDEE',
      successLighten95: '#EBFFF9',
      warning: '#FFB11A',
      warningDarken40: '#703F00',
      warningLighten40: '#FFD075',
      warningLighten90: '#FFF8EB',
      darkenBackgroundHome:
        'linear-gradient(180deg, rgba(13, 37, 54, 0.375) 7%, rgba(26, 48, 65, 0.3) 31%, rgba(0, 25, 42, 0.5) 100%)',
      gradientBlue: 'linear-gradient(to right, $skyBlue, $primaryBlue)',
      scrim: '#00192A99',
      scrimBackgroundBlur: 'rgba(0, 25, 42, 0.6)',
      cardBackgroundBlur: 'rgba(255, 255, 255, 0.15)',
      buttonBackgroundBlackBlur: 'rgba(0,0,0,0.2)'
    },
    transitions: {
      default: 'all 300ms ease',
      easingEnter: 'cubic-bezier(0, 0.5, 0.5, 1)',
      easingMove: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easingExit: 'cubic-bezier(0.5, 0, 0, 1)'
    },
    space: {
      headerHeight: '64px',
      footerHeightMd: '83px',
      footerHeightSm: '66px',
      inlineEditingBottomToolbar: '80px',
      ...Object.fromEntries(
        Array.from(Array(80)).map((v, i) => [i + 1, `${(i + 1) * 4}px`])
      )
    },
    sizes: {
      headerHeight: '64px',
      footerHeightMd: '83px',
      footerHeightSm: '66px',
      inlineEditingBottomToolbar: '80px'
    },
    zIndices: {
      header: 20,
      dropdown: 1000,
      select: 1002,
      toast: 1001,
      tooltip: 1003,
      inlineEditorMenuBar: 21,
      toolBar: 21,
      searchHomePage: 22,
      dialog: 100,
      sliderNavigation: 11
    },
    fonts: {
      primary: 'Noto Sans, -apple-system, BlinkMacSystemFont, "Segoe UI"'
    },
    fontWeights: {
      light: 300,
      normal: 400,
      semiBold: 600,
      bold: 700
    },
    lineHeights: {
      '12': 1.2,
      '13': 1.3,
      '14': 1.4,
      '15': 1.5,
      '16': 1.6
    },
    fontSizes: {
      h1Xl: '2.625rem', //42px
      h1Lg: '2.125rem', //34px
      h1Sm: '1.75rem', //28px
      h2Xl: '2.25rem', //36px
      h2Lg: '1.875rem', //30px
      h2Sm: '1.5rem', //24px
      h3Xl: '1.75rem', //28px
      h3Lg: '1.5rem', //24px
      h3Sm: '1.375rem', //22px
      h4Xl: '1.5rem', //24px
      h4Lg: '1.1875rem', //19px
      h4Sm: '1.1875rem', //19px
      h5Xl: '1.1875rem', //19px
      h5Lg: '1rem',
      h5Sm: '1rem',
      body1Xl: '2rem', //32px
      body1Lg: '1.625rem', //26px
      body1Sm: '1.1875rem', //19px
      body2Xl: '1.5rem', //24px
      body2Lg: '1.3125rem', // 21px
      body2Sm: '1.1875rem', //19px
      body3Xl: '1.1875rem', //19px
      body3: '1rem',
      body4Xl: '1.0625rem', //17px
      body4: '0.9375rem', //15px
      body5Xl: '0.9375rem', //15px
      body5: '0.875rem', //14px
      body6: '0.8125rem', //13px
      body7: '0.75rem', //12px
      button1Lg: '0.9375rem', //17px
      button1: '0.9375rem', //15px
      button2Xl: '0.9375rem', //15px
      button2: '0.875rem', //14px
      tooltip: '0.8125rem' // 13px
    },
    radii: {
      1: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
      6: '6px',
      12: '12px',
      round: '50%'
    },
    shadows: {
      default: '0 1px 2px 0 rgba(0,0,0,0.05)',
      card: '0 4px 8px 0 rgba(0,0,0,0.04)',
      cardHover: '0 8px 16px -4px rgba(0,0,0,0.16)',
      dropdown:
        '0 10px 15px -3px rgba(0,0,0,0.10), 0 4px 6px -4px rgba(0,0,0,0.10)',
      toolsHover: `0px 8px 8px -4px #00192A`
    }
  },
  utils: {
    // @ts-ignore
    d: (value) => ({
      display: value
    }),
    // @ts-ignore
    w: (value) => ({
      width: value
    }),
    // @ts-ignore
    h: (value) => ({
      height: value
    }),
    // @ts-ignore
    m: (value) => ({
      margin: value
    }),
    // @ts-ignore
    mt: (value) => ({
      marginTop: value
    }),
    // @ts-ignore
    mr: (value) => ({
      marginRight: value
    }),
    // @ts-ignore
    mb: (value) => ({
      marginBottom: value
    }),
    // @ts-ignore
    ml: (value) => ({
      marginLeft: value
    }),
    // @ts-ignore
    mx: (value) => ({
      marginLeft: value,
      marginRight: value
    }),
    // @ts-ignore
    my: (value) => ({
      marginTop: value,
      marginBottom: value
    }),
    // @ts-ignore
    p: (value) => ({
      padding: value
    }),
    // @ts-ignore
    pt: (value) => ({
      paddingTop: value
    }),
    // @ts-ignore
    pr: (value) => ({
      paddingRight: value
    }),
    // @ts-ignore
    pb: (value) => ({
      paddingBottom: value
    }),
    // @ts-ignore
    pl: (value) => ({
      paddingLeft: value
    }),
    // @ts-ignore
    px: (value) => ({
      paddingLeft: value,
      paddingRight: value
    }),
    // @ts-ignore
    py: (value) => ({
      paddingTop: value,
      paddingBottom: value
    }),
    // @ts-ignore
    bc: (value) => ({
      backgroundColor: value
    }),
    // A property for applying width/height together
    // @ts-ignore
    size: (value) => ({
      width: value,
      height: value
    }),
    // @ts-ignore
    borderRadius: (value) => ({
      borderRadius: value
    }),
    // Grid utils
    // @ts-ignore
    gtc: (repeat) => ({
      gridTemplateColumns: `repeat(${repeat}, 1fr)`
    }),
    // @ts-ignore
    gc: (value) => ({
      gridColumn: `${value}`
    }),
    // @ts-ignore
    gcSm: (value) => ({
      '@sm': { gridColumn: `${value}` }
    }),
    // @ts-ignore
    gcMd: (value) => ({
      '@md': { gridColumn: `${value}` }
    }),
    // @ts-ignore
    gcLg: (value) => ({
      '@lg': { gridColumn: `${value}` }
    }),
    // @ts-ignore
    gcXl: (value) => ({
      '@xl': { gridColumn: `${value}` }
    })
  }
}

export const { styled, getCssText, reset, config, globalCss, keyframes } =
  createStitches(commonStitchesConfig)
