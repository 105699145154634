import {
  Body5,
  Box,
  Button,
  Dialog,
  Space,
  Spinner
} from '@sefar/design-system'
import { SearchInput } from '../search/search-input'
import React, { useEffect, useState } from 'react'
import { User, useSearchUsers } from '../../api'
import { AssigneeUserCard } from '../common/assignee-user-card'

export type SelectUserDialogProps = {
  title: string
  description?: string
  saveButtonLabel?: string
  open: boolean
  setOpen: (open: boolean) => void
  onSetImplementer: (user: User) => void
  query?: { [a: string]: string }
}

export function SelectUserDialog({
  title,
  description,
  saveButtonLabel,
  open,
  setOpen,
  onSetImplementer,
  query
}: SelectUserDialogProps) {
  const [implementer, setImplementer] = useState<User>()
  const [userSearch, setUserSearch] = useState<string>('')
  const [userSearchQuery, setUserSearchQuery] = useState<
    Record<string, string | number>
  >({ page: 0 })
  const { users, isLoading } = useSearchUsers(
    { ...userSearchQuery, ...query },
    50
  )

  useEffect(() => {
    setUserSearchQuery((oldValue) => ({
      ...oldValue,
      search: userSearch.length > 1 ? userSearch : ''
    }))
  }, [userSearch])

  return (
    <Dialog
      open={open}
      setOpenControlledDialog={setOpen}
      onOpenChange={(open) => {
        setImplementer(undefined)
        setUserSearch('')
      }}
      title={title}
      footer={
        <Box css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Button
            data-cy="sendToReviewerButton"
            variant="primary"
            disabled={!implementer}
            onClick={() => implementer && onSetImplementer(implementer)}
          >
            {saveButtonLabel ?? 'Save'}
          </Button>
        </Box>
      }
    >
      <Space
        css={{
          maxHeight: '100%',
          pr: '$4',
          color: '$neutral',
          '@lg': {
            minWidth: 600
          }
        }}
      >
        {description && (
          <Space mb="MD">
            <Body5>{description}</Body5>
          </Space>
        )}

        <SearchInput
          small={true}
          placeholder="Search a user"
          data-cy="searchUserInput"
          value={userSearch}
          onChange={setUserSearch}
        />

        <Space mt="MD" css={{ d: 'flex', gap: '$2', flexDirection: 'column' }}>
          {isLoading && <Spinner />}
          {users?.map((user: User) => (
            <AssigneeUserCard
              key={user?.id}
              selected={user?.id === implementer?.id}
              user={user}
              onClick={() => setImplementer(user)}
            />
          ))}
        </Space>
      </Space>
    </Dialog>
  )
}
