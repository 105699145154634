import { keyframes, styled, CSS } from '../../../stitches.config'
import React, {
  ComponentProps,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState
} from 'react'
import { Box } from '../utils'

function createSpinnerVariant(size: number, strokeWidth: number) {
  const spinner = keyframes({
    '0%': {
      transform: 'rotate(0deg)',
      strokeDashoffset: `${0.66 * size}`
    },
    '50%': {
      transform: 'rotate(720deg)',
      strokeDashoffset: `${3.14 * size}`
    },
    '100%': {
      transform: 'rotate(1080deg)',
      strokeDashoffset: `${0.66 * size}`
    }
  })

  return {
    size,
    x: 0,
    y: 0,
    viewBox: `0 0 ${size} ${size}`,
    d: 'block',
    margin: '0 auto',

    '& circle': {
      cx: size / 2,
      cy: size / 2,
      r: size / 2 - 2,
      fill: 'transparent',
      stroke: '$primaryBlueLighten50',
      strokeWidth,
      strokeLinecap: 'round',
      strokeDasharray: 3.14 * size,
      transformOrigin: `${0.5 * size}px ${0.5 * size}px 0`,
      animation: `${spinner} 4s linear infinite`
    }
  }
}

export const StyledSpinner = styled('svg', {
  variants: {
    size: {
      small: createSpinnerVariant(16, 2),
      medium: createSpinnerVariant(24, 3),
      large: createSpinnerVariant(48, 3)
    }
  },
  defaultVariants: {
    size: 'large'
  }
})

export function Spinner(props: ComponentProps<typeof StyledSpinner>) {
  return (
    <StyledSpinner {...props}>
      <circle />
    </StyledSpinner>
  )
}

type GlobalSpinnerProps = {
  show: boolean
  containerStyles?: CSS
}
const SpinnerContext =
  createContext<Dispatch<SetStateAction<GlobalSpinnerProps>>>(undefined)
export default SpinnerContext

export function SpinnerContextProvider({ children }) {
  const [spinner, setGlobalSpinner] = useState<GlobalSpinnerProps>({
    show: false
  })
  const { show, containerStyles } = spinner

  return (
    <SpinnerContext.Provider value={setGlobalSpinner}>
      {children}
      {show && (
        <Box
          css={{
            width: '100%',
            height: '100%',
            bottom: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            zIndex: '$tooltip',
            ...(containerStyles || {})
          }}
        >
          <Spinner />
        </Box>
      )}
    </SpinnerContext.Provider>
  )
}

export function useGlobalSpinner() {
  return useContext(SpinnerContext)
}
