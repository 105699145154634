import React from 'react'
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Body6,
  Box
} from '@sefar/design-system'

type Props = {
  children: React.ReactNode
  value: string
  title: string
}

export const SidebarAccordionItem = ({ children, value, title }: Props) => (
  <AccordionItem value={value}>
    <AccordionTrigger
      css={{
        px: 0,
        py: '$2',
        mr: 0,
        maxWidth: '100%',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: 'transparent'
        }
      }}
      headerCSS={{
        maxWidth: 'calc(100% - 28px)'
      }}
    >
      <Box
        css={{
          gap: '$2',
          alignItems: 'center',
          color: '$neutral',
          maxWidth: '80%'
        }}
      >
        <Body6
          fontWeight="bold"
          css={{
            ml: '$3',
            overflow: 'hidden',
            color: '$neutralLighten30',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap'
          }}
        >
          {title}
        </Body6>
      </Box>
    </AccordionTrigger>
    <AccordionContent css={{ px: 0, pt: 16, pb: 0 }}>
      <Box css={{ d: 'flex', flexDirection: 'column', gap: '$2' }}>
        {children}
      </Box>
    </AccordionContent>
  </AccordionItem>
)
