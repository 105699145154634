import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Container,
  ContainerPadding,
  FloatingButton
} from '@sefar/design-system'

export function AddNewArticleButton({
  url,
  text,
  containerSize,
  padding
}: {
  url: string
  text: string
  containerSize?: string
  padding?: boolean
}) {
  return (
    <Container
      size={containerSize}
      css={{
        position: 'sticky',
        bottom: '$26',
        width: '100%',
        pointerEvents: 'none',
        mb: '$3',
        '@lg': {
          bottom: '$6'
        }
      }}
    >
      <Box
        as={padding ? ContainerPadding : 'div'}
        css={{
          d: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <FloatingButton
          as={Link}
          to={url}
          css={{ textDecoration: 'none', pointerEvents: 'initial' }}
          data-cy="newsCreateButton"
        >
          {text}
        </FloatingButton>
      </Box>
    </Container>
  )
}
