import useSWR from 'swr'
import { API_DOMAIN, fetcherData, init } from './common'

export const UND_PHP = 'und'
export const isTrashLanguage = (lang: string) =>
  ['zxx', 'und'].some((trash) => trash === lang)

export type Language = {
  id: string
  direction: string
  label: string
  labelEng: string
}

export function useLanguages<T = Language[]>(transform?: (data: any[]) => T) {
  const { data, error } = useSWR(
    [`${API_DOMAIN}/jsonapi/configurable_language/configurable_language`, init],
    fetcherData
  )

  return {
    languages: data
      ? (transform && transform(data)) || transferLanguages(data)
      : data,
    isLoading: !error && !data,
    isError: error
  }
}

function transferLanguages(data: any[]): Language[] {
  return (
    data
      ?.filter(
        (lang) => !isTrashLanguage(lang?.attributes?.drupal_internal__id)
      )
      ?.map(({ attributes }: any) => ({
        id: attributes.drupal_internal__id,
        direction: attributes.direction,
        label: attributes.label?.split('-')?.[1] || '',
        labelEng: attributes.label?.split('-')?.[0] || ''
      }))
      ?.sort((a, b) => a.label.localeCompare(b.label)) || []
  )
}

export function addTranslations(
  id: string,
  automatic: string[],
  manual: string[],
  publish: boolean
) {
  const automaticQuery = automatic?.length ? `&automatic=${automatic}` : ''
  const manualQuery = manual?.length ? `&manual=${manual}` : ''
  const publishedQuery = publish ? `&published=true` : ''
  return id && (automaticQuery || manualQuery)
    ? fetch(
        `${API_DOMAIN}/api_translations/translate?nid=${id}${automaticQuery}${manualQuery}${publishedQuery}`,
        init
      )
    : Promise.resolve()
}

export function publishAllTranslations(id: string) {
  return id
    ? fetch(`${API_DOMAIN}/api_translations/publish-all?nid=${id}`, {
        ...init,
        headers: {
          ...init.headers,
          'Content-Type': 'application/vnd.api+json'
        },
        method: 'PATCH',
        body: undefined
      })
    : Promise.resolve()
}
