import {
  Box,
  Button,
  Dialog,
  ReactSelect,
  Settings20,
  Space,
  Container
} from '@sefar/design-system'
import { SearchInput } from '../../components/search/search-input'
import { parseFromString } from '../../components'
import React, { useState, useEffect } from 'react'
import { ChannelTypeEnum, Language, UND_PHP, ReviewStateEnum } from '../../api'
import { useSearchParams } from 'react-router-dom'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { sessionStorageKeys } from '../../constants'

export const searchParamsNames = {
  search: 'search',
  status: 'status',
  publicationStatus: 'publicationStatus',
  reviewStatus: 'reviewStatus',
  target: 'target',
  language: 'language',
  year: 'year',
  category: 'category',
  my: 'my',
  unread: 'unread',
  location: 'location'
}

const checkSearchParamsNotEmpty = (searchParams: URLSearchParams) => {
  return !!Object.values(searchParamsNames).find((name) =>
    searchParams.get(name)
  )
}

export function MyContentFilters({
  countries,
  languages
}: {
  countries?: string[]
  languages?: Language[]
}) {
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [isSearchParamsNotEmpty, setIsSearchParamsNotEmpty] = useState<
    boolean | undefined
  >(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const languageOptions = languages && [
    { value: '', label: 'All languages' },
    ...languages
      .map((lang: Language) => ({
        value: lang.id,
        label: lang.labelEng
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  ]

  const targetOptions = countries && [
    { value: '', label: 'All regions' },
    { value: ChannelTypeEnum.global, label: 'Global' },
    ...countries
      .map((country: string) => ({
        value: country,
        label: country
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  ]

  const statusOptions = [
    { value: '', label: 'All publication status' },
    { value: true, label: 'Published' },
    { value: false, label: 'Unpublished' }
  ]

  const reviewStatusOptions = [
    { value: '', label: 'All review status' },
    { value: ReviewStateEnum['Reviewed'], label: 'Review done' },
    { value: ReviewStateEnum['On review'], label: 'Review pending' }
  ]

  const FilterControllers = (
    <Container css={{ mx: isLg ? 'auto' : '0' }}>
      <Box css={{ mb: isLg ? '$4' : '$6' }}>
        <SearchInput
          small={true}
          placeholder="Search by title"
          data-cy="filterSearchInput"
          value={searchParams.get(searchParamsNames.search) ?? ''}
          onChange={(value: string) => {
            if (value === '') {
              searchParams.delete(searchParamsNames.search)
            } else {
              searchParams.set(searchParamsNames.search, value)
            }
            sessionStorage.setItem(
              sessionStorageKeys.MY_CONTENT_FILTERS,
              searchParams.toString()
            )
            setSearchParams(searchParams)
          }}
          placeholderTextLg="Search for people, tools, documents, topics or spaces"
          placeholderTextSm="Search"
          containerProps={{ css: { flexShrink: 2 } }}
        />
      </Box>

      <Box
        css={{
          d: 'flex',
          flexDirection: isLg ? 'row' : 'column',
          justifyContent: isLg ? 'flex-start' : 'flex-end',
          gap: isLg ? '$4' : '$6'
        }}
      >
        {languages && (
          <ReactSelect
            css={{ width: isLg ? 320 : 'auto' }}
            value={
              languageOptions?.find(
                ({ value }) =>
                  value === searchParams.get(searchParamsNames.language)
              ) || languageOptions?.[0]
            }
            options={languageOptions}
            onChange={(option: string) => {
              if (option.value === '') {
                searchParams.delete(searchParamsNames.language)
              } else {
                searchParams.set(searchParamsNames.language, option.value)
              }
              sessionStorage.setItem(
                sessionStorageKeys.MY_CONTENT_FILTERS,
                searchParams.toString()
              )
              setSearchParams(searchParams)
            }}
            id="languages"
            placeholder="All languages"
          />
        )}

        {countries && (
          <ReactSelect
            css={{ width: isLg ? 320 : 'auto' }}
            value={
              targetOptions?.find(
                ({ value }) =>
                  value === searchParams.get(searchParamsNames.target)
              ) || targetOptions?.[0]
            }
            options={targetOptions}
            onChange={(option: string) => {
              if (option.value === '') {
                searchParams.delete(searchParamsNames.target)
              } else {
                searchParams.set(searchParamsNames.target, option.value)
              }
              sessionStorage.setItem(
                sessionStorageKeys.MY_CONTENT_FILTERS,
                searchParams.toString()
              )
              setSearchParams(searchParams)
            }}
            id="regions"
            placeholder="All regions"
          />
        )}

        <ReactSelect
          css={{ width: isLg ? 320 : 'auto' }}
          value={
            statusOptions.find(
              ({ value }) =>
                value ===
                parseFromString.boolean(
                  searchParams.get(searchParamsNames.publicationStatus)
                )
            ) || statusOptions[0]
          }
          options={statusOptions}
          onChange={(option) => {
            if (option.value === '') {
              searchParams.delete(searchParamsNames.publicationStatus)
            } else {
              searchParams.set(
                searchParamsNames.publicationStatus,
                option.value
              )
            }
            sessionStorage.setItem(
              sessionStorageKeys.MY_CONTENT_FILTERS,
              searchParams.toString()
            )
            setSearchParams(searchParams)
          }}
          id="publicationStatus"
          placeholder="All publication status"
        />

        <ReactSelect
          css={{ width: isLg ? 320 : 'auto' }}
          value={
            reviewStatusOptions.find(
              ({ value }) =>
                value === searchParams.get(searchParamsNames.reviewStatus)
            ) || reviewStatusOptions[0]
          }
          options={reviewStatusOptions}
          onChange={(option) => {
            if (option.value === '') {
              searchParams.delete(searchParamsNames.reviewStatus)
            } else {
              searchParams.set(searchParamsNames.reviewStatus, option.value)
            }
            sessionStorage.setItem(
              sessionStorageKeys.MY_CONTENT_FILTERS,
              searchParams.toString()
            )
            setSearchParams(searchParams)
          }}
          id="reviewStatus"
          placeholder="All review status"
        />
      </Box>
    </Container>
  )

  useEffect(() => {
    setIsSearchParamsNotEmpty(checkSearchParamsNotEmpty(searchParams))
  }, [searchParams])

  return (
    <>
      <Box
        css={{
          d: 'flex',
          mt: '$5',
          gap: '$4',
          flexDirection: 'column',
          '@lg': {
            gap: '$8'
          }
        }}
      >
        <Box
          css={{
            d: 'flex',
            gap: '$4',
            flexGrow: 1
          }}
        >
          {isLg ? (
            FilterControllers
          ) : (
            <SearchInput
              small={true}
              placeholder="Search by title"
              value={searchParams.get(searchParamsNames.search)}
              onChange={(value: string) => {
                searchParams.set(searchParamsNames.search, value)
                setSearchParams(searchParams)
              }}
              endEnhancer={
                <Box onClick={() => setOpenDialog(true)}>
                  <Settings20 />
                </Box>
              }
            />
          )}
        </Box>

        <Box
          css={{
            d: 'flex',
            gap: '$3',
            justifyContent: 'flex-end'
          }}
        >
          {isSearchParamsNotEmpty && (
            <Button
              variant="secondary"
              onClick={() => {
                sessionStorage.removeItem(sessionStorageKeys.MY_CONTENT_FILTERS)
                searchParams.delete(searchParamsNames.search)
                searchParams.delete(searchParamsNames.publicationStatus)
                searchParams.delete(searchParamsNames.reviewStatus)
                searchParams.delete(searchParamsNames.target)
                searchParams.delete(searchParamsNames.language)
                setSearchParams(searchParams)
              }}
            >
              Reset
            </Button>
          )}
        </Box>
      </Box>

      <Dialog
        title="My Content Filters"
        setOpenControlledDialog={setOpenDialog}
        open={openDialog}
        footer={
          <Space
            css={{
              d: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button onClick={() => setOpenDialog(false)}>Done</Button>
          </Space>
        }
      >
        <Space
          my="MD"
          css={{
            d: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            gap: '$6'
          }}
        >
          {FilterControllers}
        </Space>
      </Dialog>
    </>
  )
}

export function filterContent(content, searchParams) {
  const search = searchParams.get(searchParamsNames.search)
  const publicationStatus = parseFromString.boolean(
    searchParams.get(searchParamsNames.publicationStatus)
  )
  const reviewStatus = searchParams.get(searchParamsNames.reviewStatus)
  const language = searchParams.get(searchParamsNames.language)
  const target = searchParams.get(searchParamsNames.target)

  return content
    ? Object.values(content)
        .map((translations) =>
          Object.values(translations).find(
            ({ content_translation_source }) =>
              content_translation_source === UND_PHP
          )
        )
        .filter(
          ({ drupal_internal__nid }) =>
            !search ||
            Object.values(content[drupal_internal__nid]).some(
              ({ title }) =>
                title.toLowerCase().indexOf(search.toLowerCase()) !== -1
            )
        )
        .filter(
          ({ drupal_internal__nid }) =>
            !language ||
            Object.values(content[drupal_internal__nid]).some(
              ({ langcode }) => langcode === language
            )
        )
        .filter(
          ({ drupal_internal__nid }) =>
            publicationStatus === undefined ||
            Object.values(content[drupal_internal__nid]).some(
              ({ published }) => publicationStatus === published
            )
        )
        .filter(
          ({ drupal_internal__nid }) =>
            !reviewStatus ||
            Object.values(content[drupal_internal__nid]).some(
              ({ review_state }) => reviewStatus === review_state
            )
        )
        .filter(
          ({ drupal_internal__nid }) =>
            !target ||
            Object.values(content[drupal_internal__nid]).some(
              ({ publication_target }) =>
                publication_target?.some(({ value }) => value === target)
            )
        )
    : []
}
