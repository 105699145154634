import React, { useState, useEffect, useRef, useMemo } from 'react'
import {
  Idea,
  IDEA_TYPE,
  IdeaStatuses,
  IdeaStatusesTranslations,
  isIdeaManager,
  IdeaLocs
} from '../../api'
import {
  Box,
  Container,
  ContainerPadding,
  Space,
  Grid,
  Body5,
  Tag,
  Body3,
  Heading1,
  Heading3,
  Button,
  Line,
  Badge,
  Heading4,
  Document24
} from '@sefar/design-system'
import { useMedia } from 'react-use'
import { useConfigStore } from '../../state/config'
import { useTranslate } from '../../hooks/useTranslate'
import { AuthorCard } from '../../components/common/author'
import {
  ArticleComments,
  getUserFullName,
  mixpanelAnalyticsHelper
} from '../../components'
import { BREAKPOINTS_QUERIES } from '../../../../stitches.config'
import { useNavigate } from 'react-router-dom'
import { ideasPageUrl } from '../../app'
import { BottomSection } from './idea-details-edit'
import { ArticleInfoActions } from '../../components/article/article-info'
import { DateOfRealization } from '../../components/ideas/date-of-realization'
import { mapIdeaStatusesToBadgeColors } from '../../components/ideas/utils'
import {
  useIdeaValidationMapOptions,
  useIdeaValidationOptions,
  ValidateOptionsMap
} from './idea-validate'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'

function TextBlock({ title, text }: { title: string; text: string }) {
  return (
    <>
      <Heading3 css={{ mt: '$16', mb: '$5' }}>{title}</Heading3>
      <Body3 css={{ mb: '$3' }}>{text}</Body3>
    </>
  )
}

export function IdeaDetailsView({
  idea,
  onCategoryTagClick,
  onSubmitClick,
  categories,
  refresh
}: {
  idea: Idea
  onCategoryTagClick: (cat: string) => void
  onSubmitClick: () => void
  categories: Record<string, string> | undefined
  refresh?: () => void
}) {
  const { t } = useTranslate()
  const commentsBox = useRef<HTMLHeadingElement>(null)
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const navigate = useNavigate()
  const { me } = useConfigStore()
  const [isMeIdeaManager, setIsMeIdeaManager] = useState<boolean>(false)
  const {
    field_ideas_expected_earnings: earningsOptions,
    field_ideas_expected_savings: savingsOptions,
    field_ideas_impact: impactOptions,
    field_ideas_strategy_categories: strategyCategoriesOptions
  } = useIdeaValidationMapOptions()
  useEffect(() => {
    if (me) {
      setIsMeIdeaManager(isIdeaManager(me))
    }
  }, [me])

  useEffect(() => {
    if (idea?.id) {
      const authorFullName = getUserFullName(
        idea.author?.firstName,
        idea.author?.lastName
      )
      const implementerFullName = getUserFullName(
        idea.implementer?.firstName,
        idea.implementer?.lastName
      )
      mixpanelAnalyticsHelper().track('Idea viewed', {
        ideaId: idea.id,
        ideaTitle: idea.title,
        ideaAuthor: authorFullName,
        ideaImplementer: implementerFullName,
        ideaCountry: idea.country,
        ideaLanguage: idea.lang,
        ideaStatus: idea.status
      })
    }
  }, [idea])

  const scrollToComments = () => {
    if (commentsBox.current) {
      commentsBox.current.scrollIntoView()
    }
  }
  const ideaIsValidated = idea.expectedSavings || idea.implementationCosts

  const ideaLocationLabel = useMemo<string | undefined>(() => {
    if (idea?.location) {
      for (const locOption of IdeaLocs) {
        if (isEqual(sortBy(locOption.value), sortBy(idea.location))) {
          return locOption.label
        }
      }
    } else {
      return undefined
    }
  }, [IdeaLocs, idea.location])

  return (
    <>
      <Container>
        {idea && (
          <Space mb="3XL">
            <Grid>
              <Box
                css={{
                  gc: '2 / span 10',
                  gcSm: '1 / span 12',
                  d: 'flex',
                  flexDirection: 'column'
                }}
              >
                {idea?.id && idea?.status && (
                  <Box css={{ d: 'flex', mb: '$6', gap: '$2' }}>
                    <Badge status={mapIdeaStatusesToBadgeColors[idea.status]}>
                      {t(IdeaStatusesTranslations[idea.status])}
                    </Badge>
                    <DateOfRealization idea={idea} />
                  </Box>
                )}
                <Heading1 css={{ mb: '$4' }}>{idea.title}</Heading1>

                <Line mt="MD" mb="3XS" />

                <Box css={{ d: 'flex' }}>
                  {(!idea.anonymous || isMeIdeaManager) && (
                    <AuthorCard {...idea.author} />
                  )}
                  <ArticleInfoActions
                    article={idea}
                    withViews={false}
                    withLikes={true}
                    scrollToComments={scrollToComments}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid>
              <Box
                css={{
                  mt: '$5',
                  gc: '2 / span 10',
                  gcSm: '1 / span 12'
                }}
              >
                {ideaIsValidated && (
                  <Box>
                    <Heading3 css={{ mt: '$16', mb: '$5' }}>
                      {t('field_idea_validation_caption')}
                    </Heading3>
                    <Box
                      css={{
                        backgroundColor: '#F7F7F8',
                        borderRadius: 8,
                        p: '$4'
                      }}
                    >
                      <Box css={{ mb: '$8' }}>
                        <Heading4 css={{ mb: '$1', fontWeight: 600 }}>
                          {idea.expectedSavings &&
                            `${savingsOptions?.[idea.expectedSavings]} CHF`}
                          {idea.expectedTurnover &&
                            `${earningsOptions?.[idea.expectedTurnover]} CHF`}
                        </Heading4>
                        <Body3>
                          {idea.expectedSavings
                            ? t('field_idea_savings_title')
                            : t('field_idea_turnover_title')}
                        </Body3>
                      </Box>
                      <Box css={{ mb: '$8' }}>
                        <Heading4 css={{ mb: '$1', fontWeight: 600 }}>
                          {idea.implementationCosts} CHF
                        </Heading4>
                        <Body3>{t('field_idea_costs_title')}</Body3>
                      </Box>
                      {idea.expectedImpact?.length && (
                        <Box css={{ mb: '$8' }}>
                          {idea.expectedImpact.map((impact) => (
                            <Box
                              css={{
                                d: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                mb: '$5'
                              }}
                            >
                              <Box
                                css={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  mr: '$1',
                                  '& > svg': {
                                    stroke: '$neutralLighten30',
                                    width: 14,
                                    height: 14
                                  }
                                }}
                              >
                                <Document24 />
                              </Box>
                              {impactOptions?.[impact]}
                            </Box>
                          ))}
                        </Box>
                      )}
                      <Heading4 css={{ mb: '$4', fontWeight: 600 }}>
                        {t('field_idea_strategy_categories')}
                      </Heading4>
                      {idea.strategyCategories?.length && (
                        <Box
                          css={{
                            mb: '$8',
                            d: 'flex',
                            flexDirection: 'row',
                            mx: '-$2'
                          }}
                        >
                          {idea.strategyCategories.map((cat) => (
                            <Box
                              css={{
                                d: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                mx: '$2',
                                border: '1px solid $black',
                                p: '$2',
                                borderRadius: '$4'
                              }}
                            >
                              {strategyCategoriesOptions?.[cat]}
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
                <TextBlock
                  title={t('field_idea_descr_of_problem')}
                  text={idea.problem}
                />
                <TextBlock
                  title={t('field_idea_improve_idea')}
                  text={idea.improvement}
                />
                <TextBlock
                  title={t('field_idea_aim_and_benefits')}
                  text={idea.aimsBenefits}
                />
                <TextBlock
                  title={t('field_idea_people_involved')}
                  text={idea.involved}
                />
              </Box>
            </Grid>

            <Grid>
              <Space
                mt="3XL"
                css={{
                  gc: '2 / span 10',
                  gcSm: '1 / span 12',
                  d: 'flex',
                  flexDirection: 'column',
                  gap: '$4',
                  justifyContent: 'center'
                }}
              >
                {idea?.id &&
                  idea?.status === IdeaStatuses.Implemented &&
                  idea?.feedback && (
                    <BottomSection title={t('field_feedback')}>
                      {idea.feedback}
                    </BottomSection>
                  )}

                {isMeIdeaManager &&
                idea?.status === IdeaStatuses.New &&
                categories ? (
                  <BottomSection title={`${t('field_idea_form_category')}`}>
                    <Box
                      css={{
                        d: 'flex',
                        gap: '$2',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                      }}
                    >
                      {Object.keys(categories)?.map((category: string) => (
                        <Tag
                          key={category}
                          onClick={() => onCategoryTagClick(category)}
                          className={
                            idea?.categories?.includes(category) ? 'active' : ''
                          }
                          css={{ cursor: 'pointer' }}
                        >
                          {categories[category]}
                        </Tag>
                      ))}
                    </Box>
                  </BottomSection>
                ) : (
                  <Box
                    css={{
                      d: 'flex',
                      gap: '$2',
                      alignItems: 'center',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Body5 css={{ mr: '$2' }}>
                      {t('field_news_categories_text')}:
                    </Body5>
                    <Box
                      css={{
                        d: 'flex',
                        gap: '$2',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                      }}
                    >
                      {idea?.categories?.map((category) =>
                        categories?.[category] ? (
                          <Tag key={category}>{categories[category]}</Tag>
                        ) : null
                      )}
                    </Box>
                  </Box>
                )}
                {idea.location && ideaLocationLabel ? (
                  <Box
                    css={{
                      d: 'flex',
                      gap: '$2',
                      alignItems: 'center',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Body5 css={{ mr: '$2' }}>{t('field_country')}:</Body5>
                    <Tag>{ideaLocationLabel}</Tag>
                  </Box>
                ) : null}
              </Space>
            </Grid>
          </Space>
        )}
      </Container>
      {isMeIdeaManager && idea?.status === IdeaStatuses.New && (
        <Box
          css={{
            position: 'fixed',
            bottom: '0',
            width: '100%',
            background: '$neutralLighten20',
            height: '$inlineEditingBottomToolbar',
            left: '0',
            zIndex: 100
          }}
        >
          <Container css={{ height: '100%' }}>
            <ContainerPadding css={{ height: '100%' }}>
              <Box
                css={{
                  d: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: '$4',
                  height: '100%'
                }}
              >
                <Button
                  variant="secondary"
                  onClick={() => navigate(`/${ideasPageUrl}`)}
                >
                  Discard changes
                </Button>
                <Button variant="primary" onClick={() => onSubmitClick()}>
                  Save and close
                </Button>
              </Box>
            </ContainerPadding>
          </Container>
        </Box>
      )}
      {idea?.id && (
        <Box
          css={{
            mb:
              isMeIdeaManager && idea?.status === IdeaStatuses.New
                ? '$inlineEditingBottomToolbar'
                : 0
          }}
          ref={commentsBox}
          id="comments-section"
        >
          <ArticleComments
            articleId={idea.id}
            commentCount={idea.commentCount}
            type={IDEA_TYPE}
            onCommentPosted={() => refresh && refresh()}
          />
        </Box>
      )}
    </>
  )
}
