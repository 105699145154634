import { styled } from '../../../../../../stitches.config'

export const Card = styled('div', {
  color: '$neutral',
  border: '1px $neutralLighten90 solid',
  borderRadius: '$4',
  boxShadow: '$card',
  '&:hover': {
    boxShadow: '$cardHover'
  }
})
