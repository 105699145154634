import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { styled } from '../../../stitches.config'
import { Box, Label } from '../utils'
import {
  RadioGroupItemProps,
  RadioGroupProps
} from '@radix-ui/react-radio-group'

const StyledRadio = styled(RadioGroupPrimitive.Item, {
  all: 'unset',
  width: 24,
  height: 24,
  borderRadius: '100%',
  transition: '$default',
  boxSizing: 'border-box',
  border: '1px $neutralLighten80 solid',
  backgroundColor: '$white',
  '&:hover, &:focus, &.__hover, &.__focus': {
    backgroundColor: '$neutralLighten97',
    borderColor: '$neutralLighten60'
  },
  '&[data-state=checked]:not(:disabled)': {
    backgroundColor: '$primaryBlue',
    borderColor: '$primaryBlue',

    '&:hover, &:focus, &.__hover, &.__focus': {
      backgroundColor: '$primaryBlueDarken30',
      borderColor: '$primaryBlueDarken30'
    }
  },
  '&:focus:not(:disabled), &.__focus:not(:disabled)': {
    outline: '2px $primaryBlueLighten30 solid'
  },
  '&:disabled': {
    cursor: 'not-allowed',
    borderColor: '$neutralLighten80',
    backgroundColor: '$neutralLighten90'
  }
})

const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '$white'
  },
  '&[data-disabled]': {
    '&::after': {
      backgroundColor: '$neutralLighten50'
    }
  }
})
const Group = (args: Omit<RadioGroupProps, 'value'> & { value: any }) => {
  return <RadioGroupPrimitive.Root {...args} />
}
export const RadioGroup = styled(Group, {})
export function RadioItem(
  args: Omit<RadioGroupItemProps, 'value'> & { label?: string; value: any }
) {
  const { label, ...props } = args
  const id = args.id || args.value
  return (
    <Box css={{ display: 'flex', alignItems: 'center' }}>
      <StyledRadio {...props} id={id}>
        <StyledIndicator />
      </StyledRadio>
      {label && (
        <Label htmlFor={id} data-disabled={args.disabled}>
          {label}
        </Label>
      )}
    </Box>
  )
}
