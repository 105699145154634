import { TipTapEditor, TipTapProps } from './components/TipTapEditor'

import { styled } from '../../../../stitches.config'
import {
  Heading2Styles,
  Heading3Styles,
  Heading4Styles,
  Body3Styles,
  TextLinkStyles
} from '@sefar/design-system'

export const ReadingStyles = {
  color: '$neutral',

  '& a': {
    ...TextLinkStyles,
    display: 'inline',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    borderBottom: 'none',

    '&:hover, &.__hover, &:focus, &.__focus, &:active, &.__active': {
      borderBottom: 'none',
      textDecoration: 'underline'
    }
  },

  '& h2': {
    ...Heading2Styles,
    my: '$6'
  },

  '& h3': {
    ...Heading3Styles,
    my: '$5'
  },

  '& h4': {
    ...Heading4Styles,
    my: '$4'
  },

  // selector to get any h2, h3, h4 but not the very first in parent
  '& * + h2, & * + h3, & * + h4': {
    mt: '$12'
  },

  '& * blockquote': {
    mt: '$8'
  },

  '& p, & blockquote, & ul, & ol': {
    mb: '$3'
  },

  '& p': {
    ...Body3Styles,
    ...Body3Styles.variants.fontWeight.light,
    ...Body3Styles.variants.size.xl,
    '& strong': {
      fontWeight: '$semiBold'
    }
  },

  '& strong': {
    fontWeight: '$bold'
  },

  blockquote: {
    pl: '1rem',
    borderLeft: '2px solid $primaryBlue',
    ...Body3Styles,
    ...Body3Styles.variants.fontWeight.light
  },

  '& ul': {
    listStyle: 'none',
    paddingLeft: '2rem',
    ...Body3Styles,
    ...Body3Styles.variants.fontWeight.light,

    '& li': {
      d: 'flex',
      alignItems: 'baseline',

      '&::before': {
        content: '•',
        color: 'black',
        fontSize: '1.5rem',
        position: 'relative',
        top: '2px',
        mr: '0.5rem'
      }
    }
  },

  '& ol': {
    listStyle: 'decimal',
    paddingLeft: '3rem',
    ...Body3Styles,
    ...Body3Styles.variants.fontWeight.light,

    '& li': {
      marginBottom: '0.5rem'
    }
  },

  '& em': {
    fontStyle: 'italic'
  },

  '& img': {
    d: 'block',
    maxWidth: '100%',
    margin: '1rem auto'
  },

  '& figure': {
    d: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& img': {
      marginBottom: 0,
      width: '100%'
    },
    '& img[data-orientation=vertical]': {
      '@md': {
        width: '50%'
      }
    },

    marginBottom: '2rem',
    '@lg': {
      marginBottom: '3rem'
    },
    '@xxl': {
      marginBottom: '3.5rem'
    },

    '& figcaption': {
      marginTop: '$4',
      textAlign: 'center',
      color: '$neutral',
      fontSize: '$body6',
      lineHeight: '$14'
    }
  },

  '&[contenteditable="true"] figcaption': {
    '& .ProseMirror-trailingBreak': {
      content: ' '
    },
    '& .ProseMirror-trailingBreak::after': {
      content: 'Add caption to this image...',
      color: '$neutralLighten50',
      d: 'block'
    }
  },

  '& .iframe-wrapper, & .video-wrapper': {
    position: 'relative',
    paddingBottom: '56.25%',
    overflow: 'hidden',
    width: '100%',
    height: 'auto',
    margin: '2rem auto',
    '@lg': {
      margin: '3rem auto'
    },
    '@xxl': {
      margin: '3.5rem auto'
    },

    '& iframe, & video': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  },
  '& .column': {
    overflow: 'auto',
    borderRadius: '8px',
    padding: '8px',
    margin: '-8px'
  },
  '& .column-block__wrapper, & .image-figure__wrapper': {
    position: 'relative'
  },
  '& .column-block > div': {
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    gap: '24px',
    padding: '8px 0',
    margin: '8px',
    '@media screen and (max-width: 768px)': {
      display: 'block'
    }
  },
  '& .delete-column-block, & .delete-image-figure': {
    display: 'none'
  }
}

export const EditorStyles = {
  ...ReadingStyles,
  '& .draggable-item': {
    position: 'relative'
  },

  '& .drag-handle': {
    position: 'absolute',
    left: '-20px',
    width: '1rem',
    height: '1rem',
    top: 0,
    cursor: 'grab',
    backgroundImage: `url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center'
  },
  '& .column-block__wrapper .drag-handle': {
    position: 'absolute',
    left: '-17px',
    width: '10px',
    height: '90%',
    top: '5%',
    cursor: 'grab',
    backgroundImage: `url('data:image/svg+xml;charset=UTF-8,<svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 15C4 16.1 3.1 17 2 17C0.9 17 0 16.1 0 15C0 13.9 0.9 13 2 13C3.1 13 4 13.9 4 15ZM2 7C0.9 7 0 7.9 0 9C0 10.1 0.9 11 2 11C3.1 11 4 10.1 4 9C4 7.9 3.1 7 2 7ZM2 1C0.9 1 0 1.9 0 3C0 4.1 0.9 5 2 5C3.1 5 4 4.1 4 3C4 1.9 3.1 1 2 1ZM8 5C9.1 5 10 4.1 10 3C10 1.9 9.1 1 8 1C6.9 1 6 1.9 6 3C6 4.1 6.9 5 8 5ZM8 7C6.9 7 6 7.9 6 9C6 10.1 6.9 11 8 11C9.1 11 10 10.1 10 9C10 7.9 9.1 7 8 7ZM8 13C6.9 13 6 13.9 6 15C6 16.1 6.9 17 8 17C9.1 17 10 16.1 10 15C10 13.9 9.1 13 8 13Z" fill="black" fill-opacity="0.2"/></svg>')`,
    backgroundRepeat: 'repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center'
  },
  '& .column': {
    overflow: 'auto',
    border: '1px gray dashed',
    borderRadius: '8px',
    padding: '8px',
    margin: '-8px'
  },
  '& .column-block__wrapper': {
    position: 'relative'
  },
  '& .column-block > div': {
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    gap: '24px',
    padding: '8px 0',
    margin: '8px'
  },
  '& .delete-column-block, & .delete-image-figure': {
    display: 'block'
  }
}

const StyledTipTapWrapper = styled('div', {
  margin: '5rem 0',
  '& .ProseMirror': {
    padding: '0.6rem',
    background: '$white',
    borderRadius: '0 0 5px 5px',
    minHeight: '100px',
    transition: '$default',

    '&:focus': {
      border: 'none',
      outline: 'none'
    },

    '& p.is-editor-empty:first-child:before': {
      color: '$neutralLighten50',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none'
    },

    '& [data-placeholder]:empty:before': {
      color: '$neutralLighten50',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none'
    },
    ...EditorStyles
  }
})

export function TipTap(props: TipTapProps) {
  return (
    <StyledTipTapWrapper>
      <TipTapEditor {...props} />
    </StyledTipTapWrapper>
  )
}
