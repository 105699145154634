import { Node, mergeAttributes } from '@tiptap/core'

export interface VideoOptions {
  controls: boolean
  allowFullscreen: boolean
  HTMLAttributes: {
    [key: string]: any
  }
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    video: {
      setVideo: (options: {
        src: string
        id?: string
        'data-src'?: string
        poster?: string
      }) => ReturnType
    }
  }
}

export default Node.create<VideoOptions>({
  name: 'video',
  group: 'block', // belongs to the 'block' group of extensions
  selectable: true, // so we can select the video
  draggable: true, // so we can drag the video
  atom: true, // is a single unit

  addOptions() {
    return {
      allowFullscreen: true,
      controls: true,
      HTMLAttributes: {
        class: 'video-wrapper'
      }
    }
  },

  addAttributes() {
    return {
      src: {
        default: null
      },
      id: {
        default: null
      },
      'data-src': {
        default: null
      },
      poster: {
        default: null
      },
      frameborder: {
        default: 0
      },
      allowfullscreen: {
        default: this.options.allowFullscreen,
        parseHTML: () => this.options.allowFullscreen
      },
      controls: {
        default: this.options.controls,
        parseHTML: () => this.options.controls
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'video'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, {
        'data-type': 'draggable-item'
      }),
      ['video', HTMLAttributes]
    ]
  },

  addCommands() {
    return {
      setVideo:
        (options: {
          src: string
          id?: string
          'data-src'?: string
          poster?: string
        }) =>
        ({ tr, dispatch }) => {
          const { selection } = tr
          const node = this.type.create(options)

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node)
          }

          return true
        }
    }
  }
})
