import React, { useState } from 'react'
import { styled, keyframes } from '../../../stitches.config'
import { Box } from '../utils'
import { Body7 } from '../typography'
import { HeartIcon14, HeartIconFilled14 } from '../icons'

const bouncy = keyframes({
  'from, to': { transform: 'scale(1, 1)' },
  '25%': { transform: 'scale(0.9, 1.1)' },
  '50%': { transform: 'scale(1.1, 0.9)' },
  '75%': { transform: 'scale(0.95, 1.05)' }
})

const pulse = keyframes({
  '0%': {
    opacity: '0',
    transform: 'scale(0.3)'
  },
  '50%': {
    opacity: '0.5',
    transform: 'scale(1.2)'
  },
  '100%': {
    opacity: '0',
    transform: 'scale(1.5)'
  }
})

const activeStyles = {
  '& .icon-wrapper': {
    '& svg': {
      fill: '$primaryRed',
      path: {
        fill: '$primaryRed'
      },
      '&.icon-simple': {
        display: 'none'
      },
      '&.icon-filled': {
        display: 'block'
      }
    }
  }
}

export const StyledHeart = styled('div', {
  d: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& .like-button': {
    position: 'relative',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    textDecoration: 'none',
    cursor: 'pointer',

    '&:hover': {
      '& svg': {
        fill: '$primaryRed',
        path: {
          fill: '$primaryRed'
        }
      }
    },

    '& .like-overlay': {
      display: 'block',
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      background: '$primaryRed',
      opacity: '0',
      zIndex: '0'
    },

    '& .icon-wrapper': {
      d: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      zIndex: '1',

      '& svg': {
        display: 'block',
        color: '$black',
        transition: '0.3s ease-out',

        path: {
          transition: '0.3s ease-out'
        },

        '&.icon-filled': {
          display: 'none'
        }
      }
    },

    '&.is-active': {
      ...activeStyles
    }
  },

  '&.__hover': {
    '& .like-button': {
      '& .icon-wrapper': {
        '& svg': {
          path: {
            fill: '$primaryRed'
          },
          fill: '$primaryRed'
        }
      }
    }
  },

  '&.__active': {
    '& .like-button': {
      ...activeStyles
    }
  },

  '& .bouncy': {
    animation: `${bouncy} 0.6s`
  },

  '& .pulse': {
    animation: `${pulse} 0.5s ease-out`
  }
})

export const Like = ({
  count,
  onClick,
  isActive,
  customClasses = '',
  ...props
}: {
  count: number
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
  isActive: boolean
  customClasses?: string
}) => {
  const [isAnimated, setIsAnimated] = useState<boolean>(false)

  const clickHandler = (e: React.SyntheticEvent<EventTarget>) => {
    isActive ? setIsAnimated(false) : setIsAnimated(true)
    if (onClick && typeof onClick === 'function') {
      onClick(e)
    }
  }

  return (
    <StyledHeart
      {...props}
      onClick={clickHandler}
      className={isActive ? 'is-active' : ''}
    >
      <Box className={`${isActive ? 'is-active' : ''} like-button`}>
        <Box className="icon-wrapper">
          <HeartIcon14 className="icon icon-simple" />
          <HeartIconFilled14
            className={`icon icon-filled ${isAnimated ? 'bouncy' : ''}`}
          />
        </Box>
        <span className={`like-overlay ${isAnimated ? 'pulse' : ''}`} />
      </Box>
      <Body7 css={{ ml: '-10px' }} className="like-count">
        {count}
      </Body7>
    </StyledHeart>
  )
}
