import { useNavigate, useParams } from 'react-router-dom'
import { Step, Stepper } from 'react-form-stepper'
import {
  Body6,
  Box,
  Button,
  ButtonWithLoading,
  Container,
  ContainerPadding,
  DateTimePicker,
  Heading1,
  Heading4,
  Input,
  RadioGroup,
  RadioItem,
  Space,
  TextArea,
  ToastTypeEnum,
  useToastContext
} from '@sefar/design-system'
import { useEffect, useMemo, useState } from 'react'
import {
  CardsCheckboxGroup,
  CheckboxGroup
} from '../../components/checkbox-group'
import { CardsRadioGroup } from '../../components/cards-radio-group'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  createOrPatchIdea,
  IdeaStatuses,
  useIdeaStrategyCategories
} from '../../api/ideas'
import { useCanNavigateBack } from '../../hooks/use-can-navigate-back'
import { ideasPageUrl } from '../../app'
import { useTranslate } from '../../hooks/useTranslate'
import { getDateWithoutTime } from '../../components/utils'

export type ValidateOptionsForInputs = {
  [a: string]: { value: any; label: string }[]
}
export type ValidateOptionsMap = { [a: string]: { [a: string]: any } }

export function useIdeaValidationOptions() {
  const { t } = useTranslate()
  const { data: strategyCategoriesData } = useIdeaStrategyCategories()
  const strategyCategories = strategyCategoriesData
    ? Object.keys(strategyCategoriesData).map((cat) => [
        cat,
        strategyCategoriesData[cat]
      ])
    : []
  return {
    field_ideas_type: [
      ['new', t('field_idea_type_new')],
      ['improvement', t('field_idea_type_improvement')]
    ],
    field_ideas_expected_savings: [
      ['0', '0'],
      ['1-5000', '1 - 5,000'],
      ['5000-19999', '5,000 - 19,999'],
      ['20000-99999', '20,000 - 99,999'],
      ['100000', '100000+']
    ],
    field_ideas_expected_earnings: [
      ['0', '0'],
      ['1-5000', '1 - 5,000'],
      ['5000-19999', '5,000 - 19,999'],
      ['20000-99999', '20,000 - 99,999'],
      ['100000', '100000+']
    ],
    field_ideas_impact: [
      ['impacts_process', t('field_idea_impacts_process')],
      ['impacts_environment', t('field_idea_impacts_environment')],
      ['impacts_patenting', t('field_idea_impacts_patenting')],
      ['impacts_confidential', t('field_idea_impacts_confidential')]
    ],
    field_ideas_strategy_categories: strategyCategories
  }
}

export const useIdeaValidationInputOptions = () => {
  const options = useIdeaValidationOptions()
  const inputOptions: ValidateOptionsForInputs = {}
  let key: keyof typeof options
  for (key in options) {
    inputOptions[key] = options[key].map((opt) => ({
      label: opt[1],
      value: opt[0]
    }))
  }
  return inputOptions
}

export const useIdeaValidationMapOptions = () => {
  const options = useIdeaValidationOptions()
  const mapOptions: ValidateOptionsMap = {}
  let key: keyof typeof options
  for (key in options) {
    mapOptions[key] = {}
    const optionRef = mapOptions[key]
    for (const opt of options[key]) {
      if (optionRef) {
        optionRef[opt[0]] = opt[1]
      }
    }
  }
  return mapOptions
}

export const IdeaValidate = () => {
  const { ideaId } = useParams()
  const [activeStep, setActiveStep] = useState<number>(0)
  const canNavigateBack = useCanNavigateBack()
  const { t } = useTranslate()
  let ideaValidationSchema = useMemo(() => {
    return yup.object({
      field_ideas_type: yup.string().required(),
      field_ideas_expected_savings: yup.string().when('field_ideas_type', {
        is: (type: string) => type === 'new',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional()
      }),
      field_ideas_expected_increase: yup.string().when('field_ideas_type', {
        is: (type: string) => type === 'improvement',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional()
      }),
      field_ideas_implementation_costs: yup
        .number()
        .required(t('field_idea_costs_required')),
      field_ideas_impact: yup.array().of(yup.string().required()).optional(),
      field_ideas_status: yup.string().required(),
      field_ideas_feedback: yup
        .string()
        .required(t('field_idea_feedback_required')),
      field_date_of_realization: yup.date().when('field_ideas_status', {
        is: (status: IdeaStatuses) =>
          status === IdeaStatuses['Implementation in Progress'],
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.optional()
      }),
      field_ideas_strategy_categories: yup
        .array()
        .of(yup.string().required())
        .optional()
    })
  }, [t])
  const navigate = useNavigate()
  const setToast = useToastContext()
  const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
  const {
    handleSubmit,
    control,
    watch,
    trigger,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      field_ideas_type: 'new',
      field_ideas_implementation_costs: 0,
      field_ideas_expected_savings: '0',
      field_ideas_impact: [],
      field_ideas_status: IdeaStatuses['Implementation in Progress'],
      field_date_of_realization: new Date(),
      field_ideas_strategy_categories: []
    },
    resolver: yupResolver(ideaValidationSchema)
  })
  const onSubmit = handleSubmit((validation) => {
    const { field_ideas_type, field_date_of_realization, ...rest } = validation
    const ideaToSend = {
      ...(field_date_of_realization &&
      field_ideas_status === IdeaStatuses['Implementation in Progress']
        ? {
            field_date_of_realization: getDateWithoutTime(
              field_date_of_realization
            )
          }
        : {}),
      ...rest
    }
    setRequestInProgress(true)
    createOrPatchIdea(ideaToSend, ideaId)
      .then((res) => {
        if (res?.errors?.length) {
          setToast({
            message: t('field_error_common_toast_text'),
            type: ToastTypeEnum.error
          })
        } else {
          setToast({
            message: t('field_idea_validation_success'),
            type: ToastTypeEnum.success
          })
          canNavigateBack ? navigate(-1) : navigate(`/${ideasPageUrl}`)
        }
      })
      .catch(() => {
        setToast({
          message: t('field_error_common_toast_text'),
          type: ToastTypeEnum.error
        })
      })
      .finally(() => {
        setRequestInProgress(false)
      })
  })
  const field_ideas_type = watch('field_ideas_type')
  const field_ideas_status = watch('field_ideas_status')

  const fieldOptions = useIdeaValidationInputOptions()
  const {
    typeOptions,
    savingsOptions,
    earningsOptions,
    impactOptions,
    strategyCategoriesOptions
  } = useMemo(() => {
    return {
      typeOptions: fieldOptions.field_ideas_type,
      savingsOptions: fieldOptions.field_ideas_expected_savings,
      earningsOptions: fieldOptions.field_ideas_expected_earnings,
      impactOptions: fieldOptions.field_ideas_impact,
      strategyCategoriesOptions: fieldOptions.field_ideas_strategy_categories
    }
  }, [fieldOptions, t])

  useEffect(() => {
    if (field_ideas_type === 'improvement') {
      setValue('field_ideas_expected_savings', undefined)
      setValue('field_ideas_expected_increase', '0')
    } else {
      setValue('field_ideas_expected_increase', undefined)
      setValue('field_ideas_expected_savings', '0')
    }
  }, [field_ideas_type])

  return (
    <Container css={{ flexGrow: 1 }}>
      <Box css={{ width: 720, my: '$8' }}>
        <Stepper
          activeStep={activeStep}
          styleConfig={{
            activeBgColor: '#0073BE',
            activeTextColor: '#FFFFFF',
            inactiveBgColor: '#CCD1D6',
            inactiveTextColor: '#FFFFFF',
            completedBgColor: '#0073BE',
            completedTextColor: '#FFFFFF',
            size: 32,
            circleFontSize: 17,
            labelFontSize: 17,
            borderRadius: '50%',
            fontWeight: 400
          }}
          connectorStateColors
          connectorStyleConfig={{
            activeColor: '#0073BE',
            disabledColor: '#CCD1D6',
            completedColor: '#0073BE',
            size: 2,
            style: 'solid'
          }}
        >
          <Step label="Validate" />
          <Step label="Confirm" />
        </Stepper>
      </Box>
      <Box css={{ paddingBottom: 120 }}>
        {activeStep === 0 && (
          <Box>
            <Heading1 css={{ mb: '$4' }}>
              {t('field_idea_validation_title')}
            </Heading1>
            <Box css={{ mb: '$10' }}>
              <Controller
                control={control}
                name="field_ideas_type"
                render={({ field: { onChange, value } }) => (
                  <CardsRadioGroup
                    onChange={onChange}
                    value={value}
                    options={typeOptions}
                  />
                )}
              />
            </Box>

            <Box css={{ mb: '$10' }}>
              <Heading4 css={{ fontWeight: 500, mb: '$4' }}>
                {field_ideas_type === 'new'
                  ? t('field_idea_turnover_title')
                  : t('field_idea_savings_title')}
                *
              </Heading4>
              {field_ideas_type === 'new' && (
                <Controller
                  control={control}
                  name="field_ideas_expected_savings"
                  render={({ field: { onChange, value } }) => (
                    <CardsRadioGroup
                      onChange={onChange}
                      value={value}
                      options={savingsOptions}
                    />
                  )}
                />
              )}
              {field_ideas_type === 'improvement' && (
                <Controller
                  control={control}
                  name="field_ideas_expected_increase"
                  render={({ field: { onChange, value } }) => (
                    <CardsRadioGroup
                      onChange={onChange}
                      value={value}
                      options={earningsOptions}
                    />
                  )}
                />
              )}
            </Box>
            <Heading4 css={{ fontWeight: 500, mb: '$4' }}>
              {t('field_idea_costs_title')}*
            </Heading4>
            <Controller
              control={control}
              name="field_ideas_implementation_costs"
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => (
                <Input
                  id="field_ideas_implementation_costs"
                  placeholder={t('field_idea_costs_title')}
                  defaultValue=""
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                  type="number"
                  containerProps={{ css: { mb: '$8' } }}
                />
              )}
            />
            <Box css={{ mb: '$10' }}>
              <Heading4 css={{ fontWeight: 500, mb: '$4' }}>
                {t('field_idea_strategy_categories')}*
              </Heading4>
              <Controller
                control={control}
                name="field_ideas_strategy_categories"
                render={({ field: { onChange, value } }) => (
                  <CardsCheckboxGroup
                    onChange={onChange}
                    value={value}
                    options={strategyCategoriesOptions}
                  />
                )}
              />
            </Box>
            <Heading4 css={{ fontWeight: 500, mb: '$4' }}>
              {t('field_idea_impact_title')}*
            </Heading4>
            <Controller
              control={control}
              name="field_ideas_impact"
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  onChange={onChange}
                  value={value}
                  options={impactOptions}
                />
              )}
            />
            <Box
              css={{
                position: 'fixed',
                bottom: '0',
                width: '100%',
                background: '$neutralLighten20',
                height: '$inlineEditingBottomToolbar',
                left: '0',
                zIndex: 100
              }}
            >
              <Container css={{ height: '100%' }}>
                <ContainerPadding css={{ height: '100%' }}>
                  <Box
                    css={{
                      d: 'flex',
                      flexDirection: 'row',
                      h: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Button
                      variant="secondary"
                      onClick={() => navigate(-1)}
                      css={{ w: 133 }}
                    >
                      {t('field_back')}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={async () => {
                        const isValid = await trigger([
                          'field_ideas_type',
                          'field_ideas_expected_savings',
                          'field_ideas_expected_increase',
                          'field_ideas_implementation_costs'
                        ])
                        if (isValid) setActiveStep(1)
                      }}
                      css={{ w: 203 }}
                    >
                      {t('field_next')}
                    </Button>
                  </Box>
                </ContainerPadding>
              </Container>
            </Box>
          </Box>
        )}
        {activeStep === 1 && (
          <Box>
            <Heading1 css={{ mb: '$4' }}>
              {t('field_idea_confirm_title')}
            </Heading1>
            <Box css={{ mb: '$10' }}>
              <Heading4 css={{ fontWeight: 500, mb: '$4' }}>
                {t('field_idea_implementer_title')}
              </Heading4>
              <Controller
                control={control}
                name="field_ideas_status"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    value={value}
                    onValueChange={onChange}
                    css={{
                      d: 'flex',
                      flexDirection: 'column',
                      gap: '$4'
                    }}
                  >
                    <RadioItem
                      value={IdeaStatuses['Implementation in Progress']}
                      label={t('field_idea_assess_approved')}
                    />
                    <RadioItem
                      value={IdeaStatuses.Rejected}
                      label={t('field_idea_assess_rejected')}
                    />
                  </RadioGroup>
                )}
              />
            </Box>
            <Box css={{ mb: '$10' }}>
              <Controller
                control={control}
                name="field_ideas_feedback"
                render={({
                  field: { onChange, value },
                  fieldState: { error }
                }) => (
                  <TextArea
                    label={`${t('field_idea_feedback_label')}*`}
                    css={{ width: '100%', minHeight: 150 }}
                    value={value}
                    defaultValue=""
                    error={error?.message}
                    onChange={onChange}
                  />
                )}
              />
            </Box>
            {field_ideas_status ===
              IdeaStatuses['Implementation in Progress'] && (
              <Box css={{ mb: '$4' }}>
                <Space mb="2XS">
                  <Body6 css={{ color: '$neutralLighten30' }}>
                    {t('field_idea_realization_date_name')}
                  </Body6>
                </Space>
                <Controller
                  control={control}
                  name="field_date_of_realization"
                  render={({ field: { onChange, value } }) => (
                    <DateTimePicker
                      id="dateOfRealization"
                      value={value}
                      onChange={onChange}
                      inputStyle={{ width: '100%' }}
                      min={new Date()}
                      dateOnlyMode
                    />
                  )}
                />
              </Box>
            )}
            <Box
              css={{
                position: 'fixed',
                bottom: '0',
                width: '100%',
                background: '$neutralLighten20',
                height: '$inlineEditingBottomToolbar',
                left: '0',
                zIndex: 100
              }}
            >
              <Container css={{ height: '100%' }}>
                <ContainerPadding css={{ height: '100%' }}>
                  <Box
                    css={{
                      d: 'flex',
                      flexDirection: 'row',
                      h: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Button
                      variant="secondary"
                      onClick={() => setActiveStep(0)}
                      css={{ w: 133 }}
                    >
                      {t('field_back')}
                    </Button>
                    <ButtonWithLoading
                      variant="primary"
                      onClick={async () => {
                        const isValid = await trigger(['field_ideas_feedback'])
                        if (isValid) onSubmit()
                      }}
                      css={{ w: 203 }}
                      loading={requestInProgress}
                    >
                      {t('field_confirm')}
                    </ButtonWithLoading>
                  </Box>
                </ContainerPadding>
              </Container>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default IdeaValidate
