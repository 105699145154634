import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import useSWR from 'swr'
import { PURCHASE_API_BASE_URL } from './consts'
import {
  PURCHASE_REQUISITIONS_LIST_PAGE_SIZE,
  PurchaseRequisitionStatusType
} from '../consts'
import {
  PurchaseRequisitionOrder,
  PurchaseRequisitionOrderItem,
  StatusChangeComment
} from '../create/types'
import qs from 'qs'

export const apiClient = axios.create({
  baseURL: PURCHASE_API_BASE_URL
})

export const useDeliveryAddresses = () => {
  const { data, error } = useSWR('lnproducts/warehouse', fetcher)
  return {
    data,
    error
  }
}

const mapLedgerLabels = (obj: { [a: string]: any }) => {
  const labelsWithCode: { [a: string]: any } = {}
  for (const key in obj) {
    labelsWithCode[key] = `${key} - ${obj[key]}`
  }
  return labelsWithCode
}

export const useCostCenters = ({
  project,
  ledgerAccount
}: { ledgerAccount?: string; project?: string } = {}) => {
  const { data, error } = useSWR(
    ledgerAccount
      ? `lnproducts/cost-centers?${qs.stringify({ project, ledgerAccount })}`
      : null,
    fetcher
  )
  return {
    data: mapLedgerLabels(data),
    error
  }
}

export const useProjects = ({
  costCenter,
  ledgerAccount
}: { ledgerAccount?: string; costCenter?: string } = {}) => {
  const { data, error } = useSWR(
    ledgerAccount
      ? `lnproducts/projects?${qs.stringify({ costCenter, ledgerAccount })}`
      : null,
    fetcher
  )
  return {
    data: mapLedgerLabels(data),
    error
  }
}

export const useLedgers = () => {
  const { data, error } = useSWR('lnproducts/ledger-accounts', fetcher)
  return {
    data: mapLedgerLabels(data),
    error
  }
}

export const transformOrderForCreate = (order: PurchaseRequisitionOrder) => {
  const transformedOrderItems = order.purchaseRequisitionItems.map((item) => {
    const transformedItem = omit(item, 'id')
    transformedItem.productId = transformedItem.productId ?? null
    if (transformedItem.requiredDeliveryDate) {
      transformedItem.requiredDeliveryDate = new Date(
        transformedItem.requiredDeliveryDate
      ).toISOString()
    }
    return transformedItem
  })
  return {
    ...order,
    purchaseRequisitionItems: transformedOrderItems
  }
}

export const transformOrderForPatch = (order: PurchaseRequisitionOrder) => {
  const transformedOrderItems = order.purchaseRequisitionItems.map((item) => {
    const clonedItem = cloneDeep(item)
    let transformedItem: Partial<PurchaseRequisitionOrderItem> = clonedItem
    transformedItem = omit(clonedItem, 'id')
    transformedItem.productId = transformedItem.productId ?? null
    if (transformedItem.requiredDeliveryDate) {
      transformedItem.requiredDeliveryDate = new Date(
        transformedItem.requiredDeliveryDate
      ).toISOString()
    }
    return transformedItem
  })
  return {
    ...order,
    purchaseRequisitionItems: transformedOrderItems
  }
}

const fetcher = (url: string) => apiClient.get(url).then((res) => res.data)

export const purchaseApi = {
  createDraftOrderRequest: (order: PurchaseRequisitionOrder) => {
    return apiClient.post(
      '/purchase-requisitions/draft',
      transformOrderForCreate(order)
    )
  },
  getPurchaseRequisitionsList: (
    page: number | null,
    search: string | null,
    status: string | null,
    requester?: string,
    approver?: string
  ) => {
    return apiClient.get('/purchase-requisitions/searchFull', {
      params: {
        page,
        size: PURCHASE_REQUISITIONS_LIST_PAGE_SIZE,
        status,
        requester,
        approver,
        query: search
      }
    })
  },
  changeStatusOrderRequest: (
    id: string,
    status: PurchaseRequisitionStatusType,
    oldComments: StatusChangeComment[] = [],
    comment?: string,
    employeeId?: string
  ) => {
    const payload: {
      status: PurchaseRequisitionStatusType
      comments?: Partial<StatusChangeComment>[]
    } = {
      status
    }
    if (comment && employeeId) {
      payload.comments = [
        ...oldComments,
        {
          text: comment,
          status: status,
          employeeId
        }
      ]
    }
    return apiClient.patch(`/purchase-requisitions/${id}`, payload)
  },
  createOrderRequest: (order: PurchaseRequisitionOrder) => {
    return apiClient.post(
      '/purchase-requisitions',
      transformOrderForCreate(order)
    )
  },
  updateOrderRequest: (order: PurchaseRequisitionOrder) => {
    return apiClient.patch(
      `/purchase-requisitions/${order.id}`,
      transformOrderForPatch(order)
    )
  },
  deletePurchaseRequisition: (id: string) => {
    return apiClient.delete(`/purchase-requisitions/${id}`)
  },
  uploadAttachments: (data: FormData) => {
    return apiClient.post(`/purchase-requisitions/file/upload`, data)
  }
}

export const useLnItemsSearch = ({ q }: { q: string }) => {
  const { data, error } = useSWR(
    q ? `lnproducts/searchFull?q=${q}&page=0&size=50` : null,
    fetcher
  )
  return {
    data,
    error
  }
}

export const useSupplierByProductId = (productId?: string) => {
  const { data, error } = useSWR(
    productId ? `lnproducts/getItemsFromSuppliers/${productId}` : null,
    fetcher
  )
  return {
    data,
    error
  }
}

export const useProductPrice = ({
  itemCode,
  buyFromBP,
  orderQuantity,
  currency,
  orderUOM,
  priceDatetime
}: {
  itemCode?: string
  buyFromBP?: string
  orderQuantity?: number
  currency?: string
  orderUOM?: string
  priceDatetime?: string
}) => {
  const query = qs.stringify({
    itemCode,
    buyFromBP,
    orderQuantity,
    currency,
    orderUOM,
    priceDatetime
  })

  const { data, error } = useSWR(
    itemCode ? `lnproducts/price?${query}` : null,
    fetcher
  )

  return {
    data,
    error,
    loading: itemCode ? !error && !data : false
  }
}

export const usePurchaseRequisition = (id?: string) => {
  const { data, error } = useSWR(
    id ? `/purchase-requisitions/${id}` : null,
    fetcher
  )
  return {
    data,
    error
  }
}

export const usePurchaseRequisitionAttachments = (ids?: string[]) => {
  const query = qs.stringify({ ids }, { arrayFormat: 'repeat' })
  const { data, error } = useSWR(
    ids?.length ? `/purchase-requisitions/files?${query}` : null,
    fetcher
  )
  return {
    data,
    isLoading: query && !data && !error,
    error
  }
}
